import React, { useState, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { BlocksControls } from 'react-tinacms-inline'
import ChangePasswordForm from '../components/forms/ChangePasswordForm'
import defaults from './ChangePassword.defaults'
import fields from './ChangePassword.fields'
import { useMediaQuery } from '../helpers/Hooks'
import '../App.css'
import { Auth } from 'aws-amplify'
import { SessionContext } from '../context/SessionContext'


function ChangePassword ({ index, data }) {
  const history = useHistory()
  const { loadSession, navigateTo } = useContext(SessionContext)
  const isMobile = useMediaQuery('(max-width: 980px)')

  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const [oldpassword, setOldPassword] = useState('')
  const [newpassword, setNewpassword] = useState('')
  const [passwordValidationStatus, setPasswordValidationStatus] = useState({})

  const validatePassword = (value) => {
    setPasswordValidationStatus({
      uppercase: value.match('(?=.*[A-Z])') != null ? true : false,
      lowercase: value.match('(?=.*[a-z])') != null ? true : false,
      special: value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/) != null ? true : false,
      number: value.match('([0-9])') != null ? true : false,
      min: value.length >= 8 ? true : false,
    }) 
  }

  const styles = {
    forgotPasswordWrapper: {
      flexGrow: 1,
      flexShrink: 1,
      display: 'flex',
      padding: isMobile ? '35px 30px' : '75px 30px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f7f8fa'
    },
    featuredImage: {
      width: 650,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${data.background})`
    }
  }

  useEffect(() => {
    const search = history.location.search
    const email = new URLSearchParams(search).get('user')
    const password = new URLSearchParams(search).get('password')
    setEmail(email)
    setOldPassword(password)
  }, [])

  const validate = () => {
    if (email.trim() === '') return { success: false, error: 'Email requerido' }
    if (oldpassword.trim() === '') return { success: false, error: 'Vieja contraseña requerido' }
    if (newpassword.trim() === '') return { success: false, error: 'Nueva contraseña requerido' }
    return { success: true }
  }

  const onSubmit = async () => {
    const isValid = validate()
    if (isValid.success) {
      // AWS Cognito integration here
      try {
        if (navigateTo) {
          await Auth.completeNewPassword(
            navigateTo,
            newpassword
          )
          await loadSession()
          history.push('/')
        } else {
          const user = await Auth.signIn(email, oldpassword);
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            await Auth.completeNewPassword(
              user,
              newpassword
            )
            await loadSession()
            history.push('/')
          } else {
            await loadSession()
            history.push('/')
          }
        }
      } catch (error) {
        console.log(error)
        setError(error.message)
      }
    } else {
      setError(isValid.error)
    }
  }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <div className='page'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Cambiar Contraseña</title>
          <link rel='canonical' href='https://store.l3rn.io/forgot-password' />
        </Helmet>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={styles.forgotPasswordWrapper}>
            <ChangePasswordForm
              title={data.title}
              description={data.description}
              button={data.button}
              signinmessage={data.signinmessage}
              signin={data.signin}
              onSubmit={onSubmit}
              error={error}
              email={email}
              password={newpassword}
              oldpassword={oldpassword}
              onChangeEmail={value => setEmail(value)}
              onChangeOldPassword={value => setOldPassword(value)}
              onChangePassword={(value) => {
                setNewpassword(value);
                validatePassword(value);
              }}
              passwordValidationStatus={passwordValidationStatus}
            />
          </div>
          {isMobile ? <></> : <div style={styles.featuredImage} />}
        </div>
      </div>
    </BlocksControls>
  )
}

export const ChangePasswordTemplate = {
  label: 'ChangePassword',
  key: 'ChangePassword',
  fields: fields,
  defaultItem: defaults
}

export const blockChangePassword = {
  Component: ChangePassword,
  template: ChangePasswordTemplate
}
