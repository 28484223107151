import React, {useState} from 'react';
import { BlocksControls, InlineBlocks } from 'react-tinacms-inline';
import fields from './Associated.fields'
import defaults from './Associated.defaults'
import Section from '../components/Section';
import { associatedItemBlock } from './AssociatedItem';
import styled from 'styled-components'
import chevronRightWhite from '../assets/svgs/chevron-right-white.svg'
import chevronLeftWhite from '../assets/svgs/chevron-left-white.svg'
import { useCMS } from 'tinacms'

function Associated({ index, data }) {
  
  const cms = useCMS();

  const [leftNavigationVisible, setLeftNavigationVisible] = useState(false);
  const [rightNavigationVisible, setRightNavigationVisible] = useState(true);

  const scroll = (orientation) => {
    let slider = document.getElementsByClassName('associated-blocks-container')[0]
    let length = slider.offsetWidth

    let scrollWidth = slider.scrollWidth
    let offsetWidth = slider.offsetWidth

    length = orientation === 'left' ? (length * -1) : length
    slider.scrollLeft += length

    if(cms.enabled){
      setRightNavigationVisible(true)
      setLeftNavigationVisible(true)
    }else{
      setTimeout(() => {
        setRightNavigationVisible( (offsetWidth + slider.scrollLeft) >= scrollWidth ? false : true )
        setLeftNavigationVisible(slider.scrollLeft >= 1 ? true : false )
      }, 500)
    }
  }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
        <Section 
          title={data.title}
          titleStyle={{
            color: data.titleColor
          }}
          containerStyle={{
            backgroundColor: data.backgroundColor
          }}
          headerStyle={{
            justifyContent: 'center'
          }}
        >
          <Container style={{backgroundColor: data.backgroundColor}}>
            <InlineBlocks
              name="associatedList"
              direction="horizontal"
              className={`associated-blocks-container ${cms.enabled ? `tina` : ``}`}
              blocks={ASSOCIATED_BLOCKS}
              itemProps={{
                imageWidth: data.imageWidth,
                imageHeight: data.imageHeight
              }}
            />
            {
              leftNavigationVisible && (
                <LeftNavigationContainer>
                  <LeftNavigation onClick={()=>scroll('left')}>
                    <img style={{width: 14, height: 14}} src={chevronLeftWhite} alt="navigation-icon" />
                  </LeftNavigation>
                </LeftNavigationContainer>
              )
            }
            {
              rightNavigationVisible && (
                <RightNavigationContainer>
                  <RightNavigation onClick={()=>scroll()}>
                    <img style={{width: 14, height: 14}} src={chevronRightWhite} alt="navigation-icon" />
                  </RightNavigation>
                </RightNavigationContainer>
              )
            }
          </Container>
        </Section>
    </BlocksControls>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 10px 100px 70px 100px;
  position: relative;
  z-index: 0;
  & .associated-blocks-container{
    scroll-behavior: smooth;
    overflow-x: hidden;
    display: flex;
    justify-content: flex-start;
  }
  & .associated-blocks-container.tina > div{
    width: 23%;
    margin-right: 2%;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    padding: 20px !important;
  }
  & .associated-blocks-container.tina > div > div:last-child{
    width: 100% !important;
    margin: 0px !important;
    padding: 0px !important;
  }
  @media (max-width:768px){
    padding: 10px 30px 30px 30px;
  }
`

const LeftNavigationContainer = styled.div`
  position: absolute;
  width: 36px;
  height: 73%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 80px;
  top: 0px;
  @media (max-width: 768px){
    left: 24px;
  }
  @media (max-width: 480px){
    left: 8px;
  }
`

const LeftNavigation = styled.div`
  width: 36px;
  height: 50px;
  background-color: #444140;
  display: flex;
  justify-content: center;
  align-items: center;
`

const RightNavigationContainer = styled.div`
  position: absolute;
  height: 73%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  right: 80px;
  top: 0px;
  @media (max-width: 768px){
    right: 24px;
  }
  @media (max-width: 768px){
    right: 8px;
  }
`

const RightNavigation = styled.div`
  background-color: #444140;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
`

const ASSOCIATED_BLOCKS = {
  associatedItem: associatedItemBlock
};


export const AssociatedTemplate = {
  "label": "Associated",
  "key": "associated",
  "fields": fields,
  "defaultItem": defaults
};

export const blockAssociated = {
  Component: Associated,
  template: AssociatedTemplate,
}
