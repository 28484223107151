import React, { useContext } from 'react';
import CartIcon from './CartIcon';
import FavoritesIcon from './FavoritesIcon';
import styled from 'styled-components'
import { useHistory } from 'react-router-dom';
import { SessionContext } from '../context/SessionContext';
import UserMenu from './UserMenu';

function Actions({ hoverEnabled = true }) {
  
  const history = useHistory();

  const { logout } = useContext(SessionContext);

  const onRedirect = (path) => {
    history.push(path)
  }

  return (
    <Container>           
        <FavoritesIcon
          onRedirect={(path)=>onRedirect(path)}
          containerStyle={styles.actionItem}
          hoverEnabled={hoverEnabled} 
        />
        <CartIcon
          onRedirect={(path)=>onRedirect(path)}
          containerStyle={styles.actionItem}
          hoverEnabled={hoverEnabled} 
        />
        <UserMenu
          onRedirect={(path)=>onRedirect(path)}
          onLogout={()=>logout()} 
          containerStyle={styles.actionItem} 
        />  
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  @media (max-width: 980px) {
    margin-left: 8px;
  }
`;

const styles = {
  actionItem: {
    margin: '0px 8px'
  },
  icon: {
    width: 24,
    height: 24
  }
}


export default Actions;