export default [
    {
        "label": "Call to action",
        "name": "cta",
        "component": "text",
        "focusRing": false
    },
    {
        "label": "Show original price",
        "name": "showOriginalPrice",
        "component": "toggle",
        "toggleLabels": false,
        "focusRing": false
    }
]