export default [
    {
      "label": "Title",
      "name": "title",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Subtitle",
      "name": "subtitle",
      "component": "text",
      "focusRing": false
    },
    {
      label: "FAQ's",
      name: "items",
      component: "group-list",
      itemProps: item => ({
        label: item.title,
      }),
      fields: [
        {
          label: "Titulo",
          name: "title",
          component: "text",
          parse(value) {
            return value || ""
          },
        },
        {
          label: "Texto",
          name: "text",
          component: "markdown",
          parse(value) {
            return value || ""
          },
        },
      ],
    },
    {
      "label": "Background Color",
      "name": "backgroundColor",
      "colorFormat": "hex",
      "widget": "sketch",
      "component": "color",
      "focusRing": false
    },
    {
      "label": "Text Color",
      "name": "textColor",
      "colorFormat": "hex",
      "widget": "sketch",
      "component": "color",
      "focusRing": false
    }
  ]