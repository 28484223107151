import { linkBlock } from '../Link/Link';

export default [
    {
      "label": "Logo",
      "name": "image",
      "component": "image",
      "parse": media => {
        return media.previewSrc
      },
      "previewSrc": formValues => {
        return formValues;
      },
      "focusRing": false
    },
    {
        'label': 'Blocks Links',
        'name': 'links',
        'component': 'blocks',
        'templates': {
          'link': linkBlock.template,
        },
    },
]