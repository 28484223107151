export default [
    {
        "label": "Title",
        "name": "title",
        "component": "text",
        "focusRing": false
    },
    {
        label: "Content",
        name: "content",
        component: "markdown",
        parse(value) {
          return value || ""
        },
    },
    {
        "label": "Font Color",
        "name": "fontColor",
        "colorFormat": "hex",
        "widget": "sketch",
        "component": "color",
        "focusRing": false
    },
    {
        "label": "Background Color",
        "name": "backgroundColor",
        "colorFormat": "hex",
        "widget": "sketch",
        "component": "color",
        "focusRing": false
    },
    {
        "label": "Dropdown",
        "name": "dropdown",
        "component": "toggle",
        "description": "Mostrar como dropdown",
        "toggleLabels": false,
        "focusRing": false
    },
    {
        "label": "Title Align",
        "name": "titleAlign",
        "component": "select",
        "options": [ 
          {
            value: "left",
            label: "Left",
          },
          {
            value: "center",
            label: "Center",
          },
          {
            value: "right",
            label: "Rigt",
          },
        ],
        "focusRing": false
    },
    {
        "label": "Text Align",
        "name": "textAlign",
        "component": "select",
        "options": [ 
          {
            value: "left",
            label: "Left",
          },
          {
            value: "center",
            label: "Center",
          },
          {
            value: "right",
            label: "Rigt",
          },
        ],
        "focusRing": false
    }
]