import React, { useContext, useEffect, useState } from 'react'
import { useForm, usePlugin, useCMS } from 'tinacms'
import { InlineForm, InlineBlocks } from 'react-tinacms-inline'
import { PersistentContext } from '../context';
import ContactPage from '../blocks/Contact'
import { blockHero } from '../blocks/Hero';
import Loader from '../components/Loader';
import network from '../utils/network';

export default function PageContact(props) {
  const [forms, setForms] = useState([])
  const [formsRaw, setFormsRaw] = useState([])
  const [loading, setLoading] = useState(true)
  const { id, keys, saveContent, getItemData } = useContext(PersistentContext);

  const cms = useCMS();

  const CONTACT_BLOCKS = {
    blockHero,
  };

  const clearItemData = async () => {
    await saveContent({'contact': null});
    window.location.reload()
  }

  const preload = async () => {
    try {
      const site = keys && keys.id ? keys.id : id;
      const {data} = await network.get(`public/site/${site}/form`)
      if (data && data.data) {
        const temp = data.data.map(item => ({value: item.id, label: item.title}))
        await setFormsRaw(data.data)
        await setForms(temp)
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      await setLoading(false)
    }
  }

  useEffect(() => {
    preload()
  }, [])

  const fields = [
    {
        "label": "Background",
        "name": "background",
        "component": "image",
        "parse": media => {
          return media.previewSrc
        },
        "previewSrc": formValues => {
          return formValues;
        },
        "focusRing": false
    },
    {
      "name": "forms",
      "label": "Formulario",
      "component": "select",
      "options": [
        {value: -1, label: 'Seleccionar'},
        ...forms
      ],
      "focusRing": false
    },
    {
      label: 'Secciones',
      name: 'blocks',
      component: 'blocks',
      templates: {
        'blockHero': blockHero.template,
      }
    }
  ];

  const data = getItemData('contact');

  const setUp = () => {
    if(data.blocks){
      if(data.blocks[0]){
        if(data.blocks[0]._template === "blockContact"){
          clearItemData() 
        }
      }
    }
    
    const formConfig = {
      id: 'templateContact',
      fields,
      onSubmit: async (data) => {
        await saveContent({'contact': data});
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data,
    }
    return formConfig;
  }
  
  const formConfig = setUp();
  const [modifiedValues, form] = useForm(formConfig, {
    fields,
    values: data,
    label: 'Contacto',
  });
  usePlugin(form)

  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`} style={{minHeight: 'calc(100vh - 225px)'}}>
      <InlineForm form={form}>
        <ContactPage data={modifiedValues} forms={formsRaw}/>
        <InlineBlocks name="blocks" blocks={CONTACT_BLOCKS} />
      </InlineForm>
    </div>
  )
}