import React, { useState } from 'react';
import '../../../App.css';
import { useMediaQuery } from '../../../helpers/Hooks'

import masked from '../../../assets/svgs/masked.svg'
import unmasked from '../../../assets/svgs/unmasked.svg'

function Password(props) {

  const [visible, setVisible] = useState(false)

  const isMobile = useMediaQuery('(max-width: 480px)');
  const isSmallDevice = useMediaQuery('(max-width: 360px)');

  const styles = {
    field: {
      marginBottom: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    head: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    action: {
      fontWeight: '400',
      fontSize: 13,
      fontFamily: 'Muli',
      color: '#9ea2b4',
      textDecoration: 'underline'
    },
    label: {
      fontWeight: '600',
      fontSize: 14,
      fontFamily: 'Muli',
      color: '#8891a6',
      marginBottom: 6
    },
    validation: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 20
    },
    validationItem: {
      width: isMobile ? '100%' : '50%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: '3px 0px'
    },
    validationDot: {
      height: 10,
      width: 10,
      borderRadius: 5,
      background: '#ddd',
      transition: 'background 0.2s'
    },
    validationDotActive: {
      background: '#09C269'
    },
    validationText: {
      marginLeft: 8,
      fontWeight: '400',
      fontSize: 13,
      fontFamily: 'Muli',
      color: 'rgb(136, 145, 166)',
      textAlign: 'left'
    },
    mask: {
      position: 'absolute',
      width: 24,
      height: 24,
      right: 14,
      top: 34.5
    }
  }

  const _keyDownHandler = (e) => { 
    e.key === 'Enter' && e.preventDefault();
    return e.key === 'Enter' && props.onEnter ? props.onEnter() : null
  }

  return (
    <div style={{...styles.field, ...props.containerStyle}}>
        <div style={styles.head}>
            {
              (props.labelType === 'label' || !props.labelType) && (
                <div style={styles.label}>{props.label ? props.label : 'Contraseña'}</div>
              )
            }
            { (props.forgotPassword && !isSmallDevice) ? <div style={styles.action} className="touchable-opacity" onClick={()=>props.onForgotPasswordClick()}>Olvide mi contraseña</div> : <></> }
        </div>
        <input
            type={props.masking ? visible ? "text" : "password" : "password"} 
            className="password-input"
            style={{...props.inputStyles}}
            value={props.value}
            onKeyDown={_keyDownHandler}
            onChange={(e)=>props.onChange(e.target.value)} 
            placeholder={props.labelType === 'placeholder' ? props.label ? props.label : "Contraseña" : ""}
        />
        {
          props.masking && (
            <img 
              alt="password-mask"
              className="touchable-opacity"
              style={styles.mask} 
              onClick={()=>setVisible(!visible)} 
              src={visible ? unmasked : masked} 
            />
          )
        }
        { (props.forgotPassword && isSmallDevice) ? <div style={styles.action} className="touchable-opacity" onClick={()=>props.onForgotPasswordClick()}>Olvide mi contraseña</div> : <></> }
        {
          props.validationStatus && (
            <div style={styles.validation}>
              <div style={styles.validationItem}>
                <div style={{...styles.validationDot, ...(props.validationStatus.lowercase ? styles.validationDotActive : null) }}/>
                <div style={styles.validationText}>Un carácter en minúscula</div>
              </div>
              <div style={styles.validationItem}>
                <div style={{...styles.validationDot, ...(props.validationStatus.uppercase ? styles.validationDotActive : null)}}/>
                <div style={styles.validationText}>Un carácter en mayúscula</div>
              </div>
              <div style={styles.validationItem}>
                <div style={{...styles.validationDot, ...(props.validationStatus.special ? styles.validationDotActive : null)}}/>
                <div style={styles.validationText}>Un carácter especial</div>
              </div>
              <div style={styles.validationItem}>
                <div style={{...styles.validationDot, ...(props.validationStatus.number ? styles.validationDotActive : null)}}/>
                <div style={styles.validationText}>Un número</div>
              </div>
              <div style={styles.validationItem}>
                <div style={{...styles.validationDot, ...(props.validationStatus.min ? styles.validationDotActive : null)}}/>
                <div style={styles.validationText}>8 caracteres como mínimo</div>
              </div>
            </div>
          )
        }
    </div>
  );
}

export default Password;