import React, { useState, useContext } from 'react'
import { Helmet } from "react-helmet"
import { InlineTextarea } from 'react-tinacms-inline'
import {loadStripe} from '@stripe/stripe-js';
import TouchableOpacity from '../components/TouchableOpacity'
import CategoryLabel from '../components/CategoryLabel'
import SubmitButton from '../components/buttons/SubmitButton'
import trash from '../assets/svgs/trash.svg'
import chevronLeft from '../assets/svgs/chevron-left.svg'
import { useMediaQuery } from '../helpers/Hooks'
import network from '../utils/network';
import { ShopContext } from '../context/ShopContext'
import { PersistentContext } from '../context';
import { SessionContext } from '../context/SessionContext';
import { formatDuration, formatPrice } from '../helpers/StatisticalFunctions';
import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack'
import { makeToast } from '../utils'
import '../App.css';

function Cart({ data }) {
    const { enqueueSnackbar } = useSnackbar()
    const [stripeLoading, setStripeLoading] = useState(false);

    const isSmallDevice = useMediaQuery('(max-width: 480px)')
    const isMobile = useMediaQuery('(max-width: 768px)')
    const isLargeDevice = useMediaQuery('(max-width: 980px)')

    const { cart = [], removeProductFromCart, isProductLoading, isAnyProductLoading, loadingCart } = useContext(ShopContext);
    const { production } = useContext(PersistentContext);
    const { isAuthenticated } = useContext(SessionContext);

  const styles = {
    container: {
        paddingTop: 72,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    title: {
        fontFamily: 'Muli',
        fontSize: isSmallDevice ? 24 : 34,
        fontWeight: '800',
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    backButtonContainer: {
        marginRight: 12,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 42,
        width: 42
    },
    trashIcon: {
        width: 19,
        height: 19
    },
    table: {
        width: '100%',
        marginTop: 40
    },
    head: {
        borderBottom: '#eaeaea 1.5px solid'
    },
    header: {
        textTransform: 'uppercase',
        color: '#99a4bc',
        fontFamily: 'Muli',
        fontWeight: '800',
        textAlign: 'left',
        padding: '8px 0px'
    },
    priceHeader: {
        textAlign: 'right'
    },
    data: {
        verticalAlign: 'middle',
        padding: '22px 6px'
    },
    item: {
        borderBottom: '2px solid rgb(234, 234, 234)'
    },
    itemPreviewImage: {
        width: isLargeDevice ? 56 : 180,
        height: isLargeDevice ? 100 : 110,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: isLargeDevice ? 'contain' : 'cover'
    },
    itemTitle: {
        fontSize: 19,
        fontFamily: 'Muli',
        fontWeight: '800',
        color: '#313540',
        textAlign: 'left'
    },
    itemDescription: {
        color: 'rgb(153, 164, 188)',
        fontFamily: 'Muli',
        fontSize: 13,
        paddingTop: 4,
        textAlign: 'left',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: '4',
        WebkitBoxOrient: 'vertical'
    },
    itemActions: {
        width: 56
    },
    itemCategory: {
        display: 'inherit',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    itemDuration: {
        color: 'rgb(153, 164, 188)',
        fontFamily: 'Muli',
        fontWeight: '800',
        textAlign: 'left',
        fontSize: 14,
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    itemPrice: {
        color: '#313540',
        fontWeight: '800',
        fontFamily: 'Muli',
        textAlign: 'right',
        fontSize: 14,
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    promoCode: {
        paddingTop: isMobile ? 10 : 30
    },
    checkout: {
        width: '100%',
        marginTop: 50,
        backgroundColor: '#f0f2f5',
        boxShadow: '0px -8px 12px #eaeaea'
    },
    checkoutContent: {
        height: 88,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    checkoutLabel: {
        fontFamily: 'Muli',
        fontSize: 16,
        marginRight: 30,
        fontWeight: '800'
    },
    list: {
        borderTop: '2px solid rgb(234, 234, 234)',
        marginTop: 24,
        listStyle: 'none'
    },
    mobileItemContainer: {
        display: 'flex',
        borderBottom: '2px solid rgb(234, 234, 234)',
        padding: '14px 0px'
    },
    mobileItemAction: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 6
    },
    mobileItemImage: {
        padding: '0px 6px'
    },
    mobileItemDescription: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingLeft: 6
    },
    mobileItemPreviewImage: {
        width: isSmallDevice ? 25 : 160,
        height: isSmallDevice ? '100%' : 100,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: isSmallDevice ? 'contain' : 'cover'
    },
    mobileTitle: {
        fontFamily: 'Muli',
        fontSize: 19,
        fontWeight: '800',
        color: '#313540',
        textAlign: 'left'
    },
    mobileDuration: {
        color: 'rgb(153, 164, 188)',
        fontWeight: '800',
        fontFamily: 'Muli',
        textAlign: 'left',
        fontSize: 16,
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    mobilePrice: {
        color: '#313540',
        fontWeight: '800',
        textAlign: 'right',
        fontFamily: 'Muli',
        fontSize: 22,
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    emptyState: {
        width: '100%',
        height: 300,
        background: 'rgb(248, 248, 250)',
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 800,
        fontFamily: 'Muli',
        color: 'rgb(158, 162, 180)',
        alignItems: 'center'
    }
  };

  const total = () => {
      let res = 0
      cart.forEach(element => {
        res += element.price ? element.price.unit_amount : 0
      });
      return formatPrice(res, cart[0].price.currency)
  }

  const goToStripeCheckout = async () => {
    if(!production){
        makeToast(enqueueSnackbar, 'Checkout deshabilitado en vista previa', 'info')
        return;
    }
    setStripeLoading(true);
    // Move this key to SITE_DATA?
    const stripe = await loadStripe('pk_test_51Hm4WFDsVvlWtoAQemSKV496y7frfX4CRAFfjK6JMmDa5tqk39azU08pdsPIn2GhUfX4l7KHLYE48nwp6ryySgSZ00FTatqiIU');
    const redirectPath = window.location.pathname
    const res = await network.post(`${!isAuthenticated ? `public/` : ``}site/${window.SITE_DATA.id}/stripe/session`, { 
        prevUrl:  redirectPath,
        ...(!isAuthenticated && { courses: cart.map(item => {return {courseId: item.courseId}}) }) 
    })
    const sessionId = res.data.id;
    await stripe.redirectToCheckout({ sessionId });
    setStripeLoading(false);
  }

  return (
    <>
      <div className="page">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Carrito de Compras</title>
          <link rel="canonical" href="https://store.l3rn.io/cart" />
        </Helmet>
        <div style={styles.container}>
            
            <div className="general-padding" style={styles.title}>
                {
                    isMobile ? 
                        <TouchableOpacity containerStyle={styles.backButtonContainer} onPress={()=>window.history.back()}>
                            <img style={{ width: 24, height: 24 }} src={chevronLeft} alt="back-icon" />
                        </TouchableOpacity>
                    :
                        null
                }
                <InlineTextarea name="title" focusRing={false} />
            </div>

            <div style={{ minHeight: '60vh'}} className="general-padding">
                {
                    loadingCart ? (
                        <div style={{height: 'calc(100vh - 350px)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <CircularProgress style={{ color: '#00A8D8' }}/>
                        </div>
                    ) :
                    cart.length <= 0 ? (
                        <div style={styles.emptyState}>Aún no has agregado productos al carrito</div>
                    ) :
                    isMobile ?
                        <ul style={styles.list}>
                            {
                                cart.map((element, index) => {
                                    return (
                                        <li key={index}>
                                            <div style={styles.mobileItemContainer}>
                                                <div style={styles.mobileItemAction}>
                                                    {
                                                        isProductLoading(element.courseId) ?
                                                            <CircularProgress style={{color: '#00A8D8', width:24,height:24}}/>
                                                        :
                                                            <TouchableOpacity onPress={()=>removeProductFromCart(element.courseId)}>
                                                                <img style={styles.trashIcon} alt="trash" src={trash} />
                                                            </TouchableOpacity>
                                                    }
                                                </div>
                                                <div style={styles.mobileItemImage}>
                                                    <div 
                                                        style={{...styles.mobileItemPreviewImage, backgroundImage: `url(${element.background})`}} 
                                                    />
                                                </div>
                                                <div style={styles.mobileItemDescription}>
                                                    <div style={styles.mobileTitle}>{element.title}</div>
                                                    <div style={styles.mobileDuration}>{formatDuration(element.expected_duration)}</div>
                                                    <div style={styles.mobilePrice}>{element.price ? formatPrice(element.price.unit_amount, element.price.currency) : null}</div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    :
                    <table style={styles.table}>
                        <thead>
                            <tr style={styles.head}>
                                <th/>
                                <th/>
                                <th style={styles.header}>Curso</th>
                                <th style={styles.header}>Categoría</th>
                                <th style={styles.header}>Duración</th>
                                <th style={styles.header}>
                                    <div style={styles.priceHeader}>Precio</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                cart.map((element, index) => {
                                    return (
                                        <tr key={index} style={styles.item}>
                                            <td style={{...styles.data, width: 56}}>
                                                <div style={styles.itemActions}>
                                                    {
                                                        isProductLoading(element.courseId) ?
                                                            <CircularProgress style={{color: '#00A8D8',width:24,height:24}}/>
                                                        :
                                                            <TouchableOpacity onPress={()=>removeProductFromCart(element.courseId)}>
                                                                <img style={styles.trashIcon} alt="trash" src={trash} />
                                                            </TouchableOpacity>
                                                    }
                                                </div>
                                            </td>
                                            <td style={styles.data}>
                                                <div style={{...styles.itemPreviewImage, backgroundImage: `url(${element.background})`}} />
                                            </td>
                                            <td style={styles.data}>
                                                <div style={{ maxWidth: 360 }}>
                                                    <div style={styles.itemTitle}>{element.title}</div>
                                                    <div style={styles.itemDescription}>{element.description}</div>
                                                </div>
                                            </td>
                                            <td style={styles.data}>
                                                <CategoryLabel 
                                                    styles={styles.itemCategory} 
                                                    name={element.categoryName} 
                                                />
                                            </td>
                                            <td style={styles.data}>
                                                <div style={styles.itemDuration}>{formatDuration(element.expected_duration)}</div>
                                            </td>
                                            <td style={styles.data}>
                                                <div style={styles.itemPrice}>{element.price ? formatPrice(element.price.unit_amount, element.price.currency) : null}</div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
            
                }
            </div>
            
            {
                cart.length > 0 && (
                    <div style={styles.checkout}>
                        <div style={styles.checkoutContent} className="general-padding">
                            <div style={styles.checkoutLabel}>{data.checkout}</div>
                            <SubmitButton
                                onPress={goToStripeCheckout}
                                loading={stripeLoading || isAnyProductLoading()}
                                styles={{padding: '0px 46px', height: 46, width: 'initial'}}
                                label={`Total: ${total()}`}
                            /> 
                        </div>
                    </div>
                )
            }

        </div>
      </div>
    </>
  );
};

export default Cart;