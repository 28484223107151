export default [
    {
      "label": "Profile Picture",
      "name": "image",
      "component": "image",
      "parse": media => {
        return media.previewSrc
      },
      "previewSrc": formValues => {
        return formValues;
      },
      "focusRing": false
    },
    {
      "label": "Author",
      "name": "author",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Testimonial",
      "name": "testimonial",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Author's Location",
      "name": "location",
      "component": "text",
      "focusRing": false
    },
]