import React from 'react';
import { InlineTextarea, BlocksControls } from 'react-tinacms-inline';
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components'
import MainButton from '../../components/buttons/MainButton'

function TextImage({ index, data }) {

  const isFlipped = () => {
    return data.order === 2 ? true : false
  }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <Container style={{flexDirection: isFlipped() ? 'row-reverse' : 'row', ...(data.background && {background: 'linear-gradient(180deg, #f5f5f2 80%, transparent 20%)'}) }}>
        <Image 
          style={{
            margin: isFlipped() ? '0px 36px 0px 0px' : '0px 0px 0px 36px',
            width: isFlipped() ? 638 : 560,
            height: isFlipped() ? 378 : 690, 
            backgroundImage: `url(${data.image})`
          }}
        />
        <Content style={{ margin: isFlipped() ? '0px 100px 0px 0px' : '0px 0px 0px 100px'}}>
          <Subtitle>
            <InlineTextarea name="subtitle" focusRing={false} />
          </Subtitle>
          <Title>
            <InlineTextarea name="title" focusRing={false} />
          </Title>
          <Description>
            <InlineTextarea name="description" focusRing={false} />
          </Description>
          <MainButton styles={{marginTop: 30}} label={data.button} />
        </Content>
      </Container>
    </BlocksControls>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 70px 100px;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  @media (max-width: 768px) {
    padding: 36px 30px 140px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: flex-start; 
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
`

const Image = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 24px;
`

const Title = styled.div`
  color: #01214d;
  font-size: 50px;
  line-height: 62px;
  font-weight: 800;
  font-family: Arial;
  text-align: left;
`

const Subtitle = styled.div`
  color: #f58e68;
  font-size: 16px;
  font-weight: 700;
  font-family: Arial;
  text-align: left;
`

const Description = styled.div`
  width: 100%;
  margin-top: 12px;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  font-family: Arial;
  line-height: 28px;
  color: #9c9e9f;
`

export const TextImageTemplate = {
  "label": "Text Image",
  "key": "textImage",
  "fields": fields,
  "defaultItem": defaults
};

export const blockTextImage = {
  Component: TextImage,
  template: TextImageTemplate,
}