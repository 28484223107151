import React from 'react';
import { BlocksControls } from 'react-tinacms-inline';
import { useHistory } from 'react-router-dom';

import youtube from '../../assets/svgs/youtube.svg';
import whatsapp from '../../assets/svgs/whatsapp.svg';
import instagram from '../../assets/svgs/instagram.svg';

function Social({ index, data, socialsIconColor }) {

  const history = useHistory();

  function getIcon(platform) {
    return {
      'youtube': youtube,
      'whatsapp': whatsapp,
      'instagram': instagram
    }[platform];
  }
  return (
    <div key={index}>
      <BlocksControls index={index}>
        <div
          onClick={e => history.push(data.link)}
          style={{...containerStyle, color: socialsIconColor}}
        >
          <img alt="social-icon" style={{height: 24}} src={getIcon(data.platform)} />
        </div>
      </BlocksControls>
    </div>
  );
}

const containerStyle = {
  padding: '0px 10px'
}

export const socialBlock = {
  Component: Social,
  template: {
    name: "social",
    key: "platform",
    label: "Social Item",
    component: "group-list",
    defaultItem: {
        "platform": "instagram",
        "link": "#"
    },
    fields: [
      {
        "label": "Platform",
        "name": "platform",
        "component": "select",
        "options": [ 
          {
            value: "youtube",
            label: "Youtube",
          },
          {
            value: "whatsapp",
            label: "Whatsapp",
          },
          {
            value: "instagram",
            label: "Instagram",
          },
        ],
        "focusRing": false
      },
      {
        "label": "Link",
        "name": "link",
        "component": "text",
        "focusRing": false
      }
    ],
  },
};