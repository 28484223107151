import React, { createContext, useState, useEffect, useContext } from 'react'
import { getCart, addProduct, removeProduct } from '../helpers/cart'
import { PersistentContext } from '../context'
import { SessionContext } from '../context'
import { useSnackbar } from 'notistack'
import { makeToast } from '../utils'

export const ShopContext = createContext()

const ShopContextProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { keys, id, production } = useContext(PersistentContext)
  const { isAuthenticated, isSessionLoaded } = useContext(SessionContext)

  const [cart, setCart] = useState([])
  const [loadingCart, setLoadingCart] = useState(false)
  const [loadingProduct, setLoadingProduct] = useState(null)
  const [local, setLocal] = useState(false)

  const fetchCart = async () => {
    setLoadingCart(true)
    const siteId = production ? keys.id : id
    const { data } = await getCart(siteId)
    setCart(data)
    setLoadingCart(false)
  }

  const fetchLocalCart = () => {
    const localStorageCart = localStorage.getItem('cart')
    localStorageCart ? setCart(JSON.parse(localStorageCart)) : saveLocalCart([])
  }

  const uploadLocalCart = async () => {
    const localStorageCart = localStorage.getItem('cart') 
    if(localStorageCart) {
      let parsed = JSON.parse(localStorageCart)
      if(Array.isArray(parsed)){
        if(parsed.length > 0){
          parsed.forEach(element => {
            addProductToCart(element)
          })
          localStorage.removeItem('cart')
        }
      } 
    }
  }

  const cleanLocalCart = async () => {
    const localStorageCart = localStorage.getItem('cart') 
    if(localStorageCart) {
      localStorage.removeItem('cart') 
    }
  }

  const addProductToCart = async (product) => {
    setLoadingProduct(product.id)
    if (local) {
      const updatedCart = [...cart]
      const updatedItemIndex = updatedCart.findIndex(
        item => item.id === product.id
      )
      if (updatedItemIndex < 0) {
        updatedCart.push({ ...product, quantity: 1, courseId: product.id })
        setCart(updatedCart)
        saveLocalCart(updatedCart)
      } else {
        makeToast(enqueueSnackbar, 'Ya agregaste este producto', 'warning')
        return
      }
    } else {
      const siteId = production ? keys.id : id
      await addProduct(siteId, { courseId: product.id })
      await fetchCart()
    }
    setLoadingProduct(null)
    makeToast(enqueueSnackbar, 'Producto agregado al carrito')
  }

  const removeProductFromCart = async productId => {
    setLoadingProduct(productId)
    if (local) {
      const updatedCart = [...cart]
      const updatedItemIndex = updatedCart.findIndex(
        item => item.id === productId
      )
      const updatedItem = { ...updatedCart[updatedItemIndex] }
      updatedItem.quantity--
      if (updatedItem.quantity <= 0) {
        updatedCart.splice(updatedItemIndex, 1)
        setCart(updatedCart)
        saveLocalCart(updatedCart)
      }
    } else {
      const siteId = production ? keys.id : id
      await removeProduct(siteId, productId)
      await fetchCart()
    }
    setLoadingProduct(null)
    makeToast(enqueueSnackbar, 'Producto eliminado del carrito', 'warning')
  }

  const isAnyProductLoading = () => {
    return loadingProduct !== null
  }

  const isProductLoading = productId => {
    return loadingProduct === productId
  }

  const isProductInCart = productId => {
    return cart
      ? cart.find(cartItem => cartItem.courseId === productId)
        ? true
        : false
      : false
  }

  const saveLocalCart = updatedCart => {
    localStorage.setItem(
      'cart',
      JSON.stringify(updatedCart.length > 0 ? updatedCart : [])
    )
  }

  useEffect(() => {
    if(isSessionLoaded){
      if(isAuthenticated){
        setLocal(false)
        fetchCart()
      } else {
        setLocal(true)
        fetchLocalCart()
      }
    }
  }, [isAuthenticated, isSessionLoaded])

  return (
    <ShopContext.Provider
      value={{
        cart,
        local,
        loadingCart,
        addProductToCart,
        removeProductFromCart,
        isProductInCart,
        isProductLoading,
        isAnyProductLoading,
        uploadLocalCart,
        cleanLocalCart
      }}
    >
      {children}
    </ShopContext.Provider>
  )
}

export default ShopContextProvider
