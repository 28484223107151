import React, { useContext } from 'react'
import { useForm, usePlugin, useCMS } from 'tinacms'
import { InlineForm } from 'react-tinacms-inline'
import CartPage from '../blocks/Cart';
import { PersistentContext } from '../context';

export default function PageCart(props) {
  const { saveContent, getItemData } = useContext(PersistentContext);

  const cms = useCMS();

  const clearItemData = async () => {
    await saveContent({'cart': null});
    window.location.reload()
  }

  const setUp = () => {
    const data = getItemData('cart');
    if(data.blocks){
      if(data.blocks[0]){
        if(data.blocks[0]._template === "blockCart"){
          clearItemData() 
        }
      }
    }
    const formConfig = {
      id: 'templateCart',
      fields: [
        {
          "label": "Title",
          "name": "title",
          "component": "text",
          "focusRing": false
        },
        {
          "label": "Checkout",
          "name": "checkout",
          "component": "text",
          "focusRing": false
        }
      ],
      onSubmit: async (data) => {
        await saveContent({'cart': data});
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data,
    }
    return formConfig;
  }
  const formConfig = setUp();
  const [modifiedValues, form] = useForm(formConfig);
  usePlugin(form)

  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`}>
      <InlineForm form={form}>
        <CartPage data={modifiedValues}/>
      </InlineForm>
    </div>
  )
}