import React from 'react';
import { Helmet } from "react-helmet";
import { InlineTextarea } from 'react-tinacms-inline';
import { useMediaQuery } from '../helpers/Hooks';
import MainButton from '../components/buttons/MainButton'

function NotFound({ index, data }) {

    const isMobile = useMediaQuery('(max-width: 768px)');

    const styles = {
        container: {
            display: 'flex', 
            flexDirection: 'column',
            width: '100%',
            minHeight: '90vh',
            backgroundSize: 'cover',
            backgroundPosition: isMobile ? '0%' : 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${data.background})`,
            padding: '0px 30px'
        },
        bigTitle: {
            fontSize: isMobile ? 124 : 180,
            color: '#0067ff',
            fontWeight: '800',
            marginTop: isMobile ? 50 : 0,
            fontFamily: 'Muli'
        },
        title: {
            fontWeight: '800',
            fontSize: 32,
            fontFamily: 'Muli',
            color: '#434348',
            marginBottom: 12
        },
        subtitle: {
            fontSize: 20,
            fontFamily: 'Muli',
            fontWeight: '600',
            color: '#969eb3'
        }
    }

  return (
    <div className="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 No Encontrado</title>
        <link rel="canonical" href="https://store.l3rn.io/404" />
      </Helmet>
      <div style={styles.container}>
          <div style={styles.bigTitle}>
              <InlineTextarea name="main" focusRing={false} />
          </div>
          <div style={styles.title}>
              <InlineTextarea name="title" focusRing={false} />
          </div>
          <div style={styles.subtitle}>
              <InlineTextarea name="subtitle" focusRing={false} />
          </div>
          <div style={styles.subtitle}>
              <InlineTextarea name="cta" focusRing={false} />
          </div>
          <div style={{ marginTop: 40, marginBottom: 85 }}>
              <MainButton 
                  onClick={() => alert('Submit')} 
                  type="primary"
                  styles={{ padding: isMobile ? '15px 30px' : '6px 30px' }}
                  label={data.button} 
              />
          </div>
      </div>
    </div>
  );
};

export default NotFound;