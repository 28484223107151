import React, { useEffect, useContext, useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { Skeleton } from '@material-ui/lab'
// import { CheckCircle } from '@material-ui/icons'
import { CircularProgress } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useParams, useHistory } from 'react-router-dom'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
// import CheckBoxTwoToneIcon from '@material-ui/icons/CheckBoxTwoTone';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { PersistentContext, CourseContext, SessionContext } from '../context'
// import { percentage, formatQuantity } from '../helpers/StatisticalFunctions'
import MainButton from '../components/buttons/MainButton'
import CategoryLabel from '../components/CategoryLabel'
import VideoPlayer from '../components/VideoPlayer'
import CourseStat from '../components/CourseStat'
import { useMediaQuery } from '../helpers/Hooks'
import Comments from '../components/Comments'
import Section from '../components/Section'
import network from '../utils/network'
import '../App.css'

const courseDefault = {
  category: {
    name: 'E-Commerce'
  },
  statistics: {
    users: 100,
    likes: 100
  },
  attachments: [],
  defaultContent: {
    id: 1,
    media: 'course-1.webp',
    title: 'Tus primeras ventas',
    content: {
      description:
        '<p>Praesent Tincidunt Tempus Tincidunt. Suspendisse Potenti. In Tincidunt Leo Elit, Sit Amet Malesuada Justo Posuere Eget. Maecenas Tincidunt Ultrices Urna, A Condimentum Mauris Efficitur Tincidunt. Sed Non Posuere Purus. Fusce Interdum Magna A Ipsum Mattis Ultrices. Integer Auctor Molestie Nibh, Sit Amet Tincidunt. Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Vestibulum In Rhoncus Libero. Phasellus Condimentum Nulla Turpis, Quis Luctus Diam Fringilla Vitae. Duis Non Pretium Ante. In Sit Amet Imperdiet Velit, Iaculis Efficitur Nunc. Nulla Vitae Risus In Nibh Viverra Tempor. Nulla Id Est Ultricies, Consequat Sem Ac, Viverra Tellu.<p>'
    },
    sales: 92030,
    likes: 4300,
    expected_duration: '1 hr 30 min',
    discount: '30% Menos $39.99',
    price: 19.99,
    url: 'http://v2v.cc/~j/theora_testsuite/320x240.ogg'
  },
  defaultModule: {
    id: 1
  },
  userContent: {
    minutes: 0
  },
  defaultData: {},
  modules: [
    {
      id: 1,
      title: 'Bienvenido al curso',
      media: 'module-1.webp',
      content: [
        {
          id: 1,
          title: 'Bienvenido al curso',
          media: 'module-1.webp'
        }
      ]
    },
    {
      id: 2,
      title: 'Acerca de tu instructor',
      media: 'module-2.webp',
      content: [
        {
          id: 2,
          title: 'Acerca de tu instructor',
          media: 'module-2.webp'
        }
      ]
    },
    {
      id: 3,
      title: 'Objetivos y metas',
      media: 'module-3.webp',
      content: [
        {
          id: 3,
          title: 'Objetivos y metas',
          media: 'module-3.webp'
        }
      ]
    },
    {
      id: 4,
      title: 'Introducción',
      media: 'module-4.webp',
      content: [
        {
          id: 4,
          title: 'Introducción',
          media: 'module-4.webp'
        }
      ]
    }
  ],
  instructor: {
    id: 1,
    name: 'John Doe',
    job: 'Instructor',
    picture: 'profile.webp',
    biography:
      'Donec massa elit, porta et nibh quis, mattis faucibus turpis. Nam nec est sed'
  }
}

function Course (props) {
  const myRef = useRef(null)
  const history = useHistory()
  const session = useRef(null)
  const { param1 } = useParams()
  const fingerPrint = useRef(null)
  const { user } = useContext(SessionContext)
  const [loadingNext, setLoadingNext] = useState(false)
  const [loadingVideo, setLoadingVideo] = useState(false)
  const [loadingFinishing, setLoadingFinishing] = useState(false)
  const { keys, id, production } = useContext(PersistentContext)
  const [loadingGeneral, setLoadingGeneral] = useState(true)
  const { course, error, nextChapter, updateUserContent } = useContext(
    CourseContext
  )

  const preload = async () => {
    const siteId = production ? keys.id : id
    try {
      await nextChapter(siteId, param1, {
        moduleId: null,
        contentId: null
      })
      setLoadingGeneral(false)
    } catch (error) {
      console.log('error', error)
      history.replace('/')
    }
  }

  let defaultData = null

  useEffect(() => {
    if (production) {
      preload()
    }
  }, [param1])

  const isMediumDevice = useMediaQuery('(max-width: 980px)')
  const isMobile = useMediaQuery('(max-width: 768px)')

  const styles = {
    container: {
      width: '100%'
    },
    section1: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'space-between',
      paddingBottom: 56
    },
    section1Left: {
      width: isMobile ? '100%' : '70%'
    },
    section1Right: {
      display: 'flex',
      alignItems: 'flex-start',
      width: isMobile ? '100%' : '30%',
    },
    stats: {
      display: 'flex',
      display: 'none',
      flexWrap: 'wrap'
    },
    videoContainer: {
      boxShadow: '#d4d4d4 0px 0px 12px 0px',
      // marginTop: 28
    },
    modules: {
      height: isMobile ? 'auto' : 'calc(100vh - 150px)',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      boxShadow: '#d4d4d4 0px 0px 12px 0px',
      padding: isMediumDevice ? '10px 15px' : '15px 20px',
      overflow: 'auto',
      backgroundColor: '#FFF',
    },
    modulesHeader: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 14
    },
    modulesTitle: {
      color: 'rgb(67, 67, 74)',
      fontSize: isMobile ? 14 : 20,
      fontFamily: 'Muli',
      fontWeight: isMobile ? '600' : '800',
      textAlign: 'left',
      marginRight: 5,
      textTransform: 'uppercase',
      borderBottom: '1px solid #C3C3C3',
      width: '100%',
      padding: '10px',
    },
    modulesCount: {
      fontWeight: '800',
      fontFamily: 'Muli',
      color: '#9d9fad'
    },
    module: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
      cursor: 'pointer',
      padding: '15px 20px',
    },
    moduleSelected: {
      display: 'flex',
      marginBottom: 10,
      alignItems: 'center',
      backgroundColor: 'rgba(33, 150, 243, 0.2)',
      padding: '15px 20px',
      borderRadius: 5
    },
    modulePreviewImage: {
      marginRight: 10,
      paddingTop: 3,
    },
    moduleName: {
      color: 'rgb(67, 67, 74)',
      fontWeight: '800',
      fontFamily: 'Muli',
      marginLeft: isMediumDevice ? 8 : 30,
      textAlign: 'left',
      textTransform: 'uppercase'
    },
    moduleNameSelected: {
      color: '#3D6CFB',
      textAlign: 'left',
      fontWeight: '800',
      fontFamily: 'Muli',
      textTransform: 'uppercase',
      marginLeft: isMediumDevice ? 8 : 30
    },
    modulesButton: {
      width: '100%',
      marginBottom: 10,
      fontFamily: 'Muli',
      fontSize: isMobile ? 16 : 14,
      padding: isMobile ? '4px 8px' : 8
    },

    finishedButton: {
      width: '100%',
      borderRadius: 0,
      padding: '15px 24px',
      boxShadow: '#d4d4d4 0px 0px 12px 0px',
    },

    section2: {
      backgroundColor: 'white',
      padding: '36px 0px',
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'space-between'
    },
    section2Left: {
      width: isMobile ? '100%' : '61%'
    },
    section2Right: {
      width: isMobile ? '100%' : '39%',
      paddingTop: isMobile ? 0 : 26,
      marginLeft: isMobile ? 0 : 50,
      marginTop: isMobile ? 26 : 0
    },

    welcome: {
      marginTop: isMobile ? 0 : 48,
      paddingBottom: 36
    },
    welcomeTitle: {
      color: '#43434a',
      fontSize: 32,
      fontWeight: '800',
      fontFamily: 'Muli',
      textAlign: 'left'
    },
    welcomeText: {
      textAlign: 'left',
      lineHeight: isMobile ? '34px' : '28px',
      color: isMobile ? 'rgb(134 133 133)' : 'rgb(193, 193, 193)',
      fontSize: isMobile ? 19 : 16,
      fontFamily: 'Muli',
      marginTop: 5
    },
    attachment: {
      width: isMobile ? 'calc(100% - 42px)' : 'calc(100% - 52px)',
      boxShadow: '#d4d4d4 0px 0px 12px 0px',
      padding: isMobile ? 21 : 26,
      backgroundColor: '#fff',
      marginBottom: 25
    },
    attachmentTitle: {
      color: '#334256',
      fontSize: 22,
      fontWeight: '800',
      fontFamily: 'Muli',
      textAlign: 'left'
    },
    attachmentProfile: {
      display: 'flex',
      padding: isMobile ? '16px 0px' : '23px 0px'
    },
    attachmentProfilePicture: {
      height: isMediumDevice ? 32 : 54,
      width: isMediumDevice ? 32 : 54,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      flexGrow: 0,
      flexShrink: 0
    },
    attachmentContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingLeft: isMobile ? 12 : 21
    },
    attachmentName: {
      fontSize: 17,
      fontWeight: '800',
      fontFamily: 'Muli',
      color: '#334256'
    },
    instructor: {
      width: isMobile ? 'calc(100% - 42px)' : 'calc(100% - 52px)',
      padding: isMobile ? 21 : 26,
      backgroundColor: '#334256',
      marginBottom: 25
    },
    instructorTitle: {
      color: '#ffffff',
      fontSize: 22,
      fontWeight: '800',
      fontFamily: 'Muli',
      textAlign: 'left'
    },
    instructorProfile: {
      display: 'flex',
      padding: isMobile ? '16px 0px' : '23px 0px'
    },
    instructorProfilePicture: {
      height: isMediumDevice ? 32 : 54,
      width: isMediumDevice ? 32 : 54,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      flexGrow: 0,
      flexShrink: 0
    },
    instructorContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingLeft: isMobile ? 12 : 21
    },
    instructorName: {
      fontSize: 17,
      fontWeight: '800',
      fontFamily: 'Muli',
      color: '#ffffff'
    },
    instructorPosition: {
      color: 'rgb(213 215 218)',
      fontSize: 14,
      fontFamily: 'Muli',
      textAlign: 'left',
      marginTop: 2
    },
    instructorBio: {
      color: '#b6bac0',
      textAlign: 'justify',
      lineHeight: isMediumDevice ? '16px' : '28px',
      fontSize: isMediumDevice ? 11 : 16,
      fontFamily: 'Muli',
      marginTop: isMediumDevice ? 5 : 12
    },
    instructorBioMobile: {
      color: '#b6bac0',
      textAlign: 'justify',
      fontSize: 16,
      fontFamily: 'Muli',
      lineHeight: '21px',
      marginTop: 5
    },
    title: {
      display: 'none',
      color: '#43434a',
      fontSize: 32,
      fontWeight: '800',
      fontFamily: 'Muli',
      textAlign: 'left',
      textTransform: 'uppercase'
    },
    containerDescription: {
      padding: 20,
      marginTop: 5,
      minHeight: 492,
      borderRadius: 20,
      backgroundColor: '#fff',
    },
    secondDescription: {
      fontSize: 19,
      color: '#000',
      textAlign: 'left',
    }
  }

  const onFinished = async (ev, value) => {
    const siteId = production ? keys.id : id
    try {
      await setLoadingFinishing(true)
      await updateUserContent(siteId, param1, {
        moduleId: course.defaultModule.id,
        contentId: course.defaultContent.id,
        finished:
          !course.userContent || !course.userContent.finished ? true : false
      })
      await nextChapter(siteId, param1, {}, false)
    } catch (error) {
      console.log('error', error)
    } finally {
      await setLoadingFinishing(false)
    }
  }

  const onChangeNextModule = async ev => {
    const siteId = production ? keys.id : id
    const index = course.modules.findIndex(
      item =>
        item.id ===
        (defaultData.defaultModule.parentModule || defaultData.defaultModule.id)
    )
    if (index + 1 < course.modules.length) {
      await setLoadingNext(true)
      await setLoadingVideo(true)
      await nextChapter(siteId, param1, {
        moduleId: course.modules[index + 1].id,
        contentId: null
      })
      await setLoadingNext(false)
      await setLoadingVideo(false)
    }
  }

  // const onChangeBackModule = async ev => {
  //   const siteId = production ? keys.id : id
  //   const index = course.modules.findIndex(
  //     item =>
  //       item.id ===
  //       (defaultData.defaultModule.parentModule || defaultData.defaultModule.id)
  //   )
  //   if (index - 1 >= 0 && course.modules[index - 1].content.length) {
  //     await setLoadingNext(true)
  //     await setLoadingVideo(true)
  //     await nextChapter(siteId, param1, {
  //       moduleId: course.modules[index - 1].id,
  //       contentId: null
  //     })
  //     await setLoadingVideo(false)
  //     await setLoadingNext(false)
  //   }
  // }

  const onChangeVideo = async (ev, item) => {
    const siteId = production ? keys.id : id
    await setLoadingNext(true)
    await setLoadingVideo(true)
    await nextChapter(siteId, param1, {
      moduleId: item.moduleId,
      contentId: item.id
    })
    await setLoadingNext(false)
    await setLoadingVideo(false)
  }

  // const renderChapter = (rows = []) => {
  //   return rows.map((element, index) => {
  //     return (
  //       <div
  //         key={index}
  //         onClick={ev =>
  //           defaultData.defaultContent.id !== element.id &&
  //           onChangeVideo(ev, element)
  //         }
  //         style={
  //           defaultData.defaultContent.id === element.id
  //             ? styles.moduleSelected
  //             : styles.module
  //         }
  //       >
  //         {!element.submodule && (
  //           <div
  //             style={{
  //               position: 'relative',
  //               ...styles.modulePreviewImage,
  //               backgroundImage: production
  //                 ? `url(${defaultData.data.background})`
  //                 : `url(${require(`../assets/images/${element.media}`)})`
  //             }}
  //           >
  //             {element && element.userContent && element.userContent.finished & (
  //               <CheckCircle
  //                 style={{
  //                   color: '#4F6BF7',
  //                   position: 'absolute',
  //                   top: '50%',
  //                   left: '50%',
  //                   marginLeft: -12,
  //                   marginTop: -12
  //                 }}
  //               />
  //             )}
  //           </div>
  //         )}
  //         <div
  //           style={
  //             defaultData.defaultContent &&
  //             defaultData.defaultContent.id === element.id
  //               ? styles.moduleNameSelected
  //               : styles.moduleName
  //           }
  //         >
  //           {element.title}
  //         </div>
  //       </div>
  //     )
  //   })
  // }

  const renderModule = (module) => {
    return (
      <div>
        <div style={styles.modulesHeader}>
          <div style={styles.modulesTitle}>{module && module.title}</div>
        </div>
        <div style={{ overflowY: 'auto', flexGrow: 1, flexShrink: 1 }}>
          { module.content.map((leccion) => {
            return (
              <div
                key={leccion.title}
                onClick={ev =>
                  defaultData.defaultContent.id !== leccion.id &&
                  onChangeVideo(ev, leccion)
                }
                style={
                  defaultData.defaultContent.id === leccion.id
                    ? styles.moduleSelected
                    : styles.module
                }
              >
              {!leccion.submodule && (
                <div
                  style={{
                    position: 'relative',
                    ...styles.modulePreviewImage,
                  }}
                  >
                {leccion && leccion.userContent && leccion.userContent.finished ? (
                  <CheckBoxIcon
                    style={{
                      color: '#4F6BF7',
                    }}
                  />
                ) : (
                  <CheckBoxOutlineBlankIcon
                    style={{
                      color: '#4F6BF7',
                    }}
                  />
                )}
                </div>
              )}
                {leccion.title}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderSubModule = (module) => {
    return (
      <div>
        <div style={styles.modulesHeader}>
          <div style={{...styles.modulesTitle, borderBottom: 'none'}}>{module && module.title}</div>
        </div>
        <div style={{ overflowY: 'auto', flexGrow: 1, flexShrink: 1 }}>
          { module.submodules.map((submodule) => {
            return (
              <>
                <div style={styles.modulesHeader}>
                  <div style={styles.modulesTitle}>{submodule && submodule.title}</div>
                </div>
                {
                  submodule.content.map((leccion) => (
                    <div
                      key={leccion.title}
                      onClick={ev =>
                        defaultData.defaultContent.id !== leccion.id &&
                        onChangeVideo(ev, leccion)
                      }
                      style={
                        defaultData.defaultContent.id === leccion.id
                          ? styles.moduleSelected
                          : styles.module
                      }
                    >
                    {!leccion.submodule && (
                      <div
                        style={{
                          position: 'relative',
                          ...styles.modulePreviewImage,
                        }}
                        >
                      {leccion && leccion.userContent && leccion.userContent.finished ? (
                        <CheckBoxIcon
                          style={{
                            color: '#4F6BF7',
                          }}
                        />
                      ) : (
                        <CheckBoxOutlineBlankIcon
                          style={{
                            color: '#4F6BF7',
                          }}
                        />
                      )}
                      </div>
                    )}
                      {leccion.title}
                    </div>
                  ))
                }
              </>
            )
          })}
        </div>
      </div>
    )
  }

  const renderChapters = () => {
    if (defaultData && defaultData.defaultModule && defaultData.modules) {
      return (
        <div style={styles.modules}>
          { defaultData.modules.map((module) => {
              if (module && module.content) {
                return renderModule(module); 
              } else if (module && module.submodules) {
                return renderSubModule(module); 
              }
            })
          }
        </div>
      )
    }
  };

  const renderLoading = () => {
    return (
      <>
        <div className='general-padding' style={styles.section1}>
          <div style={styles.section1Left}>
            <Skeleton
              variant='text'
              animation='pulse'
              width={254}
              height={50}
            />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Skeleton
                variant='text'
                animation='pulse'
                width={100}
                height={50}
                style={{ marginRight: 10 }}
              />
              <Skeleton
                variant='text'
                animation='pulse'
                width={50}
                height={50}
                style={{ marginRight: 10 }}
              />
              <Skeleton
                variant='text'
                animation='pulse'
                width={50}
                height={50}
                style={{ marginRight: 10 }}
              />
            </div>
            <Skeleton
              variant='rect'
              animation='pulse'
              width='100%'
              height={492}
              style={{ marginBottom: 30 }}
            />
            <Skeleton
              variant='text'
              animation='pulse'
              width='100%'
              height={75}
            />
            <Skeleton
              variant='text'
              animation='pulse'
              width='100%'
              height={200}
            />
          </div>
          <div
            style={{
              ...styles.section1Right,
              display: 'flex',
              alignItems: 'flex-end'
            }}
          >
            <Skeleton
              variant='rect'
              animation='pulse'
              width='100%'
              height='100%'
            />
          </div>
        </div>
        <div className='general-padding' style={styles.section2}>
          <div style={styles.section2Left}>
            <Skeleton
              variant='text'
              animation='pulse'
              width='100%'
              height={50}
            />
            <Skeleton
              variant='text'
              animation='pulse'
              width='100%'
              height={50}
            />
            <Skeleton
              variant='rect'
              animation='pulse'
              width='100%'
              height={200}
            />
          </div>
          <div style={styles.section2Right}>
            <Skeleton
              variant='rect'
              animation='pulse'
              width='100%'
              height={250}
            />
          </div>
        </div>
      </>
    )
  }

  const track = async (videoId) => {
    const fp = await FingerprintJS.load()
    fingerPrint.current = await fp.get()
    session.current = sessionStorage.getItem(`${window.SITE_DATA.id}-session`)
    const eventData = {
      session_id: session.current,
      uuid: fingerPrint.current.visitorId,
      event_time: new Date().toISOString(),
      source: 'web',
      event_type: 'video',
      url_origin: window.location.origin,
      url_path: window.location.pathname,
      referrer: document.referrer,
      os_platform: window.navigator.platform,
      device_lang: window.navigator.language,
      extra_data: { videoId }
    }
    const apiUrl = `public/${window.SITE_DATA.id}/track`
    if (user && user.username) {
      eventData.user_id = user.username
    }
    await network.post(apiUrl, { eventData })
  }

  defaultData = production ? course : courseDefault

  const isVideo = defaultData.defaultContent.media && defaultData.defaultContent.media.includes(".mp4") ? true : false

  const videoOptions =
    defaultData && defaultData.defaultContent
      ? {
          sources:
            defaultData && defaultData.defaultContent
              ? [defaultData.defaultContent.media]
              : [],
          onPlay: () => {
            console.log('onPlay', defaultData.defaultContent)
            track(defaultData.defaultContent.videoId)
          },
          timeupdate: time => {
            console.log('time', time)
            updateUserContent(production ? keys.id : id, param1, {
              moduleId: defaultData.defaultModule.id,
              contentId: defaultData.defaultContent.id,
              finished: defaultData.userContent.finished,
              minutes: parseInt(time)
            })
          },
          ended: () => {
            console.log('ended')
            onFinished()
          }
        }
      : {}

  return (
    <div className='page'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Curso</title>
        <link rel='canonical' href='https://store.l3rn.io/course' />
      </Helmet>
      <div style={styles.container}>
        {error && error.error && (
          <Section
            title={error && error.error}
            containerStyle={{backgroundColor: '#f7f8fa'}}
            headerStyle={{ padding: '18px 0px 18px' }}
          />
        )}
        <Section
          headerStyle={{ padding: '18px 0px 18px' }}
          containerStyle={{ backgroundColor: '#f7f8fa' }}
        >
          {loadingGeneral ? (
            renderLoading()
          ) : (
            <div className='general-padding' style={styles.section1}>
              <div style={styles.section1Left}>
                <div style={styles.title}>
                  {defaultData.defaultContent &&
                    defaultData.defaultContent.title}
                </div>
                <div style={styles.stats}>
                  {
                    defaultData.category && defaultData.category.name ? (
                      <CategoryLabel
                        styles={{
                          display: isMobile ? 'none' : 'initial',
                          fontSize: 11,
                          borderRadius: 3,
                          margin: '6px 12px 6px 0px',
                          fontFamily: 'Muli'
                        }}
                        name={defaultData.category && defaultData.category.name}
                      />
                    ) : null
                  }
                  <CourseStat
                    containerStyle={{ margin: '6px 12px 6px 0px' }}
                    iconStyle={{ width: 22, height: 22 }}
                    textStyle={{
                      fontSize: 16,
                      marginLeft: 7,
                      fontFamily: 'Muli'
                    }}
                    icon='sales'
                    value={
                      defaultData.statistics && defaultData.statistics.users
                    }
                  />
                  {/* <CourseStat
                    containerStyle={{ margin: '6px 12px 6px 0px' }}
                    iconStyle={{ width: 22, height: 22 }}
                    textStyle={{
                      fontSize: 16,
                      marginLeft: 7,
                      fontFamily: 'Muli'
                    }}
                    icon='like'
                    value={`${percentage(
                      defaultData.statistics ? defaultData.statistics.users : 0,
                      defaultData.statistics ? defaultData.statistics.likes : 0
                    )} (${formatQuantity(
                      defaultData.statistics ? defaultData.statistics.likes : 0
                    )})`}
                  /> */}
                </div>
                <div style={styles.videoContainer}>
                  {defaultData.defaultContent.media && production ? (
                    loadingVideo ? (
                        <div
                          style={{
                            height: 'calc(100vh - 207px)',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#000000',
                          }}
                        >
                            <CircularProgress size={50} style={{ color: "#FFFFFF"}} />
                      </div>
                    ) : (
                      <>
                        {
                          isVideo ? (
                            <VideoPlayer
                              ref={myRef}
                              videoOptions={videoOptions}
                              minutes={defaultData.userContent.minutes}
                            />
                          ) : (
                            <img
                              alt="preview-image"
                              src={defaultData.defaultContent.media}
                              style={{ width: '100%', height: 'calc(100vh - 207px)', objectFit: 'contain' }}
                            />
                          )
                        }
                      </>
                    )
                  ) : (
                    <div style={styles.containerDescription}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: defaultData.defaultContent.content.description
                        }}
                        style={styles.secondDescription}
                      />
                    </div>
                  )}
                </div>
                {
                  defaultData.defaultContent && defaultData.defaultContent.content && (
                    <>
                      {isMobile ? (
                        <MainButton
                          type='primary'
                          label={
                            defaultData.userContent &&
                            defaultData.userContent.finished
                              ? 'Desmarcar Como Terminado'
                              : 'Marcar Como Terminado'
                          }
                          styles={styles.finishedButton}
                          loading={loadingFinishing}
                          onPress={ev =>
                            onFinished(ev, defaultData.userContent.finished)
                          }
                        />
                      ) : null}
                      {!isMobile ? (
                        <MainButton
                          type='primary'
                          styles={styles.finishedButton}
                          label={
                            defaultData.userContent &&
                            defaultData.userContent.finished
                              ? 'Desmarcar Como Terminado'
                              : 'Marcar Como Terminado'
                          }
                          loading={loadingFinishing}
                          onPress={ev =>
                            onFinished(ev, defaultData.userContent.finished)
                          }
                        />
                      ) : null}
                      {
                        defaultData.defaultContent.media && production ? (
                          <div style={styles.welcome}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: defaultData.defaultContent.content.description
                              }}
                              style={styles.welcomeText}
                            />
                          </div>
                        ) : null
                      }
                    </>
                  )
                }
              </div>
              <div style={styles.section1Right}>{renderChapters()}</div>
            </div>
          )}
        </Section>
        {!loadingGeneral && !loadingVideo && (
          <div className='general-padding' style={styles.section2}>
            <div style={styles.section2Left}>
              {defaultData.defaultContent && defaultData.defaultContent.id && (
                <Comments
                  site={ production ? keys.id : id }
                  course={param1}
                  content={defaultData.defaultContent.id}
                  allowComments={defaultData.defaultContent.allowComments}
                />
              )}
            </div>
            <div style={styles.section2Right}>
              {defaultData.attachments && defaultData.attachments.length ? (
                <div style={styles.attachment}>
                  <div style={styles.attachmentTitle}>Descargas</div>
                  {defaultData.attachments.map((attachment, index) => {
                    return (
                      <div
                        key={`attachments-${index}`}
                        style={styles.attachmentProfile}
                      >
                        <div style={styles.attachmentContent}>
                          <a
                            href={attachment.file}
                            style={styles.attachmentName}
                            target='_blank'
                          >
                            {attachment && attachment.name}
                          </a>
                        </div>
                      </div>
                    )
                  })}
                </div>
              ) : null}
              {defaultData.instructores &&
                defaultData.instructores.map &&
                defaultData.instructores.map((instructor, index) => {
                  return (
                    <div key={`instructors-${index}`} style={styles.instructor}>
                      <div style={styles.instructorTitle}>Instructor</div>
                      <div style={styles.instructorProfile}>
                        <div
                          style={{
                            ...styles.instructorProfilePicture,
                            backgroundImage:
                              production || instructor
                                ? `url(${instructor.picture})`
                                : `url(${require(`../assets/images/${courseDefault.instructor.picture}`)})`
                          }}
                        />
                        <div style={styles.instructorContent}>
                          <div style={styles.instructorName}>
                            {instructor && instructor.name}
                          </div>
                          <div style={styles.instructorPosition}>
                            {instructor && instructor.job}
                          </div>
                          <div style={styles.instructorBio}>
                            {instructor && instructor.biography}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Course
