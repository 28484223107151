import image3 from '../../assets/images/image3.png';
import image4 from '../../assets/images/image4.png';
import image5 from '../../assets/images/image5.png';
import background from '../../assets/images/footer-background.png';

export default {
    "_template": 'blockBoxes',
    "title": "Lorem ipsum dolor sit amet",
    "subtitle": "LEARN MORE ABOUT",
    "background": background,
    "boxesList": [
        {
          "_template": "boxesItem",
          "image": image3,
          "date": "Oct 10 2019",
          "title": "Adressing Volatility",
          "description": "Understand how the different aspects of volatility work so you can be better prepared when it hits."
        },
        {
          "_template": "boxesItem",
          "image": image4,
          "date": "Oct 10 2019",
          "title": "Invest without knowledge",
          "description": "Understand how the different aspects of volatility work so you can be better prepared when it hits."
        },
        {
          "_template": "boxesItem",
          "image": image5,
          "date": "Oct 10 2019",
          "title": "Trust the process",
          "description": "Understand how the different aspects of volatility work so you can be better prepared when it hits."
        }
    ]
}