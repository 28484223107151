import React, {createContext, useState, useEffect} from "react";
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

export const SessionContext = createContext();

const SessionContextProvider = ({ children }) => {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [navigateTo, setNavigateTo] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSessionLoaded, setIsSessionLoaded] = useState(false);
  const [error, setError] = useState(null);

  const loadSession = async () => {
    try {
      await setIsAuthenticating(true);
      const user = await Auth.currentAuthenticatedUser();
      await setUser(user);
      await setIsAuthenticated(true);
    } catch (err) {
      await setIsAuthenticated(false);
      await setError(err.message);
    } finally {
      await setIsSessionLoaded(true);
      await setIsAuthenticating(false);
    }
  };

  useEffect(() => {
    loadSession();
  }, [])
  
  const login = async (username, password) => {
    try {
      await setIsAuthenticating(true);
      const user = await Auth.signIn(username, password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        await setNavigateTo(user)
        history.push(`/changepassword?user=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`)
        return user;
      } else {
        await setUser(user);
        await setIsAuthenticated(true);
        history.replace('/')
      }
    } catch (err) {
      console.log('err', err)
      setError(err.message);
      await setIsAuthenticated(false);
      await setError(err.message);
    } finally {
      await setIsAuthenticating(false);
    }
  };

  const logout = async () => {
    await Auth.signOut();
    await setUser(null);
    await setIsAuthenticated(false);
    history.replace('/')
  };

  return (
    <SessionContext.Provider
      value={{
        login,
        logout,
        navigateTo,
        loadSession,
        isAuthenticating,
        isAuthenticated,
        isSessionLoaded,
        error,
        user
      }}
    >
        {children}
      </SessionContext.Provider>
  )

};

export default SessionContextProvider; 
