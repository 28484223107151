import logo from '../../assets/images/logo.png';

export default {
    "_template": "blockNavbar",
    "image": logo,
    "links": [
        {
            "_template": "link",
            "label": "Home",
            "link": "#"
        },
        {
            "_template": "link",
            "label": "About us",
            "link": "#"
        },
        {
            "_template": "link",
            "label": "Our Products",
            "link": "#"
        },
        {
            "_template": "link",
            "label": "Contact Us",
            "link": "#"
        }
    ],
    "position": 'absolute',
    "linksTextColor": "#ffffff",
    "backgroundColor": "#292c2e",
    "showActions": true
}