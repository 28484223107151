import React, { useContext, useState } from 'react'
import cartIcon from '../assets/svgs/cart.svg'
import cartActive from '../assets/svgs/cart-active.svg'
import StatusDot from './StatusDot'
import { ShopContext } from '../context/ShopContext'
import TouchableOpacity from './TouchableOpacity'
import styled from 'styled-components'
import CourseDropdownItem from './CourseDropdownItem'
import { formatPrice } from '../helpers/StatisticalFunctions'
import MainButton from './buttons/MainButton'
import { CircularProgress } from '@material-ui/core'

function CartIcon({onRedirect, containerStyle, hoverEnabled}) {

  const { cart, loadingCart, isAnyProductLoading } = useContext(ShopContext);

  const [visible, setVisible] = useState(false)

  const toggle = (option) => {
      setVisible(option ? option : !visible)
  }

  const redirect = (path) => {
    onRedirect(path)
    toggle(false)
  }

  const hasItems = () => {
    return cart ? cart.length > 0 ? true : false : false
  }

  const total = () => {
    let res = 0
    cart.forEach(element => {
      res += element.price ? element.price.unit_amount : 0
    });
    return formatPrice(res, cart[0].price ? cart[0].price.currency : 'mxn')
  }

  const legibleCart = () => {
    if(cart){
      if(Array.isArray(cart)){
        return true   
      }
    }
    return false
  }

  return (
    <>
      <TouchableOpacity
        opacity={1}
        containerStyle={{ position:'relative', zIndex:2}}
        onHoverEnter={()=> hoverEnabled ? toggle(true) : null}
        onHoverLeave={()=> hoverEnabled ? toggle(false) : null}
      >
        <Container style={containerStyle}>
          <TouchableOpacity
            containerStyle={{display: 'flex'}}
            onPress={()=>redirect('/cart')}
          >
            <Icon alt="cart-icon" src={hasItems() ? cartActive : cartIcon} />
            { hasItems() ? <StatusDot /> : null }
          </TouchableOpacity>
          {
            hoverEnabled && (
              <Dropdown style={{ visibility: visible ? 'visible' : 'hidden' }}>
                {
                  (loadingCart || isAnyProductLoading()) ?
                    <EmptyState style={{padding: 30}}> 
                      <CircularProgress style={{ color: '#00A8D8' }}/>
                    </EmptyState>
                  :
                    legibleCart() && (
                      <>
                        {
                          cart.length <= 0 && (
                            <EmptyState> 
                              Aún no has agregado cursos a tu carrito
                            </EmptyState>
                          )
                        }
                        {
                          cart.map((item, index) => (
                            <CourseDropdownItem
                              key={index}
                              id={item.courseId}
                              onPress={()=>redirect(`/course-detail/${item.courseId}`)}
                              background={item.background}
                              title={item.title}
                              categoryName={item.categoryName}
                              price={item.price}
                              originalPrice={item.originalPrice}
                              isPurchased={item.isPurchased}
                              hideAddToCart={true}
                            />
                          ))
                        }
                        <div style={{width: '100%', padding: 16}}>
                          {
                            cart.length > 0 && (
                              <Total>
                                Total: { total() }
                              </Total>
                            )
                          }
                          <MainButton
                            onPress={() => redirect('/cart')}
                            type='primary'
                            label='Ir al carrito'
                            styles={{ width: '100%', marginTop: 12, padding: 10}}
                          />
                        </div>
                      </>
                    )
                }
              </Dropdown>
            )
          }
          {
            visible && (
              <Anchor/>
            )
          }
        </Container>
      </TouchableOpacity>
      {
        visible && (
          <TouchableOpacity
            opacity={1}
            containerStyle={ {position: 'fixed', zIndex: 1, background: 'transparent', width: '100%', height: '100%', left: 0, top: 0}}
            onHoverEnter={()=>toggle(false)}
            onMouseMove={()=>toggle(false)}
          />
        )
      }
    </>

  );
}

const Container = styled.div`
  position: relative;
`

export const Dropdown = styled.div`
    top: 28px;
    right: 0;
    background-color: white;
    position: absolute;
    width: 320px;
    max-height: 400px;
    overflow-y: scroll;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 24%) 0px 0px 12px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #989898;
    }
    ::-webkit-scrollbar-corner {
        background: #0c0c0c;
    }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`

const Total = styled.div`
  color: rgb(67, 67, 74);
  font-size: 18px
  font-family: Muli;
  font-weight: 800;
  text-align: left
`

const EmptyState = styled.div`
  width: 100%;
  padding: 30px 30px 0px 30px;
  height: 50px;
  display: flex; 
  justify-content: center; 
  align-items: center;
  color: rgb(67, 67, 74);
  font-size: 14px;
  font-family: Muli;
  font-weight: 600;
`

const Anchor = styled.div`
  position: absolute;
  width: 45px;
  height: 8px;
  bottom: -8px;
  right: 0px;
`

export default CartIcon;
