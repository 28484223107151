import { linkBlock } from '../Link/Link';
import { socialBlock } from '../Social/Social';

export default [
    {
      "label": "Logo",
      "name": "logo",
      "component": "image",
      "parse": media => {
        return media.previewSrc
      },
      "previewSrc": formValues => {
        return formValues;
      },
      "focusRing": false
    },
    {
      "label": "Background Type",
      "name": "backgroundType",
      "component": "select",
      "options": [ 
        {
          value: "backgroundImage",
          label: "Background Image",
        },
        {
          value: "backgroundColor",
          label: "Background Color",
        }
      ],
      "focusRing": false
    },
    {
        "label": "Background Image",
        "name": "backgroundImage",
        "component": "image",
        "parse": media => {
          return media.previewSrc
        },
        "previewSrc": formValues => {
          return formValues;
        },
        "focusRing": false
    },
    {
      "label": "Background Color",
      "name": "backgroundColor",
      "colorFormat": "hex",
      "widget": "sketch",
      "component": "color",
      "focusRing": false
    },
    {
        'label': 'Blocks Links',
        'name': 'links',
        'component': 'blocks',
        'templates': {
          'link': linkBlock.template,
        },
    },
    {
      "label": "Links Text Color",
      "name": "linksTextColor",
      "description": "Establece el color de los links, puede ser sobreescrito por los settings de cada link individual",
      "colorFormat": "hex",
      "widget": "sketch",
      "component": "color",
      "focusRing": false
    },
    {
      "label": "Show Socials",
      "name": "showSocials",
      "component": "toggle",
      "description": "Mostrar redes sociales",
      "toggleLabels": false,
      "focusRing": false
    },
    {
      'label': 'Blocks Socials',
      'name': 'socials',
      'component': 'blocks',
      'templates': {
        'social': socialBlock.template
      },
    }
]