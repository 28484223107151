import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { useMediaQuery } from '../helpers/Hooks'
import '../App.css';

function Section({containerStyle, hideHeader, headerStyle, loading, titleStyle, title, action, onActionClick, children}) {

  const isMobile = useMediaQuery('(max-width: 768px)');
  
  return (
    <div style={{...styles.container, ...containerStyle}}>
        {
          !hideHeader && (
            <div style={{...styles.header, ...headerStyle}} className="general-padding">
              {
                loading ? (
                    <Skeleton variant="text" animation="pulse" width={254} height={50} />
                ) : (
                  <div style={{ ...styles.title, ...titleStyle }}>{title}</div>
                )
              }
              { action && !isMobile ? <div onClick={()=>onActionClick()} style={styles.action}>{action}</div> : <div/> }
            </div>
          )
        }
        {children}
    </div>
  );
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center'
  },
  header: {
    padding: '36px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    color: '#43434a',
    fontSize: 32,
    fontWeight: '800',
    fontFamily: 'Muli',
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  action: {
    color: '#1c7bfe',
    fontSize: 18,
    fontWeight: '600',
    fontFamily: 'Muli',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}

export default Section;
