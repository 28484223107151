export default [
    {
      "label": "Image",
      "name": "image",
      "component": "image",
      "parse": media => {
        return media.previewSrc
      },
      "previewSrc": formValues => {
        return formValues;
      },
      "focusRing": false
    },
    {
      "label": "Title",
      "name": "title",
      "component": "text",
      "focusRing": false
    },
]