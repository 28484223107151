export default {
    "_template": "templateRefundPolicy",
    "title": "Política de reembolso",
    "policy": `

Para obtener detalles sobre nuestras políticas y fechas límites sobre reembolsos, consulta la siguiente información. Ten en cuenta que nuestras políticas difieren entre pagos de suscripciones y pagos únicos realizados para compras de cursos, especializaciones y proyectos guiados y esas opciones de pago pueden variar de un servicio a otro.

También debes tener en cuenta que nos tomamos muy en serio las transgresiones a nuestros Términos de Uso y Política de Privacidad que no tenemos la obligación de ofrecer reembolsos a nuestros estudiantes que transgreden estos términos, incluso si sus solicitudes están formuladas dentro del período de reembolso designado. Del mismo modo, no tenemos ninguna obligación de ofrecer reembolsos atrasados para los estudiantes que no aprueben un Contenido que se ofrece o que de otra manera no están satisfechos con su calificación final.


#### ¿Cómo puedo pedir mi reembolso?

En caso de haber realizado la compra de un curso, y éste no haya sido de tu agrado, puedes solicitar tu reembolso, siempre y cuando se cumplan las siguientes condiciones:

* La solicitud de reembolso debe haberse realizado en un plazo no mayor a 14 días naturales después de la compra.
* Si el curso se estrenó después de la compra, dicho plazo será contado desde el día de su estreno.
* Solo será válido el reembolso, si no se ha visualizado más del 50% del contenido.
* Para completar tu devolución, requerimos un recibo o prueba de compra.

Si compraste un bundle/pack y éste no ha sido de tu agrado, puedes solicitar tu reembolso, siempre y cuando se cumplan los siguientes puntos:

* La solicitud de reembolso debe haberse realizado en un plazo no mayor a 14 días naturales después de la compra.
* Si el curso se estrenó después de la compra, dicho plazo será contado desde el día de su estreno.
* Solo será válido el reembolso, si no se ha visualizado más del 50% del contenido.
* Si alguno de los cursos del paquete se ha visualizado más del 50%, el reembolso no será válido.

Se puede solicitar el cambio de curso, siempre y cuando el curso tenga el mismo precio o uno de mayor valor pagando la diferencia. En caso de querer un curso de menor valor, no se reembolsará la diferencia.


Para solicitar tu reembolso ponte en contacto con nosotros al correo de:  **{correo electrónico al que puedan mandar mensaje los clientes y que sea  de la empresa, correo electrónico identificado con la empresa }**


#### ¿Qué pasa después de que solicité mi reembolso?

Si tu solicitud de reembolso cumple con las condiciones indicadas anteriormente:

* Recibirás un correo confirmando tu reembolso.
* Se cancelará tu acceso al curso o cursos reembolsados.


#### Reembolsos (donde aplique)

Una  vez que tu devolución es recibida e inspeccionada, enviaremos un correo electrónico para notificarte que hemos recibido tu devolución.  También te notificaremos la aprobación o rechazo de tu reembolso.
Si eres aprobado, entonces tu reembolso será procesado y un crédito será automáticamente aplicado a tu tarjeta o método original de pago, en una cierta cantidad de días.


#### Reembolsos tardíos o perdidos (donde aplique)

Si aún no has recibido tu reembolso, por favor chequea tu cuenta bancaria otra vez.
Luego contacta a tu compañía de tarjeta, puede tomar algún tiempo antes de que tu reembolso se postee oficialmente.

El próximo paso, contacta a tu banco.  A menudo pasa cierto tiempo antes de que tu reembolso se postee.


En caso de que pasen 10 días, has seguido todos estos pasos y aún no haz recibido el reembolso, por favor contáctanos a **{correo electrónico al que puedan mandar mensaje los clientes y que sea  de la empresa, correo electrónico identificado con la empresa }**

Los reembolsos no son aplicables en eventos presenciales u online, ni en programas de suscripción.



Si tu suscripción a un programa generó cargos, no serán reembolsables, ni tendrá validez para cambios de producto. Se puede cancelar la suscripción para evitar cobros futuros.

*Aplican restricciones.

Para solicitar tu reembolso ponte en contacto con nosotros al correo de:  **{correo electrónico al que puedan mandar mensaje los clientes y que sea  de la empresa, correo electrónico identificado con la empresa }**

    `,
}