import React, { createContext, useState, useContext } from "react";
import { useFormScreenPlugin, useForm, useCMS } from 'tinacms';
import { ThemeProvider } from "styled-components";
import { PersistentContext } from '../context';
  
export const ThemeContext = createContext();

const isBrowser = typeof window !== "undefined";
const userPrefDark = isBrowser ? localStorage.getItem("isDarkMode") : false;
const initialDarkMode = userPrefDark === "true" ? true : false;

export default function ThemeLayout(props) {
  const cms = useCMS();
  const { saveSiteConfig, getItemConfig } = useContext(PersistentContext);

  const [isDarkMode, setIsDarkmode] = useState(initialDarkMode);

  const setUp = () => {
    const response = getItemConfig('theme');
    const formConfig = {
      label: "Color",
      fields: [
        {
          label: "Color",
          name: "ThemeColor",
          component: "group",
          fields: [
            {
              label: "Black",
              name: "black",
              component: "color",
              colorFormat: "hex",
            },
            {
              label: "White",
              name: "white",
              component: "color",
              colorFormat: "hex",
            },
            {
              label: "Primary",
              name: "primary",
              component: "color",
              colorFormat: "hex",
            },
            {
              label: "Secondary",
              name: "secondary",
              component: "color",
              colorFormat: "hex",
            },
          ],
        },
      ],
      onSubmit: async (data) => {
        await saveSiteConfig({'theme': data});
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: response,
    };
    return formConfig;
  };

  const ThemeForm = setUp();
  
  const [data, form] = useForm(ThemeForm);
  
  useFormScreenPlugin(form);

  const toggleDarkMode = () => {
    const newMode = !isDarkMode;
    setIsDarkmode(newMode);
    if (typeof window !== "undefined") {
      localStorage.setItem("isDarkMode", newMode);
    }
  }

  const theme = {
    isDarkMode: isDarkMode,
    color: {
      primary: data.ThemeColor.primary,
      secondary: data.ThemeColor.secondary,
      black: isDarkMode ? data.ThemeColor.black : data.ThemeColor.black,
      white: isDarkMode ? data.ThemeColor.white : data.ThemeColor.white,
      background: !isDarkMode ? data.ThemeColor.black : data.ThemeColor.white,
      foreground: isDarkMode ? data.ThemeColor.black : data.ThemeColor.white,
    },
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleDarkMode,
      }}
    >
      <ThemeProvider theme={theme}>
        {props.children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
