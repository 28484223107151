import React from 'react';
import { InlineTextarea, BlocksControls, InlineBlocks } from 'react-tinacms-inline';
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components';
import { buttonBlock } from '../Button/Button';

function FormImage({ index, data }) {
  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <Container>
        <Content>
          <Title>
            <InlineTextarea name="title" focusRing={false} />
          </Title>
          <Description>
            <InlineTextarea name="description" focusRing={false} />
          </Description>
          <Form>
            <TextInput placeholder="TAG HERE"/>
            <TextInput placeholder="TAG HERE"/>
          </Form>
          <Actions>
            <InlineBlocks
              max={1}
              name="buttons"
              blocks={BUTTON_BLOCKS}
              className={'hero-carousel-inline-blocks'}
            />
          </Actions>
        </Content>
        <ImageContainer style={{backgroundImage: `url(${data.background})`}} />
      </Container>
    </BlocksControls>
  );
}

const BUTTON_BLOCKS = {
  button: buttonBlock,
};

const Container = styled.div`
  width: 100%;
  align-items: flex-start;
  display: flex;
  background-color: #f5f5f2;
`

const ImageContainer = styled.div`
  height: 90vh;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  flex-shrink: 1;
  flex-grow: 1;
  background-size: cover;
  background-repeat: no-repeat;
`

const Content = styled.div`
  width: 600px;
  padding: 70px 100px;
  text-align: left;
  color: white;
  @media (max-width: 768px) {
    padding: 36px 30px 140px;
  }
`

const Title = styled.div`
  color: #01214d;
  font-size: 55px;
  line-height: 62px;
  font-weight: 800;
  font-family: Arial;
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 36px;
  }
`

const Description = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-family: Arial;
  line-height: 28px;
  padding-top: 24px;
  color: #9c9e9f;
  @media (max-width: 768px) {
    font-size: 22px;
    padding-top: 18px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
`

const TextInput = styled.input`
  background-color: transparent;
  padding: 14px 0px;
  border: none;
  border-bottom: #01214d 2px solid;
  font-size: 14px;
  color: #f58e68;
  font-weight: 700;
  font-family: Arial;
  margin-bottom: 24px;
  &::-webkit-input-placeholder {
    font-size: 14px;
    color: #f58e68;
    font-weight: 700;
    font-family: Arial;
  }
  &::-moz-placeholder {
    font-size: 14px;
    color: #f58e68;
    font-weight: 700;
    font-family: Arial;
  }
  &:-ms-input-placeholder {
    font-size: 14px;
    color: #f58e68;
    font-weight: 700;
    font-family: Arial;
  }
  &:-moz-placeholder {
    font-size: 14px;
    color: #f58e68;
    font-weight: 700;
    font-family: Arial;
  }  
`

const Actions = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  & .hero-carousel-inline-blocks{
    display: flex;
    column-gap: 20px;
    row-gap: 16px;
    flex-wrap: wrap;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const FormImageTemplate = {
  "label": "Form & Image",
  "key": "formImage",
  "fields": fields,
  "defaultItem": defaults
};

export const blockFormImage = {
  Component: FormImage,
  template: FormImageTemplate,
}