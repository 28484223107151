import React, { useContext } from 'react';
import { useForm, usePlugins, useCMS } from 'tinacms';
import { MarkdownFieldPlugin } from 'react-tinacms-editor'
import { InlineForm, InlineBlocks } from 'react-tinacms-inline';
import { PersistentContext } from '../context';
import { blockFaq } from '../blocks/Faq';

export default function PageFaq(props) {
  const { saveContent, getItemData } = useContext(PersistentContext);

  const cms = useCMS();

  const FAQ_BLOCKS = {
    blockFaq,
  };

  const setUp = () => {
    const data = getItemData('faq');
    const formConfig = {
      id: 'templateFaq',
      fields: [{
        label: 'Secciones',
        name: 'blocks',
        component: 'blocks',
        templates: {
          'blockFaq': blockFaq.template,
        }
      }],
      onSubmit: async (data) => {
        await saveContent({'faq': data});
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data,
    }
    return formConfig;
  }
  const formConfig = setUp();
  const [, form] = useForm(formConfig);
  usePlugins([form, MarkdownFieldPlugin])

  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`}>
      <InlineForm form={form}>
        <InlineBlocks name="blocks" blocks={FAQ_BLOCKS} />
      </InlineForm>
    </div>
  )
}