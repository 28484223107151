import React from 'react';
import { BlocksControls, InlineBlocks } from 'react-tinacms-inline'
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components'
import { boxesItemBlock } from '../BoxesItem/BoxesItem';

function Boxes({ index, data }) {
  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <Container style={{backgroundImage: `url(${data.background})`}}>
        <Subtitle>{data.subtitle}</Subtitle>
        <Title>{data.title}</Title>
        <InlineBlocks
          max={3}
          name="boxesList"
          direction="horizontal"
          className="boxes-blocks-container"
          blocks={BOXES_BLOCKS}
        />
      </Container>
    </BlocksControls>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 70px 100px;
  background-size: 100% 60%, cover;
  background-position-y: bottom;
  background-repeat: no-repeat;
  & .boxes-blocks-container{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
  }
`

const Title = styled.div`
  color: #292c2e;
  font-size: 34px;
  padding-top: 8px;
  font-weight: 800;
  font-family: Arial;
`

const Subtitle = styled.div`
  color: #9dbe15;
  font-size: 16px;
  font-weight: 800;
  font-family: Arial;
`

const BOXES_BLOCKS = {
  boxesItem: boxesItemBlock
};

export const BoxesTemplate = {
  "label": "Boxes",
  "key": "boxes",
  "fields": fields,
  "defaultItem": defaults
};

export const blockBoxes = {
  Component: Boxes,
  template: BoxesTemplate,
}