import React from 'react';
import styled from 'styled-components';
import { BlocksControls, InlineBlocks, InlineImage } from 'react-tinacms-inline';
import { linkBlock } from '../Link/Link';
import defaults from './defaults'
import fields from './fields'

function Footer({index, data}) {
  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
        <Container style={{backgroundImage: `url(${data.background})`}}>
            <div style={{height: 40}}>
                <InlineImage
                    name="logo"
                    parse={media => {
                        return media.previewSrc
                    }}
                    previewSrc={formValues => {
                        return formValues;
                    }}
                >
                    {
                    (props) => (
                        <img alt="footer-logo" style={{height: 40}} src={props.src}/>
                    )
                    }
                </InlineImage>
            </div>
            <MenuContainer>
                <InlineBlocks
                    name="links"
                    blocks={LINK_BLOCKS}
                    className={'footer-menu'}
                    itemProps={{
                        whiteMode: true
                    }}
                />
            </MenuContainer>
            <div/>
        </Container>
    </BlocksControls>
  );
}

const LINK_BLOCKS = {
    link: linkBlock,
};


const Container = styled.div`
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 100px;
    background-size: cover;
    background-repeat: no-repeat;
`

const MenuContainer = styled.div`
    display: flex;
    align-items: center;
    & .footer-menu{
        display: flex;
        align-items: center;
    }
`

export const FooterTemplate = {
    "label": "Footer",
    "key": "footer",
    "fields": fields,
    "defaultItem": defaults
};
  
export const blockFooter = {
    Component: Footer,
    template: FooterTemplate,
}