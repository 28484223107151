import React, {useContext} from "react"
import Helmet from "react-helmet";
import { useFormScreenPlugin, useForm, useCMS } from 'tinacms';
import icon from '../assets/images/favicon.webp'
import { PersistentContext } from '../context';

export const SeoDefaultValues = {
  icon: icon,
  lang: "es",
  title: "L3RN",
  description: "Tu propia academia digital",
};

export function SEOLayout(props) {
  const cms = useCMS();
  const { saveSiteConfig, getItemConfig, keys } = useContext(PersistentContext);

  const setUp = () => {
    const response = getItemConfig('seo');
    const SiteForm = {
      label: "Site",
      fields: [
        {
          label: "Icon",
          name: "icon",
          component: "image",
          parse: media => {
            return media.previewSrc
          },
          previewSrc: formValues => {
            return formValues;
          },
          focusRing: false
        },
        {
          label: "Title",
          name: "title",
          component: "text",
          parse(value) {
            return value || ""
          },
        },
        {
          label: "Palabras clave",
          name: "keywords",
          component: "text",
          parse(value) {
            return value || ""
          },
        },
        {
          label: "Descripción",
          name: "description",
          component: "text",
          parse(value) {
            return value || ""
          },
        },
        {
          label: "Permitir que los motores de búsqueda indexen esta página",
          name: "allow",
          component: "toggle",
          parse(value) {
            return value || ""
          },
        },
        {
          label: "Título de Social Share",
          name: "socialShare",
          component: "text",
          parse(value) {
            return value || ""
          },
        },
        {
          label: "Descripción de Social Share",
          name: "socialDescription",
          component: "text",
          parse(value) {
            return value || ""
          },
        },
        {
          label: "Imagen de Social Share",
          name: "socialImage",
          component: "image",
          parse: media => {
            return media.previewSrc
          },
          previewSrc: formValues => {
            return formValues;
          },
          focusRing: false
        },
      ],
      onSubmit: async (data) => {
        const saved = await saveSiteConfig({ 'seo': data });
        if (saved) {
          cms.alerts.success('Cambios guardados!');
        } else {
          cms.alerts.error('Error al guardar');
        }
      },
      initialValues: response,
    }
    return SiteForm;
  };
  
  const ThemeForm = setUp();
  const [data, form] = useForm(ThemeForm);
  useFormScreenPlugin(form);
  const merge = { ...SeoDefaultValues, ...data };
  const { title, lang, description, icon, allow, keywords, socialShare, socialImage, socialDescription } = merge;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
        {
          "href": icon,
          "rel": "icon",
          "type": "image/png",
        },
        {
          "href": icon,
          "rel": "apple-touch-icon",
        }
      ]}
    >
      <title>{title}</title>
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta key="title" property="og:title" content={socialShare} />
      <meta property="og:url" content={keys && keys.baseUrl} />
      <meta property="og:site_name" content={`${title} | ${description}`} />
      <meta key="image" property="og:image" content={socialImage} />
      <meta property="og:description" content={socialDescription} />
      <meta property="og:image:alt" content={title} />
      <meta property="og:locale" content="es_mx" />
      <meta name="twitter:text:title" content={`${title} | ${description}`} />
      <meta name="twitter:image" content={socialImage} />
      <meta name="twitter:image:alt" content={socialImage} />
      <meta name="twitter:card" content="summary_large_image" />
      {!allow && <meta name="robots" content="noindex" />}
    </Helmet >
  )
}
