import background from '../../assets/images/cover.png';

export default {
    "_template": "blockHeroCarousel",
    "background": background,
    "title": "Lorem ipsum dolor sit amel dipiscing elit sedel",
    "description": "Dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat ullamco laboris nisi delde para.",
    "buttons": [
        {
            "_template": "button",
            "label": "Call To Action",
            "style": "primary",
            "link": "#"
        }
    ]
}