import React, { useEffect, useState, useContext } from 'react';
import { BlocksControls } from 'react-tinacms-inline';
import fields from './Courses.fields'
import defaults from './Courses.defaults'
import Section from '../components/Section';
import Courses from '../components/Courses';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getCourses } from '../helpers/courses';
import { PersistentContext } from '../context';
import { SessionContext } from '../context/SessionContext';
import { useCMS } from 'tinacms'
import { useHistory } from 'react-router-dom';

function CoursesBlock({ index, data }) {
  const cms = useCMS();
  const history = useHistory();

  const { keys, id, production } = useContext(PersistentContext);
  const { isAuthenticated, isSessionLoaded } = useContext(SessionContext);

  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadCourses = async (loading = true) => {
    const siteId = production ? keys.id : id 
    loading === true && setLoading(true)
    const courses = await getCourses(siteId, {filterName: 'show_on_home', filterValue: 'true'}, isAuthenticated)
    await setCourses(courses.data)
    loading === true && setLoading(false)
  }

  useEffect(() => {
    if(!production || isSessionLoaded){
      loadCourses()
    }
  },[production, isSessionLoaded])

  const filter = (courses) => {
    return courses ? courses.slice(0, data.coursesShown) : []
  }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      {
        loading ?
          <div style={{ backgroundColor: data.backgroundColor, height: 190, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <CircularProgress style={{ color: '#00A8D8' }}/>
          </div>
        :
          <>
            {
              cms.enabled && filter(courses).length <= 0 ?
                <div style={{...styles.emptyState, backgroundColor: data.backgroundColor}}>
                  <div style={styles.emptyStateTitle}>Aún no tienes cursos</div>
                  <div>Agrega cursos en el panel de administrador</div>
                </div>
              :
                <Section
                  containerStyle={{ backgroundColor: data.backgroundColor, paddingBottom: 70 }}
                  titleStyle={{
                    color: data.titleColor
                  }}
                  title={data.title}
                  action={data.link}
                  onActionClick={() => history.push('/course-catalog')}
                >
                  <Courses
                    reload={()=>loadCourses(false)}
                    courses={filter(courses)}
                  />
                </Section>
            }
          </>
      }
    </BlocksControls>
  );
}

const styles = {
  emptyState: {
    padding: '60px 0px'
  },
  emptyStateTitle: {
    color: 'rgb(67, 67, 74)',
    fontSize: 18,
    fontWeight: 800,
    fontFamily: 'Muli'
  }
}

export const CoursesTemplate = {
  "label": "Courses",
  "key": "Courses",
  "fields": fields,
  "defaultItem": defaults
};

export const blockCourses = {
  Component: CoursesBlock,
  template: CoursesTemplate,
}