import blockHeroDefaults from './blocks/Hero.defaults'
import SignInDefaults from './pages/SignIn.defaults'
import SignUpDefaults from './pages/SignUp.defaults'
import blockCounterDefaults from './blocks/Counter.defaults'
import blockCategoriesDefaults from './blocks/Categories.defaults'
import blockCoursesDefaults from './blocks/Courses.defaults'
import blockEventsDefaults from './blocks/Events.defaults'
import blockDiscountDefaults from './blocks/Discount.defaults'
import blockTestimonialsDefaults from './blocks/Testimonials.defaults'
import ContactDefaults from './pages/Contact.defaults'
import NotFoundDefaults from './pages/NotFound.defaults'
import CartDefaults from './pages/Cart.defaults'
import OrderCompletedDefaults from './pages/OrderCompleted.defaults'
import PaymentFailedDefaults from './pages/PaymentFailed.defaults'
import blockHero2Defaults from './blocks/Hero2.defaults'
import CoursesByCategoryDefaults from './pages/CourseCatalog.defaults'
import MyCoursesDefaults from './pages/MyCourses.defaults'
import MyFavoritesDefaults from './pages/MyFavorites.defaults'
import CourseDetailDefaults from './pages/CourseDetail.defaults'
import EventDetailDefaults from './pages/EventDetail.defaults'
import CourseDefaults from './pages/Course.defaults'
import blockForgotPasswordDefaults from './blocks/ForgotPassword.defaults'
import ForgotPasswordEmailSentDefaults from './blocks/ForgotPasswordEmailSent.defaults'
import ForgotPasswordVerificationDefaults from './blocks/ForgotPasswordVerification.defaults'
import ChangePasswordDefaults from './blocks/ChangePassword.defaults'
import blockFaqDefaults from './blocks/Faq.defaults'
import TermsDefaults from './pages/Terms.defaults'
import LegalAdviceDefaults from './pages/LegalAdvice.defaults'
import PrivacyPolicyDefaults from './pages/PrivacyPolicy.defaults'
import RefundPolicyDefaults from './pages/RefundPolicy.defaults'
import AboutDefaults from './pages/About.defaults'
import CategoryDefaults from './pages/Category.defaults'
import { ThemeDefaultValues } from './dataLanding'
import { NavbardefaultValues } from './layout/Navbar'
import { FooterdefaultValues } from './layout/Footer'
import { SeoDefaultValues } from './layout/Seo'


export const data = {
    "home": { "blocks" : [ 
        blockHeroDefaults,
        blockCounterDefaults,
        blockCategoriesDefaults,
        blockCoursesDefaults,
        blockDiscountDefaults,
        blockTestimonialsDefaults
    ] },
    "cart": CartDefaults,
    "not-found": NotFoundDefaults,
    "contact": ContactDefaults,
    "sign-in": SignInDefaults,
    "sign-up": SignUpDefaults,
    "order-completed": { ...OrderCompletedDefaults },
    "payment-failed": { ...PaymentFailedDefaults },
    "course-catalog": {
        "topBlocks": [blockHero2Defaults],
        ...CoursesByCategoryDefaults,
        "bottomBlocks": [blockDiscountDefaults]
    },
    "category": {
        ...CategoryDefaults,
        "blocks": [blockDiscountDefaults]
    },
    "my-courses": { ...MyCoursesDefaults },
    "my-favorites": { ...MyFavoritesDefaults },
    "course-detail": {
        ...CourseDetailDefaults,
        "blocks": [blockCoursesDefaults]
    },
    "event-detail": {
        ...EventDetailDefaults,
        "blocks": [blockEventsDefaults]
    },
    "course": {
        ...CourseDefaults,
        "blocks": [blockCoursesDefaults]
    },
    "forgot-password": {"blocks": [blockForgotPasswordDefaults]},
    "forgot-password-email-sent": {"blocks": [ForgotPasswordEmailSentDefaults]},
    "forgotpasswordverification": {"blocks": [ForgotPasswordVerificationDefaults]},
    "changepassword": {"blocks": [ChangePasswordDefaults]},
    "faq": {"blocks": [blockFaqDefaults]},
    "terms": { ...TermsDefaults },
    "legal-advice": { ...LegalAdviceDefaults },
    "privacy-policy": { ...PrivacyPolicyDefaults },
    "refund-policy": { ...RefundPolicyDefaults },
    "about": { ...AboutDefaults },
    "header": NavbardefaultValues,
    "footer": FooterdefaultValues,
    "theme": ThemeDefaultValues,
    "seo": SeoDefaultValues,
}

export default (template) => data[template];