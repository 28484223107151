import React from 'react';
import { BlocksControls } from 'react-tinacms-inline';
import { useHistory } from 'react-router-dom';

function Button({ index, data, disabled, styles, onPress }) {

  const history = useHistory();

  return (
    <div key={index}>
      <BlocksControls index={index}>
        <button
          onClick={e => onPress ? onPress() : history.push(data.link)}
          className='touchable-opacity'
          disabled={disabled ? disabled : false}
          style={{
            ...buttonStyle,
            ...(data.style === 'primary'
              ? primary
              : data.style === 'secondary'
              ? secondary
              : data.style === 'tertiary'
              ? tertiary
              : primary),
            ...styles,
            ...(disabled ? disabledStyle : {})
          }}
        >
          {data.label}
        </button>
      </BlocksControls>
    </div>
  );
}

const buttonStyle = {
  outline: 'none',
  border: 'none',
  borderRadius: 2,
  padding: '12px 42px',
  fontSize: 16,
  fontWeight: '800',
  fontFamily: 'Arial'
}

const disabledStyle = {
  backgroundColor: '#7398FD',
  color: 'white',
  border: '#7398FD 2px solid'
}

const primary = {
  backgroundColor: '#98bd07',
  color: '#ffffff',
  border: '#98bd07 2px solid'
}

const secondary = {
  backgroundColor: 'transparent',
  color: 'white',
  border: 'white 2px solid'
}

const tertiary = {
  backgroundColor: 'transparent',
  color: '#016cff',
  border: '#016cff 2px solid'
}

export const buttonBlock = {
  Component: Button,
  template: {
    name: "button",
    key: "label",
    label: "Button Item",
    component: "group-list",
    defaultItem: {
        "label": "Button",
        "style": "primary",
        "link": "#"
    },
    fields: [
      {
        "label": "Label",
        "name": "label",
        "component": "text",
        "focusRing": false
      },
      {
        "label": "Style",
        "name": "style",
        "component": "select",
        "options": [ 
          {
            value: "primary",
            label: "Primary",
          },
          {
            value: "secondary",
            label: "Outline",
          },
          {
            value: "tertiary",
            label: "Outline Alt",
          },
        ],
        "focusRing": false
      },
      {
        "label": "Link",
        "name": "link",
        "component": "text",
        "focusRing": false
      }
    ],
  },
};