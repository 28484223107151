import React, { useContext, useEffect } from 'react'
import { useForm, usePlugin, useCMS } from 'tinacms'
import { InlineForm, InlineBlocks } from 'react-tinacms-inline'
import { blockHero } from '../blocks/Hero'
import { blockHeroSocials } from '../blocks/HeroSocials'
import { blockCounter } from '../blocks/Counter'
import { blockCategories } from '../blocks/Categories'
import { blockCourses } from '../blocks/Courses';
import { blockDiscount } from '../blocks/Discount';
import { blockTestimonials } from '../blocks/Testimonials';
import { blockAssociated } from '../blocks/Associated';
import { blockLegalAdvice } from '../blocks/LegalAdvice';
import { PersistentContext } from '../context';
import { SessionContext } from '../context';
import { ShopContext } from '../context';
import { MarkdownFieldPlugin } from 'react-tinacms-editor'
import { Helmet } from "react-helmet";

export default function PageHome(props) {
  const { saveContent, getItemData, production } = useContext(PersistentContext);
  const {  isAuthenticated, isSessionLoaded } = useContext(SessionContext);
  const { uploadLocalCart } = useContext(ShopContext);

  const cms = useCMS();

  const HOME_BLOCKS = {
    blockHero,
    blockHeroSocials,
    blockCounter,
    blockCategories,
    blockCourses,
    blockDiscount,
    blockTestimonials,
    blockAssociated,
    blockLegalAdvice
  };

  useEffect(()=>{
    if(production){
      if(isSessionLoaded){
        if(isAuthenticated){
          uploadLocalCart()
        }
      }
    }
  },[production, isSessionLoaded, isAuthenticated])

  const setUp = () => {
    const data = getItemData('home');
    const formConfig = {
      id: 'templateHome',
      fields: [{
        label: 'Secciones',
        name: 'blocks',
        component: 'blocks',
        templates: {
          'blockHero': blockHero.template,
          'blockHeroSocials': blockHeroSocials.template,
          'blockCounter': blockCounter.template,
          'blockCategories': blockCategories.template,
          'blockCourses': blockCourses.template,
          'blockDiscount': blockDiscount.template,
          'blockTestimonials': blockTestimonials.template,
          'blockAssociated': blockAssociated.template,
          'blockLegalAdvice': blockLegalAdvice.template
        }
      }],
      onSubmit: async (data) => {
        await saveContent({'home': data});
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data,
    }
    return formConfig;
  }
  const formConfig = setUp();
  const [, form] = useForm(formConfig);
  usePlugin([form, MarkdownFieldPlugin])
  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Inicio</title>
        <link rel="canonical" href="https://store.l3rn.io/home" />
      </Helmet>
      <InlineForm form={form}>
        <InlineBlocks name="blocks" blocks={HOME_BLOCKS} />
      </InlineForm>
    </div>
  )
}
