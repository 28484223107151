import React from 'react';
import { BlocksControls, InlineBlocks } from 'react-tinacms-inline'
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components'
import { boxesItemBlock } from '../BoxesItem/BoxesItem';

function Boxes({ index, data }) {
  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <Container> 
        <Subtitle>{data.subtitle}</Subtitle>
        <Title>{data.title}</Title>
        <InlineBlocks
          max={3}
          name="boxesList"
          direction="horizontal"
          className="boxes-blocks-container"
          blocks={BOXES_BLOCKS}
        />
      </Container>
    </BlocksControls>
  );
}


const Container = styled.div`
  width: 100%;
  padding: 70px 100px;
  & .boxes-blocks-container{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
  }
`

const Title = styled.div`
  color: #01214d;
  font-size: 50px;
  line-height: 62px;
  font-weight: 800;
  font-family: Arial;
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 36px;
  }
`

const Subtitle = styled.div`
  color: #f58e68;
  font-size: 16px;
  font-weight: 700;
  font-family: Arial;
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 36px;
  }
`

const BOXES_BLOCKS = {
  boxesItem: boxesItemBlock
};

export const BoxesTemplate = {
  "label": "Boxes",
  "key": "boxes",
  "fields": fields,
  "defaultItem": defaults
};

export const blockBoxes = {
  Component: Boxes,
  template: BoxesTemplate,
}