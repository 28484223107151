import React, { useState } from 'react';
import { BlocksControls, InlineBlocks, InlineImage } from 'react-tinacms-inline';
import { useCMS } from 'tinacms'
import { useMediaQuery } from '../../../../helpers/Hooks'
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components';
import { linkBlock } from '../Link/Link';
import cart from '../../assets/svgs/cart.svg'
import heart from '../../assets/svgs/heart.svg'
import menu from '../../assets/svgs/menu.svg'
import close from '../../assets/svgs/close.svg'

function Navbar({index, data}) {
  
  const cms = useCMS()
  const isMobile = useMediaQuery('(max-width: 980px)');
  
  const [visible, setVisible] = useState(false)

  const toggle = (option) => {
    setVisible(option ? option : !visible)
  }

  const handleCurtainClick = (e) => {
    e.stopPropagation()
    toggle()
  }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
        <Container 
            style={{
                position: data.position,
                backgroundColor: data.backgroundColor,
                ...(cms.enabled && { position: 'initial'})
            }}
        >
            <div style={{height: 40}}>
                <InlineImage
                    name="image"
                    parse={media => {
                        return media.previewSrc
                    }}
                    previewSrc={formValues => {
                        return formValues;
                    }}
                >
                    {
                    (props) => (
                        <img alt="navbar-logo" style={{height: 40, objectFit: 'contain'}} src={props.src}/>
                    )
                    }
                </InlineImage> 
            </div>           
            <div style={{display:'flex'}}>
                {
                    isMobile ?
                        <MobileMenu>
                            <div style={{display:'flex'}} onClick={()=>toggle()}>
                                {
                                    visible ? (
                                        <img style={{ width: 24, height: 20}} src={close} alt="close-icon" />
                                    ) : (
                                        <img style={{ width: 24, height: 24}} src={menu} alt="menu-icon" />
                                    )
                                }
                            </div>
                        
                            <MobileMenuList 
                                style={{
                                    display: visible ? 'block' : 'none',
                                    backgroundColor: data.backgroundColor 
                                }}
                            > 
                                <InlineBlocks
                                    name="links"
                                    blocks={LINK_BLOCKS}
                                    className={'navbar-menu-links'}
                                    itemProps={{
                                        linksTextColor: data.linksTextColor
                                    }}
                                />
                            </MobileMenuList>

                            {
                                visible && (
                                    <Curtain onClick={(e)=>handleCurtainClick(e)} />
                                )
                            }

                        </MobileMenu>
                    :
                        <>
                            <MenuContainer>
                                <InlineBlocks
                                    name="links"
                                    blocks={LINK_BLOCKS}
                                    className={'navbar-menu-links'}
                                    itemProps={{
                                        linksTextColor: data.linksTextColor
                                    }}
                                />
                            </MenuContainer>
                            {
                                data.showActions && (
                                    <Actions>
                                        <ActionsItem>
                                            <img alt="cart-icon" style={{width: 24, height: 24}} src={cart}/>
                                        </ActionsItem>
                                        <ActionsItem>
                                            <img alt="heart-icon" style={{width: 24, height: 24}} src={heart}/>
                                        </ActionsItem>
                                    </Actions>
                                )
                            }
                        </>
                }
            </div>
        </Container>
    </BlocksControls>
  );
}

const LINK_BLOCKS = {
    link: linkBlock,
};

const Container = styled.div`
    width: 100%;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 100px;
    z-index: 1;
    @media (max-width: 980px){
        padding: 0px 30px;
    }
`

const Actions = styled.div`
    display: flex;
    align-items: center;
`

const ActionsItem = styled.div`
    padding: 0px 10px;
`

const MenuContainer = styled.div`
    display: flex;
    align-items: center;
    & .navbar-menu-links{
        display: flex;
        align-items: center;
    }
`

const MobileMenu = styled.div`
    position: relative;
    margin-left: 5px;
`

const MobileMenuList = styled.div`
    position: absolute;
    z-index: 2;
    right: 0px;
    width: 320px;
    box-shadow: 1px 0px 12px -3px black;
    & .navbar-menu-links{
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        padding: 26px;
    }
`

const Curtain = styled.div`
    position: fixed; 
    top: 0; 
    left: 0; 
    width: 100vw; 
    height: 100vh; 
    z-index: 1;
`


export const NavbarTemplate = {
    "label": "Navbar",
    "key": "navbar",
    "fields": fields,
    "defaultItem": defaults
};
  
export const blockNavbar = {
    Component: Navbar,
    template: NavbarTemplate,
}