/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext } from 'react'
// components

import { SEOLayout } from './layout/Seo'
import NavbarLayout from './layout/Navbar'
import { FooterLayout } from './layout/Footer'
import ShopContextProvider from './context/ShopContext'
import ThemeContextProvider from './context/ThemeContext'
import { SessionContext } from './context/SessionContext'
import { CourseContextProvider } from './context/CourseContext'
import CategoryContextProvider from './context/CategoryContext'
import { PersistentContext } from './context'

import Home from './pages/Home'
import Cart from './pages/Cart'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import Contact from './pages/Contact'
import NotFound from './pages/NotFound'
import MyCourses from './pages/MyCourses'
import MyFavorites from './pages/MyFavorites'
import CourseCatalog from './pages/CourseCatalog'
import OrderCompleted from './pages/OrderCompleted'
import PaymentFailed from './pages/PaymentFailed'
import ForgotPassword from './pages/ForgotPassword'
import ForgotPasswordEmailSent from './pages/ForgotPasswordEmailSent'
import ForgotPasswordVerification from './pages/ForgotPasswordVerification'
import ChangePassword from './pages/ChangePassword'
import PrivacyPolicy from './pages/PrivacyPolicy'
import CourseDetail from './pages/CourseDetail'
import EventDetail from './pages/EventDetail'
import RefundPolicy from './pages/RefundPolicy'
import Course from './pages/Course'
import Terms from './pages/Terms'
import LegalAdvice from './pages/LegalAdvice'
import Faq from './pages/Faq'
import PageNotFound from './PageNotFound'
import About from './pages/About'
import Category from './pages/Category'
import Loader from './components/Loader'

function Template (props) {
  
  let { page } = props;
  const { loading, production } = useContext(PersistentContext)
  const { isSessionLoaded } = useContext(SessionContext)
  
  if (!page) {
    page = 'home'
  }

  const renderPageContent = () => {
    switch (page) {
      case 'home':
        return <Home />
      case 'contact':
        return <Contact />
      case 'sign-in':
        return <SignIn />
      case 'sign-up':
        return <SignUp />
      case 'not-found':
        return <NotFound />
      case 'cart':
        return <Cart />
      case 'order-completed':
        return <OrderCompleted />
      case 'payment-failed':
        return <PaymentFailed />
      case 'course-catalog':
        return <CourseCatalog />
      case 'my-courses':
        return <MyCourses />
      case 'my-favorites':
        return <MyFavorites />
      case 'course-detail':
        return <CourseDetail />
      case 'event-detail':
        return <EventDetail />
      case 'course':
        return <Course />
      case 'forgot-password':
        return <ForgotPassword />
      case 'forgot-password-email-sent':
        return <ForgotPasswordEmailSent />
      case 'forgotpasswordverification':
        return <ForgotPasswordVerification />
      case 'changepassword':
        return <ChangePassword />
      case 'faq':
        return <Faq />
      case 'terms':
        return <Terms />
      case 'legal-advice':
        return <LegalAdvice />
      case 'privacy-policy':
        return <PrivacyPolicy />
      case 'refund-policy':
        return <RefundPolicy />
      case 'about':
        return <About />
      case 'category':
        return <Category />
      default:
        return <PageNotFound />
    }
  }

  if (production && !isSessionLoaded) {
    return <Loader />
  }

  return (
    <ShopContextProvider>
      <CourseContextProvider>
        <CategoryContextProvider>
          <ThemeContextProvider>
            <SEOLayout />
            <NavbarLayout />
            {loading ? <Loader /> : renderPageContent()}
            <FooterLayout />
          </ThemeContextProvider>
        </CategoryContextProvider>
      </CourseContextProvider>
    </ShopContextProvider>
  )
}

export default Template
