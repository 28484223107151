import React from 'react';
import { BlocksControls } from 'react-tinacms-inline';
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components';
import Form from '../../../../components/forms/ContactForm'

function ContactForm({index, data, modifiedValues, forms}) {
    
    let form = null
  
    if (typeof index !== 'undefined' && data && data.forms && forms && forms.length) {
      const index = forms.findIndex(item => item.id === data.forms)
      form = forms[index]
    }

    const getAngle = () => {
        let angle = data.backgroundGradientAngle
        if(angle){
            if(angle >= 0 && angle <= 360){
                return angle
            }
        }
        return 0
    }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
        <Container 
            style={{
                background: `linear-gradient(${getAngle()}deg, ${data.backgroundGradientFirstColor}, ${data.backgroundGradientSecondColor})`
            }}
        >
            <ContactFormContainer>
                {
                    form && (
                        <Form
                        form={form}
                        button={'Submit'}
                        onSuccess={()=>alert('Contact Form Sent')}
                        labelType="placeholder"
                        containerStyles={formStyles.container}
                        titleStyles={formStyles.title}
                        descriptionStyles={formStyles.description}
                        separatorStyles={formStyles.separator}
                        formStyles={formStyles.form}
                        submitButtonStyles={formStyles.submitButton}
                        inputStyles={formStyles.input}
                        />
                    )
                }
            </ContactFormContainer>
        </Container>
    </BlocksControls>
  );
}

const Container = styled.div`
    min-height: 100px;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 70px 100px;
    @media (max-width: 980px){
        padding: 30px;
    }
`

const ContactFormContainer = styled.div`
    width: 600px;
    @media (max-width: 980px){
        width: 100%;
    }
`

const formStyles = {
  container: {
      width: '100%',
      padding: 0
  },
  title: {
      textAlign: 'center',
      fontSize: 26,
      fontFamily: 'Arial',
      fontWeight: '700',
      color: '#ffffff'
  },
  description: {
      color: '#ffffff',
      fontWeight: '500',
      fontFamily: 'Arial',
      fontSize: 20,
      textAlign: 'center'
  },
  separator: {
      display: 'none'
  },
  form: {
      paddingTop: 0
  },
  submitButton: {
      width: '100%',
      fontFamily: 'Arial',
      fontSize: 20,
      height: 50,
  },
  input: {
    height: '50px',
    backgroundColor: 'white',
    border: 'grey 1px solid',
    fontSize: 16
  }
}

export const ContactFormTemplate = {
    "label": "ContactForm",
    "key": "contactForm",
    "fields": fields,
    "defaultItem": defaults
}

export const blockContactForm = {
    Component: ContactForm,
    template: ContactFormTemplate,
}