import React from 'react';
import { BlocksControls, InlineTextarea, InlineImage } from 'react-tinacms-inline';
import '../App.css';

export const fields = [
  {
    "label": "Icon",
    "name": "icon",
    "component": "image",
    "parse": media => {
      return media.previewSrc
    },
    "previewSrc": formValues => {
      return formValues;
    },
    "focusRing": false
  },
  {
    "label": "Title",
    "name": "title",
    "component": "text",
    "focusRing": false
  },
  {
    "label": "Description",
    "name": "description",
    "component": "text",
    "focusRing": false
  },
];

function Counter({ index, data: { icon } }) {

  return (
    <BlocksControls focusRing={{ offset: 0 }} insetControls index={index}>
      <div className="counter-item-container" style={index === 0 ? styles.containerFirst : {}}>
        <div className="counter-item-header">
          <div style={styles.iconContainer}>
            <InlineImage
              name="icon"
              parse={media => {
                return media.previewSrc
              }}
              previewSrc={formValues => {
                return formValues;
              }}
            >
              {(props) => (
                <img alt="icon" style={styles.icon} src={props.src} />
              )}
            </InlineImage>
          </div>
          <div style={styles.title}>
            <InlineTextarea name="title" focusRing={false} />
          </div>
        </div>
        <div style={styles.description} className="counter-item-description">
          <InlineTextarea name="description" focusRing={false} />
        </div>
      </div>
    </BlocksControls>
  );
}

const styles = {
  containerFirst: {
    paddingLeft: '0px',
    borderLeft: 'none'
  },
  title: {
    fontSize: 22,
    color: '#58585c',
    fontWeight: '800',
    fontFamily: 'Muli',
    marginLeft: '10px',
    textAlign: 'left'
  },
  description: {
    textAlign: 'left',
    marginTop: '18px',
    color: '#999ea7',
    fontSize: '14px',
    fontFamily: 'Muli',
    fontWeight: '600'
  },
  iconContainer: {
    width: '31px'
  },
  icon: {
    width: '28px',
    height: '28px'
  }
};

export const counterBlock = {
  Component: Counter,
  template:
  {
    name: "counter",
    key: "label",
    label: "Counter Item",
    component: "group-list",
    itemProps: item => ({
      label: item.title,
    }),
    defaultItem: {
      "_template": "counter",
      "title": "+20 Países",
      "description": "Pellentesque quis consectetur tortor, egestas scelerisque velit. Nulla Leo odio.",
      "icon": require(`../assets/svgs/world.svg`),
    },
    fields: [
      {
        "label": "Icon",
        "name": "icon",
        "component": "image",
        "parse": media => {
          return media.previewSrc
        },
        "previewSrc": formValues => {
          return formValues;
        },
        "focusRing": false
      },
      {
        "label": "Title",
        "name": "title",
        "component": "text",
        "focusRing": false
      },
      {
        "label": "Description",
        "name": "description",
        "component": "text",
        "focusRing": false
      },
    ],
  },
};
