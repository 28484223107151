import React from 'react';
import { BlocksControls } from 'react-tinacms-inline';
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components'
import { useMediaQuery } from '../../../../helpers/Hooks'

function Image({ index, data }) {

  const isMobile = useMediaQuery('(max-width: 768px)')

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
        <Img
            alt="image"
            src={(isMobile && data.mobileImage) ? data.mobileImage : (!isMobile && data.image) ? data.image : data.image}
            style={{
                height: '100%',
                width: '100%'
            }}
        />
    </BlocksControls>
  );
}

const Img = styled.img`
  object-fit: contain;
`

export const ImageTemplate = {
  "label": "Image",
  "key": "image",
  "fields": fields,
  "defaultItem": defaults
};

export const blockImage = {
  Component: Image,
  template: ImageTemplate,
}