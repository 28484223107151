import React from 'react';
import { InlineTextarea, BlocksControls, InlineBlocks } from 'react-tinacms-inline';
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components';
import { buttonBlock } from '../Button/Button'
import { useMediaQuery } from '../../../../helpers/Hooks'

function Hero({ index, data }) {

  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <Container 
        style={{backgroundImage: `url(${isMobile ? data.mobileBackground : data.background})`}}
      >
        <Content>
          <Title>
            <InlineTextarea name="title" focusRing={false} />
          </Title>
          <Description>
            <InlineTextarea name="description" focusRing={false} />
          </Description>
          <Actions>
            <InlineBlocks
              name="buttons"
              blocks={BUTTON_BLOCKS}
              className={'hero-inline-blocks'}
            />
          </Actions>
        </Content>
      </Container>
    </BlocksControls>
  );
}

const BUTTON_BLOCKS = {
  button: buttonBlock,
};

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  width: 900px;
  text-align: center;
  color: white;
`

const Title = styled.div`
  color: white;
  font-size: 45px;
  line-height: 68px;
  font-weight: 700;
  font-family: Arial;
`

const Description = styled.div`
  font-size: 24px;
  font-family: Arial;
  line-height: 40px;
  padding-top: 22px;
  color: white;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 22px;
    padding-top: 18px;
  }
`

const Actions = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  & .hero-inline-blocks{
    display: flex;
    column-gap: 20px;
    row-gap: 16px;
    flex-wrap: wrap;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const HeroTemplate = {
  "label": "Hero",
  "key": "hero",
  "fields": fields,
  "defaultItem": defaults
};

export const blockHero = {
  Component: Hero,
  template: HeroTemplate,
}