import React, { useContext } from 'react';
import { useForm, usePlugins, useCMS } from 'tinacms';
import { InlineForm } from 'react-tinacms-inline';
import PrivacyPolicyPage from '../blocks/PrivacyPolicy';
import { PersistentContext } from '../context';
import { MarkdownFieldPlugin } from 'react-tinacms-editor'

export default function PagePrivacyPolicy(props) {
  const { saveContent, getItemData } = useContext(PersistentContext);

  const cms = useCMS();

  const clearItemData = async () => {
    await saveContent({'privacy-policy': null});
    window.location.reload()
  }
  
  const setUp = () => {
    const data = getItemData('privacy-policy');
    if(data.blocks){
      if(data.blocks[0]){
        if(data.blocks[0]._template === "blockPrivacyPolicy"){
          clearItemData() 
        }
      }
    }
    const formConfig = {
      id: 'templatePrivacyPolicy',
      fields: [
        {
          "label": "Title",
          "name": "title",
          "component": "text",
          "focusRing": false
        },
        {
          "label": "Privacy Policy",
          "name": "policy",
          "component": "markdown",
          "focusRing": false
        }
      ],
      onSubmit: async (data) => {
        await saveContent({'privacy-policy': data});
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data,
    }
    return formConfig;
  }
  const formConfig = setUp();
  const [modifiedValues, form] = useForm(formConfig);
  usePlugins([form, MarkdownFieldPlugin])
  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`}>
      <InlineForm form={form}>
        <PrivacyPolicyPage data={modifiedValues} />
      </InlineForm>
    </div>
  )
}