import React from 'react';
import { InlineTextarea, BlocksControls } from 'react-tinacms-inline';
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

function Summary({ index, data }) {

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <Wrapper 
        style={{
          backgroundColor: data.backgroundColor,
        }}
      >
        
        <Container>
          <Title style={{marginBottom: 0, color: data.titleColor}}>
            <InlineTextarea name="title" focusRing={false} />
          </Title>

            <Content 
                style={{
                    justifyContent: data.contentAlign === 'left' ? 'flex-start' :
                                    data.contentAlign === 'center' ? 'center' :
                                    data.contentAlign === 'right' ? 'flex-end' : 'flex-start',
                    textAlign: data.textAlign, 
                    color: data.contentColor
                }}
            >
                <ReactMarkdown 
                    skipHtml={true} 
                    children={data.content} 
                />
            </Content>
        </Container>

      </Wrapper>
    </BlocksControls>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 70px 100px;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  @media (max-width: 980px) {
    padding: 30px;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: center;
    row-gap: 30px;
  }
`

const Container = styled.div`
  width: 100%;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  & ul{
      list-style: disc;
  }
`

const Title = styled.h2`
  width: 100%;
  font-size: 30px;
  font-weight: 800;
  font-family: Arial;
  text-align: left;
`

export const SummaryTemplate = {
  "label": "Summary",
  "key": "summary",
  "fields": fields,
  "defaultItem": defaults
};

export const blockSummary = {
  Component: Summary,
  template: SummaryTemplate,
}