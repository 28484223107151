import React, { useContext } from 'react';
import { useForm, usePlugins, useCMS } from 'tinacms';
import { InlineForm, InlineTextarea } from 'react-tinacms-inline';
import { PersistentContext } from '../context';
import { MarkdownFieldPlugin } from 'react-tinacms-editor'
import ReactMarkdown from 'react-markdown'
import { Helmet } from "react-helmet";
import '../App.css';

export default function PageLegalAdvice(props) {
  const { saveContent, getItemData } = useContext(PersistentContext);

  const cms = useCMS();

  const setUp = () => {
    const data = getItemData('legal-advice');
    
    const formConfig = {
      id: 'templateLegalAdvice',
      fields: [
        {
          "label": "Title",
          "name": "title",
          "component": "text",
          "focusRing": false
        },
        {
          "label": "Content",
          "name": "content",
          "component": "markdown",
          "focusRing": false
        }
      ],
      onSubmit: async (data) => {
        await saveContent({ 'legal-advice': data });
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data,
    }
    return formConfig;
  }
  const formConfig = setUp();
  const [modifiedValues, form] = useForm(formConfig);
  usePlugins([form, MarkdownFieldPlugin])
  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`}>
      <InlineForm form={form}>
        <div className="page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Aviso Legal</title>
                <link rel="canonical" href="https://store.l3rn.io/legal-advice" />
            </Helmet>
            <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#ffffff' }}>
                <div style={styles.content} className="general-padding">
                <div style={styles.header}>
                    <div style={styles.title}>
                        <InlineTextarea name="title" focusRing={false}/>
                    </div>
                </div>
                <div style={styles.separator} />
                <div className="markdown" style={styles.markdown}>
                    <ReactMarkdown skipHtml={true} children={modifiedValues.content} />
                </div>
                </div>
            </div>
        </div>
      </InlineForm>
    </div>
  )
}

const styles = {
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px 0px'
    },
    header: {
        width: '100%'
    },
    title: {
        color: 'rgb(67, 67, 74)',
        fontWeight: '800',
        fontSize: 34,
        fontFamily: 'Muli'
    },
    subtitle: {
        fontWeight: '600',
        fontSize: 19,
        fontFamily: 'Muli',
        color: 'rgb(158, 162, 180)',
        marginTop: 14,
        lineHeight: '30px',
        width: 750
    },
    separator: {
        backgroundColor: '#eaebec',
        height: 3,
        width: '100%',
        marginTop: 36,
        marginBottom: 26
    },
    markdown: {
        textAlign: 'left'
    }
  }