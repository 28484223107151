import logo from '../../assets/images/logo.png';

export default {
    "_template": "blockNavbar",
    "image": logo,
    "links": [
        {
            "_template": "link",
            "label": "HOME",
            "link": "#"
        },
        {
            "_template": "link",
            "label": "ABOUT US",
            "link": "#"
        },
        {
            "_template": "link",
            "label": "OUR PRODUCTS",
            "link": "#"
        },
        {
            "_template": "link",
            "label": "CONTACT US",
            "link": "#"
        }
    ]
}