import { API } from 'aws-amplify'

export default {
  post: async (url, body, headers) => {
    try {
      const data = await API.post('API', url, { body, headers })
      return { data }
    } catch (error) {
      throw error;
    }
  },
  get: async (url, headers) => {
    try {
      const data = await API.get('API', url, { headers })
      return { data }
    } catch (error) {
      throw error;
    }
  },
  put: async (url, body) => {
    try {
      const data = await API.put('API', url, { body })
      return { data }
    } catch (error) {
      throw error;
    }
  },
  del: async url => {
    try {
      const data = await API.del('API', url)
      return { data }
    } catch (error) {
      throw error;
    }
  }
}
