import logo from '../../assets/images/logo.png'
import background from '../../assets/images/footer-background.png';

export default {
    "_template": "blockFooter",
    "logo": logo,
    "background": background,
    "links": [
        {
            "_template": "link",
            "label": "Home",
            "link": "#"
        },
        {
            "_template": "link",
            "label": "About us",
            "link": "#"
        },
        {
            "_template": "link",
            "label": "Our Products",
            "link": "#"
        },
        {
            "_template": "link",
            "label": "Contact Us",
            "link": "#"
        }
    ]
}