import React, {useContext} from 'react';
import { Helmet } from "react-helmet";
import { InlineTextarea } from 'react-tinacms-inline';
import MainButton from '../components/buttons/MainButton'
import styled from 'styled-components'
import { SessionContext } from '../context';
import { useHistory } from 'react-router-dom';

function OrderCompleted({ index, data }) {

    const history = useHistory();

    const { isAuthenticated } = useContext(SessionContext);

  return (
    <div className="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Orden Completada</title>
        <link rel="canonical" href="https://store.l3rn.io/order-completed" />
      </Helmet>
      <Container style={{backgroundImage: `url(${data.background})`}}>
          <div style={styles.title}>
            <InlineTextarea name="title" focusRing={false} />
          </div>
          <div style={styles.subtitle}>
            <InlineTextarea name="subtitle" focusRing={false} />
          </div>
          <div style={styles.subtitle}>
              {
                  !isAuthenticated && (
                    'También recibirás un correo electrónico con los datos para acceder a tu cuenta'
                  )
              }
          </div>
          <div style={{ marginTop: 40 }}>
              {
                isAuthenticated ? 
                    <MainButton 
                        onPress={() => history.push('my-courses')} 
                        type="primary"
                        styles={{ padding: '6px 30px' }}
                        label={'Ir a mis cursos'} 
                    />
                :
                    <MainButton 
                        onPress={() => history.push('home')} 
                        type="primary"
                        styles={{ padding: '6px 30px' }}
                        label={'Regresar a la página principal'} 
                    />
              }
          </div>
      </Container>
    </div>
  )
}

const Container = styled.div`
    display: flex; 
    flex-direction: column;
    width: calc(100% - 60px);
    justify-content: center;
    min-height: 90vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0px 30px;
    @media (max-width: 768px) {
        background-position: 0%
    }
`

const styles = {
    title: {
        fontWeight: '800',
        fontSize: 32,
        fontFamily: 'Muli',
        color: '#434348',
        marginBottom: 12
    },
    subtitle: {
        fontSize: 20,
        fontFamily: 'Muli',
        fontWeight: '600',
        color: '#969eb3'
    }
}

export default OrderCompleted