import React, { useState } from 'react'
import '../App.css';
import styled from 'styled-components';
import chevronRightWhite from '../assets/svgs/chevron-right-white.svg'
import chevronLeftWhite from '../assets/svgs/chevron-left-white.svg'

function CategoriesSlider({ categories, selectedCategory, onSelectCategory }) {

    const [leftNavigationVisible, setLeftNavigationVisible] = useState(false);
    const [rightNavigationVisible, setRightNavigationVisible] = useState(true);

    const scroll = (orientation) => {
        let slider = document.getElementById('courses-by-category-slider')
        let length = slider.firstChild.offsetWidth

        let scrollWidth = slider.scrollWidth
        let offsetWidth = slider.offsetWidth

        length = orientation === 'left' ? (length * -1) : length
        slider.scrollLeft += length

        setTimeout(() => {
            setRightNavigationVisible( (offsetWidth + slider.scrollLeft) >= scrollWidth ? false : true )
            setLeftNavigationVisible(slider.scrollLeft >= 1 ? true : false )
        }, 500)
    }

    const allCategory = {
        id: 0,
        name: 'Todos',
        index: -1
    }

  return (
    <>
        {
            categories && (
                categories.length <= 0 ?
                null
            :
                <>
                    <div id="courses-by-category-slider" style={styles.categorySlider} className="hidden-scrollbar">
                        <Category
                            className="touchable-opacity"
                            onClick={()=>onSelectCategory(allCategory)} 
                            style={{...(allCategory.id === selectedCategory.id ? styles.categoryActive : null)}} 
                            key={allCategory.index}
                        >
                            {allCategory.name}
                        </Category>
                        {
                            categories.map((category, index) => {
                                return (
                                    <Category
                                        className="touchable-opacity"
                                        onClick={()=>onSelectCategory(category)} 
                                        style={{...(category.id === selectedCategory.id ? styles.categoryActive : null)}} 
                                        key={index}
                                    >
                                        {category.name}
                                    </Category>
                                )
                            })
                        }
                    </div>
                    {
                        leftNavigationVisible ?
                            <div
                                className="touchable-opacity"
                                onClick={()=>scroll('left')}
                                style={{...styles.navigation, ...styles.navigationLeft}}
                            >
                                <img style={{width: 14, height: 14}} src={chevronLeftWhite} alt="navigation-icon" />
                            </div>
                        :
                        null
                    }
                    {
                        rightNavigationVisible ?
                            <div
                                className="touchable-opacity"
                                onClick={()=>scroll()}
                                style={{...styles.navigation, ...styles.navigationRight}}
                            >
                                <img style={{width: 14, height: 14}} src={chevronRightWhite} alt="navigation-icon" />
                            </div>
                        :
                            null
                    }
                </>
            )
        }
    </>
  );
}

const styles = {
    categorySlider: {
        display: 'flex',
        width: '100%',
        overflow: 'scroll',
        scrollBehavior: 'smooth'
    },
    categoryActive: {
        backgroundColor: '#3d506a',
        color: 'white'
    },
    navigation: {
        position: 'absolute',
        backgroundColor: '#016cff',
        height: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 36
    },
    navigationRight: {
        top: 0,
        right: 0,
    },
    navigationLeft: {
        top: 0,
        left: 0,
    }
}

export const Category = styled.div`
    background-color: #f8f8fa;
    color: #828999;
    width: 16.6666667%;
    font-weight: 800;
    font-size: 17px;
    font-family: Muli;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    border-right: #828999 1px solid;
    transition: background-color 0.4s, color 0.4s, opacity 0.4s;
    @media (max-width: 980px) {
        width: 50%;
    }
    @media (max-width: 480px) {
        width: 100%;
    }
`;

export default CategoriesSlider