import React from 'react';
import { BlocksControls, InlineBlocks, InlineImage } from 'react-tinacms-inline';
import { linkBlock } from '../Link/Link';
import { socialBlock } from '../Social/Social';
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components';

function Footer({index, data}) {
  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
        <Container 
            style={{
                ...(data.backgroundType === 'backgroundImage' && {backgroundImage: `url(${data.backgroundImage})`}),
                ...(data.backgroundType === 'backgroundColor' && {backgroundColor: data.backgroundColor})
            }}
        >
            <div style={{height: 40}}>
                <InlineImage
                    name="logo"
                    parse={media => {
                        return media.previewSrc
                    }}
                    previewSrc={formValues => {
                        return formValues;
                    }}
                >
                    {
                        (props) => (
                            <img alt="footer-logo" style={{height: 40}} src={props.src}/>
                        )
                    }
                </InlineImage>
            </div>
            <MenuContainer>
                <InlineBlocks
                    name="links"
                    blocks={LINK_BLOCKS}
                    direction="horizontal"
                    className={'footer-menu'}
                    itemProps={{
                        linksTextColor: data.linksTextColor
                    }}
                />
            </MenuContainer>
            {
                data.showSocials && (
                    <Socials>
                        <InlineBlocks
                            name="socials"
                            blocks={SOCIAL_BLOCKS}
                            direction="horizontal"
                            className={'footer-socials'}
                            itemProps={{
                                linksIconColor: data.socialsIconColor
                            }}
                        />  
                    </Socials>
                )
            }
        </Container>
    </BlocksControls>
  );
}

const LINK_BLOCKS = {
    link: linkBlock,
};
const SOCIAL_BLOCKS = {
    social: socialBlock,
};

const Container = styled.div`
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 100px;
    background-size: cover;
    background-repeat: no-repeat;
    @media (max-width: 980px){
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 30px;
        row-gap: 30px;
        height: auto;
    }
`

const MenuContainer = styled.div`
    display: flex;
    align-items: center;
    & .footer-menu{
        display: flex;
        align-items: center;
        @media(max-width: 980px){
            flex-direction: column;
            row-gap: 20px;
        }
    }
`

const Socials = styled.ul`
    display: flex;
    margin: 0px;
    & .footer-socials{
        display: flex;
        align-items: center;
        margin: 0px;
    }
`

export const FooterTemplate = {
    "label": "Footer",
    "key": "footer",
    "fields": fields,
    "defaultItem": defaults
};
  
export const blockFooter = {
    Component: Footer,
    template: FooterTemplate,
}