import React, { useState, useContext } from 'react';
import styled from 'styled-components'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core';
import SubmitButton from '../buttons/SubmitButton';
import { PersistentContext } from '../../context';
import network from "../../utils/network";
import { makeToast } from '../../utils';
import Message from './fields/Message';
import Email from './fields/Email';
import Phone from './fields/Phone';
import Name from './fields/Name';

function ContactForm(props) {
  const { form = {} } = props;
  const history = useHistory()
  const labelType = props.labelType ? props.labelType : 'label'
  const mainTitle = props.mainTitle ? props.mainTitle : false
  const { enqueueSnackbar } = useSnackbar()
  const { keys, id, production } = useContext(PersistentContext);

  const [body, setBody] = useState({})
  const [loading, setLoading] = useState(false)

  const validate = () => {
    for (let index = 0; index < form.fields.length; index++) {
      const element = form.fields[index];
      if (element.required) {
        if (!body[element.name] || body[element.name].trim() === '') {
          return { success: false, error: `${element.title} es requerido` };
        }
      }
    }
    return { success: true };
  }

  const redirect = (url) => {
    if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
      window.open(url)
    } else {
      history.push(`/${url}`)
    }
  }

  const submit = async (ev) => {
    ev && ev.preventDefault();
    const isValid = validate();
    if (isValid.success) {
      setLoading(true)
      try {
        const results = [];
        for (let index = 0; index < form.fields.length; index++) {
          const element = form.fields[index];
          results.push({name: element.name, value: body[element.name], type: element.type})
        }
        const siteId = production ? keys.id : id
        const response = await network.post(`public/site/${siteId}/form/${form.id}/entries`, {results});
        if (response.data && response.data.error) {
          makeToast(enqueueSnackbar, response.data.error, 'error')
        } else {
          await setBody({})
          form.redirect && redirect(form.redirect)
        }
      } catch (error) {
        makeToast(enqueueSnackbar, error, 'error')
      }
      setLoading(false)
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  return (
    <Container style={{...props.containerStyles}}>
      {
        mainTitle ?
          <MainTitle style={{marginBottom: 0, ...props.titleStyles}}>{form.title}</MainTitle>
        :
          <Title style={{marginBottom: 0, ...props.titleStyles}}>{form.title}</Title>
      }
      {
        form.description !== '' && (
          <Description style={{...props.descriptionStyles}}>{form.description}</Description>
        )
      }
      <Separator style={{...props.separatorStyles}} />
      <Form style={{...props.formStyles}} onSubmit={submit}>
        {
          form && form.fields && form.fields.map((item, index) => {
            switch (item.type) {
              case 'text':
                return (
                  <Name
                    key={`input-${index}`}
                    label={item.title}
                    labelType={labelType}
                    value={body[item.name] || ''}
                    onChange={(value) => setBody({...body, [item.name]: value})}
                    inputStyles={props.inputStyles}
                  />
                )
              case 'fullName':
                return (
                  <Name
                    key={`input-${index}`}
                    label={item.title}
                    labelType={labelType}
                    value={body[item.name] || ''}
                    onChange={(value) => setBody({...body, [item.name]: value})}
                    inputStyles={props.inputStyles}
                  />
                )
              case 'firstName':
                return (
                  <Name
                    key={`input-${index}`}
                    label={item.title}
                    labelType={labelType}
                    value={body[item.name] || ''}
                    onChange={(value) => setBody({...body, [item.name]: value})}
                    inputStyles={props.inputStyles}
                  />
                )
              case 'lastName':
                return (
                  <Name
                    key={`input-${index}`}
                    label={item.title}
                    labelType={labelType}
                    value={body[item.name] || ''}
                    onChange={(value) => setBody({...body, [item.name]: value})}
                    inputStyles={props.inputStyles}
                  />
                )
              case 'textarea':
                return (
                  <Message
                    key={`input-${index}`}
                    label={item.title}
                    labelType={labelType}
                    value={body[item.name] || ''}
                    onChange={(value) => setBody({...body, [item.name]: value})}
                    inputStyles={props.inputStyles}
                  />
                )
              case 'email':
                return (
                  <Email
                    key={`input-${index}`}
                    label={item.title}
                    labelType={labelType}
                    value={body[item.name] || ''}
                    onChange={(value) => setBody({...body, [item.name]: value})}
                    inputStyles={props.inputStyles}
                  />
                )
              case 'phone':
                return (
                  <Phone
                    key={`input-${index}`}
                    label={item.title}
                    labelType={labelType}
                    value={body[item.name] || ''}
                    onChange={(value) => setBody({...body, [item.name]: value})}
                    inputStyles={props.inputStyles}
                  />
                )
              default:
                return null;
            }
          })
        }
        <SubmitButtonContainer style={{...props.submitButtonContainerStyles}}>
          <SubmitButton
            loading={loading}
            onPress={() => ({})}
            label={loading ? (<CircularProgress style={{width: 16, height: 16, color: '#00A8D8'}}/>) : props.button}
            styles={{ 
              width: 225,
              ...props.submitButtonStyles
            }}
          />
        </SubmitButtonContainer>
      </Form>
    </Container>
  );
}

const Container = styled.div`
  width: 600px;
  padding: 0px 36px;
  @media (max-width: 768px) {
    width: 418px;
    padding: 30px 36px;
  }
  @media (max-width: 480px) {
    width: 100%;
    padding: 30px 15px;
  }
`

const MainTitle = styled.h1`
  color: #43434a;
  font-weight: 800;
  font-family: Muli;
  text-align: left;
  @media (max-width: 480px) {
    font-family: Muli;
    font-size: 26px;
  }
`

const Title = styled.h2`
  color: #43434a;
  font-weight: 800;
  font-family: Muli;
  text-align: left;
  @media (max-width: 480px) {
    font-family: Muli;
    font-size: 26px;
  }
`

const Description = styled.div`
  padding-top: 8px;
  padding-bottom: 21px;
  font-weight: 600;
  font-size: 17px;
  font-family: Muli;
  color: #9ea2b4;
  text-align: left;
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #016cff;
`

const Form = styled.form`
  padding-top: 30px
`

const SubmitButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export default ContactForm;