import React from 'react';
import '../../../App.css';

function Code(props) {
  
  const _keyDownHandler = (e) => { 
    e.key === 'Enter' && e.preventDefault();
    return e.key === 'Enter' && props.onEnter ? props.onEnter() : null
  }

  const styles = {
    field: {
      marginBottom: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    label: {
      fontWeight: '600',
      fontSize: 14,
      fontFamily: 'Muli',
      color: '#8891a6',
      marginBottom: 6
    }
  }

  return (
    <div style={{...styles.field, ...props.containerStyle}}>
        {
          (props.labelType === 'label' || !props.labelType) && (
            <div style={styles.label}>{props.label ? props.label : 'Código'}</div> 
          )
        }
        <input 
            type="text"
            className="name-input"
            style={{...props.inputStyles}}
            value={props.value}
            onKeyDown={_keyDownHandler}
            onChange={(e)=>props.onChange(e.target.value)} 
            placeholder={props.labelType === 'placeholder' ? props.label ? props.label : "Código" : ""} 
        />
    </div>
  );
}

export default Code;