
export default [
    {
      "label": "Main Image",
      "name": "image",
      "component": "image",
      "parse": media => {
        return media.previewSrc
      },
      "previewSrc": formValues => {
        return formValues;
      },
      "focusRing": false
    },
    {
      "label": "Show Image",
      "name": "showImage",
      "component": "toggle",
      "description": "Show Image",
      "toggleLabels": false,
      "focusRing": false
    },
    {
      "label": "Image Width",
      "name": "imageWidth",
      "component": "number",
      "description": "In pixels",
      "step": 1,
      "focusRing": false
    },
    {
      "label": "Image Height",
      "name": "imageHeight",
      "component": "number",
      "description": "In pixels",
      "step": 1,
      "focusRing": false
    },
    {
      "label": "Show Video or Image",
      "name": "type",
      "component": "select",
      "options": [ 
        {
          value: "video",
          label: "Video",
        },
        {
          value: "image",
          label: "Image",
        }
      ],
      "focusRing": false
    },
    {
      "label": "Video URL",
      "name": "videoUrl",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Autoplay",
      "name": "autoplay",
      "component": "toggle",
      "description": "El video se reproducirá automaticamente",
      "toggleLabels": false,
      "focusRing": false
    },
    {
      "label": "Video Width",
      "name": "videoWidth",
      "component": "number",
      "description": "In pixels",
      "step": 1,
      "focusRing": false
    },
    {
      "label": "Video Height",
      "name": "videoHeight",
      "component": "number",
      "description": "In pixels",
      "step": 1,
      "focusRing": false
    },
    {
      "label": "Cover Image",
      "name": "coverImage",
      "component": "image",
      "parse": media => {
        return media.previewSrc
      },
      "previewSrc": formValues => {
        return formValues;
      },
      "focusRing": false
    },
    {
      "label": "Cover Image Width",
      "name": "coverImageWidth",
      "component": "number",
      "description": "In pixels",
      "step": 1,
      "focusRing": false
    },
    {
      "label": "Cover Image Height",
      "name": "coverImageHeight",
      "component": "number",
      "description": "In pixels",
      "step": 1,
      "focusRing": false
    },
    {
      "label": "Cover Image Spacing",
      "name": "coverImageSpacing",
      "component": "number",
      "description": "In pixels",
      "step": 1,
      "focusRing": false
    },
    {
      "label": "Order",
      "name": "order",
      "component": "select",
      "options": [ 
        {
          value: 1,
          label: 'Normal',
        },
        {
          value: 2,
          label: 'Flipped',
        }
      ],
      "focusRing": false
    },
    {
        "label": "Background Gradient First Color",
        "name": "backgroundGradientFirstColor",
        "colorFormat": "hex",
        "widget": "sketch",
        "component": "color",
        "focusRing": false
    },
    {
        "label": "Background Gradient Second Color",
        "name": "backgroundGradientSecondColor",
        "colorFormat": "hex",
        "widget": "sketch",
        "component": "color",
        "focusRing": false
    },
    {
        "label": "Background Gradient Angle",
        "name": "backgroundGradientAngle",
        "component": "number",
        "step": 45,
        "focusRing": false
    },
]