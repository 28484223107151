export default [
  {
    "label": "Background",
    "name": "background",
    "component": "image",
    "parse": media => {
      return media.previewSrc
    },
    "previewSrc": formValues => {
      return formValues;
    },
    "focusRing": false
  },
  {
    "label": "Mobile Background",
    "name": "mobileBackground",
    "component": "image",
    "parse": media => {
      return media.previewSrc
    },
    "previewSrc": formValues => {
      return formValues;
    },
    "focusRing": false
  },
  {
    "label": "Title",
    "name": "title",
    "component": "text",
    "focusRing": false
  },
  {
    "label": "Description",
    "name": "description",
    "component": "text",
    "focusRing": false
  },
  {
    "label": "Searchbar",
    "name": "searchbar",
    "component": "toggle",
    "description": "Show searchbar",
    "toggleLabels": false,
    "focusRing": false
  }
]