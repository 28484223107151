import React, { useState } from 'react'
import NavigationLink from './NavigationLink'
import CategoriesDropdown from './CategoriesDropdown';
import menu from '../assets/svgs/menu.svg'
import close from '../assets/svgs/close.svg'

function MobileMenu({data}) {

  const [visible, setVisible] = useState(false)

  const toggle = (option) => {
    setVisible(option ? option : !visible)
  }

  const handleCurtainClick = (e) => {
    e.stopPropagation()
    toggle()
  }

  return (
    <div style={styles.container}>
        <div style={{display:'flex'}} onClick={()=>toggle()}>
            {
                visible ? (
                    <img style={styles.closeIcon} src={close} alt="close-icon" />
                ) : (
                    <img style={styles.menuIcon} src={menu} alt="menu-icon" />
                )
            }
        </div>
       
        <ul 
            style={{
                ...styles.list,
                visibility: visible ? 'visible' : 'hidden'
            }}
        > 
            {
                data && data.map((item, index) => (
                    <li key={`${item.label}-${index}`} style={styles.listItemStyle}>
                        {
                            item.type === "categories" ? (
                                <CategoriesDropdown 
                                    label={item.label}
                                    navigationLinkStyle={styles.navigationLinkStyle}
                                    mobile={true}
                                    onRedirect={()=>toggle(false)}
                                />
                            ) : 
                            <NavigationLink 
                                styles={styles.navigationLinkStyle} 
                                label={item.label} 
                                href={ item.type === "internalLink" ? item.internalLink : item.type === "externalLink" ? item.externalLink : item.link } 
                                onRedirect={()=>toggle(false)}
                            />
                        }
                    </li>
                ))
            }
        </ul>

        {
            visible && (
                <div onClick={(e)=>handleCurtainClick(e)} style={styles.curtain} />
            )
        }

    </div>
  );
}

const styles = {
    container : {
      position: 'relative',
      marginLeft: 15
    },
    menuIcon: {
        width: 24,
        height: 24
    },
    closeIcon: {
        width: 24,
        height: 20
    },
    list: {
        margin: 0,
        zIndex: 2,
        listStyle: 'none',
        position: 'absolute',
        backgroundColor: 'white',
        width: 210,
        right: 0,
        top: 40,
        borderRadius: 4,
        padding: '10px 30px',
        boxShadow: 'rgb(0 0 0 / 24%) 0px 0px 12px 0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    listItemStyle: {
        textAlign: 'left',
        marginBottom: 10
    },
    navigationLinkStyle: {
        padding: 0
    },
    curtain: { 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100vw', 
        height: '100vh', 
        zIndex: 1 
    }
}

export default MobileMenu;