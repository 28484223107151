import React from 'react';
import { InlineTextarea, BlocksControls, InlineBlocks } from 'react-tinacms-inline';
import defaults from './Hero2.defaults'
import { useMediaQuery } from '../helpers/Hooks'
import { buttonBlock } from './Button';

function Hero2({ index, data }) {

  const isMobile = useMediaQuery('(max-width: 768px)');

  const styles = {
    container: {
      width: '100%',
      minHeight: 520,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      display:'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    },
    content: {
      width: 885,
      padding: isMobile ? '36px 30px 140px' : '70px 100px',
      textAlign: 'left',
      color: 'white'
    },
    title: {
      color:'white',
      fontSize: isMobile ? 30 : 40,
      padding: '15px 0px',
      fontWeight: '800',
      fontFamily: 'Muli'
    },
    description: {
      fontSize: isMobile ? 22 : 22,
      lineHeight: '30px',
      padding: isMobile ? 0 : '15px 0px',
      color: 'white',
      fontFamily: 'Muli'
    },
    actions: {
      marginTop: 26,
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row'
    },
    button: {
      fontSize: isMobile ? 22 : 16,
      marginBottom: isMobile ? 30 : 0,
      fontFamily: 'Muli'
    },
  }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <div style={{...styles.container, backgroundImage: `url(${data.background})`}}>
          <div style={styles.content}>
              <div style={styles.title}>
                <InlineTextarea name="title" focusRing={false} />
              </div>
              <div style={styles.description}>
                <InlineTextarea name="description" focusRing={false} />
              </div>
              <div style={styles.actions}>
                <InlineBlocks
                  name="buttons"
                  blocks={BUTTON_BLOCKS}
                  className="hero-actions"
                />
              </div>
          </div>
      </div>
    </BlocksControls>
  );
}

const BUTTON_BLOCKS = {
  button: buttonBlock,
};

export const Hero2Template = {
  "label": "Hero2",
  "key": "hero2",
  "fields": [
    {
      "label": "Background",
      "name": "background",
      "component": "image",
      "parse": media => {
        return media.previewSrc
      },
      "previewSrc": formValues => {
        return formValues;
      },
      "focusRing": false
    },
    {
      "label": "Title",
      "name": "title",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Description",
      "name": "description",
      "component": "text",
      "focusRing": false
    },
    {
      label: 'Blocks Buttons',
      name: 'buttons',
      component: 'blocks',
      templates: {
        button: buttonBlock.template,
      },
    }
  ],
  "defaultItem": defaults
};

export const blockHero2 = {
  Component: Hero2,
  template: Hero2Template,
}