import { boxesItemBlock } from '../BoxesItem/BoxesItem';

export default [
    {
      label: 'Boxes List',
      name: 'boxesList',
      component: 'blocks',
      templates: {
        boxesItem: boxesItemBlock.template,
      }
    },
    {
      "label": "Title",
      "name": "title",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Box Image Width",
      "name": "boxImageWidth",
      "component": "number",
      "description": "In pixels",
      "step": 1,
      "focusRing": false
    },
    {
      "label": "Box Image Height",
      "name": "boxImageHeight",
      "component": "number",
      "description": "In pixels",
      "step": 1,
      "focusRing": false
    },
    {
      "label": "Box Padding",
      "name": "boxPadding",
      "component": "number",
      "description": "In pixels",
      "step": 1,
      "focusRing": false
    },
    {
      "label": "Box Shadow",
      "name": "boxShadow",
      "component": "toggle",
      "description": "Mostrar box shadow en todos los elementos",
      "toggleLabels": false,
      "focusRing": false
    },
    {
      "label": "Boxes Background Color",
      "name": "boxesBackgroundColor",
      "colorFormat": "hex",
      "widget": "sketch",
      "component": "color",
      "focusRing": false
    },
    {
      "label": "Text Color",
      "name": "textColor",
      "colorFormat": "hex",
      "widget": "sketch",
      "component": "color",
      "focusRing": false
    },
    {
      "label": "Boxes Title Color",
      "name": "boxesTitleColor",
      "colorFormat": "hex",
      "widget": "sketch",
      "component": "color",
      "focusRing": false
    },
    {
      "label": "Boxes Text Color",
      "name": "boxesTextColor",
      "colorFormat": "hex",
      "widget": "sketch",
      "component": "color",
      "focusRing": false
    },
    {
      "label": "Background Color",
      "name": "backgroundColor",
      "colorFormat": "hex",
      "widget": "sketch",
      "component": "color",
      "focusRing": false
    },
  ]