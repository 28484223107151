import React from 'react';
import { InlineTextarea, BlocksControls } from 'react-tinacms-inline';
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components'
import MainButton from '../../components/buttons/MainButton'

function TextImage({ index, data }) {

  const isFlipped = () => {
    return data.order === 2 ? true : false
  }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <Container 
        style={{
          backgroundColor: data.backgroundColor,
          flexDirection: isFlipped() ? 'row-reverse' : 'row'
        }}
      >
        <ImageContainer
          style={{
            maxWidth: `${data.imageWidth}px`,
            maxHeight: `${data.imageHeight}px`,
            width: `${data.imageWidth}px`,
            height: `${data.imageHeight}px`
          }}
        >
          <Image
            alt="image"
            src={data.image}
            style={{
              margin: isFlipped() ? `0px ${data.imageSpacing}px 0px 0px` : `0px 0px 0px ${data.imageSpacing}px`,
              height: '100%',
              width: '100%'
            }}
          />
        </ImageContainer>
        <Content style={{ margin: isFlipped() ? `0px ${data.contentSpacing}px 0px 0px` : `0px 0px 0px ${data.contentSpacing}px`}}>
          <Title style={{marginBottom: 0, color: data.textColor}}>
            <InlineTextarea name="title" focusRing={false} />
          </Title>
          <Description style={{color: data.textColor}}>
            <InlineTextarea name="description" focusRing={false} />
          </Description>
          {
            data.showButton && (
              <MainButton
                styles={{
                  marginTop: 30,
                  ...(data.buttonLabelColor && {color: data.buttonLabelColor}),
                  ...(data.buttonBackgroundColor && {backgroundColor: data.buttonBackgroundColor, borderColor: data.buttonBackgroundColor})
                }} 
                label={data.buttonLabel}
                link={data.buttonLink} 
              />
            )
          }
        </Content>
      </Container>
    </BlocksControls>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 70px 100px;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  @media (max-width: 980px) {
    padding: 30px;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: center;
    row-gap: 30px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: flex-start; 
  justify-content: center;
  width: 440px;
  flex-grow: 0;
  flex-shrink: 0;
  @media (max-width: 980px) {
    margin: 0px !important;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
`

const ImageContainer = styled.div`
  overflow: hidden;
  @media (max-width: 980px){
    width: 100% !important;
    height: unset !important;
  }
`

const Image = styled.img`
  object-fit: contain;
  @media (max-width: 980px){
    margin: 0px !important;
    height: auto !important;
  }
`

const Title = styled.h2`
  font-size: 30px;
  font-weight: 800;
  font-family: Arial;
  text-align: left;
  @media (max-width: 980px){
    text-align: center;
  }
`

const Description = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  font-family: Arial;
  margin-top: 12px;
  text-align: left;
  @media (max-width: 980px){
    text-align: center;
  }
`

export const TextImageTemplate = {
  "label": "Text Image",
  "key": "textImage",
  "fields": fields,
  "defaultItem": defaults
};

export const blockTextImage = {
  Component: TextImage,
  template: TextImageTemplate,
}