import { servicesItemBlock } from '../ServicesItem/ServicesItem';

export default [
    {
      "label": "Title",
      "name": "title",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Description",
      "name": "description",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Button Label",
      "name": "buttonLabel",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Button Link",
      "name": "buttonLink",
      "component": "text",
      "focusRing": false
    },
    {
      label: 'Services List',
      name: 'servicesList',
      component: 'blocks',
      templates: {
        servicesItem: servicesItemBlock.template,
      }
    },
    {
      "label": "Top Overflow",
      "name": "topOverflow",
      "component": "toggle",
      "description": "This element overflows to the component above",
      "toggleLabels": false,
      "focusRing": false
    },
    {
      "label": "Bottom Overflow",
      "name": "bottomOverflow",
      "component": "toggle",
      "description": "This element overflows to the component below",
      "toggleLabels": false,
      "focusRing": false
    }
  ]