import React, { useContext, useState, useEffect } from 'react'
import { useForm, usePlugin, useCMS } from 'tinacms'
import { InlineForm, InlineBlocks } from 'react-tinacms-inline'
import { Helmet } from "react-helmet"
import { useParams } from 'react-router-dom'
import { SessionContext } from '../context'
import { PersistentContext } from '../context'
import { CategoryContext } from '../context'
import { getCourses } from '../helpers/courses';
import { blockHero } from '../blocks/Hero'
import { CircularProgress } from '@material-ui/core'
import Section from '../components/Section'
import Courses from '../components/Courses'
import CategoryBanner from '../components/CategoryBanner'
import OrderByDropdown from '../components/OrderByDropdown'
import '../App.css';
import styled from 'styled-components'

export default function PageCategory() {
    
  const cms = useCMS();

  const { param1 } = useParams();

  const { isAuthenticated, isSessionLoaded } = useContext(SessionContext);
  const { saveContent, getItemData, keys, production, id } = useContext(PersistentContext);
  const { categories, categoriesLoaded } = useContext(CategoryContext);

  const [courses, setCourses] = useState([]);
  const [category, setCategory] = useState({});
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState(null);

  const CATEGORY_BLOCKS = {
    blockHero
  };

  const clearItemData = async () => {
    await saveContent({'category': null});
    window.location.reload()
  }

  const setUp = () => {
    const data = getItemData('category');
    if(data.blocks){
      if(data.blocks[0]){
        if(data.blocks[0]._template === "blockCategory"){
          clearItemData() 
        }
      }
    }
    const formConfig = {
      id: 'templateCategory',
      fields: [
        {
            "label": "Title",
            "name": "title",
            "component": "text",
            "focusRing": false
        },
        {
            label: 'Secciones',
            name: 'blocks',
            component: 'blocks',
            templates: {
              'blockHero': blockHero.template,
            }
        }
      ],
      onSubmit: async (data) => {
        await saveContent({'category': data});
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data,
    }
    return formConfig;
  }
  const formConfig = setUp();
  const [modifiedValues, form] = useForm(formConfig);
  usePlugin(form)

  const loadCourses = async () => {
      setLoading(true)
      const siteId = production ? keys.id : id
      const courses = await getCourses(siteId, param1 ? { filterName: "categoryId", filterValue: param1 } : null, isAuthenticated)
      setCourses(courses.data)
      setLoading(false)
  }

  useEffect(() => {
    if(production){
      if(isSessionLoaded && categoriesLoaded){
        loadCourses()
        let found = categories.find(element=>element.id === param1)
        if(found){
          setCategory(found)
        }
      }
    }
  }, [param1, isSessionLoaded, categoriesLoaded])

  const filter = (courses) => {
      return courses ? courses : []
  }

  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`}>
      <InlineForm form={form}>
        <div className="page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{category.name ? category.name : 'Categoría'}</title>
                <link rel="canonical" href="https://store.l3rn.io/category" />
            </Helmet>
            {
                loading ? (
                    <div style={styles.loaderContainer}>
                        <CircularProgress style={{ color: '#00A8D8' }}/>
                    </div>
                ) : (
                    <>
                        <CategoryBanner 
                          title={category.name} 
                          description={category.description}
                          url={category.view ? category.view.banner ? category.view.banner : null : null}
                        />
                        <Section
                            headerStyle={{display: 'none'}}
                            containerStyle={{ paddingTop: 50, paddingBottom: 70 }}
                        >
                            <Header className="general-padding">
                                <div style={styles.title}>{category.name}</div>
                                <OrderByDropdown
                                  containerStyle={{display:'none'}}
                                  onSelect={(option)=>setOrderBy(option)}
                                />
                            </Header>
                            <Courses 
                                courses={filter(courses)}
                            />
                        </Section>
                    </>
                ) 
            }
        </div>
        <InlineBlocks name="blocks" blocks={CATEGORY_BLOCKS} />
      </InlineForm>
    </div>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 40px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

const styles = {
    title: {
        color: '#43434a',
        fontSize: 32,
        fontWeight: '800',
        textAlign: 'left',
        fontFamily: 'Muli'
    },
    loaderContainer: {
        minHeight: 'calc(100vh - 225px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}