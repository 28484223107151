import background from '../assets/images/header.webp';
import fbIcon from '../assets/images/fb.png';
export default {
    "_template": "blockHeroSocials",
    "background": background,
    "mobileBackground": background,
    "subtitle": "Cursos de Master Muñoz",
    "title": "Los mejores cursos desde la comodidad de tu casa",
    "description": "A través de este tema y una visión única del microcontenido, pretendemos amplificar el impacto de nuestra comunidad y generar un conducto de oportunidades para nuestra iniciativa educativa.",
    "showSocials": true,
    "socialMedia": [
        {
            "_template": "socialMedia",
            "logo": fbIcon,
            "label": "/example",
            "link": "https://www.example.com/"
        },
        {
            "_template": "socialMedia",
            "logo": fbIcon,
            "label": "@example",
            "link": "https://www.example.com/"
        }
    ],
    "contentPadding": 70
}