import React from 'react';
import { useMediaQuery } from '../helpers/Hooks'

function Separator() {

  const isMobile = useMediaQuery('(max-width: 768px)');

  const styles = {
    separator: {
      height: 1.2,
      backgroundColor: '#cccccc',
      width: isMobile ? 'calc(100% - 60px)' : 'calc(100% - 200px)',
      margin: 'auto',
      marginTop: 50
    }
  }

  return (
    <div style={styles.separator} />
  );
}

export default Separator;
