import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import SignUpForm from '../components/forms/SignUpForm';
import { register } from '../helpers/Cognito';
import { useMediaQuery } from '../helpers/Hooks'
import '../App.css';

function SignUp({ index, data }) {

  const history = useHistory();

  const isMobile = useMediaQuery('(max-width: 980px)');

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const [terms, setTerms] = useState(false)
  const [password, setPassword] = useState('')
  const [subscribed, setSubscribed] = useState(false)
  const [passwordValidationStatus, setPasswordValidationStatus] = useState({})

  const styles = {
    signUpWrapper: {
      flexGrow: 1,
      flexShrink: 1,
      display: 'flex',
      padding: isMobile ? '35px 30px' : '75px 30px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f7f8fa'
    },
    featuredImage: {
      width: 650,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${data.background})`
    },
  };

  const onSubmit = async () => {
    if (terms === true) {
      if (name !== '' && email !== '' && password !== '') {
        try {
          const response = await register(name, email, password, subscribed ? 'true' : 'false');
          history.push('/sign-in');
        } catch (error) {
          console.log(error);
          setError(error && error.message ? error.message : error);
        }
      } else {
        window.alert('Todos los datos son requeridos');
      }
    } else {
      window.alert('Debes aceptar los terminos y condiciones');
    }
  }

  const validatePassword = (value) => {
    setPasswordValidationStatus({
      uppercase: value.match('(?=.*[A-Z])') !== null ? true : false,
      lowercase: value.match('(?=.*[a-z])') !== null ? true : false,
      special: value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/) !== null ? true : false,
      number: value.match('([0-9])') !== null ? true : false,
      min: value.length >= 8 ? true : false,
    }) 
  }

  const submitEnabled = () => {
    let { lowercase, uppercase, special, number, min } = passwordValidationStatus
    return (
            name !== '' &&
            email !== '' &&
            password !== '' &&
            terms &&
            subscribed &&
            lowercase &&
            uppercase &&
            special &&
            number &&
            min
          )
  }

  return (
    <div className="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Registro</title>
        <link rel="canonical" href="https://store.l3rn.io/signUp" />
      </Helmet>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={styles.signUpWrapper}>
          <SignUpForm
            title={data.title}
            description={data.description}
            signup={data.signup}
            signin={data.signin}
            error={error}
            name={name}
            email={email}
            password={password}
            terms={terms}
            subscribed={subscribed}
            onSubmit={onSubmit}
            onNameChange={(value) => setName(value)}
            onEmailChange={(value) => setEmail(value)}
            onTermsChange={(value) => setTerms(value)}
            onSubscribedChange={(value) => setSubscribed(value)}
            onPasswordChange={(value) => {
              setPassword(value);
              validatePassword(value);
            }}
            passwordValidationStatus={passwordValidationStatus}
            submitEnabled={submitEnabled()}
          />
        </div>
        {
          isMobile ? <></> :
            <div style={styles.featuredImage} />
        }
      </div>
    </div>
  );
};

export default SignUp;