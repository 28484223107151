import React, { useState } from 'react';

function TouchableOpacity({containerStyle, onPress, onHoverEnter, onHoverLeave, onMouseMove, opacity, children}) {

    const [hover, setHover] = useState(false)

    const styles = {
        container: {
            cursor: 'pointer',
            opacity: hover ? (opacity ? opacity : 0.7) : 1,
            transition: 'opacity 0.4s'
        }
    }

    const toggleHover = () => {
        setHover(!hover)
    }

    const handleMouseLeave = (e) => {
        e.stopPropagation()
        toggleHover()
        if(onHoverLeave){
            onHoverLeave()
        }
    }

    const handleMouseEnter = (e) => {
        e.stopPropagation()
        toggleHover()
        if(onHoverEnter){
            onHoverEnter()
        }
    }

    const handleMouseMove = (e) => {
        e.stopPropagation()
        if(onMouseMove){
            onMouseMove()
        }
    }

  return (
    <div 
        style={{...styles.container, ...containerStyle}}
        onMouseEnter={(e)=>handleMouseEnter(e)}
        onMouseLeave={(e)=>handleMouseLeave(e)}
        onMouseMove={(e)=>handleMouseMove(e)}
        onClick={onPress}
    >
        {children}
    </div>
  );
}

export default TouchableOpacity;
