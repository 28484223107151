import React from 'react';
import { BlocksControls, InlineBlocks } from 'react-tinacms-inline'
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components'
import { servicesItemBlock } from '../ServicesItem/ServicesItem'
import MainButton from '../../components/buttons/MainButton'

function Services({ index, data }) {
  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <Wrapper
        style={{
          ...(data.topOverflow && {
            marginTop: -90,
            paddingTop: 130
          }),
          ...(data.bottomOverflow && {
            paddingBottom: 260,
            marginBottom: -300
          })
        }}
      >
        <Container>
          <Content>
            <Title>{data.title}</Title>
            <Description>{data.description}</Description>
            <ButtonContainer>
              <MainButton 
                label={data.buttonLabel}
                link={data.buttonLink}
              />
            </ButtonContainer>
          </Content>
          <InlineBlocks
            max={8}
            name="servicesList"
            direction="horizontal"
            className="services-blocks-container"
            blocks={SERVICES_BLOCKS}
          />
        </Container>
      </Wrapper>
    </BlocksControls>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 70px 100px;
  background-size: 100% 60%, cover;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-color: #f7faff;
`

const Container = styled.div`
  padding: 0px 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .services-blocks-container{
    width: 540px;
    flex-wrap: wrap;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-grow: 0;
    flex-shrink: 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 480px;
  flex-shrink: 1;
`

const Title = styled.div`
  color: #222e3e;
  font-size: 38px;
  font-weight: 800;
  font-family: Arial;
  text-align: left;
`

const Description = styled.div`
  color: #908e8e;
  font-size: 18px;
  line-height: 36px;
  font-weight: 600;
  font-family: Arial;
  text-align: left;
  padding-top: 18px;
`

const ButtonContainer = styled.div`
  margin-top: 34px;
`

const SERVICES_BLOCKS = {
  servicesItem: servicesItemBlock
};

export const ServicesTemplate = {
  "label": "Services",
  "key": "services",
  "fields": fields,
  "defaultItem": defaults
};

export const blockServices = {
  Component: Services,
  template: ServicesTemplate,
}