import React, { useContext } from 'react'
import { useCMS } from 'tinacms'
import { SEOLayout } from './layout/Seo'
import { SessionContext } from './context/SessionContext'
import { PersistentContext } from './context'
import Landing1Home from './landings/landing1/Home'
import Landing2Home from './landings/landing2/Home'
import Landing3Home from './landings/landing3/Home'
import Landing4Home from './landings/landing4/Home'

import Loader from './components/Loader'

const renderLandingContent = (select, page) => {
  switch (select) {
    case 1:
      return <Landing1Home name={page} />
    case 2:
      return <Landing2Home name={page} />
    case 3:
      return <Landing3Home name={page} />
    case 4:
      return <Landing4Home name={page} />
    default:
      return <Landing1Home name={page} />
  }
}

function Landing (props) {
  const cms = useCMS()
  const { saveContent, getItemData, page, keys, production, data, loading } = useContext(
    PersistentContext
  )
  const {isSessionLoaded} = useContext(SessionContext)

  let template = null
  if (data && data.template) {
    template = data.template
  } else if (!loading) {
    const tempData = getItemData(page, true)
    template = tempData.template
  }

  if (production && !isSessionLoaded) {	
    return <Loader />	
  }

  return (
    <>
      <SEOLayout />
      {loading ? (<Loader />) : renderLandingContent(template, page)}
    </>
  )
}

export default Landing
