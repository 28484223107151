import React from 'react';
import '../../../App.css';

function Message(props) {

  const styles = {
    field: {
      marginBottom: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    label: {
      fontWeight: '600',
      fontSize: 14,
      fontFamily: 'Muli',
      color: '#8891a6',
      marginBottom: 6
    }
  }

  return (
    <div style={{...styles.field, ...props.containerStyle}}>
        {
          (props.labelType === 'label' || !props.labelType) && (
            <div style={styles.label}>{props.label ? props.label : 'Mensaje'}</div>
          )
        }
        <textarea 
            className="message-textarea"
            style={{...props.inputStyles}}
            value={props.value}
            onChange={(e)=>props.onChange(e.target.value)} 
            placeholder={props.labelType === 'placeholder' ? props.label ? props.label : "Mensaje" : ""}
        />
    </div>
  );
}

export default Message;