import testimonial1 from '../assets/images/testimonial-1.webp';
import testimonial2 from '../assets/images/testimonial-2.webp';

export default {
    "_template": 'blockTestimonials',
    "title": "Lo que opinan de nosotros",
    "testimonialsList": [
        {
          "_template": "testimonialsItem",
          "image": testimonial1,
          "author": "Robert Plasukin M.",
          "testimonial": "Dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo ullamco laboris nisi delde paraolore",
          "location": "Ciudad de México"
        },
        {
          "_template": "testimonialsItem",
          "image": testimonial2,
          "author": "Robert Plasukin M.",
          "testimonial": "Dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo ullamco laboris nisi delde paraolore",
          "location": "Ciudad de México"
        },
        {
          "_template": "testimonialsItem",
          "image": testimonial1,
          "author": "Robert Plasukin M.",
          "testimonial": "Dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo ullamco laboris nisi delde paraolore",
          "location": "Ciudad de México"
        }
    ],
    "itemsPerSlide": "two",
    "showImage": true,
    "showIcon": true,
    "backgroundColor": "#ffffff",
    "testimonialsBackgroundColor": "#ffffff",
    "titleColor": "#000000"
}