import React from 'react';
import { BlocksControls, InlineImage } from 'react-tinacms-inline';
import fields from './AssociatedItem.fields'
import defaults from './AssociatedItem.defaults'
import styled from 'styled-components'

function AssociatedItem({ index, data, imageWidth, imageHeight }) {

  return (
    <BlocksControls focusRing={{ offset: 0 }} insetControls index={index}>
      <Container>
        <InlineImage
          name="image"
          className="associated-item-inline-image"
          parse={media => {
            return media.previewSrc
          }}
          previewSrc={formValues => {
            return formValues;
          }}
        >
          {
            (props) => (
              <img alt="associated-image" src={props.src} 
                style={{
                    ...styles.image,
                    width: `${imageWidth}px`,
                    height: `${imageHeight}px`
                  }}
              />
            )
          }
        </InlineImage>
      </Container>
    </BlocksControls>
  );
}

const Container = styled.div`
  width: 23%;
  margin-right: 2%;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 20px;
  & .associated-item-inline-image{
      width: 100%;
  }
  @media (max-width:1200px){
    width: 31.3333332%;
  }
  @media (max-width: 768px){
    width: 48%;
  }
  @media (max-width: 480px){
    width: 98%;
    padding: 20px 20px 20px 0px;
  }
`


const styles = {
  image: {
    objectFit: 'contain'
  }
};

export const associatedItemBlock = {
  Component: AssociatedItem,
  template: {
    name: "associatedItem",
    key: "title",
    label: "Associated Item",
    component: "group-list",
    itemProps: item => ({
      label: item.title,
    }),
    defaultItem: defaults,
    fields: fields,
  },
};
