import React, { createContext } from "react";

export const KeysContext = createContext();

export class KeysContextProvider extends React.Component {
  state = {
    keys: null,
  };

  render() {
    const { keys } = this.props;
    return (
      <KeysContext.Provider value={{ keys }}>
        {this.props.children}
      </KeysContext.Provider>
    );
  }
};
