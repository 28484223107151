export function percentage(num, num2) {
    return `${((num2 * 100) / num).toFixed(0)}%`;
}

export function formatDuration(min){
    let hours = Math.floor(min / 60);
    let minutes = min % 60;
    return `${hours}hr${hours > 1 ? `s` : ``} ${minutes}min`
}

export function formatPrice(price = 0, currency = 'MXN', type='cents'){
    let currencyValue = currency.trim().toUpperCase()
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyValue,
        
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    let priceValue = type === 'cents' ? price / 100 : type === 'decimal' ? price : price
    return `${currencyValue === 'USD' ? currencyValue : ``}${formatter.format(priceValue)}`
}

export function formatQuantity(quantity) {
    return quantity >= 0 && quantity <= 999 ? quantity.toString() :
            quantity >= 1000 && quantity <= 9999 ? (quantity / 1000).toFixed(1).toString() + 'k' :
            quantity >= 10000 && quantity <= 999999 ? Math.floor(quantity / 1000).toString() + 'k' :
            quantity >= 1000000 && quantity <= 9999999 ? (quantity / 1000000).toFixed(1).toString() + 'm' :
            quantity >= 10000000 ? Math.floor(quantity / 1000000).toString() + 'm' : ''
}