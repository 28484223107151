import React, { useState, useContext } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import SignInForm from '../components/forms/SignInForm';
import { PersistentContext } from '../context';
import { trackUserIdforPinpoint } from '../helpers/Cognito';
import { useMediaQuery } from '../helpers/Hooks';
import '../App.css';
import { SessionContext } from '../context/SessionContext';

function SignIn({ index, data }) {
  const { production } = useContext(PersistentContext);
  const { 
    isAuthenticated, isAuthenticating, error, login 
  } = useContext(SessionContext);

  const history = useHistory();

  const isMobile = useMediaQuery('(max-width: 980px)');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  if (production && isAuthenticated) {
    history.replace('/home');
  }

  const onSubmit = async () => {
    if (production) {
      if (email !== '' && password !== '') {
        await login(email, password);
        trackUserIdforPinpoint();
      } else {
        window.alert('Todos los datos son requeridos');
      }
    }else{
      console.log('Sign in is disabled in development environment')
    }
  }

  const styles = {
    signInWrapper: {
      flexGrow: 1,
      flexShrink: 1,
      display: 'flex',
      padding: isMobile ? '35px 30px' : '75px 30px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f7f8fa'
    },
    featuredImage: {
      width: 650,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${data.background})`
    },
  };

  const submitEnabled = () => {
    return (
            email !== '' &&
            password !== ''
          )
  }

  return (
      <div className="page">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Login</title>
          <link rel="canonical" href="https://store.l3rn.io/signin" />
        </Helmet>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={styles.signInWrapper}>
            <SignInForm
              title={data.title}
              error={error}
              description={data.description}
              loading={isAuthenticating || !submitEnabled()}
              button={data.button}
              option={data.option}
              facebook={data.facebook}
              google={data.google}
              aditional_information={data.aditional_information}
              register={data.register}
              email={email}
              password={password}
              onSubmit={onSubmit}
              onEmailChange={(value) => setEmail(value)}
              onPasswordChange={(value) => setPassword(value)}
            />
          </div>
          {
            isMobile ? <></> :
            <div style={styles.featuredImage} />
          }
        </div>
      </div>
  );
};

export default SignIn;
