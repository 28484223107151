import React from 'react';

function CourseStat(props) {

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },

    icon: {
      width: 16,
      height: 16
    },

    text: {
      color: '#999ea7',
      fontSize: 11,
      fontWeight: '600',
      fontFamily: 'Muli',
      marginLeft: 3
    }

  }

  return props.value ? (
    <div style={{...styles.container, ...props.containerStyle}}>
        <img style={{...styles.icon, ...props.iconStyle}} alt="course-stat-icon" src={require(`../assets/svgs/${props.icon}.svg`)} />
        <div style={{...styles.text, ...props.textStyle}}>{props.value}</div>
    </div>
  ) : null
}

export default CourseStat;
