import React from 'react';
import { BlocksControls, InlineImage, InlineTextarea } from 'react-tinacms-inline';
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components';

function VideoHero({index, data, modifiedValues}) {

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
        <Wrapper 
            style={{
                background: `${data.backgroundColor}`
            }}
        >
            <InlineImage
                name="image"
                className="video-form-inline-image"
                parse={media => {
                    return media.previewSrc
                }}
                previewSrc={formValues => {
                    return formValues;
                }}
            >
                {
                (props) => (
                    <Image
                        alt="video-form-image"
                        style={{
                            objectFit: 'contain',
                            width: `${data.imageWidth}px`,
                            height: `${data.imageHeight}px`,
                            marginTop: 40
                        }} 
                        src={props.src}
                    />
                )
                }
            </InlineImage>
            <Container>
                <Content>
                    <Title>
                        <InlineTextarea name="title" focusRing={false} />
                    </Title>
                    <Description>
                        <InlineTextarea name="description" focusRing={false} />
                    </Description>
                </Content>
                <VideoWrapper>
                    <VideoContainer 
                        style={{
                            maxWidth: '100%', 
                            maxHeight: '100%',
                            width: '100%',
                            height: '100%'
                        }}
                    >
                        <video
                            width="100%"
                            height="100%"  
                            controls 
                            autoPlay={data.autoplay ? data.autoplay : false}
                            style={{position: 'relative', zIndex: 2}}
                            muted
                        >
                            <source src={data.videoUrl} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        
                        <VideoBackground />
                        <VideoBackground2 />

                    </VideoContainer>
                </VideoWrapper>
            </Container>
        </Wrapper>
    </BlocksControls>
  );
}

const Wrapper = styled.div`
    width: 100%;
    & .video-form-inline-image{
        padding: 0px 100px;
        @media (max-width: 980px){
            padding: 0px 30px;
        }
    }
`

const Container = styled.div`
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding: 50px;
    @media (max-width: 980px){
        flex-direction: column;
        justify-content: flex-start;
        padding: 30px;
    }
`

const Content = styled.div`
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 30px;
    @media (max-width: 980px){
        width: 100%;
    }
    @media (max-width: 768px){
        padding: 0px;
    }
`

const VideoWrapper = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 980px){
        margin-top: 40px;
    }
`


const Image = styled.img`
    @media(max-width: 980px){
        width: 100% !important;
    }
`

const Title = styled.h2`
  width: 100%;
  color: #292c2e;
  font-size: 38px;
  line-height: 52px;
  font-weight: 800;
  font-family: Arial;
  text-align: center;
  @media (max-width: 480px){
    font-size: 24px;
    line-height: 40px;
  }
`

const Description = styled.div`
  width: 100%;
  font-size: 22px;
  font-weight: 700;
  font-family: Arial;
  margin-top: 12px;
  text-align: center;
`

const VideoContainer = styled.div`
    position: relative;
`

const VideoBackground = styled.div`
    position: absolute;
    z-index: 1;
    top: 12px;
    bottom: -12px;
    left: -12px;
    right: -4px;
    background: #ff7600;
`

const VideoBackground2 = styled.div`
    position: absolute;
    z-index: 1;
    top: -12px;
    bottom: 30px;
    left: 14px;
    right: -12px;
    background: #005b9c;
`


export const VideoHeroTemplate = {
    "label": "VideoHero",
    "key": "videoHero",
    "fields": fields,
    "defaultItem": defaults
}

export const blockVideoHero = {
    Component: VideoHero,
    template: VideoHeroTemplate,
}