import React, { createContext } from 'react'
import {
  getCourses,
  getMyCourses,
  getCourse,
  getEvent,
  getEvents,
  getNextCourse,
  postUserContent,
  getCommentsContent,
  postComment,
  getLikedCourses
} from '../helpers/courses'

export const CourseContext = createContext()

export class CourseContextProvider extends React.Component {
  state = {
    events: [],
    courses: [],
    comments: [],
    course: {
      data: {},
      modules: [],
      content: [],
      category: {},
      instructor: {},
      statistics: {},
      attachments: [],
      userContent: {},
      defaultContent: { content: {} }
    },
    courseDetail: {
      data: { content: {} },
      statistics: {},
      modules: []
    },
    eventDetail: {
      data: { content: {} },
      statistics: {},
      modules: []
    },
    likedCourses: [],
    myCourses: [],
    error: null,
    loading: true,
    loadingMyCourses: true,
    loadingNext: true,
  }

  onLoadCourses = async site => {
    try {
      await this.setState({ loading: true })
      const { data } = await getCourses(site)
      await this.setState({ courses: data, loading: false })
    } catch (error) {
      await this.setState({ error, loading: false })
      throw error
    }
  }

  onLoadEvents = async site => {
    try {
      await this.setState({ loading: true })
      const { data } = await getEvents(site)
      await this.setState({ events: data, loading: false })
    } catch (error) {
      await this.setState({ error, loading: false })
      throw error
    }
  }

  onLoadLikedCourses = async site => {
    try {
      await this.setState({ loading: true })
      const { data } = await getLikedCourses(site)
      await this.setState({ likedCourses: data, loading: false })
    } catch (error) {
      await this.setState({ error, loading: false })
      throw error
    }
  }

  onLoadMyCourses = async site => {
    try {
      await this.setState({ loadingMyCourses: true })
      const { data } = await getMyCourses(site)
      const filter = data.map(item => {
        item.price = null
        return item
      })
      await this.setState({ myCourses: filter, loadingMyCourses: false })
    } catch (error) {
      await this.setState({ error, loadingMyCourses: false })
      throw error
    }
  }

  onLoadMyCourse = async (site, id) => {
    try {
      await this.setState({ loading: true })
      const data = await getCourse(site, id)
      await this.setState({ course: data, loading: false })
    } catch (error) {
      await this.setState({ error, loading: false })
      throw error
    }
  }

  onLoadCourse = async (site, id, session) => {
    try {
      await this.setState({ loading: true })
      const data = await getCourse(site, id, session)
      await this.setState({ courseDetail: data, loading: false })
    } catch (error) {
      await this.setState({ error, loading: false })
      throw error
    }
  }

  onLoadEvent = async (site, id, session) => {
    try {
      await this.setState({ loading: true })
      const data = await getEvent(site, id, session)
      await this.setState({ eventDetail: data, loading: false })
    } catch (error) {
      await this.setState({ error, loading: false })
      throw error
    }
  }

  nextChapterInit = async (site, id, body) => {
    try {
      await this.setState({ loading: true })
      const data = await getNextCourse(site, id, body)
      await this.setState({ course: data, loading: false })
      return data
    } catch (error) {
      await this.setState({ error, loading: false })
      throw error
    }
  }

  nextChapter = async (site, id, body, loading = true) => {
    try {
      loading && await this.setState({ loading: true })
      const data = await getNextCourse(site, id, body)
      await this.setState({ course: data, loading: false })
      return data
    } catch (error) {
      await this.setState({ error, loading: false })
      throw error
    }
  }

  updateUserContent = async (site, id, body = {}) => {
    try {
      const data = await postUserContent(site, id, body)
      this.setState({
        course: {
          ...this.state.course,
          userContent: {
            ...data
          }
        }
      })
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  loadComments = async (site, courseId, contentId, page = 0, first = false) => {
    try {
      const data = await getCommentsContent(site, courseId, contentId, page)
      if (first) {
        this.setState({ comments: data })
      } else {
        this.setState({ comments: [...this.state.comments, ...data] })
      }
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  sendComment = async (site, courseId, body = {}) => {
    try {
      await postComment(site, courseId, body)
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  render () {
    return (
      <CourseContext.Provider
        value={{
          ...this.state,
          nextChapter: this.nextChapter,
          sendComment: this.sendComment,
          onLoadEvent: this.onLoadEvent,
          onLoadEvents: this.onLoadEvents,
          loadComments: this.loadComments,
          onLoadCourse: this.onLoadCourse,
          onLoadCourses: this.onLoadCourses,
          onLoadMyCourse: this.onLoadMyCourse,
          onLoadMyCourses: this.onLoadMyCourses,
          nextChapterInit: this.nextChapterInit,
          updateUserContent: this.updateUserContent,
          onLoadLikedCourses: this.onLoadLikedCourses
        }}
      >
        {this.props.children}
      </CourseContext.Provider>
    )
  }
}
