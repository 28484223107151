import React from 'react';
import { BlocksControls, InlineTextarea } from 'react-tinacms-inline';
import fields from './TestimonialsItem.fields'
import defaults from './TestimonialsItem.defaults'
import styled from 'styled-components'
import leftQuote from '../assets/svgs/left-quote.svg';
import { useMediaQuery } from '../helpers/Hooks'

function TestimonialsItem({ index, data, itemsPerSlide, showImage, showIcon, testimonialsBackgroundColor }) {

    const isLargeDevice = useMediaQuery('(max-width: 1200px)')
  
    const getWidth = () => {
        if(itemsPerSlide){
            // from 1200px we ignore the user's selection
            if(isLargeDevice){
              return ""
            }
            return itemsPerSlide === "one" ? '98%' :
            itemsPerSlide === "two" ? '48%' :
            itemsPerSlide === "three" ? '31.3333332%' :
            itemsPerSlide === "four" ? '23%' :
            '48%'
        }else{
            return '48%'
        }
    }

  return (
    <BlocksControls focusRing={{ offset: {x: -15, y: 0} }} insetControls index={index}>
        <Container 
            style={{
                width: getWidth(),
                backgroundColor: testimonialsBackgroundColor
            }}
        >
            {
                showImage && (
                    <Image style={{ backgroundImage: `url(${data.image})`}} />
                )
            }
          <Content>
              {
                  showIcon && (
                    <Icon alt="quote-icon" src={leftQuote} />
                  )
              }
            <Text>
              <InlineTextarea name="testimonial" focusRing={false} />
            </Text>
            <Author>
              <InlineTextarea name="author" focusRing={false} />
            </Author>
            <Location>
              <InlineTextarea name="location" focusRing={false} />
            </Location>
          </Content>
        </Container>
    </BlocksControls>
  );
}

const Container = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 20px;
  margin-right: 2%;
  @media (max-width: 1200px){
    width: 48%;
  }
  @media (max-width: 980px){
    width: 98%;
  }
`

const Image = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 180px;
  height: auto;  
  @media (max-width: 480px){
      width: auto;
      height: 140px;  
  }
`

const Content = styled.div`
    padding: 21px 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    @media (max-width: 480px){
      padding: 0px;
    }
`

const Icon = styled.img`
    width: 34px;
    height: 34px;
`

const Text = styled.div`
    text-align: left;
    margin-top: 12px;
    color: #999ea7;
    font-size: 16px;
    font-weight: 600;
    font-style: italic;
    font-family: Muli;
    width: 100%
`

const Author = styled.div`
    padding-top: 12px;
    color: #43434a;
    font-size: 21px;
    font-weight: 700;
    font-family: Muli;
    text-align: left;
`

const Location = styled.div`
    text-align: left;
    color: #999ea7;
    font-size: 16px;
    font-family: Muli;
    font-weight: 600
`

export const testimonialsItemBlock = {
  Component: TestimonialsItem,
  template: {
    name: "testimonialsItem",
    key: "label",
    label: "Testimonials Item",
    component: "group-list",
    itemProps: item => ({
      label: item.name,
    }),
    defaultItem: defaults,
    fields: fields,
  },
};
