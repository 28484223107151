import { testimonialsItemBlock } from '../TestimonialsItem/TestimonialsItem';

export default [
    {
        label: 'Testimonials List',
        name: 'testimonialsList',
        component: 'blocks',
        templates: {
          testimonialsItem: testimonialsItemBlock.template,
        }
    }
]
