import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom'
import { InlineTextarea } from 'react-tinacms-inline';
import { CourseContext, PersistentContext } from '../context'
import OrderByDropdown from '../components/OrderByDropdown';
import Section from '../components/Section';
import Courses from '../components/Courses';
import styled from 'styled-components'
import '../App.css';

const courses = [
  {
      id: 1,
      previewImageUrl: 'course-1.webp',
      category: 'E-Commerce',
      title: 'Tus primeras ventas',
      description: 'Mauris laoreet sollicitudin justo, mauris laoresollicitudin justo, placerat lacus labort placerat lacus lobortis nec.',
      sales: 92030,
      likes: 4300,
      duration: '1 hr 30 min',
      discount: '30% Menos $39.99',
      price: 19.99,
      favorite: true
  },
  {
      id: 2,
      previewImageUrl: 'course-2.webp',
      category: 'E-Commerce',
      title: 'Tus primeras ventas',
      description: 'Mauris laoreet sollicitudin justo, mauris laoresollicitudin justo, placerat lacus labort placerat lacus lobortis nec.',
      sales: 92030,
      likes: 4300,
      duration: '1 hr 30 min',
      discount: '30% Menos $39.99',
      price: 19.99,
      favorite: true
  },
  {
      id: 3,
      previewImageUrl: 'course-3.webp',
      category: 'E-Commerce',
      title: 'Tus primeras ventas',
      description: 'Mauris laoreet sollicitudin justo, mauris laoresollicitudin justo, placerat lacus labort placerat lacus lobortis nec.',
      sales: 92030,
      likes: 4300,
      duration: '1 hr 30 min',
      discount: '30% Menos $39.99',
      price: 19.99,
      favorite: true
  },
  {
      id: 4,
      previewImageUrl: 'course-4.webp',
      category: 'E-Commerce',
      title: 'Tus primeras ventas',
      description: 'Mauris laoreet sollicitudin justo, mauris laoresollicitudin justo, placerat lacus labort placerat lacus lobortis nec.',
      sales: 92030,
      likes: 4300,
      duration: '1 hr 30 min',
      discount: '30% Menos $39.99',
      price: 19.99,
      favorite: true
  },
  {
      id: 5,
      previewImageUrl: 'course-1.webp',
      category: 'E-Commerce',
      title: 'Tus primeras ventas',
      description: 'Mauris laoreet sollicitudin justo, mauris laoresollicitudin justo, placerat lacus labort placerat lacus lobortis nec.',
      sales: 92030,
      likes: 4300,
      duration: '1 hr 30 min',
      discount: '30% Menos $39.99',
      price: 19.99,
      favorite: true
  },
  {
      id: 6,
      previewImageUrl: 'course-2.webp',
      category: 'E-Commerce',
      title: 'Tus primeras ventas',
      description: 'Mauris laoreet sollicitudin justo, mauris laoresollicitudin justo, placerat lacus labort placerat lacus lobortis nec.',
      sales: 92030,
      likes: 4300,
      duration: '1 hr 30 min',
      discount: '30% Menos $39.99',
      price: 19.99,
      favorite: true
  },
  {
      id: 7,
      previewImageUrl: 'course-3.webp',
      category: 'E-Commerce',
      title: 'Tus primeras ventas',
      description: 'Mauris laoreet sollicitudin justo, mauris laoresollicitudin justo, placerat lacus labort placerat lacus lobortis nec.',
      sales: 92030,
      likes: 4300,
      duration: '1 hr 30 min',
      discount: '30% Menos $39.99',
      price: 19.99,
      favorite: true
  },
  {
      id: 8,
      previewImageUrl: 'course-4.webp',
      category: 'E-Commerce',
      title: 'Tus primeras ventas',
      description: 'Mauris laoreet sollicitudin justo, mauris laoresollicitudin justo, placerat lacus labort placerat lacus lobortis nec.',
      sales: 92030,
      likes: 4300,
      duration: '1 hr 30 min',
      discount: '30% Menos $39.99',
      price: 19.99,
      favorite: true
  }
]

function MyFavorites({ index, data }) {

  const history = useHistory()
  const { keys, id, production } = useContext(PersistentContext)
  const { onLoadLikedCourses, likedCourses, loading } = useContext(CourseContext)

  const [orderBy, setOrderBy] = useState(null);

  const preload = async () => {
    const siteId = production ? keys.id : id
    if (production) {
      try {
        await onLoadLikedCourses(siteId)
      } catch (error) {
        console.log('error', error);
        history.replace('/');
      }
    }else{
      try {
        await onLoadLikedCourses(siteId)
      } catch (error) {
        console.log('error', error);
      }
    }
  }

  useEffect(() => {
    // update orderBy
  }, [orderBy])

  useEffect(() => {
    preload();
  }, [])

  const filter = (likedCourses = []) => {
    return likedCourses.slice(0, data.coursesShown)
  }

  const defaultData = production ? likedCourses : courses

  return (
    <div className="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mis Favoritos</title>
        <link rel="canonical" href="https://store.l3rn.io/my-favorites" />
      </Helmet>
      <Section
        hideHeader
        containerStyle={{ paddingTop: 72, paddingBottom: 70 }}
      >
        <Header className="general-padding">
            <div style={styles.title}>
              <InlineTextarea name="title" focusRing={false} />
            </div>
            <OrderByDropdown
              containerStyle={{display:'none'}}
              onSelect={(option)=>setOrderBy(option)}
            />
        </Header>
        <Courses loading={loading} courses={filter(defaultData)}/>
      </Section>
    </div>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

const styles = {
  title: {
      color: '#43434a',
      fontSize: 32,
      fontWeight: '800',
      textAlign: 'left',
      fontFamily: 'Muli'
  }
}

export default MyFavorites;