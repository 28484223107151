import React, {useState, useEffect} from 'react';
import CategoryItem from './CategoryItem';
import NavigationButton from './buttons/NavigationButton';
import { Carousel } from 'react-responsive-carousel';
import { useCMS } from 'tinacms'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../App.css';

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

function CategoriesList({categories}) {

  const cms = useCMS();

  const [dimensions, setDimensions] = useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 1000)

    window.addEventListener('resize', debouncedHandleResize)

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  const slidesRenderer = () => {

    let itemsPerSlide = dimensions.width <= 500 ? 1 : dimensions.width <= 840 ? 2 : dimensions.width <= 1024 ? 3 : dimensions.width <= 1224 ? 4 : dimensions.width > 1224 ? 5 : 5
    let slides = [];
    let index = 0;
    let done = false;

    // add index, used for default assets
    let mappedCategories = categories.map((category, index) => {
      category.index = index
      return category
    })

    // arrange slides and categories
    while(!done){
      let res = mappedCategories ? mappedCategories.slice(index, index + itemsPerSlide) : [];
      index = index + itemsPerSlide;
      res.length > 0 ? slides.push(res) : done = true;
    }

    return slides.map((slide, index) => (
      <div key={`box-${index}`} style={styles.slide}>
        {
          slide.map((item) => (
            <CategoryItem 
              key={item.id} 
              index={item.index}
              id={item.id} 
              name={item.name}
              background={item.view ? item.view.background : null}
            />
          ))
        }
        {
          (slide.length < itemsPerSlide) && (
            [...Array(itemsPerSlide - slide.length)].map((item, j)=>(
              <CategoryItem 
                key={`slide-filler-${j}`} 
                filler={true}
              />
            ))
          ) 
        }
      </div>
    ))

  }

  return (
    <div style={styles.container} className="general-padding">
      {
        cms.enabled && categories.length <= 0 ? 
          <div style={styles.emptyState}>
            <div style={styles.emptyStateTitle}>Aún no tienes categorías</div>
            <div>Agrega categorías en el apartado de cursos en el panel de administrador</div>
          </div>
        :
        <Carousel
          showThumbs={false}
          renderArrowPrev={(clickHandler, hasPrev, label) => (
            hasPrev && (
              <NavigationButton 
                containerStyle={{
                  position: 'absolute',
                  zIndex: 1,
                  top: dimensions.width <= 1400 ? 79 : 90,
                  left: dimensions.width <= 768 ? 5 : -20
                }}
                orientation={'left'} 
                onPress={clickHandler} 
              />
            )
          )}
          renderArrowNext={(clickHandler, hasNext, label) => (
            hasNext && (
              <NavigationButton
                containerStyle={{
                  position: 'absolute',
                  zIndex: 1,
                  top: dimensions.width <= 1400 ? 79 : 90,
                  right: dimensions.width <= 768 ? 5 : -20
                }}
                orientation={'right'} 
                onPress={clickHandler} 
              />
            )
          )}
        >
          { slidesRenderer() }
        </Carousel> 
      }
    </div>
  );
}

const styles = {
  container: {
    paddingBottom: 80
  },
  slide: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    position: 'relative'
  },
  emptyStateTitle: {
    color: 'rgb(67, 67, 74)',
    fontSize: 18,
    fontWeight: 800,
    fontFamily: 'Muli'
  }
}

export default CategoriesList;
