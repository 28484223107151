import { boxesItemBlock } from '../BoxesItem/BoxesItem';

export default [
    {
      label: 'Boxes List',
      name: 'boxesList',
      component: 'blocks',
      templates: {
        boxesItem: boxesItemBlock.template,
      }
    },
    {
      "label": "Title",
      "name": "title",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Subtitle",
      "name": "subtitle",
      "component": "text",
      "focusRing": false
    }
  ]