import React, { useContext } from 'react';
import { useForm, usePlugins, useCMS } from 'tinacms';
import { InlineForm } from 'react-tinacms-inline';
import AboutPage from '../blocks/About';
import { PersistentContext } from '../context';
import { MarkdownFieldPlugin } from 'react-tinacms-editor'

export default function PageAbout(props) {
  const { saveContent, getItemData } = useContext(PersistentContext);

  const cms = useCMS();

  const clearItemData = async () => {
    await saveContent({'about': null});
    window.location.reload()
  }


  const setUp = () => {
    const data = getItemData('about');
    if(data.blocks){
      if(data.blocks[0]){
        if(data.blocks[0]._template === "blockAbout"){
          clearItemData() 
        }
      }
    }
    const formConfig = {
      id: 'templateAbout',
      fields: [
        {
          "label": "Title",
          "name": "title",
          "component": "text",
          "focusRing": false
        },
        {
          "label": "About",
          "name": "about",
          "component": "markdown",
          "focusRing": false
        }
      ],
      onSubmit: async (data) => {
        await saveContent({ 'about': data });
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data,
    }
    return formConfig;
  }
  const formConfig = setUp();
  const [modifiedValues, form] = useForm(formConfig);
  usePlugins([form, MarkdownFieldPlugin])
  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`}>
      <InlineForm form={form}>
        <AboutPage data={modifiedValues}/>
      </InlineForm>
    </div>
  )
}