import image from '../../assets/images/image.png';

export default {
    "_template": "blockTextImage",
    "image": image,
    "title": "Lorem ipsumdel",
    "subtitle": "Powerful tagline here",
    "description": "Dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea ommodo consequat ullamco laboris nisi delde paraolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat ullamco laboris nisi delde para olore ",
    "button": "Call To Action",
    "order": 1,
    "background": true
}