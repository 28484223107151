import { associatedItemBlock } from './AssociatedItem';

export default [
    {
      "label": "Title",
      "name": "title",
      "component": "text",
      "focusRing": false
    },
    {
      label: 'Associated List',
      name: 'associatedList',
      component: 'blocks',
      templates: {
        associatedItem: associatedItemBlock.template,
      }
    },
    {
      "label": "Image width",
      "name": "imageWidth",
      "component": "number",
      "description": "Image width in pixels",
      "step": 1,
      "focusRing": false
    },
    {
      "label": "Image height",
      "name": "imageHeight",
      "component": "number",
      "description": "Image height in pixels",
      "step": 1,
      "focusRing": false
    },
    {
      "label": "Title Color",
      "name": "titleColor",
      "colorFormat": "hex",
      "widget": "sketch",
      "component": "color",
      "focusRing": false
    },
    {
      "label": "Background Color",
      "name": "backgroundColor",
      "colorFormat": "hex",
      "widget": "sketch",
      "component": "color",
      "focusRing": false
    }
  ]