import React, {useState} from 'react';
import { BlocksControls, InlineBlocks } from 'react-tinacms-inline'
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components'
import { testimonialsItemBlock } from '../TestimonialsItem/TestimonialsItem';
import chevronRightWhite from '../../assets/svgs/chevron-right-white.svg'
import chevronLeftWhite from '../../assets/svgs/chevron-left-white.svg'
import { useCMS } from 'tinacms'

function Testimonials({ index, data }) {
  const cms = useCMS();

  const [leftNavigationVisible, setLeftNavigationVisible] = useState(false);
  const [rightNavigationVisible, setRightNavigationVisible] = useState(true);

  const scroll = (orientation) => {
      let slider = document.getElementsByClassName('testimonials-blocks-container')[0]
      let length = slider.offsetWidth

      let scrollWidth = slider.scrollWidth
      let offsetWidth = slider.offsetWidth

      length = orientation === 'left' ? (length * -1) : length
      slider.scrollLeft += length

      if(cms.enabled){
        setRightNavigationVisible(true)
        setLeftNavigationVisible(true)
      }else{
        setTimeout(() => {
          setRightNavigationVisible( (offsetWidth + slider.scrollLeft) >= scrollWidth ? false : true )
          setLeftNavigationVisible(slider.scrollLeft >= 1 ? true : false )
        }, 500)
      }
  }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <Container style={{...(data.overflow && {marginTop: -110})}}>
        <InlineBlocks
          max={6}
          name="testimonialsList"
          direction="horizontal"
          className={`testimonials-blocks-container ${cms.enabled ? `tina` : ``}`}
          blocks={TESTIMONIALS_BLOCKS}
        />
        {
            leftNavigationVisible && (
              <LeftNavigation onClick={()=>scroll('left')}>
                <img style={{width: 14, height: 14}} src={chevronLeftWhite} alt="navigation-icon" />
              </LeftNavigation>
            )
        }
        {
            rightNavigationVisible && (
              <RightNavigation onClick={()=>scroll()}>
                <img style={{width: 14, height: 14}} src={chevronRightWhite} alt="navigation-icon" />
              </RightNavigation>
            )
        }
      </Container>

    </BlocksControls>
  );
}


const Container = styled.div`
  width: 100%;
  padding: 70px 100px;
  background: #fff9f0;
  position: relative;
  z-index: 0;
  & .testimonials-blocks-container{
    scroll-behavior: smooth;
    margin-top: 50px;
    overflow-x: hidden;
    display: flex;
    justify-content: flex-start;
  }
  & .testimonials-blocks-container.tina > div{
    width: 48%;
    margin-right: 2%;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    background-color: white;
    padding: 20px;
  }
  & .testimonials-blocks-container.tina > div > div:last-child{
    width: 100%;
    margin: 0px !important;
    padding: 0px !important;
  }
`

const LeftNavigation = styled.div`
  position: absolute;
  background-color: #444140;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  bottom: 180px;
  left: 80px;
`

const RightNavigation = styled.div`
  position: absolute;
  background-color: #444140;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  bottom: 180px;
  right: 80px;
`

const TESTIMONIALS_BLOCKS = {
  testimonialsItem: testimonialsItemBlock
};

export const TestimonialsTemplate = {
  "label": "Testimonials",
  "key": "testimonials",
  "fields": fields,
  "defaultItem": defaults
};

export const blockTestimonials = {
  Component: Testimonials,
  template: TestimonialsTemplate,
}