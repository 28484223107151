import background from '../../assets/images/hero-background.png';

export default {
    "_template": "blockHero",
    "background": background,
    "mobileBackground": background,
    "title": "Deldedien por cielo",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "buttons": [
        {
            "_template": "button",
            "label": "CALL TO ACTION",
            "style": "primary",
            "link": "#"
        }
    ]
}