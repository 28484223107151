
export default [
    {
      "label": "Main Image",
      "name": "image",
      "component": "image",
      "parse": media => {
        return media.previewSrc
      },
      "previewSrc": formValues => {
        return formValues;
      },
      "focusRing": false
    },
    {
      "label": "Image Width",
      "name": "imageWidth",
      "component": "number",
      "description": "In pixels",
      "step": 1,
      "focusRing": false
    },
    {
      "label": "Image Height",
      "name": "imageHeight",
      "component": "number",
      "description": "In pixels",
      "step": 1,
      "focusRing": false
    },
    {
        "label": "Title",
        "name": "title",
        "component": "text",
        "focusRing": false
    },
    {
        "label": "Description",
        "name": "description",
        "component": "text",
        "focusRing": false
    },  
    {
        "label": "Video URL",
        "name": "videoUrl",
        "component": "text",
        "focusRing": false
    },
    {
      "label": "Autoplay",
      "name": "autoplay",
      "component": "toggle",
      "description": "El video se reproducirá automaticamente",
      "toggleLabels": false,
      "focusRing": false
    },
    {
      "label": "Video Width",
      "name": "videoWidth",
      "component": "number",
      "description": "In pixels",
      "step": 1,
      "focusRing": false
    },
    {
      "label": "Video Height",
      "name": "videoHeight",
      "component": "number",
      "description": "In pixels",
      "step": 1,
      "focusRing": false
    },
    {
        "label": "Background Color",
        "name": "backgroundColor",
        "colorFormat": "hex",
        "widget": "sketch",
        "component": "color",
        "focusRing": false
    }
]