import React, {useState} from 'react'
import { useHistory } from 'react-router-dom'
import { InlineTextarea } from 'react-tinacms-inline'
import SubmitButton from '../buttons/SubmitButton'
import Password from './fields/Password'
import Email from './fields/Email'
import Code from './fields/Code'
import '../../App.css'

function ForgotPasswordVerification (props) {
  const history = useHistory()
  const [passwordValidationStatus, setPasswordValidationStatus] = useState({})

  const styles = {
    container: {
      backgroundColor: 'white',
      boxShadow: '0px 0px 12px 8px #0000001a'
    },

    title: {
      color: '#43434a',
      fontWeight: '800',
      fontFamily: 'Muli'
    },

    subtitle: {
      paddingTop: 8,
      paddingBottom: 21,
      fontWeight: '600',
      fontSize: 17,
      fontFamily: 'Muli',
      color: '#9ea2b4'
    },
    separator: {
      width: '100%',
      height: 1,
      backgroundColor: '#016cff'
    },
    form: {
      paddingTop: 50
    },

    signInMessage: {
      paddingTop: 50,
      fontWeight: '600',
      fontSize: 16,
      fontFamily: 'Muli',
      color: '#8891a6'
    },

    signInLink: {
      fontWeight: 700,
      fontFamily: 'Muli',
      textDecoration: 'underline',
      display: 'block'
    }
  }

  const validatePassword = (value) => {
    setPasswordValidationStatus({
      uppercase: value.match('(?=.*[A-Z])') !== null ? true : false,
      lowercase: value.match('(?=.*[a-z])') !== null ? true : false,
      special: value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/) !== null ? true : false,
      number: value.match('([0-9])') !== null ? true : false,
      min: value.length >= 8 ? true : false,
    }) 
  }

  const submitEnabled = () => {
    let { lowercase, uppercase, special, number, min } = passwordValidationStatus
    return (
      props.email !== '' &&
      props.code !== '' &&
      props.password !== '' &&
      lowercase &&
      uppercase &&
      special &&
      number &&
      min
    )
  }

  return (
    <div className='forgot-password-form-container' style={styles.container}>
      <div className='forgot-password-form-title' style={styles.title}>
        <InlineTextarea name='title' focusRing={false} />
      </div>
      <div style={styles.subtitle}>
        <InlineTextarea name='description' focusRing={false} />
      </div>
      <div style={styles.separator} />
      {props.error && <p style={{ color: 'red' }}>{props.error}</p>}
      <form style={styles.form}>
        <Email
          value={props.email}
          onChange={value => props.onChangeEmail(value)}
        />
        <Code
          value={props.code}
          onChange={value => props.onChangeCode(value)}
        />
        <Password
          masking={true}
          value={props.password}
          onActionClick={() => null}
          containerStyle={{position: 'relative'}}
          validationStatus={passwordValidationStatus}
          onChange={(value) => {
            props.onChangePassword(value)
            validatePassword(value);
          }}
        />
      </form>
      <SubmitButton loading={!submitEnabled()} label={props.button} onPress={() => props.onSubmit()} />
      <div style={styles.signInMessage}>
        <InlineTextarea name='signinmessage' focusRing={false} />
        <span
          onClick={() => history.push('/sign-in')}
          className='touchable-opacity'
          style={styles.signInLink}
        >
          <InlineTextarea name='signin' focusRing={false} />
        </span>
      </div>
    </div>
  )
}

export default ForgotPasswordVerification
