import { testimonialsItemBlock } from '../TestimonialsItem/TestimonialsItem';

export default [
    {
        label: 'Testimonials List',
        name: 'testimonialsList',
        component: 'blocks',
        templates: {
          testimonialsItem: testimonialsItemBlock.template,
        }
    },
    {
        "label": "Overflow",
        "name": "overflow",
        "component": "toggle",
        "description": "This element overflows to the component above",
        "toggleLabels": false,
        "focusRing": false
    }
]
