
export default [
    {
        "label": "Background Gradient First Color",
        "name": "backgroundGradientFirstColor",
        "colorFormat": "hex",
        "widget": "sketch",
        "component": "color",
        "focusRing": false
    },
    {
        "label": "Background Gradient Second Color",
        "name": "backgroundGradientSecondColor",
        "colorFormat": "hex",
        "widget": "sketch",
        "component": "color",
        "focusRing": false
    },
    {
        "label": "Background Gradient Angle",
        "name": "backgroundGradientAngle",
        "component": "number",
        "step": 45,
        "focusRing": false
    },
]