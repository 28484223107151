import React, {createContext, useState, useEffect, useContext} from "react";
import { getCategories } from "../helpers/categories";
import { PersistentContext } from '../context';

export const CategoryContext = createContext();

const CategoryContextProvider = ({ children }) => {

  const { keys, id, production } = useContext(PersistentContext);

  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);

  const fetchCategories = async () => {
    setCategoriesLoading(true)
    const siteId = production ? keys.id : id 
    const { data } = await getCategories(siteId)
    setCategories(data)
    setCategoriesLoading(false)
    setCategoriesLoaded(true)
  }

  useEffect(() => {
    fetchCategories()
  },[])

  return (
    <CategoryContext.Provider
      value={{
        categories,
        categoriesLoading,
        categoriesLoaded
      }}
    >
        {children}
    </CategoryContext.Provider>
  )

};

export default CategoryContextProvider; 
