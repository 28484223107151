import React, {useState} from 'react';
import '../App.css';
import chevronDown from '../assets/svgs/chevron-down.svg';

function OrderByDropdown({containerStyle, value, onSelect}) {

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const options = [
    'Popularidad',
    'Likes',
    'Duracion',
    'Precio'
  ];

  return (
    <div style={{...styles.container, ...containerStyle}}>
        <div onClick={()=>setDropdownVisible(!dropdownVisible)} style={styles.button} className="touchable-opacity">
            <div style={styles.text}>{!value ? 'Ordenar Por' : value}</div>
            <img alt="dropdown-icon" style={styles.icon} src={chevronDown} />
        </div>
        {
            dropdownVisible ?
                <div style={styles.dropdown}>
                    {
                        options.map(element => {
                            return <div onClick={()=>{onSelect(element); setDropdownVisible(false)}} style={styles.option} className="touchable-opacity">{element}</div>
                        })
                    }
                </div>
            : null
        }
    </div>
  );
}

const styles = {
    container: {
        position: 'relative'
    },
    button: {
        border: 'rgb(1, 108, 255) 2px solid',
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        padding: '0px 10px',
        height: 45
    },
    text: {
        color: 'rgb(1, 108, 255)',
        fontSize: 18,
        fontWeight: '800',
        fontFamily: 'Muli',
    },
    icon: {
      width: 10,
      height: 10,
      marginLeft: 40
    },
    dropdown: {
        position: 'absolute',
        right: 0,
        top: 45,
        width: '100%',
        zIndex: 1,
        backgroundColor: 'white',
        border: '2px solid rgb(1, 108, 255)',
        padding: 20
    },
    option: {
        textAlign: 'left',
        color: 'rgb(1, 108, 255)',
        fontWeight: '800',
        fontFamily: 'Muli',
        margin: '5px 0px'
    }
}

export default OrderByDropdown;