import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import ReactMarkdown from 'react-markdown'
import { BlocksControls, InlineText } from 'react-tinacms-inline';
import defaults from './Faq.defaults'
import fields from './Faq.fields'

import question from '../assets/svgs/question.svg'
import minus from '../assets/svgs/minus.svg'
import plus from '../assets/svgs/plus.svg'

import '../App.css';

function Faq({ index, data }) {

    const { items } = data;

    const [selectedQuestion, setSelectedQuestion] = useState(null)

    const styles = {
        content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '35px 0px'
        },
        title: {
            color: 'rgb(67, 67, 74)',
            fontWeight: '800',
            fontSize: 34,
            fontFamily: 'Muli'
        },
        subtitle: {
            fontWeight: '600',
            fontSize: 19,
            fontFamily: 'Muli',
            color: 'rgb(158, 162, 180)',
            marginTop: 14,
            lineHeight: '30px',
        },
        containerStyle: {
            margin: 0,
            height: 54,
            width: 470,
            margin: '25px auto 0px auto',
            backgroundColor: 'white',
            boxShadow: '0px 0px 12px -10px black'
        },
        iconStyle: {
            width: 20,
            height: 20
        },
        separator: {
            backgroundColor: '#eaebec',
            height: 3,
            width: '100%',
            marginTop: 36,
            marginBottom: 26
        },
        faqSection: {
            width: '100%'
        },
        faqList: {
            marginTop: 30,
            height: '60vh',
            overflowY: 'auto',
            paddingRight: 26
        },
        faqItem: {
            display: 'flex',
            backgroundColor: 'white',
            transition: 'height 0.4s'
        },
        faqIconContainer: {
            flexGrow: 0,
            flexShrink: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            padding: '20px 25px',
            borderBottom: 'rgb(234, 235, 236) 3px solid'
        },
        faqIcon: {
            width: 24,
            height: 24
        },
        faqTitle: {
            flexGrow: 1,
            flexShrink: 1,
            textAlign: 'left',
            padding: '20px 25px 20px 0px',
            borderBottom: 'rgb(234, 235, 236) 3px solid',
            color: 'rgb(67, 67, 74)',
            fontWeight: '800',
            fontSize: 16,
            fontFamily: 'Muli'
        },
        faqControl: {
            flexGrow: 0,
            flexShrink: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px 34px'
        },
        faqControlIcon: {
            width: 18,
            height: 18
        },
        faqAnswer: {
            color: 'rgb(158, 162, 180)',
            paddingTop: 10,
            transition: 'transform 0.4s'
        }
    };

    const toggleSelectedQuestion = (index) => {
        selectedQuestion === index ? setSelectedQuestion(null) : setSelectedQuestion(index)
    }

    return (
        <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
            <div className="page">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Preguntas Frecuentes</title>
                    <link rel="canonical" href="https://store.l3rn.io/faq" />
                </Helmet>
                <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#f7f8fa' }}>
                    <div style={styles.content} className="general-padding">
                        <div style={styles.faqSection}>
                            <div style={{ ...styles.title, fontSize: 30, textAlign: 'left' }}>
                                <InlineText name="title" focusRing={false} />
                            </div>
                            <div style={{ ...styles.subtitle, fontSize: 25, textAlign: 'left' }}>
                                <InlineText name="subtitle" focusRing={false} />
                            </div>
                            <div className="faq-scrollview" style={styles.faqList}>
                                {
                                    items && items.map((element, index) => {
                                        return (
                                            <div key={index} onClick={() => toggleSelectedQuestion(index)} className="touchable-opacity" style={styles.faqItem}>
                                                <div style={styles.faqIconContainer}>
                                                    <img style={styles.faqIcon} alt="faq-icon" src={question} />
                                                </div>
                                                <div style={styles.faqTitle}>
                                                    {element.title}
                                                    {
                                                        selectedQuestion === index && (
                                                            <ReactMarkdown skipHtml={true} children={element.text} />
                                                        )
                                                    }
                                                </div>
                                                <div style={{
                                                    ...styles.faqControl,
                                                    backgroundColor: (index % 2 === 0) ? '#144974' : '#7393ac',
                                                    borderBottom: (index % 2 === 0) ? '#144974 3px solid' : '#7393ac 3px solid'
                                                }}>
                                                    <img style={styles.faqControlIcon} alt="plus-icon" src={selectedQuestion === index ? minus : plus} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </BlocksControls>
    );
};

export const FaqTemplate = {
    "label": "Faq",
    "key": "Faq",
    "fields": fields,
    "defaultItem": defaults
};

export const blockFaq = {
    Component: Faq,
    template: FaqTemplate,
};