import React, { useState, useContext } from 'react';
import TouchableOpacity from './TouchableOpacity';
import NavigationLink from './NavigationLink';
import { useHistory } from 'react-router-dom';
import { CategoryContext } from '../context';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

function CategoriesDropdown({label, navigationLinkStyle, mobile = false, onRedirect}) {

    const history = useHistory();

    const { categories, categoriesLoading } = useContext(CategoryContext)

    const [visible, setVisible] = useState(false)

    const toggle = () => {
        setVisible(!visible)
    }

    const handleRedirect = (catId) => {
        history.push(`/category/${catId}`)
        toggle()
        return onRedirect ? onRedirect() : null
    }

    const handleCurtainClick = (e) => {
        e.stopPropagation()
        toggle()
    }

  return (
    <div 
        style={{
            ...styles.container, 
            ...(mobile && {flexDirection: 'column', alignItems: 'flex-start'})
        }}
    >

        <NavigationLink 
            label={label}
            styles={{ 
                ...navigationLinkStyle,
                paddingRight: 0, 
                display: 'flex'
            }}
            onPress={()=>toggle()}
            dropdownVisible={visible}
            showAsDropdown={true}
        />
        
        <Dropdown 
            style={{ 
                visibility: visible ? 'visible' : 'hidden',
                display: (mobile && !visible) && 'none',
                ...(mobile && styles.dropdownMobile) 
            }}
        >
            {
                categoriesLoading ? (
                    <div style={styles.loaderContainer}>
                        <CircularProgress style={{width:24,height:24, color: '#00A8D8'}} />
                    </div>
                ) :
                categories && (
                    categories.length <= 0 ? (
                        <div style={styles.emptyState}>Aún no hay categorías</div>
                    ) :
                    categories.map((category, index) => (
                        <TouchableOpacity
                            key={`CategoriesDropdown-${index}`}
                            containerStyle={{...styles.category, ...(mobile && { padding: '5px 0px', fontSize: 12})}}
                            onPress={()=>handleRedirect(category.id)}
                        >
                            {category.name}
                        </TouchableOpacity>
                    ))
                )
            }
        </Dropdown>
        {
            visible && (
                <div onClick={(e)=>handleCurtainClick(e)} style={styles.curtain} />
            )
        }
    </div>
  );
}

export const Dropdown = styled.div`
    top: 40px;
    right: 0;
    background-color: white;
    position: absolute;
    z-index: 2;
    min-width: 210px;
    max-height: 300px;
    overflow-y: scroll;
    border-radius: 4px;
    padding: 10px 30px;
    box-shadow: rgb(0 0 0 / 24%) 0px 0px 12px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #989898;
    }
    ::-webkit-scrollbar-corner {
        background: #0c0c0c;
    }
`;

const styles = {
  container: {
    position: 'relative', 
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    textDecoration: 'none',
    padding: '0px 12px',
    fontWeight: 600,
    fontFamily: 'Muli'
  },
  category: {
    fontFamily: 'Muli',
    color: 'rgb(122, 123, 146)',
    fontWeight: 'bold',
    padding: '10px 0px',
    width: '100%',
    textAlign: 'left'
  },
  curtain: { 
    position: 'fixed', 
    top: 0, 
    left: 0, 
    width: '100vw', 
    height: '100vh', 
    zIndex: 1
  },
  loaderContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  emptyState: {
    fontWeight: 600,
    fontFamily: 'Muli',
    color: 'rgb(122, 123, 146)'
  },
  dropdownMobile: {
    position: 'relative',
    top: 0,
    boxShadow: 'none',
    padding: '0px 0px 0px 15px',
    minWidth: 150
  }
}

export default CategoriesDropdown;