import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import { BlocksControls } from 'react-tinacms-inline';
import ForgotPasswordForm from '../components/forms/ForgotPasswordForm';
import defaults from './ForgotPassword.defaults'
import fields from './ForgotPassword.fields'
import { useMediaQuery } from '../helpers/Hooks'

import '../App.css';

function ForgotPassword({ index, data }) {

  const history = useHistory();

  const isMobile = useMediaQuery('(max-width: 980px)');

  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)

  const styles = {
    forgotPasswordWrapper: {
      flexGrow: 1,
      flexShrink: 1,
      display: 'flex',
      padding: isMobile ? '35px 30px' : '75px 30px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f7f8fa'
    },
    featuredImage: {
      width: 650,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${data.background})`
    },
  };

  const onSubmit = async () => {
    if (email.trim() !== '') {
      try {
        await Auth.forgotPassword(email);
        history.push('/forgot-password-email-sent');
      }
      catch (error) {
        console.log(error);
        history.push('/forgot-password-email-sent');
      }
    } else {
      setError('Email requerido')
    }
  }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <div className="page">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Recuperar Contraseña</title>
          <link rel="canonical" href="https://store.l3rn.io/forgot-password" />
        </Helmet>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={styles.forgotPasswordWrapper}>
            <ForgotPasswordForm
              title={data.title}
              description={data.description}
              button={data.button}
              signinmessage={data.signinmessage}
              signin={data.signin}
              error={error}
              email={email}
              onSubmit={onSubmit}
              onEmailChange={(value) => setEmail(value)}
            />
          </div>
          {
            isMobile ? <></> :
              <div style={styles.featuredImage} />
          }
        </div>
      </div>
    </BlocksControls>
  );
};

export const ForgotPasswordTemplate = {
  "label": "ForgotPassword",
  "key": "ForgotPassword",
  "fields": fields,
  "defaultItem": defaults
};

export const blockForgotPassword = {
  Component: ForgotPassword,
  template: ForgotPasswordTemplate,
};