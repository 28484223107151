export default [
  {
    "label": "Image",
    "name": "image",
    "component": "image",
    "parse": media => {
      return media.previewSrc
    },
    "previewSrc": formValues => {
      return formValues;
    },
    "focusRing": false
  },
  {
    "label": "Title",
    "name": "title",
    "component": "text",
    "focusRing": false
  },
  {
    "label": "Description",
    "name": "description",
    "component": "text",
    "focusRing": false
  },
  {
    "label": "Button",
    "name": "button",
    "component": "text",
    "focusRing": false
  },
  {
    "label": "Order",
    "name": "order",
    "component": "select",
    "options": [ 
      {
        value: 1,
        label: 'Normal',
      },
      {
        value: 2,
        label: 'Flipped',
      }
    ],
    "focusRing": false
  }
]