import React, { useContext } from 'react';
import { PersistentContext } from '../context';

const Disable = ({ children }) => {
  const { production } = useContext(PersistentContext);
  if (production) {
    return (
      <>
        {children}
      </>
    );
  }
  return (
    <div onClickCapture={(ev) => {
      ev.stopPropagation();
      ev.preventDefault();
    }}>
      {children}
    </div>
  );
}

export default Disable;