import React from 'react';
import { InlineTextarea, BlocksControls } from 'react-tinacms-inline';
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components'
import MainButton from '../../components/buttons/MainButton'

function TextImage({ index, data }) {

  const isFlipped = () => {
    return data.order === 2 ? true : false
  }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <Container style={{flexDirection: isFlipped() ? 'row-reverse' : 'row'}}>
        <Image 
          style={{
            margin: isFlipped() ? '0px 90px 0px 0px' : '0px 0px 0px 70px',
            width: isFlipped() ? 520 : 520,
            height: isFlipped() ? 335 : 335, 
            backgroundImage: `url(${data.image})`
          }}
        />
        <Content style={{ margin: isFlipped() ? '0px 100px 0px 70px' : '0px 70px 0px 100px'}}>
          <Title>
            <InlineTextarea name="title" focusRing={false} />
          </Title>
          <Description>
            <InlineTextarea name="description" focusRing={false} />
          </Description>
          <MainButton styles={{marginTop: 30}} label={data.button} />
        </Content>
      </Container>
    </BlocksControls>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 70px 100px;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  @media (max-width: 768px) {
    padding: 36px 30px 140px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: flex-start; 
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
`

const Image = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  flex-grow: 0;
`

const Title = styled.div`
  color: #152647;
  font-size: 34px;
  font-weight: 800;
  font-family: Arial;
  text-align: left; 
`

const Description = styled.div`
  color: #636667;
  font-size: 18px;
  line-height: 36px;
  font-weight: 600;
  font-family: Arial;
  margin-top: 22px;
  text-align: left;
`

export const TextImageTemplate = {
  "label": "Text Image",
  "key": "textImage",
  "fields": fields,
  "defaultItem": defaults
};

export const blockTextImage = {
  Component: TextImage,
  template: TextImageTemplate,
}