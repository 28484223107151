import React from 'react';
import { InlineTextarea, BlocksControls } from 'react-tinacms-inline';
import defaults from './HeroSocials.defaults'
import { useMediaQuery } from '../helpers/Hooks'
import TouchableOpacity from '../components/TouchableOpacity'
import fbIcon from '../assets/images/fb.png';

function HeroSocials({ index, data }) {

  const isMobile = useMediaQuery('(max-width: 768px)');

  const styles = {
    container: {
      width: '100%',
      minHeight: '650px',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    },
    content: {
      width: '840px',
      padding: isMobile ? '36px 30px 140px' : `${data.contentPadding ? data.contentPadding : 70}px 100px`,
      textAlign: 'left',
      color: 'white'
    },
    title: {
      color: 'white',
      fontSize: isMobile ? 30 : 38,
      paddingTop: 4,
      lineHeight: isMobile ? '36px' : '52px',
      fontWeight: '800',
      fontFamily: 'Muli',
    },
    subtitle: {
      fontSize: '24px',
      paddingTop: 15,
      fontFamily: 'Muli',
      color: 'white',
      display: isMobile ? 'none' : 'block'
    },
    description: {
      fontSize: isMobile ? 22 : 20,
      fontFamily: 'Muli',
      lineHeight: '30px',
      paddingTop: isMobile ? 18 : 23,
      color: 'white'
    },
    socialMediaList: {
      marginTop: 26,
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      flexWrap: isMobile ? 'no-wrap' : 'wrap',
      alignItems: 'flex-start',
      rowGap: isMobile ? 22 : 38
    },
    socialMediaItem: {
        width: isMobile ? 'auto' : '48%', 
        marginRight: isMobile ? '0%' : '2%', 
        display: 'flex', 
        columnGap: 16, 
        alignItems: 'center'
    },
    socialMediaLogo: {
        width: isMobile ? 22 : 32, 
        height: isMobile ? 22 : 32
    },
    socialMediaLabel: {
        fontSize: isMobile ? 16 : 18
    }
  }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <div 
        style={{
          ...styles.container, 
          backgroundImage: `url(${isMobile ? data.mobileBackground : data.background})`
        }}
      >
        <div style={styles.content}>
          {
            data.subtitle !== '' && (
              <div style={styles.subtitle}>
                <InlineTextarea name="subtitle" focusRing={false} />
              </div>
            )
          }
          {
            data.title !== '' && (
              <div style={styles.title}>
                <InlineTextarea name="title" focusRing={false} />
              </div>
            )
          }
          {
            data.description !== '' && (
              <div style={styles.description}>
                <InlineTextarea name="description" focusRing={false} />
              </div>
            )
          }
          {
            data.showSocials && (
              <div style={styles.socialMediaList}>
                {
                    data.socialMedia.map((element)=>(
                        <TouchableOpacity
                            containerStyle={styles.socialMediaItem} 
                            onPress={()=>window.open(element.link, "_blank")}
                        >
                            <img style={styles.socialMediaLogo} src={element.logo}/>
                            <div style={styles.socialMediaLabel}>{element.label}</div>
                        </TouchableOpacity>
                    ))
                }
              </div>
            )
          }
        </div>
      </div>
    </BlocksControls>
  );
}

export const HeroSocialsTemplate = {
  "label": "HeroSocials",
  "key": "heroSocials",
  "fields": [
    {
      "label": "Background",
      "name": "background",
      "component": "image",
      "parse": media => {
        return media.previewSrc
      },
      "previewSrc": formValues => {
        return formValues;
      },
      "focusRing": false
    },
    {
      "label": "Mobile Background",
      "name": "mobileBackground",
      "component": "image",
      "parse": media => {
        return media.previewSrc
      },
      "previewSrc": formValues => {
        return formValues;
      },
      "focusRing": false
    },
    {
      "label": "Title",
      "name": "title",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Subtitle",
      "name": "subtitle",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Description",
      "name": "description",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Show Socials",
      "name": "showSocials",
      "component": "toggle",
      "description": "Show Socials",
      "toggleLabels": false,
      "focusRing": false
    },
    {
        label: "Social media",
        name: "socialMedia",
        component: "group-list",
        itemProps: item => ({
          label: item.link,
        }),
        fields: [
          {
            "label": 'Logo',
            "name": 'logo',
            "component": 'image',
            "parse": media => {
              return media.previewSrc
            },
            "previewSrc": formValues => {
              return formValues;
            },
            "focusRing": false,
          },
          {
            label: "Label",
            name: "label",
            component: "text",
            parse(value) {
              return value || ""
            },
          },
          {
            label: "Link",
            name: "link",
            component: "text",
            parse(value) {
              return value || ""
            },
          },
        ],
        defaultItem: {
            "logo": fbIcon,
            "label": "@example",
            "link": "https://www.example.com/"
        },
    },
    {
      "label": "Content padding",
      "name": "contentPadding",
      "component": "number",
      "description": "Content padding in pixels",
      "step": 1,
      "focusRing": false
    }
  ],
  "defaultItem": defaults
};

export const blockHeroSocials = {
  Component: HeroSocials,
  template: HeroSocialsTemplate,
}