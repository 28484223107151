import React from 'react'
import { useParams } from 'react-router-dom'
import { TinaProvider, TinaCMS } from 'tinacms'
import { PersistentContext, PersistentContextProvider } from './context'
import RouteChangeListener from './RouteChangeListener'
import Loader from './components/Loader'
import Template from './Template'
import Landing from './Landing'

function StandaloneApp () {
  const cms = new TinaCMS({
    enabled: false
  })

  const params = useParams()
  if (!params.page) {
    params.page = 'home'
  }

  return (
    <>
      <PersistentContextProvider params={params}>
        <RouteChangeListener />
        <PersistentContext.Consumer>
          {({ type, data }) => {
            if (!data) {
              return <Loader />
            }
            if (type === 'Landing') {
              return (
                <TinaProvider cms={cms}>
                  <Landing {...params} />
                </TinaProvider>
              )
            } else {
              return (
                <TinaProvider cms={cms}>
                  <Template {...params} />
                </TinaProvider>
              )
            }
          }}
        </PersistentContext.Consumer>
      </PersistentContextProvider>
    </>
  )
}

export default StandaloneApp
