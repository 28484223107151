import { linkSectionBlock } from '../LinkSection/LinkSection';

export default [
    {
      "label": "Logo",
      "name": "logo",
      "component": "image",
      "parse": media => {
        return media.previewSrc
      },
      "previewSrc": formValues => {
        return formValues;
      },
      "focusRing": false
    },
    {
        'label': 'Blocks Sections',
        'name': 'linkSections',
        'component': 'blocks',
        'templates': {
          'link': linkSectionBlock.template,
        },
    },
    {
      "label": "Contact Title",
      "name": "contactTitle",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Contact Description",
      "name": "contactDescription",
      "component": "text",
      "focusRing": false
    },
]