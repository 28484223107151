import React, { useContext, useEffect } from 'react'
import { useForm, usePlugin, useCMS } from 'tinacms'
import { InlineForm } from 'react-tinacms-inline'
import OrderCompletedPage from '../blocks/OrderCompleted';
import { PersistentContext } from '../context';
import { ShopContext } from '../context';

export default function PageOrderCompleted(props) {
  const { saveContent, getItemData } = useContext(PersistentContext);
  const { cleanLocalCart } = useContext(ShopContext);

  const cms = useCMS();

  useEffect(()=>{
    cleanLocalCart()
  },[])

  const clearItemData = async () => {
    await saveContent({'order-completed': null});
    window.location.reload()
  }

  const setUp = () => {
    const data = getItemData('order-completed');
    if(data.blocks){
      if(data.blocks[0]){
        if(data.blocks[0]._template === "blockOrderCompleted"){
          clearItemData() 
        }
      }
    }
    const formConfig = {
      id: 'templateOrderCompleted',
      fields: [
        {
            "label": "Background",
            "name": "background",
            "component": "image",
            "parse": media => {
              return media.previewSrc
            },
            "previewSrc": formValues => {
              return formValues;
            },
            "focusRing": false
        },
        {
          "label": "Title",
          "name": "title",
          "component": "text",
          "focusRing": false
        },
        {
          "label": "Subtitle",
          "name": "subtitle",
          "component": "text",
          "focusRing": false
        },
        // {
        //     "label": "Button",
        //     "name": "button",
        //     "component": "text",
        //     "focusRing": false
        // },
      ],
      onSubmit: async (data) => {
        await saveContent({'order-completed': data});
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data,
    }
    return formConfig;
  }
  const formConfig = setUp();
  const [modifiedValues, form] = useForm(formConfig);
  usePlugin(form)

  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`}>
      <InlineForm form={form}>
        <OrderCompletedPage data={modifiedValues}/>
      </InlineForm>
    </div>
  )
}