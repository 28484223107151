import image from '../../assets/images/icon.png';

export default {
    "_template": 'blockBoxes',
    "title": "Lorem ipsum dolor",
    "subtitle": "POWERFUL TAGLINE HERE",
    "boxesList": [
        {
          "_template": "boxesItem",
          "image": image,
          "title": "TITLE HERE",
          "description": "Dolore magna aliqua ut enim adel min veniam quis nostrud desde dedsden parar del"
        },
        {
          "_template": "boxesItem",
          "image": image,
          "title": "TITLE HERE",
          "description": "Dolore magna aliqua ut enim adel min veniam quis nostrud desde dedsden parar del"
        },
        {
          "_template": "boxesItem",
          "image": image,
          "title": "TITLE HERE",
          "description": "Dolore magna aliqua ut enim adel min veniam quis nostrud desde dedsden parar del"
        }
    ]
}