import logo from '../../assets/images/logo.png'
import background from '../../assets/images/cover.png';

export default {
    "_template": "blockFooter",
    "logo": logo,
    "backgroundType": "backgroundImage",
    "backgroundImage": background,
    "backgroundColor": "#000000",
    "links": [
        {
            "_template": "link",
            "label": "Home",
            "link": "#"
        },
        {
            "_template": "link",
            "label": "About us",
            "link": "#"
        },
        {
            "_template": "link",
            "label": "Our Products",
            "link": "#"
        },
        {
            "_template": "link",
            "label": "Contact Us",
            "link": "#"
        }
    ],
    "linksTextColor": "#ffffff",
    "showSocials": true,
    "socials": [
        {
            "_template": "social",
            "platform": "instagram",
            "link": "#"
        },
        {
            "_template": "social",
            "platform": "youtube",
            "link": "#"
        },
        {
            "_template": "social",
            "platform": "whatsapp",
            "link": "#"
        },
    ],
}