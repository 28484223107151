import React from 'react';
import { InlineTextarea, BlocksControls } from 'react-tinacms-inline';
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components'
import MainButton from '../../components/buttons/MainButton'

function TextImage({ index, data }) {

  const isOverflow = () => {
    return data.imageDisplay === 2 ? true : false
  }
  
  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <Container>
        <Content>
          <Subtitle>
            <InlineTextarea name="subtitle" focusRing={false} />
          </Subtitle>
          <Title>
            <InlineTextarea name="title" focusRing={false} />
          </Title>
          <Description>
            <InlineTextarea name="description" focusRing={false} />
          </Description>
          <MainButton styles={{marginTop: 30}} label={data.button} />
        </Content>
        <ImagesContainer
          style={{
            ...(
              isOverflow() ?
                {
                  width: 620,
                  height: 329
                }
              :
                {
                  width: 620,
                  height: 375
                }
            )
          }}
        >
          <Image 
            style={{
              backgroundImage: `url(${data.image})`,
              ...(
                isOverflow() ? 
                  {
                    top: 0, 
                    left: 0
                  } 
                : 
                  {
                    right: 0, 
                    bottom: 0
                  })
            }} 
          />
          <AltImage 
            style={{
              backgroundImage: `url(${data.altImage})`,
              ...(
                isOverflow() ? 
                  { 
                    bottom: 92, 
                    right: 0,
                    width: 362,
                    height: 393 
                  } 
                : 
                  {
                    top: 0, 
                    left: 0,
                    width: 450,
                    height: 279 
                  }
              )
            }} 
          />
        </ImagesContainer>
      </Container>
      {
        data.separator && (
          <Separator />
        )
      }
    </BlocksControls>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 70px 100px;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  @media (max-width: 768px) {
    padding: 36px 30px 140px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: flex-start; 
  justify-content: center;
  width: 500px;
  margin-left: 50px;
`

const ImagesContainer = styled.div`
  margin-right: 50px;
  flex-grow: 0;
  position: relative;
  flex-shrink: 0;
`

const Image = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  width: 362px;
  height: 329px;
`

const AltImage = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
`

const Subtitle = styled.div`
  color: #9dbe15;
  font-size: 16px;
  font-weight: 800;
  font-family: Arial;
`

const Title = styled.div`
  color: #222e3e;
  font-size: 36px;
  font-weight: 800;
  font-family: Arial;
  text-align: left;
`

const Description = styled.div`
  width: 100%;
  color: #908e8e;
  font-size: 20px;
  line-height: 34px;
  font-weight: 600;
  font-family: Arial;
  margin-top: 10px;
  text-align: left;
`

const Separator = styled.div`
  width: calc(100% - 200px);
  margin: 0px 100px;
  height: 1px;
  background-color: #d0d0d0;
`

export const TextImageTemplate = {
  "label": "Text Image",
  "key": "textImage",
  "fields": fields,
  "defaultItem": defaults
};

export const blockTextImage = {
  Component: TextImage,
  template: TextImageTemplate,
}