import { CircularProgress } from '@material-ui/core'
import React from 'react'

function MainButton ({ onPress, disabled, type, label, styles, loading }) {
  return (
    <button
      onClick={e => onPress && onPress(e)}
      className='touchable-opacity'
      disabled={disabled ? disabled : false}
      style={{
        ...buttonStyle,
        ...(type === 'primary'
          ? primary
          : type === 'secondary'
          ? secondary
          : type === 'tertiary'
          ? tertiary
          : primary),
        ...styles,
        ...(disabled ? disabledStyle : {})
      }}
    >
      {
        loading ? (
          <CircularProgress size={20} color="#fff" />
        ) : label
      }
    </button>
  )
}

const buttonStyle = {
  outline: 'none',
  border: 'none',
  borderRadius: 6,
  padding: '14px 24px',
  fontSize: 16,
  fontWeight: '800',
  fontFamily: 'Muli',
  letterSpacing: 0.7
}

const disabledStyle = {
  backgroundColor: '#7398FD',
  color: 'white',
  border: '#7398FD 2px solid'
}

const primary = {
  backgroundColor: '#016cff',
  color: 'white',
  border: '#016cff 2px solid'
}

const secondary = {
  backgroundColor: 'transparent',
  color: 'white',
  border: 'white 2px solid'
}

const tertiary = {
  backgroundColor: 'transparent',
  color: '#016cff',
  border: '#016cff 2px solid'
}

export default MainButton
