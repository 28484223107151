import React from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import '../../App.css';

function NavigationButton(props) {

  const styles = {

    container: {
      backgroundColor: 'white',
      width: 43,
      height: 43,
      borderRadius: 4,
      boxShadow: '0px 1px 8px 1px #0000002e',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    
    icon: {
      width: 24,
      height: 24,
      fill: '#016cff'
    }
    
  }

  return (
    <div onClick={props.onPress} style={{...styles.container, ...props.containerStyle}} className="touchable-opacity service-boxes-navigation-button">
      {
        props.orientation === 'left' ? (
          <ChevronLeftIcon style={styles.icon} />
        ) :
        (
          <ChevronRightIcon style={styles.icon} />
        )
      }
    </div>
  );
}

export default NavigationButton;
