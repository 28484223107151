import React, {useContext} from 'react'
import CategoryLabel from './CategoryLabel'
import { formatPrice } from '../helpers/StatisticalFunctions'
import MainButton from './buttons/MainButton'
import { ShopContext } from '../context/ShopContext'
import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components'

function CourseDropdownItem({id, background, title, categoryName, price, originalPrice, onPress, onAddProductToCart, isPurchased, hideAddToCart}) {

    const { isProductInCart, isProductLoading, isAnyProductLoading } = useContext(ShopContext);
    
    const addToCart = (e) => {
        e.stopPropagation()
        onAddProductToCart()
    }

  return (
    <div style={styles.container}>
        <Course onClick={()=>onPress()}>
            <div style={{...styles.previewImage, backgroundImage: `url(${background})`}}/>
            <div style={styles.content}>
                <div style={styles.title}>{title}</div>
                <div style={styles.categoryContainer}>
                    {categoryName && <CategoryLabel name={categoryName} />}
                </div>
                <div style={styles.priceContainer}>
                    {
                        price && (
                            <>
                                {
                                    (price.currency && originalPrice) && (
                                        <>
                                            <div style={styles.originalPrice}>
                                                { formatPrice(originalPrice, price.currency) }
                                            </div>
                                            <div style={{ ...styles.price, margin: '0px 5px' }}>a</div>
                                        </>
                                    )
                                }
                                {
                                    (price.unit_amount && price.currency) && (
                                        <div style={styles.price}>
                                            { formatPrice(price.unit_amount, price.currency) }
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </div>
        </Course>
        {
            !hideAddToCart && (
                <div style={styles.addToCartContainer}>
                    {
                        !isPurchased && (
                            <MainButton
                                disabled={isAnyProductLoading()}
                                onPress={(e) => addToCart(e)}
                                type='tertiary'
                                label={
                                    isProductLoading(id) ? (
                                        <CircularProgress style={{ width: 18, height: 18, color: '#00A8D8'}} />
                                    ) : 
                                        isProductInCart(id) ? 
                                            'Quitar del Carrito'
                                        : 
                                            'Agregar a Carrito'
                                }
                                styles={{ width: '100%', padding: 8, fontSize: 14}}
                            />
                        )
                    }
                </div>
            )
        }
    </div>
  );
}

const Course = styled.div`
    padding: 16px;
    display: flex;
    width: calc(100% - 32px);
    transition: opacity 0.4s;
    cursor: pointer;
    :hover{
        opacity: 0.7;
    }
`

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white',
    borderBottom: '0.5px solid #dcdcdc',
  },
  previewImage: {
    height: 48,
    width: 100,
    borderRadius: 6,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    flexGrow: 0,
    flexShrink: 0
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: 10,
    flexGrow: 1,
    flexShrink: 1
  },
  title: {
    color: 'rgb(67, 67, 74)',
    fontSize: 16,
    fontFamily: 'Muli',
    fontWeight: 800,
    textAlign: 'left',
    lineHeight: '18px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  categoryContainer: {
    marginTop: 4
  },
  priceContainer: {
    display: 'flex',
    marginTop: 5,
    width: '100%',
    flexWrap: 'wrap'
  },
  originalPrice: {
    color: '#e68486',
    fontSize: 14,
    fontFamily: 'Muli',
    fontWeight: '600',
    textDecoration: 'line-through'
  },
  price: {
    color: 'rgb(67, 67, 74)',
    fontSize: 14,
    fontFamily: 'Muli',
    fontWeight: '600'
  },
  addToCartContainer: {
    width: '100%',
    padding: '0px 16px 16px 16px',
    width: 'calc(100% - 32px)'
  }
}

export default CourseDropdownItem;
