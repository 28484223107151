import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useForm, usePlugin, useCMS } from 'tinacms'
import { InlineForm, InlineBlocks } from 'react-tinacms-inline'
import EventDetailPage from '../blocks/EventDetail'
import { blockEvents } from '../blocks/Events'
import { PersistentContext } from '../context'
import { blockHero } from '../blocks/Hero'

export default function PageEventDetail (props) {
  const { saveContent, getItemDataById } = useContext(PersistentContext)

  const cms = useCMS()
  const { param1 } = useParams()

  const COURSE_DETAIL_BLOCKS = {
    blockHero,
    blockEvents
  }

  const clearItemData = async () => {
    await saveContent({ 'event-detail': null })
    window.location.reload()
  }

  const setUp = () => {
    const data = getItemDataById('event-detail', param1)

    if (data && data.blocks) {
      if (data.blocks[0]) {
        if (data.blocks[0]._template === 'blockEventDetail') {
          clearItemData()
        }
      }
    }

    const formConfig = {
      id: 'templateEventDetail',
      fields: [
        {
          label: 'Attributes Title',
          name: 'attributes-title',
          component: 'text',
          focusRing: false
        },
        {
          label: 'Summary Title',
          name: 'summary-title',
          component: 'text',
          focusRing: false
        },
        {
          label: 'Testimonials Title',
          name: 'testimonials-title',
          component: 'text',
          focusRing: false
        },
        {
          label: 'Secciones',
          name: 'blocks',
          component: 'blocks',
          templates: {
            blockHero: blockHero.template,
            blockEvents: blockEvents.template
          }
        }
      ],
      onSubmit: async data => {
        await saveContent({ 'event-detail': data })
        cms.alerts.success('Cambios guardados!')
      },
      initialValues: data
    }
    return formConfig
  }
  const formConfig = setUp()
  const [modifiedValues, form] = useForm(formConfig)
  usePlugin(form)

  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`}>
      <InlineForm form={form}>
        <EventDetailPage data={modifiedValues} />
        <InlineBlocks name='blocks' blocks={COURSE_DETAIL_BLOCKS} />
      </InlineForm>
    </div>
  )
}
