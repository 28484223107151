import React, { useContext } from 'react'
import { useForm, usePlugin, useCMS } from 'tinacms'
import { InlineForm, InlineBlocks } from 'react-tinacms-inline'
import { blockHero2 } from '../blocks/Hero2'
import CoursesByCategorySection from '../blocks/CoursesByCategory'
import { blockDiscount } from '../blocks/Discount'
import { PersistentContext } from '../context';
import { Helmet } from "react-helmet"

export default function PageCourseCatalog(props) {
  const { saveContent, getItemData } = useContext(PersistentContext);

  const cms = useCMS();

  const COURSE_CATALOG_TOP_BLOCKS = {
    blockHero2
  };

  const COURSE_CATALOG_BOTTOM_BLOCKS = {
    blockDiscount
  }

  const clearItemData = async () => {
    await saveContent({'course-catalog': null});
    window.location.reload()
  }

  const setUp = () => {
    const data = getItemData('course-catalog');
    if(data.blocks){
      if(data.blocks[0]){
        if(data.blocks[0]._template === "blockCoursesByCategory"){
          clearItemData() 
        }
      }
    }
    const formConfig = {
      id: 'templateCourseCatalog',
      fields: [
        {
            "label": "Courses shown",
            "name": "coursesShown",
            "component": "select",
            "options": [ 
              {
                value: 4,
                label: '4',
              },
              {
                value: 8,
                label: '8',
              },
              {
                value: 12,
                label: '12',
              },
              {
                value: 16,
                label: '16',
              },
            ],
            "focusRing": false
        },
        {
          label: 'Secciones Arriba',
          name: 'topBlocks',
          component: 'blocks',
          templates: {
            'blockHero2': blockHero2.template
          }
        },
        {
          label: 'Secciones Abajo',
          name: 'bottomBlocks',
          component: 'blocks',
          templates: {
            'blockDiscount': blockDiscount.template
          }
        }
    ],
      onSubmit: async (data) => {
        await saveContent({'course-catalog': data});
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data,
    }
    return formConfig;
  }
  const formConfig = setUp();
  const [modifiedValues, form] = useForm(formConfig);
  usePlugin(form)

  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Catálogo</title>
        <link rel="canonical" href="https://store.l3rn.io/course-catalog" />
      </Helmet>
      <InlineForm form={form}>
        <InlineBlocks name="topBlocks" blocks={COURSE_CATALOG_TOP_BLOCKS} />
        <CoursesByCategorySection data={modifiedValues} />
        <InlineBlocks name="bottomBlocks" blocks={COURSE_CATALOG_BOTTOM_BLOCKS} />
      </InlineForm>
    </div>
  )
}