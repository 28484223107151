import image from '../../assets/images/image.png';
import image2 from '../../assets/images/image2.png';
import image3 from '../../assets/images/image3.png';

export default {
    "_template": 'blockBoxes',
    "title": "Lorem ipsum dolor sit amel dipiscin",
    "boxesList": [
        {
          "_template": "boxesItem",
          "image": image,
          "title": "Lorem ipsum dolor",
          "description": "Dolore magna aliqua. Ut enim ad ullamco laboris nisi ut aliquip ex ea commodo consequat ullamco laboris nisi delde para."
        },
        {
          "_template": "boxesItem",
          "image": image2,
          "title": "Lorem ipsum dolor",
          "description": "Dolore magna aliqua. Ut enim ad ullamco laboris nisi ut aliquip ex ea commodo consequat ullamco laboris nisi delde para."
        },
        {
          "_template": "boxesItem",
          "image": image3,
          "title": "Lorem ipsum dolor",
          "description": "Dolore magna aliqua. Ut enim ad ullamco laboris nisi ut aliquip ex ea commodo consequat ullamco laboris nisi delde para."
        }
    ],
    "boxImageWidth": 370,
    "boxImageHeight": 226,
    "boxPadding": 0,
    "boxShadow": true,
    "boxesBackgroundColor": 'transparent',
    "textColor": '#292c2e',
    "boxesTitleColor": "#292c2e",
    "boxesTextColor": '#292c2e',
    "backgroundColor": '#ffffff',
}