import React from 'react';
import styled from 'styled-components'; 
import { useHistory } from 'react-router-dom';
import { InlineTextarea } from 'react-tinacms-inline';
// import GoogleSignInButton from '../buttons/GoogleSignInButton';
// import FacebookSignInButton from '../buttons/FacebookSignInButton';
import SubmitButton from '../buttons/SubmitButton';
import Password from './fields/Password';
import Email from './fields/Email';

function SignInForm({loading, onSubmit, error, email, onEmailChange, password, onPasswordChange, button, register}) {

  const history = useHistory();

  const handleRedirect = (path) => {
    history.push(path)
  }

  const handleSubmit = () => {
    return !loading ? onSubmit() : null
  }

  return (
    <Container>
      <Title>
        <InlineTextarea name="title" focusRing={false} />
      </Title>
      <div style={styles.subtitle}>
        <InlineTextarea name="description" focusRing={false} />
      </div>
      <div style={styles.separator} />
      { error && (<p style={{color: 'red'}}>{error}</p>)}
      <form onSubmit={() => handleSubmit()} style={styles.form}>
        <Email
          value={email}
          onEnter={() => handleSubmit()}
          onChange={(value) => onEmailChange(value)}
        />
        <Password
          forgotPassword={true}
          onForgotPasswordClick={() => handleRedirect('/forgot-password')}
          value={password}
          onEnter={() => handleSubmit()}
          onChange={(value) => onPasswordChange(value)}
        />
      </form>

      <SubmitButton 
        styles={{height:45}} 
        loading={loading} 
        label={button} 
        onPress={onSubmit} 
      />

      {/* 
      
      // Social Login

      <div style={styles.labeledSeparator}>
        <div style={styles.line} />
        <div style={styles.textWrapper}>
          <div style={styles.text}>
            <InlineTextarea name="option" focusRing={false} />
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
        <GoogleSignInButton label={props.google} onPress={() => null} />
        <FacebookSignInButton label={props.facebook} onPress={() => null} />
      </div> 
      
      */}

      <div style={styles.registerMessage}>
        <InlineTextarea name="aditional_information" focusRing={false} />
        <TouchableSpan 
          onClick={() => handleRedirect('/sign-up')} 
          style={styles.registerLink}
        >
          {register}
        </TouchableSpan>
      </div>
    </Container>
  );
}

const Container = styled.div`
  width: 600px;
  padding: 60px 76px;
  background-color: white;
  box-shadow: 0px 0px 12px 8px #0000001a;
  @media screen and (max-width: 768px) {
    width: 418px;
    padding: 30px 36px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 30px 15px;
  }
`

const Title = styled.div`
  font-family: Muli;
  font-size: 32px;
  color: #43434a;
  font-weight: 800;
  font-family: Muli;
`

const TouchableSpan = styled.div`
  font-weight: 700;
  font-family: Muli;
  text-decoration: underline;
  margin: 0px 5px;
  cursor: pointer;
  transition: opacity 0.4s;
  :hover{
    opacity: 0.7;
  }
`

const styles = {
  subtitle: {
    paddingTop: 8,
    paddingBottom: 21,
    fontWeight: '600',
    fontSize: 17,
    fontFamily: 'Muli',
    color: '#9ea2b4'
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: '#016cff'
  },
  form: {
    paddingTop: 30
  },
  labeledSeparator: {
    position: 'relative',
    height: 40,
    marginTop: 16
  },
  textWrapper: {
    position: 'absolute',
    width: '100%',
    top: 9,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    fontWeight: '600',
    fontSize: 15,
    fontFamily: 'Muli',
    color: '#8891a6',
    backgroundColor: 'white',
    padding: '0px 8px'
  },
  line: {
    position: 'absolute',
    width: '100%',
    height: 1,
    top: 21,
    left: 0,
    backgroundColor: '#e2e2e2'
  },
  registerMessage: {
    paddingTop: 10,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: 'Muli',
    color: '#8891a6'
  }
}

export default SignInForm;