import React, {useState, useContext} from 'react';
import { BlocksControls } from 'react-tinacms-inline';
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components';
import { formatPrice } from "../../../../helpers/StatisticalFunctions";
import ReactMarkdown from 'react-markdown'
import MainButton from '../../components/buttons/MainButton'
import { loadStripe } from '@stripe/stripe-js';
import { PersistentContext } from '../../../../context';
import network from '../../../../utils/network';
import { CircularProgress } from '@material-ui/core';

function Packages({index, data, modifiedValues, courses, webinars}) {

  const { id, keys } = useContext(PersistentContext);

  const [loading, setLoading] = useState(false)
    
  let package1 = null
  let package2 = null
  let package3 = null

  const packageType = (str) => {
    if(str !== -1){
      return str.split('-')[0]
    }else{
      return 'course'
    }
  }

  const packageId = (str) => {
    if(str !== -1){
      return str.split('-')[1]
    }else{
      return -1
    }
  }
  
  if (typeof index !== 'undefined' && data && data.package1) {

    if(courses && courses.length){
      if(packageType(data.package1) === 'course'){
        const index = courses.findIndex(item => item.id === packageId(data.package1))
        package1 = courses[index] ? {...courses[index], type: 'course'} : null
      }
    }

    if(webinars && webinars.length){
      if(packageType(data.package1) === 'webinar'){
        const index = webinars.findIndex(item => item.id === packageId(data.package1))
        package1 = webinars[index] ? {...webinars[index], type: 'webinar'} : null
      }
    }
  }

  if (typeof index !== 'undefined' && data && data.package2) {

    if(courses && courses.length){
      if(packageType(data.package2) === 'course'){
        const index = courses.findIndex(item => item.id === packageId(data.package2))
        package2 = courses[index] ? {...courses[index], type: 'course'} : null
      }
    }

    if(webinars && webinars.length){
      if(packageType(data.package2) === 'webinar'){
        const index = webinars.findIndex(item => item.id === packageId(data.package2))
        package2 = webinars[index] ? {...webinars[index], type: 'webinar'} : null
      }
    }
  }

  if (typeof index !== 'undefined' && data && data.package3) {

    if(courses && courses.length){
      if(packageType(data.package3) === 'course'){
        const index = courses.findIndex(item => item.id === packageId(data.package3))
        package3 = courses[index] ? {...courses[index], type: 'course'} : null
      }
    }

    if(webinars && webinars.length){
      if(packageType(data.package3) === 'webinar'){
        const index = webinars.findIndex(item => item.id === packageId(data.package3))
        package3 = webinars[index] ? {...webinars[index], type: 'webinar'} : null
      }
    }
  }

  const handleClick = async (e, pkgId, pkgType) => {
    e.stopPropagation()
    setLoading(true);
    const site = keys && keys.id ? keys.id : id;
    const stripe = await loadStripe('pk_test_51Hm4WFDsVvlWtoAQemSKV496y7frfX4CRAFfjK6JMmDa5tqk39azU08pdsPIn2GhUfX4l7KHLYE48nwp6ryySgSZ00FTatqiIU');
    const redirectPath = window.location.pathname;
    const res = await network.get(`public/site/${site}/stripe/session/oneclick?${pkgType === 'course' ? `courseId=${pkgId}` : pkgType === 'webinar' ? `webinarId=${pkgId}` : `courseId=${pkgId}`}&prevUrl=${redirectPath}`);
    const sessionId = res.data.id;
    const result = await stripe.redirectToCheckout({ sessionId });
    setLoading(false);
  }

  const renderPackage = (pkg) => {
    return (
      <PackageContainer>
        <Package>

          <BackgroundGradient>
            <Background 
              style={{
                backgroundImage: `url(${pkg.background})`
              }}
            />
          </BackgroundGradient>

            <PackageContent>
              <PackageTitle>{pkg.title}</PackageTitle>    
              {
                pkg.price && (
                    <>
                      {
                          (data.showOriginalPrice && pkg.price.currency && pkg.originalPrice) && (
                              <>
                                <OriginalPrice>{ formatPrice(pkg.originalPrice, pkg.price.currency) }</OriginalPrice>
                              </>
                          )
                      }
                      {
                          (pkg.price.unit_amount && pkg.price.currency) && (
                              <PackagePrice>{ formatPrice(pkg.price.unit_amount, pkg.price.currency) }</PackagePrice>
                          )
                      }
                    </>
                )
              }
              <PackageDescription>{pkg.description}</PackageDescription>
              <SummaryContainer>
                <ReactMarkdown 
                  allowDangerousHtml={true}
                  children={pkg.summary} 
                />
              </SummaryContainer>
              <MainButton
                onPress={(e)=>handleClick(e, pkg.id, pkg.type)}
                disabled={loading}
                label={loading ? <CircularProgress size={14} style={{color: '#ffffff'}}/> : data.cta ? data.cta : "Chécalo"}
                style="secondary"
                styles={{
                  marginTop: 20,
                  borderRadius: 40,
                  padding: '10px 30px',
                  fontWeight: 500
                }}
               />
            </PackageContent>

        </Package>

      </PackageContainer>
    )
  }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
        <Container>
          {
            package1 && (
              renderPackage(package1)
            )
          }
          {
            package2 && (
              renderPackage(package2)
            )
          }
          {
            package3 && (
              renderPackage(package3)
            )
          }
        </Container>
    </BlocksControls>
  );
}

const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 70px 100px;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 50px;
    @media (max-width: 980px){
        padding: 30px;
    }
`

const PackageContainer = styled.div`
  @media (max-width: 480px){
    width: 100%;
  } 
`

const Package = styled.div`
  width: 360px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 480px){
    width: 100%;
  } 
`

const config = {
  gradientSize: 2,
  borderSize: 16,
  backgroundSize: 220
}

const BackgroundGradient = styled.div`
  width: ${config.backgroundSize + config.gradientSize}px;
  height: ${config.backgroundSize + config.gradientSize}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient( 200deg, #006cac, #ff7600);
  position: relative;
  z-index: 2;
`

const Background = styled.div`
  width: ${config.backgroundSize}px;
  height: ${config.backgroundSize}px;
  border-radius: 50%;
  border: white ${config.borderSize}px solid;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

const PackageContent = styled.div`
  width: 100%;
  flex-grow: 1;
  background: white;
  border: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right,#006cac,#ff7600);
  margin-top: -60px;
  padding: 75px 20px 20px 20px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const PackageTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #ff7600;
`

const PackagePrice = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: #ff7600;
`

const PackageDescription = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
`

const SummaryContainer = styled.div`
  width: 100%;
  text-align: center;
  flex-grow: 1;
  margin-top: 20px;
`

const OriginalPrice = styled.div`
  text-decoration: line-through;
  font-size: 16px;
  font-weight: 500;
  color: #ff7600;
`

export const PackagesTemplate = {
    "label": "Packages",
    "key": "packages",
    "fields": fields,
    "defaultItem": defaults
}

export const blockPackages = {
    Component: Packages,
    template: PackagesTemplate,
}