import React from 'react';
import { BlocksControls, InlineTextarea, InlineImage } from 'react-tinacms-inline';
import fields from './fields'
import defaults from './defaults'

function BoxesItem({ index, data: { image } }) {

  return (
    <BlocksControls focusRing={{ offset: 0 }} insetControls index={index}>
      <div style={{...styles.container}}>
        <InlineImage
          name="image"
          parse={media => {
            return media.previewSrc
          }}
          previewSrc={formValues => {
            return formValues;
          }}
        >
          {
            (props) => (
              <div style={{...styles.imageContainer, backgroundImage: `url(${props.src})`}}/>
            )
          }
        </InlineImage>
        <div style={styles.title}>
          <InlineTextarea name="title" focusRing={false} />
        </div>
        <div style={styles.description} className="counter-item-description">
          <InlineTextarea name="description" focusRing={false} />
        </div>
      </div>
    </BlocksControls>
  );
}

const styles = {
  container: {
    width: 370,
    borderRadius: 18,
    padding: '25px 40px',
    overflow: 'hidden',
    boxShadow: 'rgb(218 218 218) 0px 0px 30px 0px'
  },
  imageContainer: {
    height: 24,
    width: 24,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
  image: {
    width: '28px',
    height: '28px'
  },
  title: {
    textAlign: 'left',
    color: '#01214d',
    fontSize: 14,
    lineHeight: '36px',
    fontWeight: 800,
    fontFamily: 'Arial'
  },
  description: {
    marginTop: 4,
    paddingLeft: 18,
    borderLeft: 'rgb(245, 142, 104) 4px solid',
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Arial',
    lineHeight: '21px',
    color: '#9c9e9f'
  },
};

export const boxesItemBlock = {
  Component: BoxesItem,
  template: {
    name: "boxesItem",
    key: "label",
    label: "Boxes Item",
    component: "group-list",
    itemProps: item => ({
      label: item.title,
    }),
    defaultItem: defaults,
    fields: fields,
  },
};
