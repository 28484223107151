export default [
  {
    "label": "Title",
    "name": "title",
    "component": "text",
    "focusRing": false
  },
  {
    "label": "Subtitle",
    "name": "subtitle",
    "component": "text",
    "focusRing": false
  },
  {
    label: "FAQ's",
    name: "items",
    component: "group-list",
    itemProps: item => ({
      label: item.title,
    }),
    fields: [
      {
        label: "Titulo",
        name: "title",
        component: "text",
        parse(value) {
          return value || ""
        },
      },
      {
        label: "Texto",
        name: "text",
        component: "markdown",
        parse(value) {
          return value || ""
        },
      },
    ],
  },
]