import React, { useContext, useState, useEffect } from 'react'
import network from '../../utils/network';
import { useForm, usePlugin, useCMS } from 'tinacms'
import { InlineForm, InlineBlocks } from 'react-tinacms-inline'
import { PersistentContext } from '../../context';
import { blockNavbar } from './blocks/Navbar/Navbar'
import { blockHeroCarousel } from './blocks/HeroCarousel/HeroCarousel'
import { blockVideoForm } from './blocks/VideoForm/VideoForm'
import { blockVideoHero } from './blocks/VideoHero/VideoHero'
import { blockTextImage } from './blocks/TextImage/TextImage'
import { blockFaq } from './blocks/Faq/Faq'
import { blockBoxes } from './blocks/Boxes/Boxes'
import { blockTestimonials } from './blocks/Testimonials/Testimonials'
import { blockContactForm } from './blocks/ContactForm/ContactForm'
import { blockSummary } from './blocks/Summary/Summary'
import { blockImage } from './blocks/Image/Image'
import { blockPackages } from './blocks/Packages/Packages';
import { blockFooter } from './blocks/Footer/Footer'
import { MarkdownFieldPlugin } from 'react-tinacms-editor'
import './Home.css'

export default function PageHome({name}) {
  
  const { saveContent, getItemData, id, keys } = useContext(PersistentContext);

  const [forms, setForms] = useState([])
  const [formsRaw, setFormsRaw] = useState([])
  const [courses, setCourses] = useState([])
  const [coursesRaw, setCoursesRaw] = useState([])
  const [webinars, setWebinars] = useState([])
  const [webinarsRaw, setWebinarsRaw] = useState([])
  const [loading, setLoading] = useState(true)

  const cms = useCMS();

  const loadForms = async () => {
    try {
      const site = keys && keys.id ? keys.id : id;
      const {data} = await network.get(`public/site/${site}/form`)
      if (data && data.data) {
        const temp = data.data.map(item => ({value: item.id, label: item.title}))
        await setFormsRaw(data.data)
        await setForms(temp)
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      await setLoading(false)
    }
  }

  const loadCourses = async () => {
    try {
      const site = keys && keys.id ? keys.id : id;
      const {data} = await network.get(`public/site/${site}/course`)
      if (data && data.data) {
        const temp = data.data.map(item => ({
          value: `course-${item.id}`, 
          label: item.title
        }))
        await setCoursesRaw(data.data)
        await setCourses(temp)
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      await setLoading(false)
    }
  }

  const loadWebinars = async () => {
    try {
      const site = keys && keys.id ? keys.id : id;
      const {data} = await network.get(`public/site/${site}/webinar`)
      if (data && data.data) {
        const temp = data.data.map(item => ({
          value: `webinar-${item.id}`, 
          label: item.title
        }))
        await setWebinarsRaw(data.data)
        await setWebinars(temp)
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      await setLoading(false)
    }
  }

  const preload = () => {
    loadForms()
    loadCourses()
    loadWebinars()
  }

  useEffect(() => {
    preload()
  }, [])

  const HOME_BLOCKS = {
    blockNavbar,
    blockHeroCarousel,
    blockVideoForm,
    blockVideoHero,
    blockTextImage,
    blockFaq,
    blockBoxes,
    blockTestimonials,
    blockContactForm,
    blockSummary,
    blockImage,
    blockPackages,
    blockFooter
  };

  // VideoForm Block inyect forms
  const inyectFields = {
    ...blockVideoForm.template,
    fields: [
      ...blockVideoForm.template.fields,
      {
        name: 'forms',
        focusRing: false,
        label: 'Formulario',
        component: 'select',
        options: [{ value: -1, label: 'Seleccionar' }, ...forms],
      }
    ]
  }

  // ContactForm Block inyect forms
  const contactFormInyectFields = {
    ...blockContactForm.template,
    fields: [
      ...blockContactForm.template.fields,
      {
        name: 'forms',
        focusRing: false,
        label: 'Formulario',
        component: 'select',
        options: [{ value: -1, label: 'Seleccionar' }, ...forms],
      }
    ]
  }

  // Packages Block inyect courses
  const packagesInyectFields = {
    ...blockPackages.template,
    fields: [
      ...blockPackages.template.fields,
      {
        name: 'package1',
        focusRing: false,
        label: 'Paquete 1',
        component: 'select',
        options: [{ value: -1, label: 'Seleccionar' }, ...courses, ...webinars],
      },
      {
        name: 'package2',
        focusRing: false,
        label: 'Paquete 2',
        component: 'select',
        options: [{ value: -1, label: 'Seleccionar' }, ...courses, ...webinars],
      },
      {
        name: 'package3',
        focusRing: false,
        label: 'Paquete 3',
        component: 'select',
        options: [{ value: -1, label: 'Seleccionar' }, ...courses, ...webinars],
      },
    ]
  }

  const fields = [
    {
      label: 'Secciones',
      name: 'blocks',
      component: 'blocks',
      templates: {
        'blockNavbar': blockNavbar.template,
        'blockHeroCarousel': blockHeroCarousel.template,
        'blockVideoForm': inyectFields,
        'blockVideoHero': blockVideoHero.template,
        'blockTextImage': blockTextImage.template,
        'blockFaq': blockFaq.template,
        'blockBoxes': blockBoxes.template,
        'blockTestimonials': blockTestimonials.template,
        'blockContactForm': contactFormInyectFields,
        'blockSummary': blockSummary.template,
        'blockImage': blockImage.template,
        'blockPackages': packagesInyectFields,
        'blockFooter': blockFooter.template,
      }
    }
  ]

  const setUp = () => {
    const data = getItemData('landing1', true);
    const formConfig = {
      id: 'landingHome',
      fields,
      onSubmit: async (data) => {
        await saveContent({'landing1': data});
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data,
    }
    return formConfig;
  }
  const formConfig = setUp();
  const [modifiedValues, form] = useForm(formConfig, {
    fields,
    label: 'Home',
  });
  usePlugin([form, MarkdownFieldPlugin])
  
  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`}>
      <InlineForm form={form}>
        <InlineBlocks 
          name="blocks" 
          blocks={HOME_BLOCKS}
          itemProps={{
            modifiedValues: modifiedValues,
            forms: formsRaw,
            courses: coursesRaw,
            webinars: webinarsRaw
          }}
        />
      </InlineForm>
    </div>
  )
}