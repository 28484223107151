import React, { useContext } from 'react'
import { useForm, usePlugin, useCMS } from 'tinacms'
import { InlineForm, InlineBlocks } from 'react-tinacms-inline'
import MyCoursesPage from '../blocks/MyCourses'
import { PersistentContext } from '../context';
import { blockHero } from '../blocks/Hero';

export default function PageMyCourses(props) {
  const { saveContent, getItemData } = useContext(PersistentContext);

  const cms = useCMS();

  const MY_COURSES_TOP_BLOCKS = {
    blockHero
  };

  const MY_COURSES_BOTTOM_BLOCKS = {
    blockHero
  };

  const clearItemData = async () => {
    await saveContent({'my-courses': null});
    window.location.reload()
  }

  const setUp = () => {
    const data = getItemData('my-courses');
    if(data.blocks){
      if(data.blocks[0]){
        if(data.blocks[0]._template === "blockMyCourses"){
          clearItemData() 
        }
      }
    }
    const formConfig = {
      id: 'templateMyCourses',
      fields: [
        {
            "label": "Title",
            "name": "title",
            "component": "text",
            "focusRing": false
        },
        {
            "label": "Courses shown",
            "name": "coursesShown",
            "component": "select",
            "options": [ 
              {
                value: 4,
                label: '4',
              },
              {
                value: 8,
                label: '8',
              },
              {
                value: 12,
                label: '12',
              },
              {
                value: 16,
                label: '16',
              },
            ],
            "focusRing": false
          },
          {
            label: 'Secciones Arriba',
            name: 'topBlocks',
            component: 'blocks',
            templates: {
              'blockHero': blockHero.template,
            }
          },
          {
            label: 'Secciones Abajo',
            name: 'bottomBlocks',
            component: 'blocks',
            templates: {
              'blockHero': blockHero.template,
            }
          },
      ],
      onSubmit: async (data) => {
        await saveContent({'my-courses': data});
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data,
    }
    return formConfig;
  }
  const formConfig = setUp();
  const [modifiedValues, form] = useForm(formConfig);
  usePlugin(form)

  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`} style={{minHeight: 'calc(100vh - 225px)'}}>    
      <InlineForm form={form}>
        <InlineBlocks name="topBlocks" blocks={MY_COURSES_TOP_BLOCKS} />
        <MyCoursesPage data={modifiedValues}/>
        <InlineBlocks name="bottomBlocks" blocks={MY_COURSES_BOTTOM_BLOCKS} />
      </InlineForm>
    </div>
  )
}