import React from 'react';
import { BlocksControls, InlineBlocks } from 'react-tinacms-inline';
import { counterBlock } from '../components/CounterItem';
import defaults from './Counter.defaults';
import '../App.css';

function CounterBlock({ index, data }) {
  
  const styles = {
    container: {
      marginTop: -75,
      display: 'flex',
      justifyContent: 'center'
    },
    wrapper: {
      position: 'absolute',
      top: '-85px',
      display: 'flex',
      justifyContent: 'center'
    }
  }

  return (
    <BlocksControls index={index} insetControls>
      <div style={styles.container} className="general-padding">
        <InlineBlocks
          max={4}
          name="counters"
          direction="horizontal"
          className="counter-box-inline-block"
          blocks={COUNTER_BLOCKS}
        />
      </div>
    </BlocksControls>
  );
}

const COUNTER_BLOCKS = {
  counter: counterBlock,
};

export const CounterTemplate = {
  "key": "Counter",
  "label": 'Counter',
  "fields": [
    {
      label: 'Blocks Testimonials',
      name: 'counters',
      component: 'blocks',
      templates: {
        counter: counterBlock.template,
      }
    }
  ],
  "defaultItem": {
    _template: 'blockCounter',
    counters: defaults.counters,
  },
};

export const blockCounter = {
  Component: CounterBlock,
  template: CounterTemplate,
}
