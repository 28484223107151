import React from 'react';
import { InlineTextarea } from 'react-tinacms-inline';
import Email from './fields/Email';
import Password from './fields/Password';
import Name from './fields/Name';
import SubmitButton from '../buttons/SubmitButton';
import { Checkbox, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom'

import '../../App.css';

const useStyles = makeStyles({
  checkboxRoot: {
    '&.Mui-checked': {
      color: 'rgb(1, 108, 255)'
    },
    '&:hover': {
      backgroundColor: '#00000018 !important'
    }
  }
});

function SignInForm(props) {
  
  const history = useHistory()
  const classes = useStyles();

  const styles = {
    container: {
      backgroundColor: 'white',
      boxShadow: '0px 0px 12px 8px #0000001a',
    },
    title: {
      color: '#43434a',
      fontWeight: '800',
      fontFamily: 'Muli'
    },
    subtitle: {
      paddingTop: 8,
      paddingBottom: 21,
      fontWeight: '600',
      fontFamily: 'Muli',
      fontSize: 17,
      color: '#9ea2b4'
    },
    separator: {
      width: '100%',
      height: 1,
      backgroundColor: '#016cff'
    },
    form: {
      paddingTop: 30
    },
    termsMessageContainer: {
      paddingTop: 6,
      paddingBottom: 6,
      display: 'flex'
    },
    termsMessage: {
      fontWeight: 400,
      fontFamily: 'Muli',
      fontSize: 14,
      color: '#8891a6',
      textAlign: 'left',
      paddingLeft: 10
    },
    termsMessageLink: {
      fontWeight: 700,
      fontFamily: 'Muli',
      textDecoration: 'underline'
    },
    subscribedMessageContainer: {
      paddingTop: 6,
      paddingBottom: 16,
      alignItems: 'center',
      display: 'flex'
    },
    subscribedMessage: {
      fontWeight: 400,
      fontFamily: 'Muli',
      fontSize: 14,
      color: '#8891a6',
      textAlign: 'left',
      paddingLeft: 10
    },
    subscribedMessageLink: {
      fontWeight: 700,
      fontFamily: 'Muli',
      textDecoration: 'underline'
    },
    signInMessage: {
      paddingTop: 30,
      fontWeight: '600',
      fontFamily: 'Muli',
      fontSize: 16,
      color: '#8891a6'
    },
    signInLink: {
      fontWeight: 700,
      fontFamily: 'Muli',
      textDecoration: 'underline',
      margin: '0px 5px'
    }
  }

  return (
    <div className="sign-up-form-container" style={styles.container}>
      <div className="sign-up-form-title" style={styles.title}>
        <InlineTextarea name="title" focusRing={false} />
      </div>
      <div style={styles.subtitle}>
        <InlineTextarea name="description" focusRing={false} />
      </div>
      <div style={styles.separator} />
      { props.error && (<p style={{color: 'red'}}>{props.error}</p>)}
      <form style={styles.form}>
        <Name
          value={props.name}
          onEnter={() => props.onSubmit()}
          onChange={(value) => props.onNameChange(value)}
        />
        <Email
          value={props.email}
          onEnter={() => props.onSubmit()}
          onChange={(value) => props.onEmailChange(value)}
        />
        <Password
          containerStyle={{position: 'relative'}}
          onActionClick={() => null}
          validationStatus={props.passwordValidationStatus}
          value={props.password}
          onEnter={() => props.onSubmit()}
          onChange={(value) => props.onPasswordChange(value)}
          masking={true}
        />

        <div style={styles.termsMessageContainer}>
          <Checkbox
            classes={{root: classes.checkboxRoot}}
            checked={props.terms}
            onChange={(e) => props.onTermsChange(e.target.checked)}
          />
          <div style={styles.termsMessage}>
            Al crear esta cuenta aceptas los <span onClick={() => history.push('/terms')} className="touchable-opacity" style={styles.termsMessageLink}>términos de uso</span> y nuestras <span onClick={() => history.push('/privacy-policy')} className="touchable-opacity" style={styles.termsMessageLink}>políticas de privacidad</span>
          </div>
        </div>

        <div style={styles.subscribedMessageContainer}>
          <Checkbox
            classes={{root: classes.checkboxRoot}}
            checked={props.subscribed}
            onChange={(e)=>props.onSubscribedChange(e.target.checked)}
          />
          <div style={styles.subscribedMessage}>
            Suscribirse a nuestra lista de correo electrónico
          </div>
        </div>

      </form>
     
      <SubmitButton
        loading={!props.submitEnabled}
        styles={{height:45}} 
        label={props.signup} 
        onPress={() => props.onSubmit()} 
      />

      <div style={styles.signInMessage}>
        Si ya tienes una cuenta
        <span onClick={() => window.location.href = '/sign-in'} className="touchable-opacity" style={styles.signInLink}>
          <InlineTextarea name="signin" focusRing={false} />
        </span>
      </div>

    </div>
  );
}

export default SignInForm;