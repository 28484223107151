import React, { useState, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { InlineTextarea } from 'react-tinacms-inline'
import Section from '../components/Section'
import Courses from '../components/Courses'
import { CourseContext } from '../context/CourseContext'
import { SessionContext } from '../context/SessionContext'
import OrderByDropdown from '../components/OrderByDropdown'
import { PersistentContext } from '../context'
import styled from 'styled-components'
import '../App.css'

const courses = [
  {
    id: 1,
    background: 'course-1.webp',
    category: 'E-Commerce',
    title: 'Tus primeras ventas',
    description:
      'Mauris laoreet sollicitudin justo, mauris laoresollicitudin justo, placerat lacus labort placerat lacus lobortis nec.',
    sales: 92030,
    likes: 4300,
    duration: '1 hr 30 min',
    discount: '30% Menos $39.99',
    price: 19.99
  },
  {
    id: 2,
    background: 'course-2.webp',
    category: 'E-Commerce',
    title: 'Tus primeras ventas',
    description:
      'Mauris laoreet sollicitudin justo, mauris laoresollicitudin justo, placerat lacus labort placerat lacus lobortis nec.',
    sales: 92030,
    likes: 4300,
    duration: '1 hr 30 min',
    discount: '30% Menos $39.99',
    price: 19.99
  },
  {
    id: 3,
    background: 'course-3.webp',
    category: 'E-Commerce',
    title: 'Tus primeras ventas',
    description:
      'Mauris laoreet sollicitudin justo, mauris laoresollicitudin justo, placerat lacus labort placerat lacus lobortis nec.',
    sales: 92030,
    likes: 4300,
    duration: '1 hr 30 min',
    discount: '30% Menos $39.99',
    price: 19.99
  },
  {
    id: 4,
    background: 'course-4.webp',
    category: 'E-Commerce',
    title: 'Tus primeras ventas',
    description:
      'Mauris laoreet sollicitudin justo, mauris laoresollicitudin justo, placerat lacus labort placerat lacus lobortis nec.',
    sales: 92030,
    likes: 4300,
    duration: '1 hr 30 min',
    discount: '30% Menos $39.99',
    price: 19.99
  },
  {
    id: 5,
    background: 'course-1.webp',
    category: 'E-Commerce',
    title: 'Tus primeras ventas',
    description:
      'Mauris laoreet sollicitudin justo, mauris laoresollicitudin justo, placerat lacus labort placerat lacus lobortis nec.',
    sales: 92030,
    likes: 4300,
    duration: '1 hr 30 min',
    discount: '30% Menos $39.99',
    price: 19.99
  },
  {
    id: 6,
    background: 'course-2.webp',
    category: 'E-Commerce',
    title: 'Tus primeras ventas',
    description:
      'Mauris laoreet sollicitudin justo, mauris laoresollicitudin justo, placerat lacus labort placerat lacus lobortis nec.',
    sales: 92030,
    likes: 4300,
    duration: '1 hr 30 min',
    discount: '30% Menos $39.99',
    price: 19.99
  },
  {
    id: 7,
    background: 'course-3.webp',
    category: 'E-Commerce',
    title: 'Tus primeras ventas',
    description:
      'Mauris laoreet sollicitudin justo, mauris laoresollicitudin justo, placerat lacus labort placerat lacus lobortis nec.',
    sales: 92030,
    likes: 4300,
    duration: '1 hr 30 min',
    discount: '30% Menos $39.99',
    price: 19.99
  },
  {
    id: 8,
    background: 'course-4.webp',
    category: 'E-Commerce',
    title: 'Tus primeras ventas',
    description:
      'Mauris laoreet sollicitudin justo, mauris laoresollicitudin justo, placerat lacus labort placerat lacus lobortis nec.',
    sales: 92030,
    likes: 4300,
    duration: '1 hr 30 min',
    discount: '30% Menos $39.99',
    price: 19.99
  }
]

function MyCourses ({ index, data }) {
  const history = useHistory()
  const [orderBy, setOrderBy] = useState(null)

  const { keys, id, production } = useContext(PersistentContext)
  const { onLoadMyCourses, myCourses, loadingMyCourses } = useContext(CourseContext)
  const { isSessionLoaded } = useContext(SessionContext)

  const preload = async () => {
    const siteId = production ? keys.id : id
    if (production && isSessionLoaded) {
      try {
        await onLoadMyCourses(siteId)
      } catch (error) {
        console.log('error', error)
        history.replace('/')
      }
    }else{
      try {
        await onLoadMyCourses(siteId)
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  useEffect(() => {
    preload()
  }, [isSessionLoaded])

  const filter = (myCourses = []) => {
    return myCourses.slice(0, data.coursesShown)
  }

  const defaultData = production ? myCourses : courses

  return (
    <div className='page'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Mis Cursos</title>
        <link rel='canonical' href='https://store.l3rn.io/my-myCourses' />
      </Helmet>
      <Section
        hideHeader
        containerStyle={{ paddingTop: 72, paddingBottom: 70 }}
      >
        <Header className='general-padding'>
          <div style={styles.title}>
            <InlineTextarea name='title' focusRing={false} />
          </div>
          <OrderByDropdown
            containerStyle={{display:'none'}}
            value={orderBy}
            onSelect={option => setOrderBy(option)}
          />
        </Header>
        <Courses
          redirectPath={`course`}
          forceIsPurchased={true}
          loading={loadingMyCourses}
          courses={filter(defaultData)}
        />
      </Section>
    </div>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

const styles = {
  title: {
    color: '#43434a',
    fontSize: 32,
    fontWeight: '800',
    fontFamily: 'Muli',
    textAlign: 'left'
  }
}

export default MyCourses
