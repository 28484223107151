import React, { useContext } from 'react';
import { useForm, usePlugin, useCMS } from 'tinacms';
import { InlineForm, InlineBlocks } from 'react-tinacms-inline';
import { PersistentContext } from '../context';
import { blockHero } from '../blocks/Hero';
import SignUpPage from '../blocks/SignUp';

export default function PageSignUp(props) {
  const { saveContent, getItemData } = useContext(PersistentContext);

  const cms = useCMS();

  const SIGNUP_BLOCKS = {
    blockHero,
  };

  const clearItemData = async () => {
    await saveContent({'sign-up': null});
    window.location.reload()
  }

  const setUp = () => {
    const data = getItemData('sign-up');
    
    if(data.blocks){
      if(data.blocks[0]){
        if(data.blocks[0]._template === "blockSignUp"){
          clearItemData() 
        }
      }
    }
    
    const formConfig = {
      id: 'templateSignUp',
      fields: [
        {
          "label": "Background",
          "name": "background",
          "component": "image",
          "parse": media => {
            return media.previewSrc
          },
          "previewSrc": formValues => {
            return formValues;
          },
          "focusRing": false
        },
        {
          "label": "Title",
          "name": "title",
          "component": "text",
          "focusRing": false
        },
        {
          "label": "Description",
          "name": "description",
          "component": "text",
          "focusRing": false
        },
        {
          "label": "SignUp",
          "name": "signup",
          "component": "text",
          "focusRing": false
        },
        {
          "label": "SignIn",
          "name": "signin",
          "component": "text",
          "focusRing": false
        },
        {
          label: 'Secciones',
          name: 'blocks',
          component: 'blocks',
          templates: {
            'blockHero': blockHero.template,
          }
        }
      ],
      onSubmit: async (data) => {
        await saveContent({'sign-up': data});
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data,
    }
    return formConfig;
  }
  const formConfig = setUp();
  const [modifiedValues, form] = useForm(formConfig);
  usePlugin(form)

  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`} style={{minHeight: 'calc(100vh - 225px)'}}>
      <InlineForm form={form}>
        <SignUpPage data={modifiedValues}/>
        <InlineBlocks name="blocks" blocks={SIGNUP_BLOCKS} />
      </InlineForm>
    </div>
  )
}