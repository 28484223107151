export default {
    "_template": "templateTerms",
    "title": "Términos y condiciones",
    "terms": `

DE USO DE SITIO WEB Y SERVICIOS DE **“{Nombre de la empresa}”**

**FECHA DE ACTUALIZACIÓN** 

**(XX/FECHA DE ALTA EN EL SITIO WEB/XX)** 


#### I. INTRODUCCIÓN: 

TODA PERSONA QUE DESEE HACER USO DE LOS SERVICIOS DE LA PLATAFORMA WEB DE **“{Nombre de la empresa}”**, DEBERÁ ACEPTAR LAS DISPOSICIONES DE CARÁCTER OBLIGATORIO Y VINCULATIVOS AQUÍ DESCRITAS; EN CASO DE RECHAZO, DEBERÁ ABSTENERSE DE UTILIZAR LOS SERVICIOS BRINDADOS POR EL SITIO WEB. 

LA ACEPTACIÓN DE LAS PRESENTES POLÍTICAS DE USO DEL SITIO WEB, SERÁ UNA FIRMA AUTÓGRAFA CON EL MISMO VALOR LEGAL Y PODRÁ SER ELEGIBLE COMO PRUEBA JUDICIAL EN CASO DE CUALQUIER CONTROVERSIA QUE SE SUSCITE. EL CLIENTE Y/O USUARIO ACEPTA Y SE COMPROMETE A CUMPLIR LAS SIGUIENTES CLÁUSULAS Y TÉRMINOS DE USO. 

LOS SERVICIOS Y PRODUCTOS DE ESTA PLATAFORMA ES DESTINADA A MAYORES DE EDAD, O MENORES DE EDAD QUE CUENTEN CON LA AUTORIZACIÓN DE UN TUTOR. 

#### II. PRIVACIDAD: 

Revise nuestras **Políticas de Privacidad**; estas políticas rigen la visita y el uso de la plataforma de **“{Nombre de la empresa}”**. Además de los lineamientos establecidos en leyes nacionales, adicionalmente se establecen las políticas en cumplimiento de las leyes de privacidad para los residentes de la Unión Europea o del EEE. 

#### III. OBJETO Y RELACIÓN DEL CONVENIO:

El objeto del presente documento es, describir los Términos y Condiciones (en lo sucesivo y para efectos de este documento se le denominará **“TyC”**), que se aplicarán en el uso de la plataforma web **{URL o dirección electrónica a través de la cual se accede al sitio web}“**, que en lo sucesivo se le denominará el sitio web ofrecida por **“{Nombre de la empresa}”**,; así como también para las plataformas conocidas o las que están por conocerse derivadas de **“{Nombre de la empresa}”**, sus sociedades representativas, socios, representantes y grupo corporativo. 

Toda persona con acceso al portal del Sitio Web y/o los servicios de **“{Nombre de la empresa}”**, deberá sujetarse a las políticas y principios descritos en el presente documento y otros convenios derivados del uso correcto de “El Sitio Web” y/o sus plataformas afines.

**“{Nombre de la empresa}”**, se reserva el derecho de modificar en cualquier momento los presentes **“TyC”**, así como cualesquiera otras condiciones generales o particulares que resulten del sitio web y/o plataforma.

De la misma, en cualquier momento, bajo su responsabilidad, **“{Nombre de la empresa}”**, se reserva el derecho de suspender, interrumpir o dejar de operar el sitio web y/o sus plataformas afines, por diversos motivos que deriven del proceso de operación de sus derivados.

#### IV. PERSONALIDAD Y GENERALES DE “{Nombre de la empresa}”,:

Para los efectos que deriven del presente documento y/o sus afines; el nombre **“{Nombre de la empresa}”**, es un signo distintivo debidamente registrado bajo las leyes mexicanas de propiedad industrial, misma que se encuentra bajo la representación de la persona física con actividad empresarial el señor **{Indique el nombre completo, la razón o denominación social de la persona que es propietaria}**, persona física con actividad empresarial, con domicilio laboral el ubicado en **{Ingrese el domicilio de la empresa }**
Que dentro de sus actividades comerciales se encuentra la de administración de Agencia de Marketing, Producción Audiovisual, organización y logística de eventos, así como Academia Online.


#### V. SERVICIOS Y CONTENIDOS DE “{Nombre de la empresa}”: 

Es una plataforma que opera bajo el esquema de educación continua, asesoría, y aprendizaje en diversas materias de desarrollo personal y de negocios. De la misma manera, es una plataforma online, dedicada a la venta y publicación de material didáctico, libros de textos o cursos especializados y/o interés, administrado dentro de la plataforma denominada **{Plataforma que utilizan, kajabi.. etc}** 
El usuario podrá suscribirse al material didáctico, contenido de video, audio y material de texto, mediante los diversos paquetes de pago. Los cuales podrán ser cobrados mediante plataforma de terceros proveedores. 

#### VI. REGISTRO DE USUARIOS Y ACCESOS

A. Disposición y cumplimiento de los presentes **“TyC”**, **“{Nombre de la empresa}”** otorga un permiso de uso ilimitado, no exclusivo, no sublicenciable, perpetuo, para el acceso y uso del sitio web

B. **Restricciones de “{Nombre de la empresa}”**

Con carácter general, informativo y de capacitación* (*material seleccionado), el sólo acceso al sitio web podrá ser libre. 

No obstante, **“{Nombre de la empresa}”** podrá condicionar los accesos a determinados servicios y áreas de los mismos, para el correcto uso y cumplimiento del presente convenio y políticas.

Para todos los efectos el uso del sitio web se considerará la aceptación sin reserva u objeción alguna de los presentes **“TyC”**.

De la misma manera el objetivo de **“{Nombre de la empresa}”**, es poner a disposición de los Usuarios determinada mercancía y/o servicios para cuya utilización pudiera requerir la cumplimentación de registros adicionales y/o satisfacción de determinadas contraprestaciones dinerarias.

El usuario declara, que al utilizar este sitio tiene al menos la mayoría de edad en su estado o provincia de residencia, por lo que se compromete a la veracidad del conjunto de los datos de registro facilitados a **“{Nombre de la empresa}”** y es responsable de mantenerlos actualizados en su totalidad mientras dure su condición del mismo.

El usuario será el único responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause a **“{Nombre de la empresa}”** o a terceros por la información que facilite.

Así mismo el cliente, usuario y colaborador se compromete a:
1. No retirar nota de derechos de autor, marca registrada; 
2. No reproducir y/o modificar, las marcas, contenido, procesos del sitio web  sin el pago previo y/o licencias de autor; 
3. Intentar obtener un acceso no autorizado o dañar cualquier aspecto del sitio web, sus plataformas, sus sistemas o redes relacionadas.” 
4. No reproducir el contenido libros, manuales y contenido literario sin previa autorización y/u obtención de las debidas licencias de autor. 
5. Sobre los videos y la información del programa especializado el usuario se obliga a no enajenarla, arrendarla, prestarla, grabarla, negociarla, revelarla, publicarla, transmitirla o de alguna otra forma divulgarla para fines de lucro propios autorización expresa o tácita de **“{Nombre de la empresa}”** , sus titulares, representantes, grupo corporativo, sociedades, y demás designados. 

En caso de la omisión de los puntos anteriores, por parte del usuario y/o persona externa, **“{Nombre de la empresa}”**, tomará las medidas legales que crea necesarias, para la protección de sus derechos. 

C.Detalles de los servicios. 
Los servicios brindados por parte de **“{Nombre de la empresa}”** al usuario será solamente para su uso personal y no para uso comercial con fines de lucro personal, en beneficio del usuario.

D. Responsabilidad. 
 
**“{Nombre de la empresa}”** no se ha hace responsable de los resultados obtenidos, esto en consecuencia de la visualización de los videos y los textos, lo anterior es inherente a cada usuario y la capacidad de implementación. Es decir, las habilidades aprendidas dependen de cada usuario. 
 
E. Newsletter 
 
Dentro del registro, el usuario, podrá aceptar recibir el newsletter, con contenido de los cursos, informativo y/o noticioso, ligados a los servicios de la plataforma de **“{Nombre de la empresa}”**
 
 
 
F. Licencia de uso

**“{Nombre de la empresa}”** otorga al usuario registrado en los programas y cursos proporcionados, por éste, el acceso y uso del sitio web de forma limitada, con derechos de no exclusividad. 
 
Salvo que se indique que sea de dominio público, el contenido del sitio web de **“{Nombre de la empresa}”** está protegido por las leyes de derechos de autor internacionales. 
 
A menos que se indique expresamente lo contrario en el sitio web de **“{Nombre de la empresa}”** los textos, vídeos, imágenes y otros materiales educativos y de aprendizaje que se proveen con los cursos que se ofrecen en el Sitio Web de **“{Nombre de la empresa}”** son para su uso personal, en relación únicamente con los cursos, y la capacitación personal. 
 
Algunos documentos de referencia, citas, procesos, libros, artículos y otra información en el sitio web de **“{Nombre de la empresa}”** , se utilizarán con la autorización de terceros o en sea el caso, se hará siempre la mención de los autores, respetando los derechos de autor internacional. 
 
Esta es la concesión de una licencia, no una transferencia de título, y bajo esta licencia usted no puede: 
1. Modificar o copiar los materiales; 
2. Usar los materiales para cualquier propósito comercial, o para cualquier exhibición pública (comercial o no comercial); 
3. Intentar descompilar o realizar ingeniería inversa de cualquier software contenido en **“{Nombre de la empresa}”**
4. Eliminar los derechos de autor u otras anotaciones de propiedad de los materiales; 
5. Transferir los materiales a otra persona o "reflejar" los materiales en cualquier otro servidor.

G. Plataformas

**“{Nombre de la empresa}”** puede hacer uso de plataformas online de terceros proveedores, estos para brindar un mejor servicio en el contenido, dirigido al usuario. Dichas plataformas, se basarán por sus propios términos y condiciones, que el usuario deberá respetar, de igual manera los presentes **“TyC”**.  
 
**“{Nombre de la empresa}”** no se hace responsable de la operación de dichas plataformas. 
 
H. Código de Conducta 
 
El uso del sitio web se rige bajo ciertas reglas de conducta (“Código de Conducta”); es responsabilidad del Usuario conocer, entender y cumplir con el Código de Conducta. 
**“{Nombre de la empresa}”** se reserva el derecho, a su discreción, de tomar medidas disciplinarias u otras observaciones que estime pertinentes en respuesta a una conducta que considere inaceptable, incluyendo, sin limitación, eliminar o alterar cualquier nombre de usuario o la suspensión, terminación o cancelación de la cuenta de usuario, el acceso y uso de los Servicios, o cancelar cualquier licencia concedida en estos **“TyC”**, por cualquier razón que **“{Nombre de la empresa}”** estime necesario. 
**“{Nombre de la empresa}”** se reserva el derecho a modificar el Código de Conducta en cualquier momento. 
Usuario no podrá realizar las siguientes conductas: 
1. Cualquier intento por su parte de perturbar, o alentar o promover la interrupción de los servicios puede ser una violación de las leyes penales y civiles. 
2. Ser menor de edad, sin consentimiento de un tutor. **“{Nombre de la empresa}”** no se hace responsable de los conflictos derivados de situaciones relacionadas en este punto. 
3. El usuario acepta que no violará cualquier ley de su país o leyes internacionales, normas o reglamentos en relación con su uso de los Servicios, ni tampoco interrumpir o intentar interrumpir el funcionamiento de los Servicios de cualquier forma sin limitación. 
4. El Sitio Web se encuentra destinado para personas y empresas, que desean capacitarse e implementar dichos aprendizajes, en el desarrollo personal. El Usuario debe usar este Sitio Web con intenciones legítimas y de acuerdo a las indicaciones de uso dictadas por **“{Nombre de la empresa}”**.
5. Cualquier tipo de omisión a lo anterior y de todos los **“TyC”** será causa de suspensión parcial o completa de la cuenta y suscripción de Usuario y/o colaborador, así mismo podrá ser acreedor de un proceso legal en su contra. 

#### VII. REGISTRO DE USUARIOS

A. Cuentas de Usuario

Los servicios de venta online que ofrece o pueda ofrecer **“{Nombre de la empresa}”** requerirá el acceso, por lo cual es necesario crear una cuenta **(“Cuenta de Usuario”)**; a pesar de que muchos servicios son accesibles sin el requisito de una cuenta de usuario; los presentes **“TyC”** se aplica por igual a su acceso y uso del sitio web
 
Con la finalidad de usar correctamente el sitio web y para satisfacción de el Usuario, **“{Nombre de la empresa}”** dará de alta al usuario en su base de datos de *Usuarios Registrados*; la cuenta será generada al momento de realizar el pago según corresponda, la información que se llegase a requerir para la creación de la cuenta de usuario será el nombre, correo electrónico del titular y número de teléfono celular. De igual manera, el sistema del sitio web podrá arrojar la acreditación de validación de seguridad correspondiente.

El Usuario acepta que la información brindada a **“{Nombre de la empresa}”** se ajusta a los lineamientos del **“Aviso de Privacidad”** que se encuentra en el sitio web 

El usuario no puede compartir o transferir su cuenta de usuario o la información de acceso con cualquiera que no esté expresamente establecido en este documento u autorizado expresamente por el Usuario.

El usuario es responsable de proteger la confidencialidad de la información de acceso, y será responsable de todo uso de su cuenta de usuario.

Cualquier acceso no autorizado, uso, distribución o transferencia de su cuenta de usuario o información puede resultar en que se genere suspensión, terminación o cancelación de su cuenta de usuario y su acceso y uso de los Servicios en el sitio web 

B. Pago

El usuario acepta que en el uso del sitio web los servicios generarán cargos mismos que serán cobrados mediante los portales digitales de cobro los denominados Stripe®, Pay Pal ®, u otras plataformas necesarias;**“{ añadir otros métodos de pago que tenga la empresa }”** Así mismo se podrá hacer mediante deposito bancario, el cual mismo recibo de pago deberá ser enviado al correo electrónico **{correo electrónico al que puedan mandar mensaje los clientes y que sea  de la empresa, correo electrónico identificado con la empresa }**

**“{Nombre de la empresa}”**, en cualquier momento y a su sola discreción, se reserva el derecho de establecer, eliminar y/o revisar los Cargos para alguno o todos los servicios o bienes obtenidos a través del uso de los Servicios.
El usuario podrá solicitar el reembolso de los cargos generados, siempre y cuando envíe sus comentarios al correo electrónico **{correo electrónico al que puedan mandar mensaje los clientes y que sea  de la empresa, correo electrónico identificado con la empresa }**
Cada caso será evaluado en lo particular, para brindar el mejor servicio. 

C. Cancelaciones y Devoluciones
Las cancelaciones y devoluciones **“{Nombre de la empresa}”**, serán bajo los siguientes preceptos. 
1. El usuario no podrá realizar la cancelación de los cursos en línea. 
2. En caso especial, podrá enviar un correo directo a **{correo electrónico al que puedan mandar mensaje los clientes y que sea  de la empresa, correo electrónico identificado con la empresa }**
 
#### VIII. DERECHOS DE AUTOR Y PROPIEDAD INTELECTUAL
 
El contenido aquí desarrollado es directamente propiedad y autoría de **“{Nombre de la empresa}”**, para fines de lucro de este, salvo contenido desarrollado y compartido bajo el esquema de publicidad.
 
Cualquier contenido del sitio web y/o cualquier plataforma móvil derivada de los servicios de **“{Nombre de la empresa}”**, no puede ser copiado, reproducido, re-publicado, enviado, transmitido, transcrito, traducido, almacenado, alterado, descargado o distribuido en ningún medio inventado o por inventar, ni por ningún motivo, a excepción de la compra de la licencia de uso correspondiente, misma que será sólo para los fines antes descritos, de forma única.
 
#### IX. LEY APLICABLE
A los efectos de practicar las notificaciones que correspondan y necesarias, **“{Nombre de la empresa}”**, designa como domicilio de contacto el correo electrónico **{correo electrónico al que puedan mandar mensaje los clientes y que sea  de la empresa, correo electrónico identificado con la empresa }**
Salvo que aquí se especifique lo contrario, las presentes Condiciones se regirán e interpretarán exclusivamente en virtud de la legislación de la Ciudad de México, con exclusión de sus normas sobre conflicto de leyes. 
Todas las desavenencias que deriven de este contrato serán resueltas definitivamente de acuerdo con las Reglas de Arbitraje del Centro de Arbitraje de México (CAM), por uno o más árbitros nombrados conforme a dichas Reglas. 


    `
}