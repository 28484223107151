import React from 'react';
import MainButton from './buttons/MainButton'

function ContactFormSentModal({visible, onCloseModal}) {

    const handleClick = (e) => {
        e.stopPropagation()
    }

  return (
    <>
        {
            visible && (
                <div onClick={onCloseModal} style={styles.wrapper}>
                    <div onClick={(e)=>handleClick(e)} style={styles.modalContainer}>
                        <div style={styles.content}>
                            <div style={styles.title}>¡Gracias!</div>
                            <div style={styles.subtitle}>Su mensaje ha sido enviado con éxito. Muy pronto nos pondremos en contacto contigo.</div>
                            <MainButton 
                                onPress={()=>onCloseModal()} 
                                label="Continuar"
                            />
                        </div>
                    </div>
                </div>
            )
        }
    </>
  );
}
const styles = {
    wrapper: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1
    },
    modalContainer: {
        backgroundColor: 'white',
        width: 800,
        height: 300,
        zIndex: 2,
        borderRadius: '20px',
        padding: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        fontSize: 28,
        fontFamily: 'Muli'
    },
    subtitle: {
        fontSize: 18,
        fontFamily: 'Muli',
        padding: 28
    }
}
export default ContactFormSentModal;
