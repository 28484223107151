import React from 'react';
import { BlocksControls, InlineTextarea, InlineImage } from 'react-tinacms-inline';
import fields from './fields'
import defaults from './defaults'

function BoxesItem({ index, data: { image }, boxImageWidth, boxImageHeight, boxShadow, titleColor, textColor, padding, backgroundColor }) {

  const isFirstItem = () => {
    return index === 0 ? true : false
  }

  return (
    <BlocksControls focusRing={{ offset: 0 }} insetControls index={index}>
      <div 
        style={{
          ...styles.container,
          minWidth: `${boxImageWidth}px`,
          padding: `${padding}px`,
          backgroundColor: backgroundColor,
          ...(isFirstItem() && styles.containerFirst)
        }}
      >
        <InlineImage
          name="image"
          parse={media => {
            return media.previewSrc
          }}
          previewSrc={formValues => {
            return formValues;
          }}
        >
          {
            (props) => (
              <div style={{
                ...styles.imageContainer,
                maxWidth: `${boxImageWidth}px`,
                height: `${boxImageHeight}px`, 
                backgroundImage: `url(${props.src})`,
                boxShadow: boxShadow ? '0px 0px 30px 0px #adadad' : 'none'
              }}/>
            )
          }
        </InlineImage>
        <h3 style={{...styles.title, color: titleColor}}>
          <InlineTextarea name="title" focusRing={false} />
        </h3>
        <div style={{...styles.description, color: textColor}} className="counter-item-description">
          <InlineTextarea name="description" focusRing={false} />
        </div>
      </div>
    </BlocksControls>
  );
}

const styles = {
  container: {
    width: 230,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  containerFirst: {
    borderLeft: 'none' 
  },
  imageContainer: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    margin: 'auto'
  },
  title: {
    fontSize: 28,
    fontWeight: 800,
    fontFamily: 'Arial',
    marginBottom: 0,
    marginTop: 30
  },
  description: {
    fontSize: 18,
    fontWeight: 700,
    fontFamily: 'Arial',
    marginTop: 12,
    textAlign: 'center'
  },
};

export const boxesItemBlock = {
  Component: BoxesItem,
  template: {
    name: "boxesItem",
    key: "label",
    label: "Boxes Item",
    component: "group-list",
    itemProps: item => ({
      label: item.title,
    }),
    defaultItem: defaults,
    fields: fields,
  },
};
