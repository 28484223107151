import React from 'react';
import styled from 'styled-components'

function SocialLinks(props) {
    return (
        <ul style={styles.list}>
            {
                props.items && props.items.map((item, index) => (
                    <li key={`sociallinks-${index}`} style={styles.item}>
                        <a href={item.link} target="_blank">
                            <Image alt="social-logo" src={item.logo} />
                        </a>
                    </li>
                ))
            }
        </ul>
    );
}

const Image = styled.img`
    width: 30px;
    height: 30px;
    @media (max-width: 480px){
        width: 20px;
        height: 20px;
    } 
`

const styles = {
    list: {
        listStyle: 'none',
        display: 'flex',
        margin: 0
    },
    item: {
        padding: '0px 10px'
    }
}

export default SocialLinks;
