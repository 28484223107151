import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import { InlineForm } from 'react-tinacms-inline';
import { useFormScreenPlugin, useCMS, useForm } from 'tinacms';

import Disable from '../components/Disable';
import Actions from '../components/Actions'
import logo from '../assets/images/logo.webp';
import { useMediaQuery } from '../helpers/Hooks'
import MobileMenu from '../components/MobileMenu'
import NavigationBar from '../components/NavigationBar'
import { styleVariables } from '../helpers/StyleVariables'
import { PersistentContext } from '../context';
import { SessionContext } from '../context/SessionContext';
import { CourseContext } from '../context';
import { CategoryContext } from '../context';
import CartIcon from '../components/CartIcon';
import user from '../assets/svgs/user.svg';
import '../App.css';
import { getLandings } from '../helpers/pages'

export const NavbardefaultValues = {
  background_color: "#fff",
  text_color: "#38A2FF",
  logoHeader: {
    src: logo,
  },
  menuItems: [
    {
      type: 'internalLink',
      internalLink: '/course-catalog',
      label: 'Todos los cursos',
    },
    {
      type: 'categories',
      label: 'Categorías',
    },
    {
      type: 'internalLink',
      internalLink: '/contact',
      label: 'Contacto',
    },
  ],
};

function NavbarLayout(props) {

  const { onLoadCourses, courses, loading } = useContext(CourseContext)
  const { categories, categoriesLoading } = useContext(CategoryContext)
  const { saveSiteConfig, getItemConfig, keys, production, id } = useContext(PersistentContext);
  const { isAuthenticated } = useContext(SessionContext);
  const [landings, setLandings] = useState([])

  const cms = useCMS();
  const history = useHistory();

  const linkOptions = [ 
    {
      value: "",
      label: "Ninguno"
    },
    {
      value: "/home",
      label: "Inicio",
    },
    {
      value: "/sign-in",
      label: "Login",
    },
    {
      value: "/sign-up",
      label: "Registro",
    },
    {
      value: "/forgot-password",
      label: "Recuperar Contraseña",
    },
    {
      value: "/contact",
      label: "Contacto",
    },
    {
      value: "/not-found",
      label: "404 No Encontrado",
    },
    {
      value: "/faq",
      label: "Preguntas Frecuentes",
    },
    {
      value: "/about",
      label: "Nosotros",
    },
    {
      value: "/course-catalog",
      label: "Catálogo",
    },
    {
      value: "/cart",
      label: "Carrito de Compras",
    },
    {
      value: "/order-completed",
      label: "Orden Completada",
    },
    {
      value: "/my-courses",
      label: "Mis Cursos",
    },
    {
      value: "/my-favorites",
      label: "Mis Favoritos",
    },
    {
      value: "/category",
      label: "Categorías",
    },
    {
      value: "/terms",
      label: "Términos y Condiciones",
    },
    {
      value: "/legal-advice",
      label: "Aviso Legal",
    },
    {
      value: "/privacy-policy",
      label: "Política de Privacidad",
    },
    {
      value: "/refund-policy",
      label: "Política de Reembolso",
    },
    ...landings.map(landing => {
      return {
        value: `/${landing.title}`,
        label: landing.title
      } 
    })
  ]

  const setUp = () => {
    const response = getItemConfig('header');
    const formConfig = {
      label: "Header",
      fields: [
        {
          "label": 'Logo',
          "name": 'logoHeader.src',
          "component": 'image',
          "parse": media => {
            return media.previewSrc
          },
          "previewSrc": formValues => {
            return formValues;
          },
          "focusRing": false,
        },
        {
          label: "Main Menu",
          name: "menuItems",
          component: "group-list",
          itemProps: item => ({
            label: item.label,
          }),
          fields: [
            {
              label: "Label",
              name: "label",
              component: "text",
              parse(value) {
                return value || ""
              },
            },
            {
              label: "Type",
              name: "type",
              component: "select",
              "options": [
                {
                  value: '',
                  label: 'Elige el tipo',
                },
                {
                  value: 'internalLink',
                  label: 'Link Interno',
                },
                {
                  value: 'externalLink',
                  label: 'Link Externo',
                },
                {
                  value: 'categories',
                  label: 'Categories',
                }
              ],
              parse(value) {
                return value || ""
              }
            },
            {
              label: "Link Interno",
              name: "internalLink",
              component: "select",
              options: linkOptions,
              parse(value) {
                return value || ""
              },
            },
            {
              label: "Link Externo",
              name: "externalLink",
              component: "text",
              parse(value) {
                return value || ""
              },
            },
            {
              label: "Sub Menu",
              name: "subMenu",
              component: "group-list",
              itemProps: item => ({
                key: item.link,
                label: item.label,
              }),
              fields: [
                {
                  label: "Label",
                  name: "label",
                  component: "text",
                },
                {
                  label: "Link",
                  name: "link",
                  component: "text",
                },
                {
                  label: "Sub Menu",
                  name: "subMenu",
                  component: "group-list",
                  itemProps: item => ({
                    key: item.link,
                    label: item.label,
                  }),
                  fields: [
                    {
                      label: "Label",
                      name: "label",
                      component: "text",
                    },
                    {
                      label: "Link",
                      name: "link",
                      component: "text",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      onSubmit: async (data) => {
        const saved = await saveSiteConfig({ 'header': data });
        if (saved) {
          cms.alerts.success('Cambios guardados!');
        } else {
          cms.alerts.error('Error al guardar');
        }
      },
      initialValues: response,
      // loadInitialValues
    };
    return formConfig;
  };

  const NavForm = setUp();

  const [data, form] = useForm(NavForm, { fields: NavForm.fields });
  useFormScreenPlugin(form)
  const { menuItems, logoHeader } = data;

  const isMobile = useMediaQuery('(max-width: 980px)');

  const handleClick = (e) => {
    e.preventDefault();
    document.querySelector('[value=Header]').click()
  }

  const filteredMenuItems = () => {
    return menuItems.filter(element => {
      // hide the link if href is /course-catalog and there is only one course
      if((element.internalLink === '/course-catalog' && courses.length <= 1) || loading){
        return false
      }
      // hide the link if its type categories and there are no categories
      if(!categoriesLoading && categories){
        if(element.type === 'categories' && categories.length <= 0){
          return false
        }
      }
      return true
    })
  }

  const onLoadLandings = async (siteId) => {
    const { data } = await getLandings(siteId)
    if(data){
      setLandings(data)
    }
  }

  useEffect(()=>{
    const siteId = production ? keys.id : id
    onLoadCourses(siteId)
    if(!production){
      onLoadLandings(siteId)
    }
  },[])

  return (
    <InlineForm form={form}>
      <div 
        onClick={cms.enabled ? handleClick : null} 
        style={{...styles.header, padding: `${isMobile ? `8px ${styleVariables.mobilePadding}px` : `20px ${styleVariables.desktopPadding}px`}`}}
      >
        <Disable>
          <Link to="/">
            <img alt="logo" className="app-logo" src={logoHeader.src} />
          </Link>
        </Disable>

        {
          isMobile ?
            <Disable>
              <div style={styles.mobileContainer}>
                {
                  isAuthenticated ? 
                    <Actions hoverEnabled={false} />
                  :
                  <Disable>
                    <CartIcon 
                      onRedirect={(path)=>history.push(path)}
                    />
                    <Button 
                      style={{...styles.signInButton, minWidth: 24, padding: 0, marginLeft: 18}} 
                      onClick={() => history.replace('/sign-in')}
                    >
                      <img style={{...styles.icon, marginRight: 0}} alt="user" src={user} />
                    </Button>
                  </Disable>
                }
                {
                  filteredMenuItems().length > 0 && (
                    <MobileMenu data={filteredMenuItems()}/>
                  )
                }
              </div>
            </Disable>
          :
            <>
              <div style={{ flexGrow: 1, flexShrink: 1}}/>
              <Disable>
                <NavigationBar data={filteredMenuItems()} />
              </Disable>
              {
                isAuthenticated ? 
                  <Actions />
                :
                  <Disable>
                    <CartIcon 
                      onRedirect={(path)=>history.push(path)}
                      containerStyle={{marginLeft: 15}}
                    />
                    <Button 
                      style={styles.signInButton} 
                      onClick={() => history.replace('/sign-in')}
                    >
                      <img style={styles.icon} alt="user" src={user} />
                      Inicia Sesión
                    </Button>
                  </Disable>
              }
            </>
          }
      </div>
    </InlineForm>
  );
};

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: 'rgb(0 0 0) 1px -14px 30px',
    position: 'sticky',
    zIndex: 1
  },
  mobileContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  signInButton: {
    marginLeft: 15,
    fontSize: 16,
    textTransform: 'none',
    fontWeight: 600,
    fontFamily: 'Muli',
    cursor: 'pointer',
    transition: 'color 0.4s ease 0s',
    color: 'rgb(122, 123, 146)'
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 10
  }
}

export default NavbarLayout;
