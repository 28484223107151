import React from 'react';
import { BlocksControls, InlineTextarea } from 'react-tinacms-inline';
import fields from './fields'
import defaults from './defaults'
import styled from 'styled-components'

function ServicesItem({ index, data }) {
  return (
    <BlocksControls focusRing={{ offset: 0 }} insetControls index={index}>
      <Container>
        <Content>
          <Name>
            <InlineTextarea name="name" focusRing={false} />
          </Name>
        </Content>
      </Container>
    </BlocksControls>
  );
}

const Container = styled.div`
  width: 25%;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
`

const Content = styled.div`
  width: 104px;
  height: 104px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-bottom: #3f32de 3px solid;
`

const Name = styled.div`
  font-family: Arial;
  font-weight: 700;
  color: #908e8e;
  font-size: 14px;
  align-text: center;
`

export const servicesItemBlock = {
  Component: ServicesItem,
  template: {
    name: "servicesItem",
    key: "label",
    label: "Services Item",
    component: "group-list",
    itemProps: item => ({
      label: item.name,
    }),
    defaultItem: defaults,
    fields: fields,
  },
};
