import React, { useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { ShopContext } from '../context/ShopContext'
import { SessionContext } from '../context/SessionContext'
import { formatQuantity } from '../helpers/StatisticalFunctions'
import Course from './Course'
import '../App.css'

function Courses ({courses = [],  reload, loading, forceIsPurchased, redirectPath = `course-detail`}) {

  const { addProductToCart, removeProductFromCart } = useContext(ShopContext)
  const { isAuthenticated } = useContext(SessionContext)

  const history = useHistory()

  const handleClick = course => {
    history.push(`/${redirectPath}/${course.id}`)
  }

  return (
    <List style={styles.container} className='general-padding'>
      {
        loading ?
          <LoaderContainer>
            <CircularProgress style={{ color: '#00A8D8' }}/>
          </LoaderContainer>
        :
          courses.length <= 0 ?
            <div style={styles.emptyState}>
              Parece que no tienes cursos disponibles
            </div>
          :         
            courses.map(course => (
              <CourseContainer key={course.id}>
                <Course
                  forceIsPurchased={forceIsPurchased}
                  isPurchased={course.isPurchased}
                  courseId={course.id}
                  reloadCourses={() => reload && reload()}
                  onClick={ev => handleClick(course)}
                  onAddToCart={() => addProductToCart(course)}
                  onRemoveFromCart={() => removeProductFromCart(course.id)}
                  background={course.background}
                  category={course.categoryName}
                  title={course.title}
                  description={course.description}
                  sales={
                    course.numberOfBuyers <= 1000
                      ? '< 1000'
                      : formatQuantity(course.numberOfBuyers)
                  }
                  likes={1 <= 1000 ? '< 1000' : formatQuantity(1)} //
                  expectedDuration={course.expected_duration}
                  originalPrice={course.originalPrice ? course.originalPrice : null}
                  price={course.price ? course.price.unit_amount : null}
                  currency={course.price ? course.price.currency : null}
                  favorite={course.isFavorite}
                  showFavoriteButton={isAuthenticated}
                  isPurchased={course.isPurchased}
                />
              </CourseContainer>
            ))
      }
    </List>
  )
}

export const List = styled.div``

export const CourseContainer = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
  @media (max-width: 1400px) {
    width: 32.333333%;
  }
  @media (max-width: 1120px) {
    width: 50%;
  }
  @media (max-width: 820px) {
    width: 100%;
  }
`

const LoaderContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap'
  },
  emptyState: {
    width: '100%',
    height: 300,
    background: 'rgb(248, 248, 250)',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '800',
    fontFamily: 'Muli',
    color: 'rgb(158, 162, 180)',
    alignItems: 'center'
  }
}

export default Courses
