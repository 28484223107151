import profilePicture from '../../assets/images/profile.jpg';

export default {
    "_template": 'blockTestimonials',
    "overflow": true,
    "testimonialsList": [
        {
          "_template": "testimonialsItem",
          "image": profilePicture,
          "author": "Robert Plasukin M.",
          "testimonial": "Dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo ullamco laboris nisi delde paraolore",
          "location": "Ciudad de México"
        },
        {
          "_template": "testimonialsItem",
          "image": profilePicture,
          "author": "Robert Plasukin M.",
          "testimonial": "Dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo ullamco laboris nisi delde paraolore",
          "location": "Ciudad de México"
        },
        {
          "_template": "testimonialsItem",
          "image": profilePicture,
          "author": "Robert Plasukin M.",
          "testimonial": "Dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo ullamco laboris nisi delde paraolore",
          "location": "Ciudad de México"
        }
    ]
}