import landing1BlockNavbarDefaults from './landings/landing1/blocks/Navbar/defaults'
import landing1BlockHeroCarouselDefaults from './landings/landing1/blocks/HeroCarousel/defaults'
import landing1BlockBoxesDefaults from './landings/landing1/blocks/Boxes/defaults'
import landing1BlockTextImageDefaults from './landings/landing1/blocks/TextImage/defaults'
import landing1BlockTextImageAltDefaults from './landings/landing1/blocks/TextImage/alt-defaults'
import landing1BlockTestimonialsDefaults from './landings/landing1/blocks/Testimonials/defaults'
import landing1BlockFooterDefaults from './landings/landing1/blocks/Footer/defaults'

import landing2BlockNavbarDefaults from './landings/landing2/blocks/Navbar/defaults'
import landing2BlockFormImageDefaults from './landings/landing2/blocks/FormImage/defaults'
import landing2BlockBoxesDefaults from './landings/landing2/blocks/Boxes/defaults'
import landing2BlockTextImageDefaults from './landings/landing2/blocks/TextImage/defaults'
import landing2BlockTextImageAltDefaults from './landings/landing2/blocks/TextImage/alt-defaults'
import landing2BlockTestimonialsDefaults from './landings/landing2/blocks/Testimonials/defaults'
import landing2BlockFooterDefaults from './landings/landing2/blocks/Footer/defaults'

import landing3BlockNavbarDefaults from './landings/landing3/blocks/Navbar/defaults'
import landing3BlockHeroDefaults from './landings/landing3/blocks/Hero/defaults'
import landing3BlockTextImageDefaults from './landings/landing3/blocks/TextImage/defaults'
import landing3BlockBoxesDefaults from './landings/landing3/blocks/Boxes/defaults'
import landing3BlockTextImageAltDefaults from './landings/landing3/blocks/TextImage/alt-defaults'
import landing3BlockContactFormDefaults from './landings/landing3/blocks/ContactForm/defaults'
import landing3BlockTestimonialsDefaults from './landings/landing3/blocks/Testimonials/defaults'
import landing3BlockFooterDefaults from './landings/landing3/blocks/Footer/defaults'

import landing4BlockNavbarDefaults from './landings/landing4/blocks/Navbar/defaults'
import landing4BlockHeroDefaults from './landings/landing4/blocks/Hero/defaults'
import landing4BlockServicesDefaults from './landings/landing4/blocks/Services/defaults'
import landing4BlockBoxesDefaults from './landings/landing4/blocks/Boxes/defaults'
import landing4BlockTextImageDefaults from './landings/landing4/blocks/TextImage/defaults'
import landing4BlockTextImageAltDefaults from './landings/landing4/blocks/TextImage/alt-defaults'
import landing4BlockFooterDefaults from './landings/landing4/blocks/Footer/defaults'

import { SeoDefaultValues } from './layout/Seo'

export const ThemeDefaultValues = {
    ThemeColor: {
        black: "#38a2ff",
        white: "#fff",
        primary: "#71A1F5",
        secondary: "#78B08D"
    }
};

export const data = {
    "landing1": { "blocks" : [ 
        landing1BlockNavbarDefaults,
        landing1BlockHeroCarouselDefaults,
        landing1BlockBoxesDefaults,
        landing1BlockTextImageDefaults,
        landing1BlockTextImageAltDefaults,
        landing1BlockTestimonialsDefaults,
        landing1BlockFooterDefaults
    ] },
    "landing2": {"blocks": [
        landing2BlockNavbarDefaults,
        landing2BlockFormImageDefaults,
        landing2BlockBoxesDefaults,
        landing2BlockTextImageDefaults,
        landing2BlockTextImageAltDefaults,
        landing2BlockTestimonialsDefaults,
        landing2BlockFooterDefaults
    ]},
    "landing3": {"blocks": [
        landing3BlockNavbarDefaults,
        landing3BlockHeroDefaults,
        landing3BlockTextImageDefaults,
        landing3BlockBoxesDefaults,
        landing3BlockTextImageAltDefaults,
        landing3BlockContactFormDefaults,
        landing3BlockTestimonialsDefaults,
        landing3BlockFooterDefaults
    ]},
    "landing4": {"blocks": [
        landing4BlockNavbarDefaults,
        landing4BlockHeroDefaults,
        landing4BlockServicesDefaults,
        landing4BlockBoxesDefaults,
        landing4BlockTextImageDefaults,
        landing4BlockTextImageAltDefaults,
        landing4BlockFooterDefaults
    ]},
    "theme": ThemeDefaultValues,
    "seo": SeoDefaultValues,
}

export default (template) => data[template];