import React from 'react';

function StatusDot() {
  const styles = {
    container: {
      width: 12,
      height: 12,
      backgroundColor: 'white',
      borderRadius: '50%',
      position: 'absolute',
      top: -2,
      right: -5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    dot: {
      width: 9,
      height: 9,
      backgroundColor: '#d94973',
      borderRadius: '50%'
    }
  }

  return (
    <div style={styles.container}>
        <div style={styles.dot} />
    </div>
  );
}

export default StatusDot;
