import React from 'react';
import { BlocksControls, InlineTextarea, InlineImage } from 'react-tinacms-inline';
import fields from './fields'
import defaults from './defaults'
import styled from 'styled-components'
import MainButton from '../../components/buttons/MainButton'

function BoxesItem({ index, data }) {

  const isFirstItem = () => {
    return index === 0 ? true : false
  }

  return (
    <BlocksControls focusRing={{ offset: 0 }} insetControls index={index}>
      <Container style={{...(isFirstItem() && { paddingLeft: '0px', borderLeft: 'none' })}}>
        <InlineImage
          name="image"
          parse={media => {
            return media.previewSrc
          }}
          previewSrc={formValues => {
            return formValues;
          }}
        >
          {
            (props) => (
              <Image style={{backgroundImage: `url(${props.src})`}}/>
            )
          }
        </InlineImage>
        <Content>
          <Date>
            <InlineTextarea name="date" focusRing={false} />
          </Date>
          <Title>
            <InlineTextarea name="title" focusRing={false} />
          </Title>
          <Description className="counter-item-description">
            <InlineTextarea name="description" focusRing={false} />
          </Description>
          <ReadMoreContainer>
            <MainButton
              style="secondary"
              styles={{ padding: '8px 6px'}}
              onPress={()=>console.log('Read more')} 
              label="READ MORE"
            />
          </ReadMoreContainer>
        </Content>
      </Container>
    </BlocksControls>
  );
}

const Container = styled.div`
  width: 370px;
  background-color: #ffffff;
`
const Image = styled.div`
  height: 183px;
  background-size: cover;
  backgroundPosition: center center;
  backgroundRepeat: 'no-repeat',
  position: 'relative'
`

const Content = styled.div`
  padding: 15px 30px;
`

const Date = styled.div`
  color: #cccccc;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  font-family: Arial;
  text-align: left;
`

const Title = styled.div`
  color: #222e3e;
  font-size: 22px;
  font-weight: 700;
  font-family: Arial;
  text-align: left;
`

const Description = styled.div`
  color: #868686;
  font-size: 16px;
  font-weight: 600;
  font-family: Arial;
  text-align: left;
  margin-top: 8px;
`

const ReadMoreContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
`

export const boxesItemBlock = {
  Component: BoxesItem,
  template: {
    name: "boxesItem",
    key: "label",
    label: "Boxes Item",
    component: "group-list",
    itemProps: item => ({
      label: item.title,
    }),
    defaultItem: defaults,
    fields: fields,
  },
};
