import network from '../utils/network';

export const unlock = async (site, url) => {
  try {
    const { data } = await network.get(`site/${site}/content/sign?url=${url}&expires=30`);
    return data;
  } catch (error) {
    console.log('unlock error', error);
  }
}

export const makeToast = (fn, message, type = 'success') => {
  fn(message, {
    variant: type,
    preventDuplicate: true,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    }
  })
}