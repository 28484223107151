import React from 'react'
import styled from 'styled-components'

export default function CategoryBanner({subtitle, title, description, url}) {
  return (
    <div style={{...styles.container, backgroundImage: url ? `url(${url})` : `url(${require('../assets/images/hero-2.webp')})` }}>
        <Content>
            <Subtitle>{subtitle}</Subtitle>
            <Title>{title}</Title>
            <Description>{description}</Description>
        </Content>
    </div>
  );
}

export const Content = styled.div`
    width: 840px;
    padding: 70px 100px;
    text-align: left;
    color: white;
    @media (max-width: 768px) {
        padding: 36px 30px 140px;
    }
`;

export const Title = styled.div`
    color: white;
    font-size: 38px;
    padding-top: 4px;
    line-height: 52px;
    font-weight: 800;
    font-family: Muli;
    @media (max-width: 768px) {
        line-height: 36px;
        font-size: 30px;
    }
`;

export const Subtitle = styled.div`
    font-size: 24px;
    padding-top: 15px;
    font-family: Muli;
    color: white;
    display: block;
    @media (max-width: 768px) {
        display: none;
    }
`;

export const Description = styled.div`
    font-size: 20px;
    font-family: Muli;
    line-height: 30px;
    padding-top: 23px;
    color: white;
    @media (max-width: 768px) {
        padding-top: 18px;
        font-size: 22px;
    }
`;

const styles = {
    container: {
      width: '100%',
      minHeight: '450px',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    }
}