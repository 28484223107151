import { buttonBlock } from '../Button/Button';

export default [
  {
    "label": "Background",
    "name": "background",
    "component": "image",
    "parse": media => {
      return media.previewSrc
    },
    "previewSrc": formValues => {
      return formValues;
    },
    "focusRing": false
  },
  {
    "label": "Title",
    "name": "title",
    "component": "text",
    "focusRing": false
  },
  {
    "label": "Subtitle",
    "name": "subtitle",
    "component": "text",
    "focusRing": false
  },
  {
    "label": "Description",
    "name": "description",
    "component": "text",
    "focusRing": false
  },
  {
    'label': 'Blocks Buttons',
    'name': 'buttons',
    'component': 'blocks',
    'templates': {
      'button': buttonBlock.template,
    },
  },
  {
    "label": "Button 1",
    "name": "button1",
    "component": "text",
    "focusRing": false
  },
  {
    "label": "Button 2",
    "name": "button2",
    "component": "text",
    "focusRing": false
  },
]