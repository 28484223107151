import React, { useEffect, useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { makeToast } from '../utils'
import { useSnackbar } from 'notistack'
import { Skeleton } from '@material-ui/lab'
import { loadStripe } from '@stripe/stripe-js'
import { CircularProgress } from '@material-ui/core'
// import { InlineTextarea } from 'react-tinacms-inline'
import { useParams, useHistory } from 'react-router-dom'
import network from '../utils/network'
// import Stars from '../components/Stars'
import Section from '../components/Section'
import {
  PersistentContext,
  SessionContext,
  CourseContext,
  ShopContext
} from '../context'
import { useMediaQuery } from '../helpers/Hooks'
import CourseStat from '../components/CourseStat'
import CategoryLabel from '../components/CategoryLabel'
import MainButton from '../components/buttons/MainButton'
import { formatPrice } from '../helpers/StatisticalFunctions'
import {
  // percentage,
  // formatQuantity,
  formatDuration
} from '../helpers/StatisticalFunctions'
import '../App.css'

const eventDefault = {
  category: {
    name: 'E-Commerce'
  },
  title: 'Tus primeras ventas',
  description:
    'Praesent Tincidunt Tempus Tincidunt. Suspendisse Potenti. In Tincidunt Leo Elit, Sit Amet Malesuada Justo Posuere Maecenas Tincidupraesent Tincidunt Tempus Tincidunt. Suspendisse Potetincid Leo Elit, Sit Amet Malesuada Justo Posuere Eg',
  summary:
    '<p>Praesent Tincidunt Tempus Tincidunt. Suspendisse Potenti. In Tincidunt Leo Elit, Sit Amet Malesuada Justo Posuere Maecenas Tincidupraesent Tincidunt Tempus Tincidunt. Suspendisse Potetincid Leo Elit, Sit Amet Malesuada Justo Posuere Eg</p>',
  statistics: {
    users: 0,
    likes: 0
  },
  data: {
    id: 1,
    media: 'course-1.webp',
    title: 'Tus primeras ventas',
    content: {
      description:
        '<p>Praesent Tincidunt Tempus Tincidunt. Suspendisse Potenti. In Tincidunt Leo Elit, Sit Amet Malesuada Justo Posuere Eget. Maecenas Tincidunt Ultrices Urna, A Condimentum Mauris Efficitur Tincidunt. Sed Non Posuere Purus. Fusce Interdum Magna A Ipsum Mattis Ultrices. Integer Auctor Molestie Nibh, Sit Amet Tincidunt. Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Vestibulum In Rhoncus Libero. Phasellus Condimentum Nulla Turpis, Quis Luctus Diam Fringilla Vitae. Duis Non Pretium Ante. In Sit Amet Imperdiet Velit, Iaculis Efficitur Nunc. Nulla Vitae Risus In Nibh Viverra Tempor. Nulla Id Est Ultricies, Consequat Sem Ac, Viverra Tellu.<p>'
    },
    sales: 92030,
    likes: 4300,
    expected_duration: '1 hr 30 min',
    discount: '30% Menos $39.99',
    price: 19.99,
    url: 'http://v2v.cc/~j/theora_testsuite/320x240.ogg'
  },
  defaultModule: {
    id: 1
  },
  defaultData: {},
  expectations:
    'Praesent Tincidunt Tempus Tincidunt. Suspendisse Potenti. In Tincidunt Leo Elit, Sit Amet Malesuada Justo Posuere Eget. Maecenas Tincidunt Ultrices Urna, A Condimentum Mauris Efficitur Tincidunt. Sed Non Posuere Purus. Fusce Interdum Magna A Ipsum Mattis Ultrices. Integer Auctor Molestie Nibh, Sit Amet Tincidunt. Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Vestibulum In Rhoncus Libero. Phasellus Condimentum Nulla Turpis, Quis Luctus Diam Fringilla Vitae. Duis Non Pretium Ante. In Sit Amet Imperdiet Velit, Iaculis Efficitur Nunc. Nulla Vitae Risus In Nibh Viverra Tempor. Nulla Id Est Ultricies, Consequat Sem Ac, Viverra Tellu.',
  modules: [
    {
      id: 1,
      title: 'Bienvenido al curso',
      media: 'module-1.webp',
      content: [
        {
          id: 1,
          title: 'Bienvenido al curso',
          media: 'module-1.webp'
        }
      ]
    },
    {
      id: 2,
      title: 'Acerca de tu instructor',
      media: 'module-2.webp',
      content: [
        {
          id: 2,
          title: 'Acerca de tu instructor',
          media: 'module-2.webp'
        }
      ]
    },
    {
      id: 3,
      title: 'Objetivos y metas',
      media: 'module-3.webp',
      content: [
        {
          id: 3,
          title: 'Objetivos y metas',
          media: 'module-3.webp'
        }
      ]
    },
    {
      id: 4,
      title: 'Introducción',
      media: 'module-4.webp',
      content: [
        {
          id: 4,
          title: 'Introducción',
          media: 'module-4.webp'
        }
      ]
    }
  ]
}

function EventDetail ({ index, data }) {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [stripeLoading, setStripeLoading] = useState(false)
  const [loadingGeneral, setLoadingGeneral] = useState(true)

  const isMediumDevice = useMediaQuery('(max-width: 980px)')
  const isMobile = useMediaQuery('(max-width: 768px)')

  const styles = {
    container: {
      width: '100%'
    },
    title: {
      color: '#43434a',
      fontSize: 32,
      fontWeight: '800',
      fontFamily: 'Muli',
      textAlign: 'left',
      textTransform: 'uppercase'
    },
    section1: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'space-between',
      paddingBottom: 56
    },
    section1Left: {
      width: isMobile ? '100%' : '61%'
    },
    section1Right: {
      width: '39%',
      marginLeft: 50
    },
    stats: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    videoContainer: {
      marginTop: 28,
      width: '100%',
      height: 390
    },
    tabs: {
      background: 'white',
      borderBottom: '#016cff 3px solid',
      display: 'flex'
    },
    tab: {
      width: '100%',
      height: 36,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: 'uppercase',
      fontWeight: '800',
      fontFamily: 'Muli',
      fontSize: isMediumDevice ? 11 : 13,
      color: '#d4d4d4'
    },
    activeTab: {
      color: 'rgb(1, 108, 255)',
      backgroundColor: '#e8f1fe'
    },
    review: {
      marginTop: 22,
      boxShadow: '#d4d4d4 0px 0px 12px 0px',
      padding: 22
    },
    reviewHeader: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: isMobile ? 'space-between' : 'flex-start'
    },
    reviewTitle: {
      color: 'rgb(67, 67, 74)',
      fontSize: isMobile ? 14 : 18,
      fontFamily: 'Muli',
      fontWeight: isMobile ? '600' : '800',
      textAlign: 'left',
      marginRight: 5
    },
    reviewScore: {
      color: '#75c49d',
      fontSize: isMobile ? 16 : 19,
      fontWeight: '800',
      fontFamily: 'Muli',
      margin: isMobile ? '0px' : '0px 5px'
    },
    reviewDescription: {
      fontSize: isMobile ? 16 : 13,
      textAlign: 'left',
      fontWeight: '800',
      fontFamily: 'Muli',
      lineHeight: isMobile ? '25px' : '20px',
      color: '#c1c1c1',
      marginTop: 6
    },
    reviewSubmitButton: {
      width: '100%',
      padding: isMobile ? '4px 8px' : 8,
      fontSize: isMobile ? 16 : 14,
      fontFamily: 'Muli',
      marginTop: 18
    },

    attributes: {
      marginTop: 22
    },
    attributesTitle: {
      color: 'rgb(67, 67, 74)',
      fontSize: 22,
      fontWeight: '800',
      fontFamily: 'Muli',
      textAlign: 'left',
      marginBottom: 10
    },
    attribute: {
      backgroundColor: 'white',
      width: '100%',
      padding: '8px 14px',
      marginBottom: 10,
      display: 'flex',
      alignItems: 'center'
    },
    attributeDot: {
      width: 10,
      height: 10,
      background: '#016cff',
      borderRadius: '50%'
    },
    attributeText: {
      fontSize: 14,
      textAlign: 'left',
      fontWeight: '600',
      fontFamily: 'Muli',
      color: 'rgb(193, 193, 193)',
      marginLeft: 10
    },

    section2: {
      backgroundColor: 'white',
      padding: '36px 0px',
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'space-between'
    },
    section2Left: {
      width: '61%'
    },
    section2Right: {
      width: isMobile ? '100%' : '39%',
      marginLeft: isMobile ? 0 : 50,
      marginTop: isMobile ? 50 : 0
    },
    expectations: {
      marginTop: 32,
      paddingBottom: 36,
      borderBottom: '#cecece 2px solid'
    },
    expectationsTitle: {
      color: '#43434a',
      fontSize: 32,
      fontWeight: '800',
      fontFamily: 'Muli',
      textAlign: 'left'
    },
    expectationsText: {
      textAlign: 'left',
      lineHeight: isMobile ? '34px' : '28px',
      color: isMobile ? 'rgb(134 133 133)' : 'rgb(193, 193, 193)',
      fontSize: isMobile ? 19 : 16,
      fontFamily: 'Muli',
      marginTop: 5
    },
    modules: {},
    modulesTitle: {
      color: '#43434a',
      fontSize: 32,
      fontWeight: '600',
      fontFamily: 'Muli',
      textAlign: 'left',
      marginTop: 26,
      marginBottom: 22
    },
    module: {
      borderLeftWidth: 7,
      borderLeftStyle: 'solid',
      height: 50,
      boxShadow: 'rgb(212, 212, 212) 0px 0px 12px 0px',
      padding: '0px 15px',
      display: 'flex',
      alignItems: 'center',
      fontWeight: '800',
      fontFamily: 'Muli',
      marginBottom: 20,
      textAlign: 'left'
    },

    testimonials: {
      width: '100%',
      padding: isMobile ? 21 : 26,
      backgroundColor: '#334256'
    },
    testimonialsTitle: {
      color: '#ffffff',
      fontSize: 22,
      fontWeight: '800',
      fontFamily: 'Muli',
      textAlign: 'left'
    },
    testimonial: {
      display: 'flex',
      padding: isMobile ? '16px 0px' : '36px 0px'
    },
    testimonialProfilePicture: {
      height: isMediumDevice ? 32 : 54,
      width: isMediumDevice ? 32 : 54,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      flexGrow: 0,
      flexShrink: 0
    },
    testimonialContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingLeft: isMobile ? 12 : 21
    },
    testimonialName: {
      fontSize: 17,
      fontWeight: '800',
      fontFamily: 'Muli',
      color: '#ffffff'
    },
    testimonialLocation: {
      color: 'rgb(213 215 218)',
      fontSize: 14,
      fontFamily: 'Muli',
      marginTop: 2
    },
    testimonialText: {
      color: '#b6bac0',
      textAlign: 'left',
      lineHeight: isMediumDevice ? '16px' : '28px',
      fontSize: isMediumDevice ? 11 : 16,
      fontFamily: 'Muli',
      marginTop: isMediumDevice ? 5 : 0
    },
    testimonialTextMobile: {
      color: '#b6bac0',
      textAlign: 'left',
      fontSize: 16,
      fontFamily: 'Muli',
      lineHeight: '21px',
      marginTop: 5
    },
    price: {
      color: 'rgb(67, 67, 74)',
      fontSize: 14,
      fontFamily: 'Muli',
      fontWeight: '600'
    },
    originalPrice: {
      color: '#e68486',
      fontSize: 14,
      fontFamily: 'Muli',
      fontWeight: '600',
      textDecoration: 'line-through',
      marginLeft: 6
    }
  }

  const { page, param1 } = useParams()

  const { keys, id, production } = useContext(PersistentContext)
  const { isAuthenticated } = useContext(SessionContext)
  const {
    isProductInCart,
    isProductLoading,
    isAnyProductLoading,
    addProductToCart,
    removeProductFromCart
  } = useContext(ShopContext)
  const { onLoadEvent, eventDetail } = useContext(CourseContext)

  let defaultData = eventDetail || eventDefault

  const preload = async () => {
    try {
      const siteId = keys ? keys.id : id 
      await onLoadEvent(siteId, param1, isAuthenticated)
    } catch (error) {
      console.log('errror', error) 
    }
    setLoadingGeneral(false)
  }

  const goToCourse = () => {
    history.push(`/course/${defaultData.data.id}`)
  }

  const addToCart = e => {
    e.stopPropagation()
    if (!production) {
      makeToast(enqueueSnackbar, 'Checkout deshabilitado en vista previa', 'info')
      return
    }
    let webinarId = defaultData.data.id
    if (isProductInCart(webinarId)) {
      removeProductFromCart(webinarId)
    } else {
      addProductToCart(defaultData.data)
    }
  }

  const goToBuy = async () => {
    if (!production) {
      makeToast(enqueueSnackbar, 'Checkout deshabilitado en vista previa', 'info')
      return
    }
    setStripeLoading(true)
    // Move this key to SITE_DATA?
    const stripe = await loadStripe(
      'pk_test_51Hm4WFDsVvlWtoAQemSKV496y7frfX4CRAFfjK6JMmDa5tqk39azU08pdsPIn2GhUfX4l7KHLYE48nwp6ryySgSZ00FTatqiIU'
    )
    const redirectPath = window.location.pathname
    const res = await network.get(
      `${!isAuthenticated ? `public/` : ``}site/${
        window.SITE_DATA.id
      }/stripe/session/oneclick?webinarId=${
        defaultData.data.id
      }&prevUrl=${redirectPath}`
    )
    const sessionId = res.data.id
    const result = await stripe.redirectToCheckout({ sessionId })
    setStripeLoading(false)
  }

  useEffect(() => {
    if (param1) {
      preload()
    }
  }, [param1])

  const renderLoading = () => {
    return (
      <div className='general-padding' style={{...styles.section1, flexDirection: 'column'}}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Skeleton variant='text' animation='pulse' width={254} height={50} />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Skeleton
              variant='text'
              animation='pulse'
              width={100}
              height={50}
              style={{ marginRight: 10 }}
            />
            <Skeleton
              variant='text'
              animation='pulse'
              width={50}
              height={50}
              style={{ marginRight: 10 }}
            />
            <Skeleton
              variant='text'
              animation='pulse'
              width={50}
              height={50}
              style={{ marginRight: 10 }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', width: '70%', marginRight: 20 }}>
            <Skeleton
              variant='rect'
              animation='pulse'
              width="100%"
              height={492}
            />
          </div>
          <div style={{ display: 'flex', width: '30%' }}>
            <Skeleton
              variant='rect'
              animation='pulse'
              width="100%"
              height={200}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='page'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Detalle de Curso</title>
        <link rel='canonical' href='https://store.l3rn.io/course-detail' />
      </Helmet>
      <div style={styles.container}>
        {loadingGeneral ? (
          <Section containerStyle={{ backgroundColor: '#f7f8fa' }}>
            {renderLoading()}
          </Section>
        ) : (
          <>
            <Section
              // title={defaultData && defaultData.data.title}
              containerStyle={{ backgroundColor: '#f7f8fa' }}
              headerStyle={{
                padding: '36px 0px 18px',
                textTransform: 'uppercase'
              }}
            >
              <div
                className='general-padding'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <div style={styles.title}>
                  {defaultData && defaultData.data.title}
                </div>
                <div style={styles.stats}>
                  <CategoryLabel
                    styles={{
                      display: isMobile ? 'none' : 'initial',
                      fontSize: 11,
                      borderRadius: 3,
                      margin: '6px 12px 6px 0px',
                      fontFamily: 'Muli'
                    }}
                    name={defaultData.category && defaultData.category.name}
                  />
                  <CourseStat
                    containerStyle={{ margin: '6px 12px 6px 0px' }}
                    iconStyle={{ width: 22, height: 22 }}
                    textStyle={{
                      fontSize: 16,
                      marginLeft: 7,
                      fontFamily: 'Muli'
                    }}
                    icon='sales'
                    value={
                      defaultData && defaultData.statistics
                        ? defaultData.statistics.users
                        : 0
                    }
                  />
                  <CourseStat
                    containerStyle={{ margin: '6px 12px 6px 0px' }}
                    iconStyle={{ width: 22, height: 22 }}
                    textStyle={{
                      fontSize: 16,
                      marginLeft: 7,
                      fontFamily: 'Muli'
                    }}
                    icon='time'
                    value={
                      defaultData &&
                      formatDuration(defaultData.data.expected_duration)
                    }
                  />
                </div>
              </div>
              <div className='general-padding' style={styles.section1}>
                <div style={styles.section1Left}>
                  <div style={styles.videoContainer}>
                    <img
                      alt="preview-image"
                      style={{ width: '100%', height: 390, objectFit: 'contain' }}
                      src={defaultData.data && defaultData.data.background}
                    />
                  </div>
                </div>

                {isMobile ? (
                  <div style={{ width: '100%' }}>
                    <div style={styles.expectations}>
                      <div style={styles.expectationsTitle}>
                        Esto es lo que puedes esperar del evento
                      </div>
                      <div style={styles.expectationsText}>
                        {defaultData && defaultData.data.description}
                      </div>
                    </div>

                  </div>
                ) : (
                  <div style={styles.section1Right}>
                    <div style={styles.review}>
                      <div style={styles.reviewHeader}>
                        <div style={styles.reviewTitle}>
                          {defaultData && defaultData.data.title}
                        </div>
                      </div>
                      <div style={styles.reviewDescription}>
                        {defaultData && defaultData.data.description}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          minHeight: 20.8,
                          marginTop: 11,
                          width: '100%'
                        }}
                      >
                        {defaultData.data.price && (
                          <>
                            {defaultData.data.originalPrice &&
                              defaultData.data.price.currency && (
                                <>
                                  <div style={styles.originalPrice}>
                                    {formatPrice(
                                      defaultData.data.originalPrice,
                                      defaultData.data.price.currency
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      ...styles.price,
                                      margin: '0px 5px'
                                    }}
                                  >
                                    a
                                  </div>
                                </>
                              )}
                            {defaultData.data.price.unit_amount &&
                              defaultData.data.price.currency && (
                                <div style={styles.price}>
                                  {formatPrice(
                                    defaultData.data.price.unit_amount,
                                    defaultData.data.price.currency
                                  )}
                                </div>
                              )}
                          </>
                        )}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          columnGap: 10
                        }}
                      >
                        {!defaultData.owner && (
                          <MainButton
                            disabled={isAnyProductLoading()}
                            onPress={e => addToCart(e)}
                            styles={styles.reviewSubmitButton}
                            type='tertiary'
                            label={
                              isProductLoading(defaultData.data.id) ? (
                                <CircularProgress
                                  style={{ width: 18, height: 18, color: '#00A8D8'}}
                                />
                              ) : isProductInCart(defaultData.data.id) ? (
                                'Quitar del Carrito'
                              ) : (
                                'Agregar a Carrito'
                              )
                            }
                          />
                        )}

                        <MainButton
                          type='primary'
                          disabled={isAnyProductLoading() || stripeLoading}
                          styles={styles.reviewSubmitButton}
                          label={
                            defaultData && defaultData.owner
                              ? 'Ver Ahora'
                              : 'Comprar Ahora'
                          }
                          onPress={() =>
                            defaultData && defaultData.owner
                              ? goToCourse()
                              : goToBuy()
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Section>
            <div className='general-padding' style={styles.section2}>
              {isMobile ? (
                <div style={{ width: '100%' }}>
                  <div style={styles.review}>
                    <div style={styles.reviewHeader}>
                      <div style={styles.reviewTitle}>
                        {defaultData && defaultData.data.title}
                      </div>
                    </div>
                    <div style={styles.reviewDescription}>
                      {defaultData && defaultData.data.description}
                    </div>
                    <MainButton
                      type='primary'
                      disabled={stripeLoading}
                      styles={styles.reviewSubmitButton}
                      label={
                        defaultData && defaultData.owner
                          ? 'Ver Ahora'
                          : 'Comprar Ahora'
                      }
                      onPress={() =>
                        defaultData && defaultData.owner
                          ? goToCourse()
                          : goToBuy()
                      }
                    />
                  </div>
                </div>
              ) : (
                <div style={styles.section2Left}>
                  <div style={styles.expectations}>
                    <div style={styles.expectationsTitle}>
                      Esto es lo que puedes esperar del evento
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          defaultData && defaultData.data.summary ? (
                            defaultData.data.summary
                          ) : (
                            <></>
                          )
                      }}
                      style={styles.expectationsText}
                    />
                  </div>

                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default EventDetail
