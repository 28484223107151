import React from 'react';
import { BlocksControls, InlineTextarea, InlineImage } from 'react-tinacms-inline';
import fields from './fields'
import defaults from './defaults'
import styled from 'styled-components'
import quotes from '../../assets/images/quotes.png'

function TestimonialsItem({ index, data: { image } }) {

  return (
    <BlocksControls focusRing={{ offset: {x: -15, y: 0} }} insetControls index={index}>
      <Container>

        <QuotesContainer>
          <QuotesIcon alt="quotes-icon" src={quotes} />
        </QuotesContainer>

        <Content>
          <Testimonial>
            <InlineTextarea name="testimonial" focusRing={false} />
          </Testimonial>

          <Profile>
            <div style={{width: 70}}>
              <InlineImage
                name="image"
                parse={media => {
                  return media.previewSrc
                }}
                previewSrc={formValues => {
                  return formValues;
                }}
              >
                {
                  (props) => (
                    <ImageContainer style={{backgroundImage: `url(${props.src})`}}/>
                  )
                }
              </InlineImage>
            </div>
            <AuthorInfo>
              <Author>
                <InlineTextarea name="author" focusRing={false} />
              </Author>

              <Location>
                <InlineTextarea name="location" focusRing={false} />
              </Location>
            </AuthorInfo>

          </Profile>
        </Content>

      </Container>
    </BlocksControls>
  );
}

const Container = styled.div`
  box-shadow: 0px 0px 18px -3px #e4e4e4;
  width: 48%;
  margin-right: 4%;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 20px;
`

const Content = styled.div`
  padding-left: 20px;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;

`

const Profile = styled.div`
  display: flex;
  margin-top: 30px;
`

const AuthorInfo = styled.div`
  padding-left: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const QuotesContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`

const ImageContainer = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: #fff9f0 2px solid;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`

const QuotesIcon = styled.img`
    height: 50px;
`

const Testimonial = styled.div`
  color: #929292;
  font-size: 16px;
  font-weight: 700;
  font-family: Arial;
  line-height: 31px;
  margin-top: 24px;
  text-align: left;
`

const Author = styled.div`
  color: rgb(41,44,46);
  font-size: 18px;
  font-weight: 800;
  font-family: Arial;
  text-align: left;
`

const Location = styled.div`
  color: #929292;
  font-size: 16px;
  font-weight: 800;
  font-family: Arial;
  text-align: left;
`

export const testimonialsItemBlock = {
  Component: TestimonialsItem,
  template: {
    name: "testimonialsItem",
    key: "label",
    label: "Testimonials Item",
    component: "group-list",
    itemProps: item => ({
      label: item.name,
    }),
    defaultItem: defaults,
    fields: fields,
  },
};
