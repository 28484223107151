import logo from '../../assets/images/logo.png'

export default {
    "_template": "blockFooter",
    "logo": logo,
    "linkSections": [
        {
            "_template": "linkSection",
            "title": "Our Firm",
            "links": [
                {
                    "_template": "link",
                    "label": "About us",
                    "link": "#"
                },
                {
                    "_template": "link",
                    "label": "Leadership",
                    "link": "#"
                }
            ]
        },
        {
            "_template": "linkSection",
            "title": "Insight",
            "links": [
                {
                    "_template": "link",
                    "label": "Perspective",
                    "link": "#"
                },
                {
                    "_template": "link",
                    "label": "Investment insights",
                    "link": "#"
                }
            ]
        },
        {
            "_template": "linkSection",
            "title": "Newsroo",
            "links": [
                {
                    "_template": "link",
                    "label": "Multimedia",
                    "link": "#"
                },
                {
                    "_template": "link",
                    "label": "Connect with us",
                    "link": "#"
                }
            ]
        }
    ],
    "contactTitle": "Contact",
    "contactDescription": "Send us a e mail and we’ll reach you out"
}