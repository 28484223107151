export default {
    "_template": 'blockServices',
    "title": "Lorem ipsum",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
    "buttonLabel": "About us",
    "buttonLink": "#",
    "servicesList": [
        {
          "_template": "servicesItem",
          "name": "Service Name"
        },
        {
          "_template": "servicesItem",
          "name": "Service Name"
        },
        {
          "_template": "servicesItem",
          "name": "Service Name"
        },
        {
          "_template": "servicesItem",
          "name": "Service Name"
        },
        {
          "_template": "servicesItem",
          "name": "Service Name"
        },
        {
          "_template": "servicesItem",
          "name": "Service Name"
        },
        {
          "_template": "servicesItem",
          "name": "Service Name"
        },
        {
          "_template": "servicesItem",
          "name": "Service Name"
        }
    ],
    "topOverflow": true,
    "bottomOverflow": true
}