import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { BlocksControls } from 'react-tinacms-inline'
import ForgotPasswordVerificationForm from '../components/forms/ForgotPasswordVerificationForm'
import defaults from './ForgotPasswordVerification.defaults'
import fields from './ForgotPasswordVerification.fields'
import { useMediaQuery } from '../helpers/Hooks'
import '../App.css'
import { Auth } from 'aws-amplify'

function ForgotPasswordVerification ({ index, data }) {
  const history = useHistory()

  const isMobile = useMediaQuery('(max-width: 980px)')

  const [code, setCode] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const [newpassword, setNewpassword] = useState('')

  const styles = {
    forgotPasswordWrapper: {
      flexGrow: 1,
      flexShrink: 1,
      display: 'flex',
      padding: isMobile ? '35px 30px' : '75px 30px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f7f8fa'
    },
    featuredImage: {
      width: 650,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${data.background})`
    }
  }

  useEffect(() => {
    const search = history.location.search
    const code = new URLSearchParams(search).get('code')
    const email = decodeURIComponent(new URLSearchParams(search).get('email'))
    setEmail(email)
    setCode(code)
  }, [])

  const validate = () => {
    if (email.trim() === '') return { success: false, error: 'Email requerido' }
    if (code.trim() === '') return { success: false, error: 'Codigo requerido' }
    if (newpassword.trim() === '')
      return { success: false, error: 'Nueva contraseña requerido' }
    return { success: true }
  }

  const onSubmit = async () => {
    const isValid = validate()
    if (isValid.success) {
      // AWS Cognito integration here
      try {
        await Auth.forgotPasswordSubmit(
          email,
          code,
          newpassword
        )
        history.push('/sign-in')
      } catch (error) {
        console.log(error)
        setError(error.message)
      }
    } else {
      setError(isValid.error)
    }
  }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <div className='page'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Recuperar Contraseña</title>
          <link rel='canonical' href='https://store.l3rn.io/forgot-password' />
        </Helmet>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={styles.forgotPasswordWrapper}>
            <ForgotPasswordVerificationForm
              title={data.title}
              description={data.description}
              button={data.button}
              signinmessage={data.signinmessage}
              signin={data.signin}
              onSubmit={onSubmit}
              error={error}
              code={code}
              email={email}
              password={newpassword}
              onChangeCode={value => setCode(value)}
              onChangeEmail={value => setEmail(value)}
              onChangePassword={value => setNewpassword(value)}
            />
          </div>
          {isMobile ? <></> : <div style={styles.featuredImage} />}
        </div>
      </div>
    </BlocksControls>
  )
}

export const ForgotPasswordVerificationTemplate = {
  label: 'ForgotPasswordVerification',
  key: 'ForgotPasswordVerification',
  fields: fields,
  defaultItem: defaults
}

export const blockForgotPasswordVerification = {
  Component: ForgotPasswordVerification,
  template: ForgotPasswordVerificationTemplate
}
