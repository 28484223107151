import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown'
import { BlocksControls, InlineText } from 'react-tinacms-inline';
import defaults from './LegalAdvice.defaults'
import fields from './LegalAdvice.fields'
import minus from '../assets/svgs/minus-grey.svg'
import plus from '../assets/svgs/plus-grey.svg'
import styled from 'styled-components'

function LegalAdvice({ index, data }) {

    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    return (
        <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
            <Container 
                style={{backgroundColor: data.backgroundColor}}
                onClick={() => toggle()}
            >
                <Header>
                    <Title style={{ textAlign: data.titleAlign, color: data.fontColor }}>
                        <InlineText name="title" focusRing={false} />
                    </Title>
                    {
                        data.dropdown && (
                            <FaqControl>
                                <FaqControlIcon alt="faq-control-icon" src={dropdownOpen ? minus : plus} />
                            </FaqControl>
                        )
                    }
                </Header> 
                {
                    (!data.dropdown || dropdownOpen) && (
                        <Content style={{textAlign: data.textAlign, color: data.fontColor}}>
                            <Separator/>
                            <ReactMarkdown 
                                skipHtml={true} 
                                children={data.content} 
                            />
                        </Content>
                    )
                }
            </Container>
        </BlocksControls>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 100px;
    box-shadow: 0px 0px 12px -4px #b7b7b7;
    @media (max-width: 768px){
        padding: 30px;
    }
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const Title = styled.div`
    font-size: 30px;
    color: rgb(67, 67, 74);
    font-weight: 800;
    font-size: 34px;
    font-family: Arial;
    flex-grow: 1;
    @media (max-width: 768px){
        font-size: 26px;
        font-weight: 800;
    }
`

const Content = styled.div`
    width: 100%;
    padding: 28px 0px;
    font-size: 18px;
    font-weight: 500;
    font-family: Arial;
    color: #717171;
`

const Separator = styled.div`
    width: 100%;
    height: 28px;
    @media(max-width: 480px){
        height: 0px;
    }
`

const FaqControl = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 34px;
    @media (max-width: 480px){
        padding: 20px 12px;
    }
`

const FaqControlIcon = styled.img`
    width: 18px;
    height: 18px;
`

export const LegalAdviceTemplate = {
    "label": "Legal Advice",
    "key": "legalAdvice",
    "fields": fields,
    "defaultItem": defaults
};

export const blockLegalAdvice = {
    Component: LegalAdvice,
    template: LegalAdviceTemplate,
};