import React, { useContext } from 'react';
import { useForm, usePlugin, useCMS } from 'tinacms';
import { InlineForm, InlineBlocks} from 'react-tinacms-inline';
import { PersistentContext } from '../context';
import { blockHero } from '../blocks/Hero';
import SignInPage from '../blocks/SignIn';

export default function PageSignIn(props) {
  const { saveContent, getItemData } = useContext(PersistentContext);

  const cms = useCMS();

  const SIGNIN_BLOCKS = {
    blockHero,
  };

  const clearItemData = async () => {
    await saveContent({'sign-in': null});
    window.location.reload()
  }

  const setUp = () => {

    const data = getItemData('sign-in');
    
    // Make sure its not getting blockSignIn
    if(data.blocks){
      if(data.blocks[0]){
        if(data.blocks[0]._template === "blockSignIn"){
          clearItemData() 
        }
      }
    }

    const formConfig = {
      id: 'templateSignIn',
      fields: [
        {
          "label": "Background",
          "name": "background",
          "component": "image",
          "parse": media => {
            return media.previewSrc
          },
          "previewSrc": formValues => {
            return formValues;
          },
          "focusRing": false
        },
        {
          "label": "Title",
          "name": "title",
          "component": "text",
          "focusRing": false
        },
        {
          "label": "Descripcion",
          "name": "description",
          "component": "text",
          "focusRing": false
        },
        // {
        //   "label": "Option",
        //   "name": "option",
        //   "component": "text",
        //   "focusRing": false
        // },
        // {
        //   "label": "Google",
        //   "name": "google",
        //   "component": "text",
        //   "focusRing": false
        // },
        // {
        //   "label": "Facebook",
        //   "name": "facebook",
        //   "component": "text",
        //   "focusRing": false
        // },
        {
          "label": "Button",
          "name": "button",
          "component": "text",
          "focusRing": false
        },
        {
          "label": "Terms",
          "name": "aditional_information",
          "component": "text",
          "focusRing": false
        },
        {
          "label": "Register",
          "name": "register",
          "component": "text",
          "focusRing": false
        },
        {
          label: 'Secciones',
          name: 'blocks',
          component: 'blocks',
          templates: {
            'blockHero': blockHero.template,
          }
        }
      ],
      onSubmit: async (data) => {
        await saveContent({'sign-in': data});
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data
    }
    return formConfig;
  }
  const formConfig = setUp();
  const [modifiedValues, form] = useForm(formConfig);
  usePlugin(form)

  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`} style={{minHeight: 'calc(100vh - 225px)'}}>
      <InlineForm form={form}>
        <SignInPage data={modifiedValues}/>
        <InlineBlocks name="blocks" blocks={SIGNIN_BLOCKS} />
      </InlineForm>
    </div>
  )
}
