import image6 from '../../assets/images/image6.png';
import image7 from '../../assets/images/image7.png';

export default {
    "_template": "blockTextImage",
    "image": image7,
    "altImage": image6,
    "title": "Lorem ipsum dende",
    "subtitle": "POWERFULL TAGLINE HERE",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    "button": "CONTACT US",
    "separator": false,
    "imageDisplay": 1
}