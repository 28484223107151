export default [
  {
    "label": "Title",
    "name": "title",
    "component": "text",
    "focusRing": false
  },
  {
    "label": "Subtitle",
    "name": "subtitle",
    "component": "text",
    "focusRing": false
  }
]