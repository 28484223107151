import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useSnackbar } from 'notistack'
import { loadStripe } from '@stripe/stripe-js';
import { CircularProgress } from '@material-ui/core'
import { favoriteCourse, deleteFavorite } from '../helpers/courses'
import { formatPrice } from '../helpers/StatisticalFunctions'
import MainButton from '../components/buttons/MainButton'
import FavoriteButton from './buttons/FavoriteButton'
import { PersistentContext } from '../context'
import { SessionContext } from '../context'
import CategoryLabel from './CategoryLabel'
import { CourseContext } from '../context'
import { ShopContext } from '../context/'
import CourseStats from './CourseStats'
import network from '../utils/network';
import { makeToast } from '../utils'

function Course ({
  courseId,
  isPurchased,
  forceIsPurchased,
  title,
  sales,
  likes,
  price,
  onClick,
  favorite,
  currency,
  category,
  originalPrice,
  background,
  description,
  onAddToCart,
  reloadCourses,
  expectedDuration,
  onRemoveFromCart,
  showFavoriteButton,
}) {

  const { enqueueSnackbar } = useSnackbar()
  const { isProductInCart, isProductLoading, isAnyProductLoading } = useContext(ShopContext)
  const { isAuthenticated, isSessionLoaded } = useContext(SessionContext)
  const { keys, id, production } = useContext(PersistentContext)
  const { onLoadLikedCourses } = useContext(CourseContext)
  const [favoriteLoading, setFavoriteLoading] = useState(false)
  const [stripeLoading, setStripeLoading] = useState(false)

  const favCourse = async (e) => {
    e.stopPropagation()
    const siteId = production ? keys.id : id 
    setFavoriteLoading(true)
    if (favorite) {
      await deleteFavorite(siteId, courseId)
    } else {
      await favoriteCourse(siteId, { courseId: courseId })
    }
    await reloadCourses()
    onLoadLikedCourses(siteId)
    setFavoriteLoading(false)
  }

  const handleClick = (e) => {
    e.stopPropagation()
    if (isProductInCart(courseId)) {
      onRemoveFromCart()
    } else {
      onAddToCart()
    }
  }

  const handleOneClickBuy = async (e) => {
    e.stopPropagation()
    if(!production){
      makeToast(enqueueSnackbar, 'Checkout deshabilitado en vista previa', 'info')
      return;
    }
    setStripeLoading(true);
    // Move this key to SITE_DATA?
    const stripe = await loadStripe('pk_test_51Hm4WFDsVvlWtoAQemSKV496y7frfX4CRAFfjK6JMmDa5tqk39azU08pdsPIn2GhUfX4l7KHLYE48nwp6ryySgSZ00FTatqiIU');
    const redirectPath = window.location.pathname;
    const res = await network.get(`${!isAuthenticated ? `public/` : ``}site/${window.SITE_DATA.id}/stripe/session/oneclick?courseId=${courseId}&prevUrl=${redirectPath}`);
    const sessionId = res.data.id;
    await stripe.redirectToCheckout({ sessionId });
    setStripeLoading(false);
  }

  return (
    <Container onClick={onClick}>
      <div
        style={{
          ...styles.previewImage,
          backgroundImage: `url(${background})`,
        }}
      >
        {/* 
                <div style={styles.playButtonWrapper}>
                    <PlayButton />
                </div> 
        */}
        {showFavoriteButton && (
          <FavoriteButton
            loading={favoriteLoading}
            onPress={(e) => favCourse(e)}
            favorite={favorite}
            styles={styles.favoriteButton}
          />
        )}
      </div>
      <div style={styles.content}>
        <div style={styles.categoryContainer}>
          {category && <CategoryLabel name={category} />}
        </div>
        <div style={styles.title}>{title}</div>
        <div style={styles.description}>{description}</div>
        <CourseStats sales={sales} likes={likes} duration={expectedDuration} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            minHeight: 20.8,
            marginTop: 11,
            width: '100%'
          }}
        >
          {(originalPrice && currency) && (
            <>
              <div style={styles.originalPrice}>
                { formatPrice(originalPrice, currency) }
              </div>
              <div style={{ ...styles.price, margin: '0px 5px' }}>a</div>
            </>
          )}
          {
          (price && currency) && (
            <div style={styles.price}>
              { formatPrice(price, currency) }
            </div>
          )
          }
        </div>
        <div style={styles.actions}>
          {
            (isPurchased || forceIsPurchased) ? (
              <MainButton
                disabled={isAnyProductLoading()}
                onPress={(e) => onClick(e)}
                styles={{...styles.actionsButton, width: '100%'}}
                type='primary'
                label="Ver"
              />
            ) : (
              <>
                <MainButton
                  disabled={isAnyProductLoading()}
                  onPress={(e) => handleClick(e)}
                  styles={styles.actionsButton}
                  type='tertiary'
                  label={
                    isProductLoading(courseId) ? (
                      <CircularProgress style={{ width: 18, height: 18, color: '#00A8D8'}} />
                    ) : 
                      isProductInCart(courseId) ? 
                        'Quitar del Carrito'
                      : 
                        'Agregar a Carrito'
                  }
                />
                {
                  (price && currency) && (
                    <MainButton
                      disabled={isAnyProductLoading() || stripeLoading || !isSessionLoaded}
                      onPress={(e) => handleOneClickBuy(e)}
                      styles={styles.actionsButton}
                      type='primary'
                      label={
                        isProductLoading(courseId) || stripeLoading ? (
                          <CircularProgress 
                            style={{ width: 18, height: 18, color: '#00A8D8'}} 
                          />
                        ) : 'Comprar'
                      }
                    />
                  )
                }
              </>
            )

          }
        </div>
      </div>
    </Container>
  )
}

export const Container = styled.div`
  width: 293px;
  border-radius: 6px;
  margin-bottom: 26px;
  overflow: hidden;
  background-color: white;
  border: 0.5px solid #dcdcdc;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: box-shadow 0.4s;
  :hover{
    box-shadow: 0px 0px 12px -6px black;
  }
`

const styles = {
  previewImage: {
    height: 141,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative'
  },
  playButtonWrapper: {
    position: 'absolute',
    top: 42,
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  favoriteButton: {
    position: 'absolute',
    top: 11,
    right: 16
  },
  content: {
    padding: '16px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  categoryContainer: {
    minHeight: 21.6
  },
  title: {
    paddingTop: 10,
    color: '#43434a',
    fontSize: 19,
    fontFamily: 'Muli',
    fontWeight: '800',
    textAlign: 'left'
  },
  description: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    minHeight: '57.6px',
    textAlign: 'left',
    marginTop: 3,
    color: '#999ea7',
    fontFamily: 'Muli',
    fontSize: 13,
    fontWeight: '600'
  },
  price: {
    color: 'rgb(67, 67, 74)',
    fontSize: 14,
    fontFamily: 'Muli',
    fontWeight: '600'
  },
  originalPrice: {
    color: '#e68486',
    fontSize: 14,
    fontFamily: 'Muli',
    fontWeight: '600',
    textDecoration: 'line-through',
    marginLeft: 6
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 8,
    width: '100%'
  },
  actionsButton: {
    fontSize: 12,
    fontFamily: 'Muli',
    fontWeight: '700',
    letterSpacing: 'normal',
    padding: '10px 7px',
    borderRadius: 4,
    width: 'calc(50% - 4px)'
  }
}

export default Course
