import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import bg1 from '../assets/images/sales.webp';
import bg2 from '../assets/images/ecommerce.webp';
import bg3 from '../assets/images/finances.webp';
import bg4 from '../assets/images/marketing.webp';
import bg5 from '../assets/images/leadership.webp';

import icon1 from '../assets/svgs/megaphone.svg';
import icon2 from '../assets/svgs/laptop.svg';
import icon3 from '../assets/svgs/money-bag.svg';
import icon4 from '../assets/svgs/dart.svg';
import icon5 from '../assets/svgs/thunder.svg';

function CategoryItem({index, id, name, background, filler=false}) {

  const history = useHistory();

  const defaultAssets = [
    { background: bg1, icon: icon1 },
    { background: bg2, icon: icon2 },
    { background: bg3, icon: icon3 },
    { background: bg4, icon: icon4 },
    { background: bg5, icon: icon5 }
  ]

  const getIndex = (index) => {
    let done = false
    let res = 0
    let i = index
    while(!done){
      if(i <= 4){
        res = i
        done = true
      }else{
        i -= 5
      }
    }
    return res
  }

  return (
    <>
      {
        filler ?
          <Container />
        :
          <Container
            onClick={()=>history.push(`/category/${id}`)} 
            style={{backgroundImage: `url(${background ? background : defaultAssets[getIndex(index)].background})`}}
            className="touchable-opacity"
          >
            <div style={styles.filter}>
              <Name>{name}</Name>
            </div>
          </Container>
      }
    </>
  );
}

export const Container = styled.div`
  width: 230px;
  height: 230px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 6px;
  @media (max-width: 1400px) {
    width: 200px;
    height: 200px;
  }
  @media (max-width: 480px) {
    width: 175px;
    height: 175px;
  }
`

export const Name = styled.div`
  padding-top: 36px;
  color: white;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  font-family: Muli;
  padding-bottom: 30px;
  @media (max-width: 480px) {
    padding-top: 13px;
  }
`

const styles = {
  filter: {
    width: '100%',
    height: '100%',
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  icon: {
    width: 58,
    height: 58
  }
}

export default CategoryItem;
