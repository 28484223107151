import React from 'react';
import { BlocksControls, InlineBlocks, InlineImage } from 'react-tinacms-inline';
import { linkBlock } from '../Link/Link';
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components';
import facebook from '../../assets/svgs/facebook.svg'
import instagram from '../../assets/svgs/instagram.svg'
import twitter from '../../assets/svgs/twitter.svg'
import youtube from '../../assets/svgs/youtube.svg'

function Footer({index, data}) {
  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
        <Container style={{backgroundImage: `url(${data.background})`}}>
            <div style={{height: 40}}>
                <InlineImage
                    name="logo"
                    parse={media => {
                        return media.previewSrc
                    }}
                    previewSrc={formValues => {
                        return formValues;
                    }}
                >
                    {
                    (props) => (
                        <img alt="footer-icon" style={{height: 40}} src={props.src}/>
                    )
                    }
                </InlineImage>
            </div>
            <MenuContainer>
                <InlineBlocks
                    name="links"
                    blocks={LINK_BLOCKS}
                    className={'footer-menu'}
                    itemProps={{
                        whiteMode: true
                    }}
                />
            </MenuContainer>
            <Socials>
                <SocialsItem alt="facebook-icon" src={facebook} />
                <SocialsItem alt="twitter-icon" src={twitter} />
                <SocialsItem alt="instagram-icon" src={instagram} />
                <SocialsItem alt="youtube-icon" src={youtube} />
            </Socials>
        </Container>
    </BlocksControls>
  );
}

const LINK_BLOCKS = {
    link: linkBlock,
};


const Container = styled.div`
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 100px;
    background-size: cover;
    background-repeat: no-repeat;
`

const MenuContainer = styled.div`
    display: flex;
    align-items: center;
    & .footer-menu{
        display: flex;
        align-items: center;
    }
`

const Socials = styled.div`
    display: flex;
`

const SocialsItem = styled.img`
    width: 20px;
    height: 20px;
    margin: 0px 10px;
    cursor: pointer;
    transition: opacity 0.4s;
    &:hover{
        opacity: 0.7;
    }
`

export const FooterTemplate = {
    "label": "Footer",
    "key": "footer",
    "fields": fields,
    "defaultItem": defaults
};
  
export const blockFooter = {
    Component: Footer,
    template: FooterTemplate,
}