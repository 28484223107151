import network from '../utils/network'

export const getCourses = async (
  site,
  filter = null,
  isAuthenticated = false
) => {
  try {
    const { data } = await network.get(
      `${!isAuthenticated ? `public/` : ``}site/${site}/course${
        filter
          ? `?filterName=${filter.filterName}&filterValue=${filter.filterValue}`
          : ``
      }`
    )
    return data
  } catch (error) {
    console.error('getCourses', error)
    throw error
  }
}

export const getEvents = async (
  site,
  filter = null,
  isAuthenticated = false
) => {
  try {
    const { data } = await network.get(
      `site/${site}/webinar${
        filter
          ? `?filterName=${filter.filterName}&filterValue=${filter.filterValue}`
          : ``
      }`
    )
    return data
  } catch (error) {
    console.error('getEvents', error)
    throw error
  }
}

export const getMyCourses = async site => {
  try {
    const data = await network.get(`site/${site}/mycourses`)
    return data
  } catch (error) {
    console.error('getMyCourses', error)
    throw error
  }
}

export const getLikedCourses = async site => {
  try {
    const { data } = await network.get(`site/${site}/coursesliked`)
    return data
  } catch (error) {
    console.error('getLikedCourses', error)
    throw error
  }
}

export const getCourse = async (site, id, session = false) => {
  try {
    if (session) {
      const { data } = await network.get(`site/${site}/mycourses/${id}`)
      return data
    } else {
      const { data } = await network.get(`public/site/${site}/mycourses/${id}`)
      return data
    }
  } catch (error) {
    console.error('getCourse', error)
    throw error
  }
}

export const getEvent = async (site, id, session = false) => {
  try {
    const { data } = await network.get(`site/${site}/mywebinars/${id}`)
    return data;
  } catch (error) {
    console.error('getCourse', error)
    throw error
  }
}

export const getNextCourse = async (site, id, body) => {
  try {
    const { data } = await network.post(`site/${site}/mycourses/${id}`, body)
    return data
  } catch (error) {
    console.error('getNextCourse', error)
    throw error
  }
}

export const postUserContent = async (site, id, body) => {
  try {
    const { data } = await network.post(
      `site/${site}/mycourses/${id}/usercontent`,
      body
    )
    return data
  } catch (error) {
    console.error('postUserContent', error)
    throw error
  }
}

export const getCommentsContent = async (site, id, content, page) => {
  try {
    const { data } = await network.get(
      `site/${site}/course/${id}/comments?contentId=${content}&page=${page}`
    )
    return data
  } catch (error) {
    console.error('getCommentsContent', error)
    throw error
  }
}

export const postComment = async (site, id, body) => {
  try {
    const { data } = await network.post(
      `site/${site}/course/${id}/comments`,
      body
    )
    return data
  } catch (error) {
    console.error('postComment', error)
    throw error
  }
}

export const getLikes = async site => {
  try {
    const { data } = await network.get(`site/${site}/likes`)
    return data
  } catch (error) {
    console.error('getLikes', error)
    throw error
  }
}

export const getFavorites = async site => {
  try {
    const { data } = await network.get(`site/${site}/favorites`)
    return data
  } catch (error) {
    console.error('getFavorites', error)
    throw error
  }
}

export const likeCourse = async (site, course, body) => {
  try {
    const { data } = await network.post(
      `site/${site}/course/${course}/like`,
      body
    )
    return data
  } catch (error) {
    console.error('likeCourse', error)
    throw error
  }
}

export const deleteLike = async (site, course) => {
  try {
    await network.del(`site/${site}/course/${course}/like`)
  } catch (error) {
    console.error('deleteLike', error)
    throw error
  }
}

export const favoriteCourse = async (site, body) => {
  try {
    const { data } = await network.post(`site/${site}/favorites`, body)
    return data
  } catch (error) {
    console.error('favoriteCourse', error)
    throw error
  }
}

export const deleteFavorite = async (site, course) => {
  try {
    await network.del(`site/${site}/favorites/${course}`)
  } catch (error) {
    console.error('deleteFavorite', error)
    throw error
  }
}
