import { CircularProgress } from '@material-ui/core';
import React from 'react';

import heartFull from '../../assets/svgs/heart-full.svg';
import heartOutline from '../../assets/svgs/heart-outline.svg';

function FavoriteButton(props) {

  const handleClick = (e) => {
    return !props.loading && props.onPress(e)
  } 

  return (
    <button className="touchable-opacity" onClick={(e)=>handleClick(e)} style={{...styles.button, ...props.styles}}>
        {
          props.loading ? (
            <CircularProgress style={styles.loader}/>
          ) :
          <img style={styles.image} alt="favorite-icon" src={props.favorite ? heartFull : heartOutline} />
        }
    </button>
  );
}

const styles = {
  button: {
    width: 28,
    height: 28,
    borderRadius: 4,
    border: 'none',
    boxShadow: '0px 0px 2px 0px #00000057',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  image: {
    width: 16,
    height: 16
  },
  loader: {
    color: '#00A8D8',
    width: 16,
    height: 16
  }
}

export default FavoriteButton;