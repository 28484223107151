import React from 'react'
import styled from 'styled-components';
import searchIcon from '../assets/svgs/search.svg';

export default function SearchBar({placeholder}) {
    return (
      <Container>
          <Input placeholder={placeholder} />
          <IconContainer>
            <Icon alt="search-icon" src={searchIcon} />
          </IconContainer>
      </Container>
    );
  }
  
const Container = styled.div`
    display: flex;
    background-color: #233042;
`

const Input = styled.input`
  background-color: white;
  border: #f1f1f1 2px solid;
  border-right: 0px;
  padding: 0px 20px;
  height: 42px;
  width: 350px;
  font-family: Arial;
  font-weight: 600;
  color: #908e8e;
  transition: outline 0.4s;
  &:focus{
    outline: none;
  }
  &::-webkit-input-placeholder {
    font-size: 16px;
    color: #908e8e;
    font-weight: 600;
    font-family: Arial;
  }
  &::-moz-placeholder {
    font-size: 16px;
    color: #908e8e;
    font-weight: 600;
    font-family: Arial;
  }
  &:-ms-input-placeholder {
    font-size: 16px;
    color: #908e8e;
    font-weight: 600;
    font-family: Arial;
  }
  &:-moz-placeholder {
    font-size: 16px;
    color: #908e8e;
    font-weight: 600;
    font-family: Arial;
  }  
`

const IconContainer = styled.div`
  background-color: white;
  height: 42px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #f1f1f1 2px solid;
  border-left: 0px;
`

const Icon = styled.img`
  width: 36px;
  height: 36px;
`