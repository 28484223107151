import background from '../../assets/images/cover.png';

export default {
    "_template": "blockFormImage",
    "background": background,
    "title": "Lorem ipsu dolor sit",
    "description": "Dolore magna aliqua ut enim ad min veniam quis nostrud dedsden parar to",
    "buttons": [
        {
            "_template": "button",
            "label": "Call To Action",
            "style": "primary",
            "link": "#"
        }
    ]
}