import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown'
import { BlocksControls, InlineText } from 'react-tinacms-inline';
import defaults from './defaults'
import fields from './fields'
import minus from '../../assets/svgs/minus.svg'
import plus from '../../assets/svgs/plus.svg'
import styled from 'styled-components'

function Faq({ index, data }) {

    const { items } = data;

    const [selectedQuestion, setSelectedQuestion] = useState(null)

    const toggleSelectedQuestion = (index) => {
        selectedQuestion === index ? setSelectedQuestion(null) : setSelectedQuestion(index)
    }

    return (
        <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
            <Container style={{backgroundColor: data.backgroundColor}}>
                <div style={styles.faqSection}>
                    {
                        data.title !== '' && (
                            <h2 style={{marginBottom: 0, ...styles.title, fontSize: 30, textAlign: 'left', color: data.textColor }}>
                                <InlineText name="title" focusRing={false} />
                            </h2>
                        )
                    }
                    {
                        data.subtitle !== '' && (
                            <h3 style={{marginBottom: 0, ...styles.subtitle, fontSize: 25, textAlign: 'left', color: data.textColor }}>
                                <InlineText name="subtitle" focusRing={false} />
                            </h3>
                        )
                    }
                    <div style={styles.faqList}>
                        {
                            items && items.map((element, index) => {
                                return (
                                    <FaqItem key={index} onClick={() => toggleSelectedQuestion(index)}>
                                        <div style={styles.faqContent}>
                                            <h3 style={styles.faqTitle}>{element.title}</h3>
                                            {
                                                selectedQuestion === index && (
                                                    <>
                                                        <div style={{width: '100%', height: 28}}/>
                                                        <ReactMarkdown 
                                                            skipHtml={true} 
                                                            children={element.text} 
                                                        />
                                                    </>
                                                )
                                            }
                                        </div>
                                        <div style={styles.faqControl}>
                                            <img style={styles.faqControlIcon} alt="plus-icon" src={selectedQuestion === index ? minus : plus} />
                                        </div>
                                    </FaqItem>
                                )
                            })
                        }
                    </div>
                </div>
            </Container>
        </BlocksControls>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 100px;
    @media (max-width: 980px){
        padding: 30px;
    }
`

const FaqItem = styled.div`
    display: flex;
    background-color: white;
    width: 48%;
    height: fit-content;
    margin-bottom: 30px;
    box-shadow: 0px 0px 12px -4px #b7b7b7;
    transition: height 0.4s;
    @media (max-width: 980px){
        width: 100%;
    }
`

const styles = {
    title: {
        fontWeight: '800',
        fontSize: 34,
        fontFamily: 'Arial'
    },
    subtitle: {
        fontWeight: '600',
        fontSize: 19,
        fontFamily: 'Arial',
        marginTop: 14,
        lineHeight: '30px',
        marginBottom: 34
    },
    faqSection: {
        width: '100%'
    },
    faqList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    faqContent: {
        flexGrow: 1,
        flexShrink: 1,
        textAlign: 'left',
        padding: 28,
        fontSize: 18,
        fontWeight: '500',
        fontFamily: 'Arial',
        color: '#717171'
    },
    faqTitle: {
        marginBottom: 0,
        color: 'rgb(67, 67, 74)',
        fontWeight: '800',
        fontSize: 16,
        fontFamily: 'Arial'
    },
    faqControl: {
        flexGrow: 0,
        flexShrink: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 34px'
    },
    faqControlIcon: {
        width: 18,
        height: 18
    },
    faqAnswer: {
        color: 'rgb(158, 162, 180)',
        paddingTop: 10,
        transition: 'transform 0.4s'
    }
};

export const FaqTemplate = {
    "label": "Faq",
    "key": "Faq",
    "fields": fields,
    "defaultItem": defaults
};

export const blockFaq = {
    Component: Faq,
    template: FaqTemplate,
};