import React from 'react';
import { InlineTextarea, BlocksControls, InlineBlocks } from 'react-tinacms-inline';
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components';
import { buttonBlock } from '../Button/Button';

function HeroCarousel({ index, data }) {
  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <Container style={{backgroundImage: `url(${data.background})`}}>
        <Content>
          <Title style={{marginBottom: 0}}>
            <InlineTextarea name="title" focusRing={false} />
          </Title>
          <Description>
            <InlineTextarea name="description" focusRing={false} />
          </Description>
          <Actions>
            <InlineBlocks
              name="buttons"
              blocks={BUTTON_BLOCKS}
              className={'hero-carousel-inline-blocks'}
            />
          </Actions>
        </Content>
      </Container>
    </BlocksControls>
  );
}

const BUTTON_BLOCKS = {
  button: buttonBlock,
};

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const Content = styled.div`
  width: 60%;
  padding: 70px 100px;
  text-align: left;
  color: white;
  @media (max-width: 768px) {
    padding: 36px 30px 140px;
  }
`

const Title = styled.h2`
  color: white;
  font-size: 55px;
  line-height: 72px;
  font-weight: 700;
  font-family: Arial;
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 36px;
  }
`

const Description = styled.div`
  font-size: 22px;
  font-family: Arial;
  line-height: 36px;
  padding-top: 24px;
  color: white;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 22px;
    padding-top: 18px;
  }
`

const Actions = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: row;
  & .hero-carousel-inline-blocks{
    display: flex;
    column-gap: 20px;
    row-gap: 16px;
    flex-wrap: wrap;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const HeroCarouselTemplate = {
  "label": "Hero Carousel",
  "key": "heroCarousel",
  "fields": fields,
  "defaultItem": defaults
};

export const blockHeroCarousel = {
  Component: HeroCarousel,
  template: HeroCarouselTemplate,
}