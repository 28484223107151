import React from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'

export default class VideoPlayer extends React.Component {
  componentDidMount () {
    // instantiate Video.js
    this._updateInterval = null
    this.player = videojs(
      this.videoNode,
      {
        controls: true,
        autoplay: false,
        sources: [],
        ...this.props.videoOptions
      },
      function onPlayerReady () {}
    )
    this.player.on('pause', this.pause)
    this.player.on('ended', this.ended)
    this.player.on('playing', this.playing)
    this.player.on('durationchange', this.durationchange)
    this.player.on('play', () => {
      this.props.videoOptions.onPlay()
      this._updateInterval = setInterval(() => {
        this.timeupdate()
      }, 5000)
    })
    this.player.on('pause', () => {
      clearInterval(this._updateInterval)
    })
    this.player.currentTime(this.props.minutes)
  }

  // destroy player on unmount
  componentWillUnmount () {
    if (this.player) {
      // this.player.dispose()
    }
  }

  timeupdate = () => {
    this.props.videoOptions.timeupdate && this.props.videoOptions.timeupdate(this.player.currentTime())
  }

  ended = () => {
    this.props.videoOptions.ended && this.props.videoOptions.ended()
  }

  durationchange = () => {
    this.props.durationchange && this.props.durationchange()
  }

  playing = () => {
    this.props.playing && this.props.playing()
  }

  pause = () => {
    this.props.pause && this.props.pause()
  }

  setSource = url => {
    this.player.src(url)
  }

  paused = () => {
    return this.player.paused
  }

  render() {
    return (
      <div data-vjs-player style={{ width: '100%', height: 'calc(100vh - 207px)' }}>
        <video
          ref={node => (this.videoNode = node)}
          className='video-js'
        ></video>
      </div>
    )
  }
}
