import background from '../assets/images/header.webp';

export default {
    "_template": "blockHero",
    "background": background,
    "mobileBackground": background,
    "subtitle": "Cursos de Master Muñoz",
    "title": "Los mejores cursos desde la comodidad de tu casa",
    "description": "A través de este tema y una visión única del microcontenido, pretendemos amplificar el impacto de nuestra comunidad y generar un conducto de oportunidades para nuestra iniciativa educativa.",
    "showButtons": true,
    "buttons": [
        {
            "_template": "button",
            "label": "Ver Todos Los Cursos",
            "style": "primary",
            "link": "course-catalog"
        },
        {
            "_template": "button",
            "label": "Empezar Ahora",
            "style": "secondary",
            "link": "sign-in"
        }
    ],
    "contentPadding": 70
}