import background from '../assets/images/signup.webp';

export default {
    "_template": "blockForgotPasswordVerification",
    "background": background,
    "title": "Establecer nueva contraseña",
    "description": "Ingrese el código de verificación enviado a su dirección de correo electrónico a continuación, su dirección de correo electrónico y una nueva contraseña.",
    "button": "Restablecer contraseña",
    "signinmessage": "Regresar a",
    "signin": "Iniciar sesión"
}