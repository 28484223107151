import React, { createContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { data as landingsData } from '../dataLanding'
import { data as templateData } from '../data'
import { init } from '../helpers/Cognito'
import network from '../utils/network'

export const PersistentContext = createContext()

export const PersistentContextProvider = props => {

  const {params} = props
  const {page, param1} = params

  const history = useHistory();
  const [id, setId] = useState(null)
  const [type, setType] = useState(null)
  const [data, setData] = useState(null)
  const [config, setConfig] = useState({})
  const [loading, setLoading] = useState(true)
  const [keys, setKeys] = useState(window.SITE_DATA)
  const [production, setProduction] = useState(true)

  useEffect(() => {
    init(keys)
  }, [])

  useEffect(() => {
    preload()
    window.scrollTo(0, 0)
  }, [page, param1])

  const preload = async () => {
    try {
      await setLoading(true)
      if (keys.id) {
        let newUrl = page
        if (param1) {
          switch (page) {
            case 'course-detail':
              newUrl = `${page}-${param1}`
              break;
            case 'event-detail':
              newUrl = `${page}-${param1}`
              break;
            default: 
              newUrl = page
              break;
          }
        }
        const { data } = await network.get(`public/site/${keys.id}/pages/${newUrl}`)
        await setConfig(data.config)
        await setType(data.type)
        await setData(data.data)
        await setLoading(false)
      }
    } catch (error) {
      console.log(error)
      if (templateData[page]) {
        await setType(null)
        await setData(templateData[page])
        await setLoading(false)
      } else {
        history.replace('/not-found')
      }
    }
  }

  const getItemDataById = (key, id) => {
    const newKey = `${key}-${id}`
    if (data && data[newKey] && Object.keys(data[newKey]).length) {
      return data[newKey]
    } else if (data && data[key] && Object.keys(data[key]).length) {
      return data[key]
    } else {
      return templateData[key]
    }
  }

  const getItemConfig = (key) => {
    if (config && config[key] && Object.keys(config[key]).length) {
      return config[key];
    } else if (templateData) {
      return templateData[key];
    }
  }

  const getItemData = (key, landing = false) => {
    if (data && data[key] && Object.keys(data[key]).length) {
      return data[key]
    } else if (landing) {
      return landingsData[key]
    } else if (templateData) {
      return templateData[key]
    }
  }

  const saveContent = async (newData, key = null) => {}

  const saveSiteConfig = async (newData, key = null) => {}

  return (
    <PersistentContext.Provider
      value={{
        data,
        page,
        keys,
        type,
        loading,
        production,
        templateData,
        getItemData,
        saveContent,
        getItemConfig,
        saveSiteConfig,
        getItemDataById,
      }}
    >
      {props.children}
    </PersistentContext.Provider>
  )
}
