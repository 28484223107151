import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import ContactFormSentModal from '../components/ContactFormSentModal';
import ContactForm from '../components/forms/ContactForm';
import { useMediaQuery } from '../helpers/Hooks';
import '../App.css';

function Contact({ index, data, forms }) {

  const isMobile = useMediaQuery('(max-width: 980px)');

  const [formSentModalVisible, setFormSentModalVisible] = useState(false)


  let form = null
  
  if (data && data.forms && forms && forms.length) {
    const index = forms.findIndex(item => item.id === data.forms)
    form = forms[index]
  }
  
  return (
    <div className="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contacto</title>
        <link rel="canonical" href="https://store.l3rn.io/contact" />
      </Helmet>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {
              isMobile ? <></> :
              <div 
                style={{
                  ...styles.featuredImage, 
                  backgroundImage: `url(${data.background})`
                }}
              />
          }
          <div 
            style={{...styles.contactWrapper, 
              padding: isMobile ? '35px 30px' : '75px 30px'
            }}
          >
            {
              form && (
                <ContactForm
                  form={form}
                  button={data.button}
                  onSuccess={()=>setFormSentModalVisible(true)}
                />
              )
            }
          </div>
      </div>
      {
          !isMobile ? <></> :
          <div 
            style={{
              ...styles.featuredImageMobile, 
              backgroundImage: `url(${data.background})`
            }} 
          />
      }
      <ContactFormSentModal
        visible={formSentModalVisible}
        onCloseModal={()=>setFormSentModalVisible(false)}
      />
    </div>
  )
}

const styles = {
  contactWrapper: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    minHeight: '80vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  featuredImage: {
    width: 760,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  featuredImageMobile: {
    width: '100%',
    height: 200,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  }
}

export default Contact;