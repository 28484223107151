import React from 'react'
import { BlocksControls, InlineBlocks, InlineImage } from 'react-tinacms-inline'
import { linkSectionBlock } from '../LinkSection/LinkSection'
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components'
import MainButton from '../../components/buttons/MainButton'

function Footer({index, data}) {
  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
        <Container>
            <div style={{height: 40}}>
                <InlineImage
                    name="logo"
                    parse={media => {
                        return media.previewSrc
                    }}
                    previewSrc={formValues => {
                        return formValues;
                    }}
                >
                    {
                        (props) => (
                            <Logo alt="footer-logo" src={props.src}/>
                        )
                    }
                </InlineImage>
            </div>
            <MenuContainer>
                <InlineBlocks
                    name="linkSections"
                    blocks={LINK_SECTION_BLOCKS}
                    className={'footer-link-sections'}
                />
            </MenuContainer>
            <Contact>
                <Title>
                    {data.contactTitle}
                </Title>
                <Description>
                    {data.contactDescription}
                </Description>
                <Form>
                    <Input placeholder="Email"/>
                    <MainButton  
                        label="Send"
                        onPress={(e)=>alert('Send')}
                        styles={{
                            padding: '8px 24px',
                            borderRadius: 0,
                            fontSize: 14,
                            fontWeight: 600
                        }}
                    />
                </Form>
            </Contact>
        </Container>
    </BlocksControls>
  );
}

const LINK_SECTION_BLOCKS = {
    linkSection: linkSectionBlock,
};


const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 100px 100px;
    background-color: #f7faff;
    background-size: cover;
    background-repeat: no-repeat;
`

const Logo = styled.img`
    width: 180px;
    height: 100%;
    max-height: 40px;
    object-fit: contain;
    object-position: left;
`

const MenuContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-shrink: 1;
    flex-grow: 1;
    & .footer-link-sections{
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
    }
`

const Contact = styled.div`
    display: flex;
    flex-direction: column;
`

const Title = styled.div`
    color: #152647;
    font-family: Arial;
    font-weight: 700;
    font-size: 14px;
    text-align: left;
`

const Description = styled.div`
    font-family: Arial;
    color: rgb(99, 102, 103);
    font-weight: 600;
    margin-top: 26px;
    text-align: left;
`

const Form = styled.div`
    margin-top: 20px;
    display: flex;
`

const Input = styled.input`
    background-color: white;
    border: none;
    padding: 0px 20px;
    height: 42px;
    width: 250px;
    font-family: Arial;
    font-weight: 600;
    color: #908e8e;
    transition: outline 0.4s;
    &:focus{
        outline: rgb(63, 50, 222) 1px solid;
    }
    &::-webkit-input-placeholder {
        font-size: 16px;
        color: #908e8e;
        font-weight: 600;
        font-family: Arial;
    }
    &::-moz-placeholder {
        font-size: 16px;
        color: #908e8e;
        font-weight: 600;
        font-family: Arial;
    }
    &:-ms-input-placeholder {
        font-size: 16px;
        color: #908e8e;
        font-weight: 600;
        font-family: Arial;
    }
    &:-moz-placeholder {
        font-size: 16px;
        color: #908e8e;
        font-weight: 600;
        font-family: Arial;
    }  
`

export const FooterTemplate = {
    "label": "Footer",
    "key": "footer",
    "fields": fields,
    "defaultItem": defaults
};
  
export const blockFooter = {
    Component: Footer,
    template: FooterTemplate,
}