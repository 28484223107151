import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { FormLabel } from '@material-ui/core'
import { BlocksControls, InlineTextarea } from 'react-tinacms-inline'
import defaults from './ForgotPasswordEmailSent.defaults'
import MainButton from '../components/buttons/MainButton'
import fields from './ForgotPasswordEmailSent.fields'
import { useMediaQuery } from '../helpers/Hooks'
import { useSnackbar } from 'notistack'
import { makeToast } from '../utils'
import '../App.css'

function ForgotPasswordEmailSent ({ index, data }) {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const isMobile = useMediaQuery('(max-width: 980px)')

  const [email, setEmail] = useState('sealtiel92@hotmail.com')
  const [error, setError] = useState(null)

  const styles = {
    container: {
      backgroundColor: 'white',
      boxShadow: '0px 0px 12px 8px #0000001a'
    },
    forgotPasswordWrapper: {
      flexGrow: 1,
      flexShrink: 1,
      display: 'flex',
      padding: isMobile ? '35px 30px' : '75px 30px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f7f8fa'
    },
    featuredImage: {
      width: 650,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${data.background})`
    },
    title: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: 30,
      marginBottom: 15
    },
    subtitle: {
      fontFamily: 'Montserrat',
      fontSize: 17,
      fontWeight: 600,
      color: '#959ca8',
      lineHeight: '28px',
      marginBottom: 20
    },
    image: {
      borderRadius: '50%',
      width: 100,
      boxShadow: '2px 2px 25px 0px #ddd'
    }
  }

  const onSubmit = async () => {
    if (email.trim() !== '') {
      history.push('/forgot-password-email-sent')
    } else {
      makeToast(enqueueSnackbar, 'Email requerido', 'error')
    }
  }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <div className='page'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Recuperar Contraseña</title>
          <link rel='canonical' href='https://store.l3rn.io/forgot-password' />
        </Helmet>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: 'calc(100vh - 206.99px)'
          }}
        >
          <div style={styles.forgotPasswordWrapper}>
            <div
              className='forgot-password-form-container'
              style={styles.container}
            >
              <div className='forgot-password-form-title' style={styles.title}>
                <InlineTextarea name='title' focusRing={false} />
              </div>
              <div style={styles.subtitle}>
                <InlineTextarea name='description' focusRing={false} />
              </div>
              <MainButton
                onPress={() => history.push('/sign-in')}
                style={{ fontSize: 16, padding: 10 }}
                label="Iniciar Sesión"
              />
              <div style={{margin: 20}}>
                <FormLabel style={{ fontSize: 14, fontWeight: '500' }}>
                  No recibí el correo{' '}
                  <label
                    className='link link-bold'
                    style={{ fontSize: 14, fontWeight: '600', cursor: 'pointer' }}
                    onClick={() => history.goBack()}
                  >
                    Enviar Nuevamente
                  </label>
                </FormLabel>
              </div>
            </div>
          </div>
          {isMobile ? <></> : <div style={styles.featuredImage} />}
        </div>
      </div>
    </BlocksControls>
  )
}

export const ForgotPasswordEmailSentTemplate = {
  label: 'ForgotPasswordEmailSent',
  key: 'ForgotPasswordEmailSent',
  fields: fields,
  defaultItem: defaults
}

export const blockForgotPasswordEmailSent = {
  Component: ForgotPasswordEmailSent,
  template: ForgotPasswordEmailSentTemplate
}
