import React, { useState } from 'react';
import TouchableOpacity from './TouchableOpacity';
import styled from 'styled-components';
import user from '../assets/svgs/user.svg';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

function UserMenu({containerStyle, onRedirect, onLogout}) {

    const [visible, setVisible] = useState(false)

    const toggle = () => {
        setVisible(!visible)
    }

    const redirect = (path) => {
      onRedirect(path)
      toggle()
    }

    const handleCurtainClick = (e) => {
      e.stopPropagation()
      toggle()
    }

  return (
    <div style={{...styles.container, ...containerStyle}}>

        <TouchableOpacity containerStyle={{display:'flex',flexWrap: 'no-wrap'}} onPress={()=>toggle()}>
            <img style={styles.icon} alt="user-icon" src={user} />
            {
                visible ?
                    <KeyboardArrowUpIcon style={styles.dropdownIconStyle}/>
                : 
                    <KeyboardArrowDownIcon style={styles.dropdownIconStyle}/>    
            }
        </TouchableOpacity>
        
        <Dropdown style={{ visibility: visible ? 'visible' : 'hidden' }}>
            <TouchableOpacity
                containerStyle={styles.button}
                onPress={()=>redirect('/my-courses')}
            >
                Mis cursos
            </TouchableOpacity>
            <TouchableOpacity
                containerStyle={styles.button}
                onPress={()=>onLogout()}
            >
                Cerrar Sesión
            </TouchableOpacity>
        </Dropdown>
        {
            visible && (
                <div onClick={(e)=>handleCurtainClick(e)} style={styles.curtain} />
            )
        }
    </div>
  );
}

export const Dropdown = styled.div`
    top: 40px;
    right: 0;
    background-color: white;
    position: absolute;
    z-index: 2;
    min-width: 210px;
    max-height: 300px;
    overflow-y: scroll;
    border-radius: 4px;
    padding: 10px 30px;
    box-shadow: rgb(0 0 0 / 24%) 0px 0px 12px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #989898;
    }
    ::-webkit-scrollbar-corner {
        background: #0c0c0c;
    }
`;

const styles = {
  container: {
    position: 'relative', 
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    width: 24,
    height: 24
  },
  dropdownIconStyle: {
    color: '#7a7b92',
    width: 18, 
    marginLeft: 2
  },
  button: {
    fontFamily: 'Muli',
    color: 'rgb(122, 123, 146)',
    fontWeight: 'bold',
    padding: '10px 0px',
    width: '100%',
    textAlign: 'left'
  },
  curtain: { 
    position: 'fixed', 
    top: 0, 
    left: 0, 
    width: '100vw', 
    height: '100vh', 
    zIndex: 1
  }
}

export default UserMenu;