import network from '../utils/network';

export const getLandings = async (site) => {
    try {
      const { data } = await network.get(`site/${site}/pages?type=Landing&offset=0`);
      return data;
    } catch (error) {
      console.error('getLandings', error);
      return error;
    }
}