import React, { useContext, useEffect, useState } from 'react'
import heart from '../assets/svgs/heart.svg'
import heartFull from '../assets/svgs/heart-full.svg'
import { PersistentContext } from '../context/index'
import { CourseContext } from '../context/CourseContext'
import { SessionContext } from '../context/SessionContext'
import { ShopContext } from '../context/ShopContext'
import TouchableOpacity from './TouchableOpacity'
import styled from 'styled-components'
import CourseDropdownItem from './CourseDropdownItem'
import MainButton from './buttons/MainButton'
import { CircularProgress } from '@material-ui/core'

function FavoritesIcon({onRedirect, containerStyle, hoverEnabled}) {

  const [visible, setVisible] = useState(false)

  const { likedCourses, onLoadLikedCourses, loading } = useContext(CourseContext);
  const { id, production, keys } = useContext(PersistentContext);
  const { isSessionLoaded, isAuthenticated } = useContext(SessionContext);
  const { addProductToCart, removeProductFromCart, isProductInCart } = useContext(ShopContext);

  const toggle = (option) => {
      setVisible(option ? option : !visible)
  }

  const redirect = (path) => {
    onRedirect(path)
    toggle(false)
  }

  const hasItems = () => {
    return likedCourses ? likedCourses.length > 0 ? true : false : false
  }

  const onAddProductToCart = (course) => {
    if (isProductInCart(course.id)) {
      removeProductFromCart(course.id)
    } else {
      addProductToCart(course)
    }
  }

  useEffect(()=>{
    if(production && keys){
        if(isSessionLoaded){
            if(isAuthenticated){
                const siteId = production ? keys.id : id 
                onLoadLikedCourses(siteId)
            }
        }
    }
  },[isSessionLoaded])

  return (
    <>
      <TouchableOpacity
        opacity={1}
        containerStyle={{ position:'relative', zIndex:2 }}
        onHoverEnter={()=> hoverEnabled ? toggle(true) : null}
        onHoverLeave={()=> hoverEnabled ? toggle(false) : null}
      >
        <Container style={containerStyle}>
          <TouchableOpacity
            containerStyle={{display: 'flex'}}
            onPress={()=>redirect('/my-favorites')}
          >
            <Icon alt="heart" src={hasItems() ? heartFull : heart} />
          </TouchableOpacity>
          {
            hoverEnabled && (
              <Dropdown style={{ visibility: visible ? 'visible' : 'hidden' }}>
                {
                  loading ?
                    <EmptyState style={{padding: 30}}> 
                      <CircularProgress style={{ color: '#00A8D8' }}/>
                    </EmptyState>
                  :
                    hasItems() && (
                      <>
                        {
                          likedCourses.length <= 0 && (
                            <EmptyState>
                              Aún no has agregado cursos a tus favoritos
                            </EmptyState>
                          )
                        }
                        {
                          likedCourses.map((item, index) => (
                            <CourseDropdownItem
                              key={index}
                              id={item.id}
                              onPress={()=>redirect(`/course-detail/${item.id}`)}
                              onAddProductToCart={()=>onAddProductToCart(item)}
                              background={item.background}
                              title={item.title}
                              categoryName={item.categoryName}
                              price={item.price}
                              originalPrice={item.originalPrice}
                              isPurchased={item.isPurchased}
                            />
                          ))
                        }
                        <div style={{width: '100%', padding: 16}}>
                          <MainButton
                            onPress={() => redirect('/my-favorites')}
                            type='primary'
                            label='Ir a mis favoritos'
                            styles={{ width: '100%', padding: 10}}
                          />
                        </div>
                      </>
                    )
                }
              </Dropdown>
            )
          }
          {
            visible && (
              <Anchor/>
            )
          }
        </Container>
      </TouchableOpacity>
      {
        visible && (
          <TouchableOpacity
            opacity={1}
            containerStyle={ {position: 'fixed', zIndex: 1, background: 'transparent', width: '100%', height: '100%', left: 0, top: 0}}
            onHoverEnter={()=>toggle(false)}
            onMouseMove={()=>toggle(false)}
          />
        )
      }
    </>
  );
}

const Container = styled.div`
  position: relative;
`

export const Dropdown = styled.div`
    top: 28px;
    right: 0;
    background-color: white;
    position: absolute;
    width: 320px;
    max-height: 400px;
    overflow-y: scroll;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 24%) 0px 0px 12px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #989898;
    }
    ::-webkit-scrollbar-corner {
        background: #0c0c0c;
    }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`

const EmptyState = styled.div`
  width: 100%;
  padding: 30px 30px 0px 30px;
  height: 50px;
  display: flex; 
  justify-content: center; 
  align-items: center;
  color: rgb(67, 67, 74);
  font-size: 14px;
  font-family: Muli;
  font-weight: 600;
`

const Anchor = styled.div`
  position: absolute;
  width: 45px;
  height: 8px;
  bottom: -8px;
  right: 0px;
`

export default FavoritesIcon;
