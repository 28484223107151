import React from 'react'
import styled from 'styled-components';
import userIcon from '../assets/svgs/user.svg';
import chevronDownIcon from '../assets/svgs/chevron-down.svg';

export default function UserMenu({containerStyle}) {
    return (
      <Container style={{...containerStyle}}>
          <UserIcon alt="user-icon" src={userIcon} />
          <div style={{display: 'flex', alignItems: 'center'}}>
              <Text>LOG IN</Text>
              <ChevronDownIcon alt="chevron-down-icon" src={chevronDownIcon} />
          </div>
      </Container>
    );
  }
  
const Container = styled.div`
    display: flex;
    align-items: center;
`

const Text = styled.div`
    color: white;
    font-weight: 700;
    font-family: 'Arial';
    font-size: 14px;
    padding: 0px 8px;
`

const UserIcon = styled.img`
  width: 24px;
  height: 24px;
`

const ChevronDownIcon = styled.img`
  width: 8px;
  height: 8px;
`