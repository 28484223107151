import React from 'react';
import { InlineTextarea } from 'react-tinacms-inline';
import Email from './fields/Email';
import SubmitButton from '../buttons/SubmitButton';
import '../../App.css';

function ForgotPasswordForm(props) {

  const styles = {
    container: {
      backgroundColor: 'white',
      boxShadow: '0px 0px 12px 8px #0000001a',
    },

    title: {
      color: '#43434a',
      fontWeight: '800',
      fontFamily: 'Muli',
    },

    subtitle: {
      paddingTop: 8,
      paddingBottom: 21,
      fontWeight: '600',
      fontSize: 17,
      fontFamily: 'Muli',
      color: '#9ea2b4'
    },
    separator: {
      width: '100%',
      height: 1,
      backgroundColor: '#016cff'
    },
    form: {
      paddingTop: 50
    },

    signInMessage: {
      paddingTop: 50,
      fontWeight: '600',
      fontSize: 16,
      fontFamily: 'Muli',
      color: '#8891a6'
    },

    signInLink: {
      fontWeight: 700,
      fontFamily: 'Muli',
      textDecoration: 'underline',
      display: 'block'
    }

  }

  return (
    <div className="forgot-password-form-container" style={styles.container}>
      <div className="forgot-password-form-title" style={styles.title}>
        <InlineTextarea name="title" focusRing={false} />
      </div>
      <div style={styles.subtitle}>
        <InlineTextarea name="description" focusRing={false} />
      </div>
      <div style={styles.separator} />
      { props.error && (<p style={{color: 'red'}}>{props.error}</p>)}
      <form style={styles.form}>
        <Email
          value={props.email}
          onEnter={() => props.onSubmit()}
          onChange={(value) => props.onEmailChange(value)}
        />
      </form>
      <SubmitButton label={props.button} onPress={() => props.onSubmit()} />

      <div style={styles.signInMessage}>
        <InlineTextarea name="signinmessage" focusRing={false} />
        <span onClick={() => window.location.href = '/signin'} className="touchable-opacity" style={styles.signInLink}>
          <InlineTextarea name="signin" focusRing={false} />
        </span>
      </div>

    </div>
  );
}

export default ForgotPasswordForm;