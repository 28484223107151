import background from '../assets/images/not-found-bg.webp'

export default {
    "_template": "templateNotFound",
    "background": background,
    "main": "404",
    "title": "Oops… Página no encontrada",
    "subtitle": "No podemos encontrar la página que estas buscando.",
    "cta": "Porfavor trata de nuevo o regresa a la página principal para seguir navegando",
    "button": "Regresar a Página Principal"
};