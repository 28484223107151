import React from 'react'
import { useHistory } from 'react-router-dom'
import { InlineTextarea } from 'react-tinacms-inline'
import SubmitButton from '../buttons/SubmitButton'
import Password from './fields/Password'
import Email from './fields/Email'
import '../../App.css'

function ChangePasswordForm (props) {
  const history = useHistory()

  const styles = {
    container: {
      backgroundColor: 'white',
      boxShadow: '0px 0px 12px 8px #0000001a'
    },

    title: {
      color: '#43434a',
      fontWeight: '800',
      fontFamily: 'Muli'
    },

    subtitle: {
      paddingTop: 8,
      paddingBottom: 21,
      fontWeight: '600',
      fontSize: 17,
      fontFamily: 'Muli',
      color: '#9ea2b4'
    },
    separator: {
      width: '100%',
      height: 1,
      backgroundColor: '#016cff'
    },
    form: {
      paddingTop: 50
    },

    signInMessage: {
      paddingTop: 50,
      fontWeight: '600',
      fontSize: 16,
      fontFamily: 'Muli',
      color: '#8891a6'
    },

    signInLink: {
      fontWeight: 700,
      fontFamily: 'Muli',
      textDecoration: 'underline',
      display: 'block'
    }
  }

  return (
    <div className='forgot-password-form-container' style={styles.container}>
      <div className='forgot-password-form-title' style={styles.title}>
        <InlineTextarea name='title' focusRing={false} />
      </div>
      <div style={styles.subtitle}>
        <InlineTextarea name='description' focusRing={false} />
      </div>
      <div style={styles.separator} />
      {props.error && <p style={{ color: 'red' }}>{props.error}</p>}
      <form style={styles.form}>
        <Email
          value={props.email}
          onChange={value => props.onChangeEmail(value)}
        />
        <Password
          label="Contraseña vieja"
          value={props.oldpassword}
          onChange={value => props.onChangeOldPassword(value)}
        />
        <Password
          label="Contraseña nueva"
          value={props.password}
          onChange={value => props.onChangePassword(value)}
          containerStyle={{position: 'relative'}}
          onActionClick={() => null}
          validationStatus={props.passwordValidationStatus}
          onEnter={() => props.onSubmit()}
          masking={true}
        />
      </form>
      <SubmitButton label={props.button} onPress={() => props.onSubmit()} />
      <div style={styles.signInMessage}>
        <InlineTextarea name='signinmessage' focusRing={false} />
        <span
          onClick={() => history.push('/sign-in')}
          className='touchable-opacity'
          style={styles.signInLink}
        >
          <InlineTextarea name='signin' focusRing={false} />
        </span>
      </div>
    </div>
  )
}

export default ChangePasswordForm
