import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { InlineForm } from 'react-tinacms-inline';
import { useFormScreenPlugin, useCMS, useForm } from 'tinacms';
import { PersistentContext } from '../context';
import { CourseContext } from '../context';

import '../App.css';
import logo from '../assets/images/logo-alt.webp';
import backgroundImage from '../assets/images/footer-background.webp';
import NavigationBar from '../components/NavigationBar';
import SocialLinks from '../components/SocialLinks';
import { useMediaQuery } from '../helpers/Hooks';
import Disable from '../components/Disable';

import instagram from '../assets/svgs/instagram.svg';
import whatsapp from '../assets/svgs/whatsapp.svg';
import youtube from '../assets/svgs/youtube.svg';
import styled from 'styled-components'
import { getLandings } from '../helpers/pages'

export const FooterdefaultValues = {
  text_color: "#FFF",
  background_color: "#344A6F",
  background: backgroundImage,
  logoFooter: {
    src: logo,
  },
  menuItems: [
    {
      type: 'internalLink',
      link: '/about',
      label: 'Acerca de Nosotros',
    },
    {
      type: 'internalLink',
      link: '/contact',
      label: 'Contacto',
    },
    {
      type: 'internalLink',
      link: '/faq',
      label: 'Preguntas Frecuentes',
    },
  ],
  socialMedia: [
    {
      link: '/instagram',
      logo: instagram,
    },
    {
      link: '/whatsapp',
      logo: whatsapp,
    },
    {
      link: '/youtube',
      logo: youtube,
    },
  ],
};

export function FooterLayout(props) {

  const { saveSiteConfig, getItemConfig, keys, production, id } = useContext(PersistentContext);
  const { onLoadCourses, courses, loading } = useContext(CourseContext)

  const cms = useCMS();
  const [landings, setLandings] = useState([])

  const linkOptions = [
    {
      value: "",
      label: "Ninguno"
    },
    {
      value: "/home",
      label: "Inicio",
    },
    {
      value: "/sign-in",
      label: "Login",
    },
    {
      value: "/sign-up",
      label: "Registro",
    },
    {
      value: "/forgot-password",
      label: "Recuperar Contraseña",
    },
    {
      value: "/contact",
      label: "Contacto",
    },
    {
      value: "/not-found",
      label: "404 No Encontrado",
    },
    {
      value: "/faq",
      label: "Preguntas Frecuentes",
    },
    {
      value: "/about",
      label: "Nosotros",
    },
    {
      value: "/course-catalog",
      label: "Catálogo",
    },
    {
      value: "/cart",
      label: "Carrito de Compras",
    },
    {
      value: "/order-completed",
      label: "Orden Completada",
    },
    {
      value: "/my-courses",
      label: "Mis Cursos",
    },
    {
      value: "/my-favorites",
      label: "Mis Favoritos",
    },
    {
      value: "/category",
      label: "Categorías",
    },
    {
      value: "/terms",
      label: "Términos y Condiciones",
    },
    {
      value: "/legal-advice",
      label: "Aviso Legal",
    },
    {
      value: "/privacy-policy",
      label: "Política de Privacidad",
    },
    {
      value: "/refund-policy",
      label: "Política de Reembolso",
    },
    ...landings.map(landing => {
      return {
        value: `/${landing.title}`,
        label: landing.title
      } 
    })
  ]

  const setUp = () => {

    const response = getItemConfig('footer');

    const formConfig = {
      label: "Footer",
      fields: [
        {
          "label": 'Logo',
          "name": 'logoFooter.src',
          "component": 'image',
          "parse": media => {
            return media.previewSrc
          },
          "previewSrc": formValues => {
            return formValues;
          },
          "focusRing": false,
        },
        {
          "label": 'Background',
          "name": 'background',
          "component": 'image',
          "parse": media => {
            return media.previewSrc
          },
          "previewSrc": formValues => {
            return formValues;
          },
          "focusRing": false,
        },
        {
          label: "Background Color",
          name: "background_color",
          component: "color",
          colorFormat: "hex",
        },
        {
          label: "Footer menu",
          name: "menuItems",
          component: "group-list",
          itemProps: item => ({
            label: item.link,
          }),
          fields: [
            {
              label: "Label",
              name: "label",
              component: "text",
              parse(value) {
                return value || ""
              },
            },
            {
              label: "Type",
              name: "type",
              component: "select",
              "options": [ 
                {
                  value: '',
                  label: 'Elige el tipo',
                },
                {
                  value: 'internalLink',
                  label: 'Link Interno',
                },
                {
                  value: 'externalLink',
                  label: 'Link Externo',
                }
              ],
              parse(value) {
                return value || ""
              }
            },
            {
              label: "Link Interno",
              name: "internalLink",
              component: "select",
              options: linkOptions,
              parse(value) {
                return value || ""
              },
            },
            {
              label: "Link Externo",
              name: "externalLink",
              component: "text",
              parse(value) {
                return value || ""
              },
            },
          ],
        },
        {
          label: "Social media",
          name: "socialMedia",
          component: "group-list",
          itemProps: item => ({
            label: item.link,
          }),
          fields: [
            {
              "label": 'Logo',
              "name": 'logo',
              "component": 'image',
              "parse": media => {
                return media.previewSrc
              },
              "previewSrc": formValues => {
                return formValues;
              },
              "focusRing": false,
            },
            {
              label: "Link",
              name: "link",
              component: "text",
              parse(value) {
                return value || ""
              },
            },
          ],
        },
      ],
      onSubmit: async (data) => {
        await saveSiteConfig({ 'footer': data });
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: response,
      // loadInitialValues
    };
    return formConfig;
  };

  const NavForm = setUp();

  const [data, form] = useForm(NavForm, { fields: NavForm.fields });
  useFormScreenPlugin(form)

  const { menuItems, logoFooter, background, background_color, socialMedia } = data;

  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleClick = (e) => {
    e.preventDefault();
    document.querySelector('[value=Footer]').click()
  }

  // hide the link if href is /course-catalog and there is only one course
  // also hide it while loading courses
  const filteredMenuItems = () => {
    return menuItems.filter(element => {
      return ((element.internalLink === '/course-catalog' && courses.length <= 1) || loading) ? false : true
    })
  }

  const onLoadLandings = async (siteId) => {
    const { data } = await getLandings(siteId)
    if(data){
      setLandings(data)
    }
  }

  useEffect(()=>{
    const siteId = production ? keys.id : id
    onLoadCourses(siteId)
    if(!production){
      onLoadLandings(siteId)
    }
  },[])

  return (
    <InlineForm form={form}>
      <Container 
        style={{ 
          backgroundImage: `url(${background})`, 
          backgroundColor: background_color 
        }} 
        onClick={cms.enabled ? handleClick : null}
      >
        <Disable>
          <Link to="/">
            <Logo alt="logo-alt" src={logoFooter.src} />
          </Link> 
        </Disable>        
        <Disable>
        {
          isMobile ? 
            <NavigationBar
              data={filteredMenuItems()}
              containerStyle={{flexDirection: 'column', rowGap: 10}}
              navigationLinkStyle={{ color: 'white' }}
            />
          :
            <NavigationBar
              data={filteredMenuItems()}
              listItemStyle={{ padding: '0px 10px' }}
              navigationLinkStyle={{ color: 'white' }}
            />
        }
        </Disable>
        <Disable>
          <SocialLinks items={socialMedia} />
        </Disable>
      </Container>
    </InlineForm>
  );
};

const Container = styled.div`
  display: flex;
  height: 140px;
  justify-content: space-between;
  align-items: center;  
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0px 100px;
  @media (max-width: 768px){
    padding: 30px;
    flex-direction: column;
    height: auto;
    row-gap: 30px;
  }
`

const Logo = styled.img`
  width: 180px;
  cursor: pointer;
`