import React from 'react';
import { BlocksControls } from 'react-tinacms-inline';
import { useHistory } from 'react-router-dom';

function Link({ index, whiteMode, styles, data }) {

  const history = useHistory();

  return (
    <div key={index}>
      <BlocksControls index={index}>
        <button
          onClick={e => history.push(data.link)}
          className='touchable-opacity'
          style={{...buttonStyle, ...(whiteMode && whiteModeStyle), ...styles}}
        >
          {data.label}
        </button>
      </BlocksControls>
    </div>
  );
}

const buttonStyle = {
  outline: 'none',
  border: 'none',
  fontFamily: 'Arial',
  background: 'transparent',
  color: 'grey',
  fontWeight: 700,
  padding: '0px 20px'
}

const whiteModeStyle = {
  color: '#ffffff'
}

export const linkBlock = {
  Component: Link,
  template: {
    name: "link",
    key: "label",
    label: "Link Item",
    component: "group-list",
    defaultItem: {
        "label": "Link",
        "link": "#"
    },
    fields: [
      {
        "label": "Label",
        "name": "label",
        "component": "text",
        "focusRing": false
      },
      {
        "label": "Link",
        "name": "link",
        "component": "text",
        "focusRing": false
      }
    ],
  },
};