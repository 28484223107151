import network from '../utils/network';

export const getCategories = async (site) => {
  try {
    const { data } = await network.get(`public/site/${site}/category`);
    return data;
  } catch (error) {
    console.error('getCategories', error);
    return error;
  }
}

export const getCategory = async (site, categoryId) => {
  try {
    const { data } = await network.get(`public/site/${site}/category/${categoryId}`);
    return data;
  } catch (error) {
    console.error('getCategory', error);
    return error;
  }
}