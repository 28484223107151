import React from 'react';
import { InlineTextarea, BlocksControls, InlineBlocks } from 'react-tinacms-inline';
import defaults from './Hero.defaults'
import { useMediaQuery } from '../helpers/Hooks'
import { buttonBlock } from './Button';

function Hero({ index, data }) {
  
  const isMobile = useMediaQuery('(max-width: 768px)');

  const styles = {
    container: {
      width: '100%',
      minHeight: '650px',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    },
    content: {
      width: '840px',
      padding: isMobile ? '36px 30px 140px' : `${data.contentPadding ? data.contentPadding : 70}px 100px`,
      textAlign: 'left',
      color: 'white'
    },
    title: {
      color: 'white',
      fontSize: isMobile ? 30 : 38,
      paddingTop: 4,
      lineHeight: isMobile ? '36px' : '52px',
      fontWeight: '800',
      fontFamily: 'Muli',
    },
    subtitle: {
      fontSize: '24px',
      paddingTop: 15,
      fontFamily: 'Muli',
      color: 'white',
      display: isMobile ? 'none' : 'block'
    },
    description: {
      fontSize: isMobile ? 22 : 20,
      fontFamily: 'Muli',
      lineHeight: '30px',
      paddingTop: isMobile ? 18 : 23,
      color: 'white'
    },
    actions: {
      marginTop: 26
    }
  }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <div 
        style={{
          ...styles.container, 
          backgroundImage: `url(${isMobile ? data.mobileBackground : data.background})`
        }}
      >
        <div style={styles.content}>
          {
            data.subtitle !== '' && (
              <div style={styles.subtitle}>
                <InlineTextarea name="subtitle" focusRing={false} />
              </div>
            )
          }
          {
            data.title !== '' && (
              <div style={styles.title}>
                <InlineTextarea name="title" focusRing={false} />
              </div>
            )
          }
          {
            data.description !== '' && (
              <div style={styles.description}>
                <InlineTextarea name="description" focusRing={false} />
              </div>
            )
          }
          {
            data.showButtons && (
              <div style={styles.actions}>
                <InlineBlocks
                  name="buttons"
                  blocks={BUTTON_BLOCKS}
                  className="hero-actions"
                />
              </div>
            )
          }
        </div>
      </div>
    </BlocksControls>
  );
}

const BUTTON_BLOCKS = {
  button: buttonBlock,
};

export const HeroTemplate = {
  "label": "Hero",
  "key": "hero",
  "fields": [
    {
      "label": "Background",
      "name": "background",
      "component": "image",
      "parse": media => {
        return media.previewSrc
      },
      "previewSrc": formValues => {
        return formValues;
      },
      "focusRing": false
    },
    {
      "label": "Mobile Background",
      "name": "mobileBackground",
      "component": "image",
      "parse": media => {
        return media.previewSrc
      },
      "previewSrc": formValues => {
        return formValues;
      },
      "focusRing": false
    },
    {
      "label": "Title",
      "name": "title",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Subtitle",
      "name": "subtitle",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Description",
      "name": "description",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Show Buttons",
      "name": "showButtons",
      "component": "toggle",
      "description": "Show Buttons",
      "toggleLabels": false,
      "focusRing": false
    },
    {
      "label": 'Blocks Buttons',
      "name": 'buttons',
      "component": 'blocks',
      "templates": {
        button: buttonBlock.template,
      },
    },
    {
      "label": "Content padding",
      "name": "contentPadding",
      "component": "number",
      "description": "Content padding in pixels",
      "step": 1,
      "focusRing": false
    }
  ],
  "defaultItem": defaults
};

export const blockHero = {
  Component: Hero,
  template: HeroTemplate,
}