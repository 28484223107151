import React from 'react';
import { BlocksControls, InlineBlocks, InlineImage } from 'react-tinacms-inline';
import { useCMS } from 'tinacms'
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components';
import { linkBlock } from '../Link/Link';
import cart from '../../assets/svgs/cart.svg'
import heart from '../../assets/svgs/heart.svg'

function Navbar({index, data}) {
  const cms = useCMS()
  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
        <Container style={{...(cms.enabled && {position: 'initial'})}}>
            <div style={{height: 40}}>
                <InlineImage
                    name="image"
                    parse={media => {
                        return media.previewSrc
                    }}
                    previewSrc={formValues => {
                        return formValues;
                    }}
                >
                    {
                    (props) => (
                        <img alt="navbar-logo" style={{height: 40}} src={props.src}/>
                    )
                    }
                </InlineImage> 
            </div>           
            <MenuContainer>
                <InlineBlocks
                    name="links"
                    blocks={LINK_BLOCKS}
                    className={'navbar-menu'}
                />
            </MenuContainer>
            <Actions>
                <ActionsItem>
                    <img alt="cart-icon" style={{width: 24, height: 24}} src={cart}/>
                </ActionsItem>
                <ActionsItem>
                    <img alt="heart-icon" style={{width: 24, height: 24}} src={heart}/>
                </ActionsItem>
            </Actions>
        </Container>
    </BlocksControls>
  );
}

const LINK_BLOCKS = {
    link: linkBlock,
};

const Container = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 100px;
    z-index: 1;
    background-color: #f5f5f2;
`

const Actions = styled.div`
    display: flex;
    align-items: center;
`

const ActionsItem = styled.div`
    padding: 0px 10px;
`

const MenuContainer = styled.div`
    display: flex;
    align-items: center;
    & .navbar-menu{
        display: flex;
        align-items: center;
    }
`

export const NavbarTemplate = {
    "label": "Navbar",
    "key": "navbar",
    "fields": fields,
    "defaultItem": defaults
};
  
export const blockNavbar = {
    Component: Navbar,
    template: NavbarTemplate,
}