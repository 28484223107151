import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

function NavigationLink ({
  label,
  href,
  styles,
  onPress,
  showAsDropdown,
  dropdownVisible,
  onRedirect
}) {

  const [hover, setHover] = useState(false)

  const toggleHover = () => {
    setHover(!hover)
  }

  let link = (
    <Link
      onMouseEnter={() => toggleHover()}
      onMouseLeave={() => toggleHover()}
      style={{
        ...containerStyle,
        color: hover ? '#49494a' : '#7a7b92',
        ...styles
      }}
      to={href}
      onClick={() => (onRedirect ? onRedirect() : null)}
    >
      {label}
    </Link>
  )
  if (
    href &&
    (href.indexOf('http://') === 0 || href.indexOf('https://') === 0)
  ) {
    link = (
      <a
        onMouseEnter={() => toggleHover()}
        onMouseLeave={() => toggleHover()}
        style={{
          ...containerStyle,
          color: hover ? '#49494a' : '#7a7b92',
          ...styles
        }}
        href={href}
        target='_blank'
        onClick={() => (onRedirect ? onRedirect() : null)}
      >
        {label}
      </a>
    )
  }

  return (
    <>
      {href
        ? link
        : onPress && (
            <div
              onMouseEnter={() => toggleHover()}
              onMouseLeave={() => toggleHover()}
              style={{
                ...containerStyle,
                color: hover ? '#49494a' : '#7a7b92',
                ...styles
              }}
              onClick={() => (onPress ? onPress() : null)}
            >
              {label}
              {showAsDropdown &&
                (dropdownVisible ? (
                  <KeyboardArrowUpIcon style={dropdownIconStyle} />
                ) : (
                  <KeyboardArrowDownIcon style={dropdownIconStyle} />
                ))}
            </div>
          )}
    </>
  )
}

const containerStyle = {
  textDecoration: 'none',
  padding: '0px 12px',
  fontWeight: 600,
  fontFamily: 'Muli',
  cursor: 'pointer',
  display: 'block',
  transition: 'color 0.4s'
}

const dropdownIconStyle = {
  width: 18,
  marginLeft: 2
}

export default NavigationLink
