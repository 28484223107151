import logo from '../../assets/images/logo.png';
import image from '../../assets/images/image.png';

export default {
    "_template": "blockVideoForm",
    "image": logo,
    "showImage": true,
    "imageWidth": 520,
    "imageHeight": 160,
    "type": 'video',
    "videoUrl": 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    "autoplay": true,
    "videoWidth": 645,
    "videoHeight": 375,
    "coverImage": image,
    "coverImageWidth": 645,
    "coverImageHeight": 375,
    "coverImageSpacing": 0,
    "order": 1,
    "backgroundGradientFirstColor": "#897ec0",
    "backgroundGradientSecondColor": "#793a7e",
    "backgroundGradientAngle": 45,
    "forms": -1,
}