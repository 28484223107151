import React from 'react';
import { Helmet } from "react-helmet";
import { InlineTextarea } from 'react-tinacms-inline';
import ReactMarkdown from 'react-markdown'
import '../App.css';

function RefundPolicy({ index, data }) {

  const styles = {
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px 0px'
    },
    header: {
        width: '100%'
    },
    title: {
        color: 'rgb(67, 67, 74)',
        fontWeight: '800',
        fontSize: 34,
        fontFamily: 'Muli'
    },
    subtitle: {
        fontWeight: '600',
        fontSize: 19,
        fontFamily: 'Muli',
        color: 'rgb(158, 162, 180)',
        marginTop: 14,
        lineHeight: '30px',
        width: 750
    },
    separator: {
        backgroundColor: '#eaebec',
        height: 3,
        width: '100%',
        marginTop: 36,
        marginBottom: 26
    },
    markdown: {
        textAlign: 'left'
    }
  };

  return (
    <div className="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Política de Reembolso</title>
        <link rel="canonical" href="https://store.l3rn.io/refund-policy" />
      </Helmet>
      <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#ffffff' }}>
        <div style={styles.content} className="general-padding">
          <div style={styles.header}>
              <div style={styles.title}>
                  <InlineTextarea name="title" focusRing={false}/>
              </div>
          </div>
          <div style={styles.separator} />
          <div className="markdown" style={styles.markdown}>
              <ReactMarkdown skipHtml={true} children={data.policy} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;