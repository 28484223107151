import image from '../../assets/images/image.png';
import image2 from '../../assets/images/image2.png';

export default {
    "_template": "blockTextImage",
    "image": image,
    "altImage": image2,
    "title": "Lorem ipsum dende",
    "subtitle": "POWERFULL TAGLINE HERE",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    "button": "CONTACT US",
    "separator": true,
    "imageDisplay": 2
}