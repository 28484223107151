import React, { useContext } from 'react';
import { useForm, usePlugin, useCMS } from 'tinacms';
import { InlineForm, InlineBlocks } from 'react-tinacms-inline';
import { blockChangePassword } from '../blocks/ChangePassword';
import { PersistentContext } from '../context';

export default function PageChangePassword(props) {
  const { saveContent, getItemData } = useContext(PersistentContext);

  const cms = useCMS();

  const HOME_BLOCKS = {
    blockChangePassword,
  };

  const setUp = () => {
    const data = getItemData('changepassword');
    const formConfig = {
      id: 'templateChangePassword',
      fields: [{
        label: 'Secciones',
        name: 'blocks',
        component: 'blocks',
        templates: {
          'blockChangePassword': blockChangePassword.template,
        }
      }],
      onSubmit: async (data) => {
        await saveContent({'changepassword': data});
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data,
    }
    return formConfig;
  }
  const formConfig = setUp();
  const [, form] = useForm(formConfig);
  usePlugin(form)

  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`}>
      <InlineForm form={form}>
        <InlineBlocks name="blocks" blocks={HOME_BLOCKS} />
      </InlineForm>
    </div>
  )
}