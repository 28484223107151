export default [
  {
    "label": "Title",
    "name": "title",
    "component": "text",
    "focusRing": false
  },
  {
    "label": "Link",
    "name": "link",
    "component": "text",
    "focusRing": false
  },
  {
    "label": "Events shown",
    "name": "coursesShown",
    "component": "select",
    "options": [ 
      {
        value: 4,
        label: '4',
      },
      {
        value: 8,
        label: '8',
      },
      {
        value: 12,
        label: '12',
      },
      {
        value: 16,
        label: '16',
      },
    ],
    "focusRing": false
  },
  {
    "label": "Background Color",
    "name": "backgroundColor",
    "colorFormat": "hex",
    "widget": "sketch",
    "component": "color",
    "focusRing": false
  },
  {
    "label": "Title Color",
    "name": "titleColor",
    "colorFormat": "hex",
    "widget": "sketch",
    "component": "color",
    "focusRing": false
  },
]