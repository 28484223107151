import React, { Component } from "react";
 
class PageNotFound extends Component {
  render() {
    return (
      <>
        <div style={styles.container}>
            <div style={styles.bigTitle}>
                404
            </div>
            <div style={styles.title}>
                Oops… Página no encontrada
            </div>
            <div style={styles.subtitle}>
                No podemos encontrar la página que estas buscando.
            </div>
            <div style={styles.subtitle}>
                Porfavor trata de nuevo o regresa a la página principal para seguir navegando
            </div>
            <div style={styles.buttonContainer}>
                <button onClick={()=>window.history.back()} style={styles.button}>Regresar a Página Principal</button>
            </div>
        </div>
      </>
    );
  }
}

const styles = {
  container: {
      display: 'flex', 
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      minHeight: '100vh'
  },
  bigTitle: {
      fontSize: 160,
      lineHeight: '160px',
      color: 'rgb(20 73 116)',
      fontWeight: '800',
      marginTop: 0,
      fontFamily: 'Montserrat'
  },
  title: {
      fontWeight: '800',
      fontSize: 32,
      fontFamily: 'Montserrat',
      color: '#434348',
      marginBottom: 12
  },
  subtitle: {
      fontSize: 20,
      fontFamily: 'Montserrat',
      fontWeight: '600',
      color: '#969eb3'
  },
  buttonContainer: {
      marginTop: 40,
      marginBottom: 85 
  },
  button: {
      padding: '20px 40px',
      fontWeight: '600',
      border: 'none',
      fontSize: 16,
      borderRadius: 6,
      backgroundColor: 'rgb(26, 158, 117)',
      color: 'white',
      fontFamily: 'Montserrat',
      cursor: 'pointer',
      transition: 'opacity 0.4s'
  }
}

export default PageNotFound;