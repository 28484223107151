import background from '../assets/images/signin.webp';

export default {
    "_template": "templateSignIn",
    "background": background,
    "title": "Bienvenido de vuelta",
    "description": "Hola de nuevo. Ingresa tus datos y accede",
    "option": "O accede con:",
    "aditional_information": "Si no tienes una cuenta",
    "register": "Regístrate",
    "button": "Iniciar sesión",
    "google": "Entrar con Google",
    "facebook": "Entrar con Facebook"
}