import React, { useEffect, useState, useContext } from 'react';
import { BlocksControls } from 'react-tinacms-inline'
import fields from './Discount.fields'
import defaults from './Discount.defaults'
import Section from '../components/Section'
import Courses from '../components/Courses'
import CircularProgress from '@material-ui/core/CircularProgress'
import { getCourses } from '../helpers/courses'
import { SessionContext } from '../context'
import { PersistentContext } from '../context'
import Separator from '../components/Separator'
import { useCMS } from 'tinacms'
import { useHistory } from 'react-router-dom';

const styles = {
  emptyState: {
    padding: '60px 0px'
  },
  emptyStateTitle: {
    color: 'rgb(67, 67, 74)',
    fontSize: 18,
    fontWeight: 800,
    fontFamily: 'Muli'
  }
}

function DiscountBlock({ index, data }) {
  const cms = useCMS();
  const history = useHistory();

  const { isAuthenticated, isSessionLoaded } = useContext(SessionContext);
  const { id, keys, production } = useContext(PersistentContext);

  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadCourses = async (loading = true) => {
    const siteId = production ? keys.id : id 
    loading === true && setLoading(true)
    const courses = await getCourses(siteId, {filterName: 'originalPrice', filterValue: true}, isAuthenticated)
    await setCourses(courses.data)
    loading === true && setLoading(false)
  }

  useEffect(() => {
    if(!production || isSessionLoaded){
      loadCourses()
    }
  },[production, isSessionLoaded])

  const filter = (courses) => {
    return courses ? courses.slice(0, data.coursesShown) : []
  }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      {
        loading ?
          <div style={{height: 190, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <CircularProgress style={{ color: '#00A8D8' }}/>
          </div>
        :
          <>
            {
              cms.enabled && courses.length <= 0 ?
                <div style={styles.emptyState}>
                  <div style={styles.emptyStateTitle}>Aún no hay cursos en descuento</div>
                  <div>Agrega cursos en el panel de administrador</div>
                </div>
              :
              <Section
                title={data.title}
                action={data.link}
                onActionClick={() => history.push('/course-catalog')}
                >
                <Courses
                  reload={()=>loadCourses(false)}
                  courses={filter(courses)}
                />
                <Separator />
              </Section>
            }
          </>
      }
    </BlocksControls>
  );
}

export const DiscountTemplate = {
  "label": "Discount",
  "key": "Discount",
  "fields": fields,
  "defaultItem": defaults
};

export const blockDiscount = {
  Component: DiscountBlock,
  template: DiscountTemplate,
}
