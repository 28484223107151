import React, { useState, useEffect, useContext } from 'react'
import Section from '../components/Section'
import Courses from '../components/Courses'
import OrderByDropdown from '../components/OrderByDropdown'
import '../App.css';
import { useCMS } from 'tinacms'
import styled from 'styled-components';
import { getCourses } from '../helpers/courses'
import { PersistentContext } from '../context';
import { SessionContext } from '../context/SessionContext';
import { CategoryContext } from '../context/CategoryContext';
import { CircularProgress } from '@material-ui/core'
import CategoriesSlider from '../components/CategoriesSlider';
import { useParams } from "react-router-dom";

function CoursesByCategoryBlock({ index, data }) {

    const { param1 } = useParams();

    const cms = useCMS();

    const { keys, id, production } = useContext(PersistentContext);
    const { isAuthenticated, isSessionLoaded } = useContext(SessionContext);
    const { categories, categoriesLoading, categoriesLoaded } = useContext(CategoryContext);

    const [loading, setLoading] = useState(false)
    const [loadingCourses, setLoadingCourses] = useState(false)
    const [courses, setCourses] = useState([])
    const [orderBy, setOrderBy] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState({
        id: 0,
        name: 'Todos',
        index: -1
    });

    const changeCategory = async (category) => {
        const siteId = production ? keys.id : id
        setSelectedCategory(category)
        setLoadingCourses(true)
        const courses = await getCourses(siteId, (category && category.id !== 0) ? { filterName: "categoryId", filterValue: category.id } : null, isAuthenticated )
        await setCourses(courses.data)
        setLoadingCourses(false)
    }

    const loadCourses = async () => {
        const siteId = production ? keys.id : id 
        setLoading(true)
        const courses = await getCourses(siteId, param1 ? { filterName: "categoryId", filterValue: param1 } : null, isAuthenticated )
        await setCourses(courses.data)
        setLoading(false)
    }

    useEffect(() => {
        if(production){
            if(isSessionLoaded && categoriesLoaded){
                loadCourses()
                if(param1){ 
                    let found = categories.find(element => element.id === param1)
                    found && setSelectedCategory(categories.find(element => element.id === param1))
                }
            }
        }else{
            if(categoriesLoaded){
                loadCourses()
            }
        }
    },[param1, isSessionLoaded, categoriesLoaded])


    const filter = (courses) => {
        return courses ? courses : []
    }

  return (
    <>
        {
            loading || categoriesLoading ? (
                <div style={styles.loaderContainer}>
                    <CircularProgress style={{ color: '#00A8D8' }}/>
                </div>
            ) :
            cms.enabled && courses.length <= 0 ?
            <div style={styles.emptyState}>
                <div style={styles.emptyStateTitle}>Aún no tienes cursos</div>
                <div>Agrega cursos en el panel de administrador</div>
            </div>
            :
            <Section
                containerStyle={{ paddingBottom: 70 }}
            >
                <div style={styles.categorySliderContainer} className="general-padding">
                    {
                        cms.enabled && categories.length <= 0 ?
                            <div style={styles.emptyState}>
                                <div style={styles.emptyStateTitle}>Aún no tienes categorías</div>
                                <div>Agrega categorías en el apartado de cursos en panel de administrador</div>
                            </div>
                        :
                        <CategoriesSlider 
                            categories={categories}
                            selectedCategory={selectedCategory}
                            onSelectCategory={(category)=>changeCategory(category)}
                        />
                    }
                </div>
                <Header className="general-padding">
                    <div style={styles.title}>{selectedCategory.name}</div>
                    <OrderByDropdown
                        containerStyle={{display:'none'}}
                        onSelect={(option)=>setOrderBy(option)}
                    />
                </Header>
                {
                    loadingCourses ? (
                        <div style={styles.loaderContainer}>
                            <CircularProgress style={{ color: '#00A8D8' }}/>
                        </div>
                   ) :  
                   <Courses 
                    courses={filter(courses)}
                   />
                }
            </Section>
        } 
   </>
  );
}

const styles = {
    categorySliderContainer: {
        position: 'relative'
    },
    categorySlider: {
        display: 'flex',
        width: '100%',
        overflow: 'scroll',
        scrollBehavior: 'smooth'
    },
    categoryActive: {
        backgroundColor: '#3d506a',
        color: 'white'
    },
    navigation: {
        position: 'absolute',
        backgroundColor: '#016cff',
        height: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 36
    },
    navigationRight: {
        top: 0,
        right: 0,
    },
    navigationLeft: {
        top: 0,
        left: 0,
    },
    title: {
        color: '#43434a',
        fontSize: 32,
        fontWeight: '800',
        fontFamily: 'Muli',
        textAlign: 'left'
    },
    emptyState: {
        padding: '60px 0px'
    },
    emptyStateTitle: {
        color: 'rgb(67, 67, 74)',
        fontSize: 18,
        fontWeight: 800,
        fontFamily: 'Muli'
    },
    loaderContainer: {
        minHeight: 300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 40px;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;

export const Category = styled.div`
    background-color: #f8f8fa;
    color: #828999;
    width: 16.6666667%;
    font-weight: 800;
    font-size: 17px;
    font-family: Muli;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    border-right: #828999 1px solid;
    transition: background-color 0.4s, color 0.4s, opacity 0.4s;
    @media (max-width: 980px) {
        width: 50%;
    }
    @media (max-width: 480px) {
        width: 100%;
    }
`;

export default CoursesByCategoryBlock