import React, { useState, useContext } from 'react'
import { Avatar, CircularProgress } from '@material-ui/core'
import { useMediaQuery } from '../helpers/Hooks'
import MainButton from '../components/buttons/MainButton'
import { CourseContext } from '../context'
import { useEffect } from 'react'
import moment from 'moment'
import 'moment/locale/es'
moment().locale('es')

const Comments = ({
  site = null,
  course = null,
  content = null,
  allowComments = false
}) => {
  const { comments, loadComments, sendComment } = useContext(CourseContext)
  const [page, setPage] = useState(0)
  const [comment, setComment] = useState('')
  const [loading, setloading] = useState(true)
  const [hasMore, setHasMore] = useState(true)
  const [loadingMore, setloadingMore] = useState(false)
  const isMobile = useMediaQuery('(max-width: 768px)')
  const [loadingComment, setloadingComment] = useState(false)

  const onLoadMore = async page => {
    await setloadingMore(true)
    await loadComments(site, course, content, page)
    await setloadingMore(false)
  }

  const load = async (page = 0) => {
    try {
      setloading(true)
      await loadComments(site, course, content, page, true)
    } catch (error) {
      console.log('error', error)
    } finally {
      setloading(false)
    }
  }

  useEffect(() => {
    if (site && course && content) {
      load()
    }
  }, [])

  useEffect(() => {
    if (comments && comments.length) {
      if (comments.length < (page + 1) * 10) {
        setHasMore(false)
      } else {
        setPage(page + 1)
      }
    }
  }, [comments])

  const styles = {
    comments: {
      marginTop: 26,
      paddingBottom: isMobile ? 0 : 36,
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column'
    },
    commentsTitle: {
      color: '#43434a',
      fontSize: 32,
      fontWeight: '600',
      fontFamily: 'Muli',
      textAlign: 'left'
    },
    commentsTextarea: {
      width: '100%',
      marginTop: 18,
      padding: '12px 18px',
      height: 67,
      fontWeight: '800',
      fontFamily: 'Muli',
      resize: 'none',
      borderWidth: 1
    },
    commentsButton: {
      padding: '8px 24px',
      borderRadius: 4,
      marginTop: 20
    },
    commentsNotAllowed: {
      color: 'rgb(67, 67, 74)',
      fontSize: 16,
      fontWeight: 600,
      fontFamily: 'Muli'
    }
  }

  const onComment = async ev => {
    if (comment.trim() !== '') {
      if (site && course && content) {
        try {
          await setloadingComment(true)
          await sendComment(site, course, {
            contentId: content,
            comment
          })
          await load()
          setComment('')
        } catch (error) {
          console.log('error', error)
        } finally {
          await setloadingComment(false)
        }
      }
    }
  }

  return (
    <div style={styles.comments}>
      <div style={styles.commentsTitle}>Comentarios</div>
      {allowComments ? (
        <>
          <div
            style={{
              width: '100%',
              marginBottom: 20,
              paddingBottom: 20,
              borderBottom: '#cecece 2px solid',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <textarea
              value={comment}
              placeholder='Escribe aqui...'
              style={styles.commentsTextarea}
              onChange={ev => setComment(ev.target.value)}
            />
            <MainButton
              type='primary'
              onPress={onComment}
              disabled={loadingComment}
              label={
                loadingComment
                  ? 'Agregando comentario...'
                  : 'Postear Comentario'
              }
              styles={styles.commentsButton}
            />
          </div>
          {loading && <CircularProgress size={20} style={{ color: '#00A8D8' }} />}
          {!loading &&
            comments.map((item, index) => {
              const passed = moment(item.createdAt).format('MMM DD, YYYY')
              return (
                <div
                  key={`comments-${index}`}
                  style={{
                    width: '100%',
                    marginTop: 10,
                    display: 'flex',
                    marginBottom: 10,
                    paddingBottom: 25,
                    flexDirection: 'row',
                    borderBottomWidth: 1,
                    borderBottomColor: '#ddd',
                    borderBottomStyle: 'solid'
                  }}
                >
                  <div style={{ padding: 15, paddingTop: 0, width: '100%' }}>
                    <div
                      style={{
                        color: '#43434a',
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row'
                        }}
                      >
                        <Avatar alt='s' />
                        <p
                          style={{
                            marginLeft: 15,
                            color: '#43434a',
                            fontWeight: 'bold',
                            textTransform: 'uppercase'
                          }}
                        >
                          {item.user_fullname}
                        </p>
                      </div>
                      <p
                        style={{ textTransform: 'uppercase', color: '#43434a' }}
                      >
                        {passed}
                      </p>
                    </div>
                    <p style={{ color: '#43434a', textAlign: 'left' }}>
                      {item.comment}
                    </p>
                  </div>
                </div>
              )
            })}
          {!loading && hasMore && comments.length ? (
            <div style={{ width: '100%' }}>
              {!loadingMore ? (
                <a
                  style={{ color: '#3D6CFB' }}
                  onClick={ev => {
                    ev.preventDefault()
                    onLoadMore(page)
                  }}
                >
                  Cargar mas comentarios
                </a>
              ) : (
                <CircularProgress size={20} style={{ color: '#00A8D8' }}/>
              )}
            </div>
          ) : null}
        </>
      ) : (
        <div style={styles.commentsNotAllowed}>
          Los comentarios están deshabilitados en esta lección
        </div>
      )}
    </div>
  )
}

export default Comments
