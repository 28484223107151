export default {
    "_template": "templateAbout",
    "title": "Acerca de nosotros",
    "about": `

Una página Acerca de nosotros ayuda a su empresa a dar una buena primera impresión y es fundamental para generar confianza y lealtad en los clientes. Es por eso que hemos creado esta herramienta gratuita y fácil de usar que le permite generar instantáneamente una página Acerca de nosotros personalizada para su tienda.

Una vez que se genera su contenido, podrá modificarlo y personalizarlo hasta que sea el correcto. ¡Crea el tuyo ahora!

    `
}