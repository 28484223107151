import React, { useContext } from 'react'
import { useForm, usePlugin, useCMS } from 'tinacms'
import { InlineForm, InlineTextarea } from 'react-tinacms-inline'
import { PersistentContext } from '../context';
import { Helmet } from "react-helmet";
import MainButton from '../components/buttons/MainButton'
import styled from 'styled-components'

export default function PagePaymentFailed(props) {
  const { saveContent, getItemData } = useContext(PersistentContext);

  const cms = useCMS();

  const clearItemData = async () => {
    await saveContent({'payment-failed': null});
    window.location.reload()
  }

  const setUp = () => {
    const data = getItemData('payment-failed');
    if(data.blocks){
      if(data.blocks[0]){
        if(data.blocks[0]._template === "blockPaymentFailed"){
          clearItemData() 
        }
      }
    }
    const formConfig = {
      id: 'templatePaymentFailed',
      fields: [
        {
            "label": "Background",
            "name": "background",
            "component": "image",
            "parse": media => {
              return media.previewSrc
            },
            "previewSrc": formValues => {
              return formValues;
            },
            "focusRing": false
        },
        {
          "label": "Title",
          "name": "title",
          "component": "text",
          "focusRing": false
        },
        {
          "label": "Subtitle",
          "name": "subtitle",
          "component": "text",
          "focusRing": false
        },
        {
            "label": "Button",
            "name": "button",
            "component": "text",
            "focusRing": false
        },
      ],
      onSubmit: async (data) => {
        await saveContent({'payment-failed': data});
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data,
    }
    return formConfig;
  }
  const formConfig = setUp();
  const [modifiedValues, form] = useForm(formConfig);
  usePlugin(form)

  const homeRedirect = () => {
    window.location.href = '/'
  }

  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`}>
      <InlineForm form={form}>
        <div className="page">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Pago Fallido</title>
            <link rel="canonical" href="https://store.l3rn.io/payment-failed" />
        </Helmet>
        <Container style={{backgroundImage: `url(${modifiedValues.background})`}}>
            <div style={styles.title}>
                <InlineTextarea name="title" focusRing={false} />
            </div>
            <div style={styles.subtitle}>
                <InlineTextarea name="subtitle" focusRing={false} />
            </div>
            <div style={{ marginTop: 40 }}>
                <MainButton 
                    onPress={() => homeRedirect()} 
                    type="primary"
                    styles={{ padding: '15px 30px' }}
                    label={modifiedValues.button} 
                />
            </div>
        </Container>
        </div>
      </InlineForm>
    </div>
  )
}

export const Container = styled.div`
    display: flex; 
    flex-direction: column;
    width: 100%;
    justify-content: center;
    min-height: 90vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media (max-width: 768px) {
        background-position: 0%;
    }
`;

const styles = {       
    title: {
        fontWeight: '800',
        fontSize: 32,
        fontFamily: 'Muli',
        color: '#434348',
        marginBottom: 12
    },
    subtitle: {
        fontSize: 20,
        fontFamily: 'Muli',
        fontWeight: '600',
        color: '#969eb3'
    }
}