import { testimonialsItemBlock } from './TestimonialsItem';

export default [
    {
        "label": "Title",
        "name": "title",
        "component": "text",
        "focusRing": false
    },
    {
        label: 'Testimonials List',
        name: 'testimonialsList',
        component: 'blocks',
        templates: {
          testimonialsItem: testimonialsItemBlock.template,
        }
    },
    {
        "label": "Items per slide",
        "name": "itemsPerSlide",
        "component": "select",
        "options": [ 
          {
            value: "one",
            label: "1",
          },
          {
            value: "two",
            label: "2",
          },
          {
            value: "three",
            label: "3",
          },
          {
            value: "four",
            label: "4",
          },
        ],
        "focusRing": false
    },
    {
        "label": "Show Image",
        "name": "showImage",
        "component": "toggle",
        "description": "Mostrar Imagen",
        "toggleLabels": false,
        "focusRing": false
      },
      {
        "label": "Show Icon",
        "name": "showIcon",
        "component": "toggle",
        "description": "Mostrar icono",
        "toggleLabels": false,
        "focusRing": false
      },
      {
        "label": "Background Color",
        "name": "backgroundColor",
        "colorFormat": "hex",
        "widget": "sketch",
        "component": "color",
        "focusRing": false
      },
      {
        "label": "Testimonials Background Color",
        "name": "testimonialsBackgroundColor",
        "colorFormat": "hex",
        "widget": "sketch",
        "component": "color",
        "focusRing": false
      },
      {
        "label": "Title Color",
        "name": "titleColor",
        "colorFormat": "hex",
        "widget": "sketch",
        "component": "color",
        "focusRing": false
      }
]
