import network from '../utils/network';

export const addProduct = async (site, body) => {
    try {
      const { data } = await network.post(`site/${site}/cart`, body);
      return data;
    } catch (error) {
      console.error('addProduct', error);
      return error;
    }
}

export const removeProduct = async (site, productId) => {
    try {
      const { data } = await network.del(`site/${site}/cart/${productId}`);
      return data;
    } catch (error) {
      console.error('removeProduct', error);
      return error;
    }
}

export const getCart = async (site) => {
    try {
      const { data } = await network.get(`site/${site}/cart`);
      return data;
    } catch (error) {
      console.error('getCart', error);
      return error;
    }
}