import React, { useContext } from 'react'
import { useForm, usePlugin, useCMS } from 'tinacms'
import { InlineForm } from 'react-tinacms-inline'
import CoursePage from '../blocks/Course.js'
import { PersistentContext } from '../context';
import { blockCourses } from '../blocks/Courses';

export default function PageCourse(props) {
  const { saveContent, getItemData } = useContext(PersistentContext);

  const cms = useCMS();

  const clearItemData = async () => {
    await saveContent({'course': null});
    window.location.reload()
  }

  const setUp = () => {
    const data = getItemData('course');
    if(data.blocks){
      if(data.blocks[0]){
        if(data.blocks[0]._template === "blockCourse"){
          clearItemData()
        }
      }
    }
    const formConfig = {
      id: 'templateCourse',
      fields: [
        {
          label: 'Secciones',
          name: 'blocks',
          component: 'blocks',
          templates: {
            'blockCourses': blockCourses.template,
          }
        }
      ],
      onSubmit: async (data) => {
        await saveContent({ 'course': data });
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data,
    }
    return formConfig;
  }
  const formConfig = setUp();
  const [modifiedValues, form] = useForm(formConfig);
  usePlugin(form)

  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`}>
      <InlineForm form={form}>
        <CoursePage data={modifiedValues} />
      </InlineForm>
    </div>
  )
}