export default {
    "_template": "blockCounter",
    "title": "",
    "counters": [
      {
        "_template": "counter",
        "icon": require(`../assets/svgs/world.svg`),
        "title": "+20 Países",
        "description": "Pellentesque quis consectetur tortor, egestas scelerisque velit. Nulla Leo odio."
      },
      {
        "_template": "counter",
        "icon": require(`../assets/svgs/microphone.svg`),
        "title": "+500 Conferencias",
        "description": "Pellentesque quis consectetur tortor, egestas scelerisque velit. Nulla Leo odio."
      },
      {
        "_template": "counter",
        "icon": require(`../assets/svgs/star.svg`),
        "title": "+5 años",
        "description": "Pellentesque quis consectetur tortor, egestas scelerisque velit. Nulla Leo odio."
      },
      {
        "_template": "counter",
        "icon": require(`../assets/svgs/heart-message.svg`),
        "title": "+2000 Miembros",
        "description": "Pellentesque quis consectetur tortor, egestas scelerisque velit. Nulla Leo odio."
      }
    ]
}