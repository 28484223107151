import React from 'react';
import { InlineTextarea, BlocksControls } from 'react-tinacms-inline'
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components'
import SearchBar from '../../components/SearchBar'
import { useMediaQuery } from '../../../../helpers/Hooks'

function Hero({ index, data }) {

  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <Container 
        style={{backgroundImage: `url(${isMobile ? data.mobileBackground : data.background})`}}
      >        
        <Content>
          <Title>
            <InlineTextarea name="title" focusRing={false} />
          </Title>
          <Description>
            <InlineTextarea name="description" focusRing={false} />
          </Description>
          <Actions>
            {
              data.searchbar && (
                <SearchBar placeholder="Search"/>
              )
            }
          </Actions>
        </Content>
      </Container>
    </BlocksControls>
  );
}

const Container = styled.div`
  width: calc(100% - 200px);
  margin: auto;
  position: relative;
  z-index: 2;
  min-height: 75vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Content = styled.div`
  width: 630px;
  padding-right: 132px;
  text-align: center;
  color: white;
`

const Title = styled.div`
  color: #222e3e;
  font-size: 38px;
  font-weight: 800;
  font-family: Arial;
  text-align: left;
`

const Description = styled.div`
  color: #908e8e;
  font-size: 18px;
  line-height: 36px;
  font-weight: 600;
  font-family: Arial;
  text-align: left;
  padding-top: 18px;
  @media (max-width: 768px) {
    font-size: 22px;
    padding-top: 18px;
  }
`

const Actions = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  & .hero-inline-blocks{
    display: flex;
    column-gap: 20px;
    row-gap: 16px;
    flex-wrap: wrap;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const HeroTemplate = {
  "label": "Hero",
  "key": "hero",
  "fields": fields,
  "defaultItem": defaults
};

export const blockHero = {
  Component: Hero,
  template: HeroTemplate,
}