import Amplify, { Auth } from 'aws-amplify'
import Analytics from '@aws-amplify/analytics'
import configDev from '../config.dev.json'
import configProd from '../config.production.json'

const initFb = (keys = {}) => {
  if (keys && keys.facebookPixel) {
    const script = document.createElement('script')
    script.type = "text/javascript"
    script.text = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${keys.facebookPixel}');  
      fbq('track', 'PageView');
      console.log("fbq init")
    `
    document.body.appendChild(script)
  }
}

const initTiktok = (keys = {}) => {
  if (keys && keys.tiktokPixel) {
    const script = document.createElement('script')
    script.type = "text/javascript"
    script.text = `
      console.log("tiktok init")
    `
    document.body.appendChild(script)
  }
}

const initGoogleTag = (keys = {}) => {
  if (keys && keys.gogoleTag) {
    const script = document.createElement('script')
    script.type = "text/javascript"
    script.text = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${keys.gogoleTag}');
      console.log("google init")
    `
    document.body.appendChild(script)
  }
}

export const init = (keys = {}) => {
  Amplify.configure({
    Auth: {
      region: 'us-east-1',
      mandatorySignId: true,
      userPoolId: keys.userPoolId,
      userPoolWebClientId: keys.clientId,
      identityPoolId: keys.identityPoolId
    },
    API: {
      endpoints: [
        {
          name: 'API',
          endpoint:
            window.STAGE === 'dev'
              ? configDev.api.baseUrl
              : configProd.api.baseUrl,
          custom_header: async () => {
            try {
              return {
                Authorization: `Bearer ${(await Auth.currentSession())
                  .getAccessToken()
                  .getJwtToken()}`
              }
            } catch (error) {
              return {}
            }
          }
        }
      ]
    }
  })
  Analytics.configure({
    AWSPinpoint: {
      region: 'us-east-1',
      mandatorySignIn: false,
      appId: window.SITE_DATA.pinpointId
    }
  })
  Analytics.autoTrack('session', {
    enable: true,
    provider: 'AWSPinpoint'
  })
  Analytics.autoTrack('pageView', {
    enable: true,
    eventName: 'pageView',
    type: 'SPA',
    provider: 'AWSPinpoint',
    getUrl: () => {
      return window.location.origin + window.location.pathname
    }
  })
  trackUserIdforPinpoint()
  initFb(keys)
  initTiktok(keys)
  initGoogleTag(keys)
}

const mappedobjects = f => obj =>
  Object.keys(obj).reduce((acc, key) => ({ ...acc, [key]: f(obj[key]) }), {})
const Arrayofourstrings = value => [`${value}`]
const mapArrayofourstrings = mappedobjects(Arrayofourstrings)

export const trackUserIdforPinpoint = async () => {
  try {
    const { attributes } = await Auth.currentAuthenticatedUser()
    const userAttributes = mapArrayofourstrings(attributes)
    Analytics.updateEndpoint({
      address: attributes.email,
      channelType: 'EMAIL',
      optOut: 'NONE',
      userId: attributes.sub,
      userAttributes
    })
  } catch (error) {
    console.log('trackUserIdforPinpoint Error', error)
  }
}

export const register = async (fullname, email, password, subscribed) => {
  try {
    const response = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name: fullname,
        'custom:subscribed': subscribed
      }
    })
    return response
  } catch (error) {
    console.log('register', error)
    throw error
  }
}

export const load = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    return user
  } catch (error) {
    console.log('load', error)
    throw error
  }
}

export const login = async (email, password) => {
  try {
    const user = await Auth.signIn(email, password)
    return user
  } catch (error) {
    console.log('login', error)
    throw error
  }
}

export const logout = async () => {
  try {
    await Auth.signOut()
  } catch (error) {
    console.log('logout', error)
    throw error
  }
}
