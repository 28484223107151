import React, { useContext } from 'react'
import { useForm, usePlugin, useCMS } from 'tinacms'
import { InlineForm, InlineBlocks } from 'react-tinacms-inline'
import { PersistentContext } from '../../context';
import { blockNavbar } from './blocks/Navbar/Navbar'
import { blockHero } from './blocks/Hero/Hero'
import { blockTextImage } from './blocks/TextImage/TextImage'
import { blockBoxes } from './blocks/Boxes/Boxes'
import { blockServices } from './blocks/Services/Services'
import { blockFooter } from './blocks/Footer/Footer'

export default function PageHome({name}) {
  const { saveContent, getItemData } = useContext(PersistentContext);

  const cms = useCMS();

  const HOME_BLOCKS = {
    blockNavbar,
    blockHero,
    blockServices,
    blockBoxes,
    blockTextImage,
    blockFooter
  };

  const setUp = () => {
    const data = getItemData('landing4', true);
    const formConfig = {
      id: 'landing4Home',
      fields: [{
        label: 'Secciones',
        name: 'blocks',
        component: 'blocks',
        templates: {
          'blockNavbar': blockNavbar.template,
          'blockHero': blockHero.template,
          'blockServices': blockServices.template,
          'blockBoxes': blockBoxes.template,
          'blockTextImage': blockHero.template,
          'blockFooter': blockFooter.template
        }
      }],
      onSubmit: async (data) => {
        await saveContent({'landing4': data});
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data,
    }
    return formConfig;
  }
  const formConfig = setUp();
  const [, form] = useForm(formConfig);
  usePlugin(form)

  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`}>
      <InlineForm form={form}>
        <InlineBlocks name="blocks" blocks={HOME_BLOCKS} />
      </InlineForm>
    </div>
  )
}