import background from '../assets/images/signup.webp';

export default {
    "_template": "blockForgotPassword",
    "background": background,
    "title": "Recuperar contraseña",
    "description": "Ingresa tu correo electrónico y te enviaremos un enlace para restablecer tu contraseña",
    "button": "Restablecer contraseña",
    "signinmessage": "Regresar a",
    "signin": "Iniciar sesión"
}