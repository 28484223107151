import { linkBlock } from '../Link/Link';

export default [
    {
      "label": "Logo",
      "name": "image",
      "component": "image",
      "parse": media => {
        return media.previewSrc
      },
      "previewSrc": formValues => {
        return formValues;
      },
      "focusRing": false
    },
    {
        'label': 'Blocks Links',
        'name': 'links',
        'component': 'blocks',
        'templates': {
          'link': linkBlock.template,
        },
    },
    {
      "label": "Position",
      "name": "position",
      "component": "select",
      "description": "Este cambio solo se verá fuera del editor",
      "options": [ 
        {
          value: 'absolute',
          label: 'absolute'
        },
        {
          value: 'initial',
          label: 'initial'
        }
      ],
      "focusRing": false
    },
    {
      "label": "Links Text Color",
      "name": "linksTextColor",
      "description": "Establece el color de los links, puede ser sobreescrito por los settings de cada link individual",
      "colorFormat": "hex",
      "widget": "sketch",
      "component": "color",
      "focusRing": false
    },
    {
      "label": "Background Color",
      "name": "backgroundColor",
      "description": "Establece el color de fondo",
      "colorFormat": "hex",
      "widget": "sketch",
      "component": "color",
      "focusRing": false
    },
    {
      "label": "Show Actions",
      "name": "showActions",
      "component": "toggle",
      "description": "Mostrar iconos de acciones (carrito & favoritos)",
      "toggleLabels": false,
      "focusRing": false
    }
]