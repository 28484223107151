import React from 'react';
import { useHistory } from 'react-router-dom';

export default function MainButton({ index, link, style, label, disabled, styles }) {

  const history = useHistory();

  return (
    <button
        onClick={e => history.push(link)}
        className='touchable-opacity'
        disabled={disabled ? disabled : false}
        style={{
        ...buttonStyle,
        ...(style === 'primary'
            ? primary
            : style === 'secondary'
            ? secondary
            : style === 'tertiary'
            ? tertiary
            : primary),
        ...styles,
        ...(disabled ? disabledStyle : {})
        }}
    >
        {label}
    </button>
  );
}

const buttonStyle = {
  outline: 'none',
  border: 'none',
  borderRadius: 28,
  padding: '8px 34px',
  fontSize: 16,
  fontWeight: '800',
  fontFamily: 'Arial'
}

const disabledStyle = {
  backgroundColor: '#7398FD',
  color: 'white',
  border: '#7398FD 2px solid'
}

const primary = {
  backgroundColor: '#f58e68',
  color: 'white',
  border: '#f58e68 2px solid'
}

const secondary = {
  backgroundColor: 'transparent',
  color: 'white',
  border: 'white 2px solid'
}

const tertiary = {
  backgroundColor: 'transparent',
  color: '#016cff',
  border: '#016cff 2px solid'
}