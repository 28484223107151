import React from 'react';
import { BlocksControls, InlineTextarea, InlineImage } from 'react-tinacms-inline';
import fields from './fields'
import defaults from './defaults'
import styled from 'styled-components'

function TestimonialsItem({ index, data: { image } }) {

  return (
    <BlocksControls focusRing={{ offset: {x: -15, y: 0} }} insetControls index={index}>
      <Wrapper>
        <Container>

          <Content>
            <div style={{width: 200}}>
              <InlineImage
                name="image"
                parse={media => {
                  return media.previewSrc
                }}
                previewSrc={formValues => {
                  return formValues;
                }}
              >
                {
                  (props) => (
                    <ImageContainer style={{backgroundImage: `url(${props.src})`}}/>
                  )
                }
              </InlineImage>
            </div>

            <div style={{paddingLeft: 20, paddingRight: 60, flexGrow: 1, flexShrink: 1, display: 'flex', flexDirection: 'column'}}>
              <Testimonial>
                <InlineTextarea name="testimonial" focusRing={false} />
              </Testimonial>

              <AuthorInfo>
                <Author>
                  <InlineTextarea name="author" focusRing={false} />
                </Author>

                <Location>
                  <InlineTextarea name="location" focusRing={false} />
                </Location>
              </AuthorInfo>
            </div>
          </Content>

          <Line />

        </Container>
      </Wrapper>
    </BlocksControls>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
`

const Container = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 28px 28px 0px 28px;
  border-radius: 24px;
  box-shadow: 0px 0px 12px 0px #cacaca;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
`

const AuthorInfo = styled.div`
  display: flex;
  margin-top: 10px;
`

const ImageContainer = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 28px;
  border: #fff9f0 2px solid;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`
const Testimonial = styled.div`
  color: #929292;
  font-size: 16px;
  font-weight: 700;
  font-family: Arial;
  line-height: 31px;
  margin-top: 30px;
  text-align: left;
`

const Author = styled.div`
  color: #01214d;
  font-size: 18px;
  font-weight: 800;
  font-family: Arial;
  text-align: left;
`

const Location = styled.div`
  color: #929292;
  font-size: 18px;
  font-weight: 800;
  font-family: Arial;
  text-align: left;
  margin-left: 14px;
`

const Line = styled.div`
  margin-left: calc(200px + 20px);
  margin-right: 60px;
  background-color: rgb(245, 142, 104);
  border-radius: 10px;
  height: 7px;
  margin-top: calc(28px - 7px);
`

export const testimonialsItemBlock = {
  Component: TestimonialsItem,
  template: {
    name: "testimonialsItem",
    key: "label",
    label: "Testimonials Item",
    component: "group-list",
    itemProps: item => ({
      label: item.name,
    }),
    defaultItem: defaults,
    fields: fields,
  },
};
