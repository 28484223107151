import React from 'react';
import { BlocksControls, InlineBlocks } from 'react-tinacms-inline'
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components'
import { boxesItemBlock } from '../BoxesItem/BoxesItem';
import { useCMS } from 'tinacms'

function Boxes({ index, data }) {
  
  const cms = useCMS()

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <Container
        style={{backgroundColor: data.backgroundColor}}
      > 
        <Title style={{marginBottom: 0, color: data.textColor}}>{data.title}</Title>
        <InlineBlocks
          max={8}
          name="boxesList"
          direction="horizontal"
          className={`boxes-blocks-container ${cms.enabled ? `tina-enabled` : ``}`}
          blocks={BOXES_BLOCKS}
          itemProps={{
            boxImageWidth: data.boxImageWidth,
            boxImageHeight: data.boxImageHeight,
            boxShadow: data.boxShadow,
            titleColor: data.boxesTitleColor,
            textColor: data.boxesTextColor,
            padding: data.boxPadding,
            backgroundColor: data.boxesBackgroundColor
          }}
        />
      </Container>
    </BlocksControls>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 70px 100px;
  & .boxes-blocks-container{
    column-gap: 45px;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 54px;
    justify-content: space-around;
  }
  & .boxes-blocks-container.tina-enabled > div{
    width: auto;
  }
  @media (max-width: 980px){
    padding: 30px;
  }
`

const Title = styled.h2`
  font-size: 38px;
  line-height: 52px;
  font-weight: 800;
  font-family: Arial;
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 36px;
  }
`

const BOXES_BLOCKS = {
  boxesItem: boxesItemBlock
};

export const BoxesTemplate = {
  "label": "Boxes",
  "key": "boxes",
  "fields": fields,
  "defaultItem": defaults
};

export const blockBoxes = {
  Component: Boxes,
  template: BoxesTemplate,
}