import React, { useEffect, useState } from 'react'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import SessionContextProvider from './context/SessionContext'
import StandaloneApp from './StandaloneApp'
import './App.css'

function App () {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    let mounted = true
    const init = async () => {
      const response = await fetch('/site.json', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
      window.SITE_DATA = await response.json()
      mounted && setLoaded(true)
    }
    if (!window.SITE_DATA) {
      init()
    } else {
      setLoaded(true)
    }
    return () => {
      mounted = false
    }
  }, [])

  if (!loaded) return null

  return (
    <div className='App'>
      <SnackbarProvider maxSnack={3}>
        <Router>
          <SessionContextProvider>
            <Switch>
              <Route
                exact
                component={StandaloneApp}
                path='/:page?/:param1?/:param2?/:param3?'
              />
            </Switch>
          </SessionContextProvider>
        </Router>
      </SnackbarProvider>
    </div>
  )
}

export default App
