import React, { useContext } from 'react';
import { BlocksControls } from 'react-tinacms-inline';
import fields from './Categories.fields'
import defaults from './Categories.defaults'
import Section from '../components/Section';
import CategoriesList from '../components/CategoriesList';
import { useMediaQuery } from '../helpers/Hooks';
import { CategoryContext } from '../context';
import { CircularProgress } from '@material-ui/core';

function Categories({ index, data }) {

  const { categories, categoriesLoading } = useContext(CategoryContext);

  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
        <Section containerStyle={{marginTop: isMobile ? 28 : 16}} title={data.title}>
          {
            categoriesLoading ? (
              <CircularProgress style={{ color: '#00A8D8' }}/>
            ) :
            categories && (
              <CategoriesList categories={categories} />
            )
          }
        </Section>
    </BlocksControls>
  );
}

export const CategoriesTemplate = {
  "label": "Categories",
  "key": "Categories",
  "fields": fields,
  "defaultItem": defaults
};

export const blockCategories = {
  Component: Categories,
  template: CategoriesTemplate,
}
