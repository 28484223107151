import React from 'react';

function CategoryLabel(props) {

  const styles = {
    container: {
      backgroundColor: '#48566e',
      color: 'white',
      borderRadius: 6,
      padding: '4px 10px',
      textTransform: 'uppercase',
      fontSize: 9.3,
      letterSpacing: 1,
      fontWeight: '800',
      fontFamily: 'Muli'
    }
  }

  return (
    <div style={{...styles.container, ...props.styles}}>
        {props.name}
    </div>
  );
}

export default CategoryLabel;
