import React from 'react';
import { BlocksControls, InlineBlocks } from 'react-tinacms-inline';
import { linkBlock } from '../Link/Link'
import styled from 'styled-components'

function LinkSection({ index, data }) {
  return (
    <div key={index}>
      <BlocksControls index={index}>
        <Container>
          <Title>
            {data.title}
          </Title>
          <InlineBlocks
            name="links"
            blocks={LINK_BLOCKS}
            className={'link-list'}
            itemProps={{
              styles: {
                padding: 0,
                color: '#636667',
                fontWeight: 600
              }
            }}
          />
        </Container>
      </BlocksControls>
    </div>
  );
}

const Title = styled.div`
  color: #152647;
  font-family: 'Arial';
  font-weight: 700;
  font-size: 14px;
  text-align: left;
`

const Container = styled.div`
  & .link-list{
    margin-top: 20px;
    row-gap: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
`

const LINK_BLOCKS = {
    link: linkBlock,
};

export const linkSectionBlock = {
  Component: LinkSection,
  template: {
    name: "linkSection",
    key: "label",
    label: "Link Section",
    component: "group-list",
    defaultItem: {
        "title": "My Title",
        "links": [
            {
                "_template": "link",
                "label": "Home",
                "link": "#"
            },
            {
                "_template": "link",
                "label": "About us",
                "link": "#"
            }
        ]
    },
    fields: [
      {
        "label": "Title",
        "name": "title",
        "component": "text",
        "focusRing": false
      },
      {
        'label': 'Blocks Links',
        'name': 'links',
        'component': 'blocks',
        'templates': {
          'link': linkBlock.template,
        },
      }
    ],
  },
};