import React from 'react';
import { BlocksControls } from 'react-tinacms-inline';
import '../App.css';
import { useHistory } from 'react-router-dom';
import MainButton from '../components/buttons/MainButton'

function Button({ index, data }) {

  const history = useHistory();

  return (
    <div key={index}>
      <BlocksControls index={index}>
        <MainButton 
            onPress={() => history.push(data.link)} 
            type={data.style}
            label={data.label} 
        />
      </BlocksControls>
    </div>
  );
}

export const buttonBlock = {
  Component: Button,
  template: {
    name: "button",
    key: "label",
    label: "Button Item",
    component: "group-list",
    defaultItem: {
        "label": "Button",
        "style": "primary",
        "link": "#"
    },
    fields: [
      {
        "label": "Label",
        "name": "label",
        "component": "text",
        "focusRing": false
      },
      {
        "label": "Style",
        "name": "style",
        "component": "select",
        "options": [ 
          {
            value: "primary",
            label: "Primary",
          },
          {
            value: "secondary",
            label: "Outline",
          },
          {
            value: "tertiary",
            label: "Outline Alt",
          },
        ],
        "focusRing": false
      },
      {
        "label": "Link",
        "name": "link",
        "component": "text",
        "focusRing": false
      }
    ],
  },
};