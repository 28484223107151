export default [
  {
    "label": "Image",
    "name": "image",
    "component": "image",
    "parse": media => {
      return media.previewSrc
    },
    "previewSrc": formValues => {
      return formValues;
    },
    "focusRing": false
  },
  {
    "label": "Image Width",
    "name": "imageWidth",
    "component": "number",
    "description": "In pixels",
    "step": 1,
    "focusRing": false
  },
  {
    "label": "Image Height",
    "name": "imageHeight",
    "component": "number",
    "description": "In pixels",
    "step": 1,
    "focusRing": false
  },
  {
    "label": "Image Spacing",
    "name": "imageSpacing",
    "component": "number",
    "description": "In pixels",
    "step": 1,
    "focusRing": false
  },
  {
    "label": "Title",
    "name": "title",
    "component": "text",
    "focusRing": false
  },
  {
    "label": "Description",
    "name": "description",
    "component": "text",
    "focusRing": false
  },
  {
    "label": "Content Spacing",
    "name": "contentSpacing",
    "component": "number",
    "description": "In pixels",
    "step": 1,
    "focusRing": false
  },
  {
    "label": "Show Button",
    "name": "showButton",
    "component": "toggle",
    "description": "Show Button",
    "toggleLabels": false,
    "focusRing": false
  },
  {
    "label": "Button Label",
    "name": "buttonLabel",
    "component": "text",
    "focusRing": false
  },
  {
    "label": "Button Link",
    "name": "buttonLink",
    "component": "text",
    "focusRing": false
  },
  {
    "label": "Button Label Color",
    "name": "buttonLabelColor",
    "colorFormat": "hex",
    "widget": "sketch",
    "component": "color",
    "focusRing": false
  },
  {
    "label": "Button Background Color",
    "name": "buttonBackgroundColor",
    "colorFormat": "hex",
    "widget": "sketch",
    "component": "color",
    "focusRing": false
  },
  {
    "label": "Order",
    "name": "order",
    "component": "select",
    "options": [ 
      {
        value: 1,
        label: 'Normal',
      },
      {
        value: 2,
        label: 'Flipped',
      }
    ],
    "focusRing": false
  },
  {
    "label": "Background Color",
    "name": "backgroundColor",
    "colorFormat": "hex",
    "widget": "sketch",
    "component": "color",
    "focusRing": false
  },
  {
    "label": "Text Color",
    "name": "textColor",
    "colorFormat": "hex",
    "widget": "sketch",
    "component": "color",
    "focusRing": false
  }
]