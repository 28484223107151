import background from '../assets/images/signup.webp';

export default {
    "_template": "blockChangePassword",
    "background": background,
    "title": "Restablecer Contraseña",
    "description": "Ingrese su dirección de correo electrónico y una nueva contraseña.",
    "button": "Restablecer contraseña",
    "signinmessage": "Regresar a",
    "signin": "Iniciar sesión"
}