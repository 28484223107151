import React from 'react';
import { BlocksControls, InlineBlocks, InlineImage } from 'react-tinacms-inline'
import { useCMS } from 'tinacms'
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components'
import { linkBlock } from '../Link/Link'
import heart from '../../assets/svgs/heart.svg'
import cart from '../../assets/svgs/cart.svg'
import blueSquare from '../../assets/images/blue-square.png'

function Navbar({index, data}) {
  const cms = useCMS()
  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
        <Container 
            style={{
                ...(cms.enabled && {position: 'initial'}),
                backgroundImage: `url(${blueSquare})`
            }}
        >
            <Padding>
                <LogoContainer>
                    <InlineImage
                        name="image"
                        parse={media => {
                            return media.previewSrc
                        }}
                        previewSrc={formValues => {
                            return formValues;
                        }}
                    >
                        {
                            (props) => (
                                <img alt="navbar-logo" style={{height: 30}} src={props.src}/>
                            )
                        }
                    </InlineImage> 
                </LogoContainer>          
                <MenuContainer>
                    <InlineBlocks
                        name="links"
                        blocks={LINK_BLOCKS}
                        className={'navbar-menu'}
                    />
                </MenuContainer>
                <div style={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1, flexShrink: 1, columnGap: 18}}> 
                    <img alt="cart-icon" style={{height: 30}} src={cart}/>
                    <img alt="heart-icon" style={{height: 30}} src={heart}/>
                </div>
            </Padding>
        </Container>
    </BlocksControls>
  );
}

const LINK_BLOCKS = {
    link: linkBlock,
};

const Container = styled.div`
    width: 100%;
    height: 100px;
    padding: 0px 100px;
    z-index: 1;
    background-size: 100px 100%;
    background-position: left;
    background-repeat: no-repeat;
`

const LogoContainer = styled.div`
    height: 30px;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
`

const MenuContainer = styled.div`
    display: flex;
    align-items: center;
    & .navbar-menu{
        display: flex;
        align-items: center;
    }
`

const Padding = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;
`

export const NavbarTemplate = {
    "label": "Navbar",
    "key": "navbar",
    "fields": fields,
    "defaultItem": defaults
};
  
export const blockNavbar = {
    Component: Navbar,
    template: NavbarTemplate,
}