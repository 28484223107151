import background from '../assets/images/signup.webp';
import icon from '../assets/images/check.png';

export default {
    "_template": "blockForgotPasswordEmailSent",
    "background": background,
    "icon": icon,
    "title": "Correo enviado exitosamente",
    "description": "Porfavor revisa tu correo electrónico y da click en el link recibido para restablecer tu contraseña",
    "button": "Iniciar sesión",
    "signinmessage": "No recibí el correo",
    "signin": "Enviar nuevamente"
}