import React, { useContext } from 'react'
import { useForm, usePlugin, useCMS } from 'tinacms'
import { InlineForm } from 'react-tinacms-inline'
import NotFoundPage from '../blocks/NotFound'
import { PersistentContext } from '../context';

export default function PageNotFound(props) {
  const { saveContent, getItemData } = useContext(PersistentContext);

  const cms = useCMS();

  const clearItemData = async () => {
    await saveContent({'not-found': null});
    window.location.reload()
  }

  const setUp = () => {
    const data = getItemData('not-found');

    if(data.blocks){
      if(data.blocks[0]){
        if(data.blocks[0]._template === "blockNotFound"){
          clearItemData() 
        }
      }
    }
    
    const formConfig = {
      id: 'templateNotFound',
      fields: [
        {
            "label": "Background",
            "name": "background",
            "component": "image",
            "parse": media => {
              return media.previewSrc
            },
            "previewSrc": formValues => {
              return formValues;
            },
            "focusRing": false
        },
        {
          "label": "Main",
          "name": "main",
          "component": "text",
          "focusRing": false
        },
        {
          "label": "Title",
          "name": "title",
          "component": "text",
          "focusRing": false
        },
        {
          "label": "Subtitle",
          "name": "subtitle",
          "component": "text",
          "focusRing": false
        },
        {
          "label": "CTA",
          "name": "cta",
          "component": "text",
          "focusRing": false
        },
        {
            "label": "Button",
            "name": "button",
            "component": "text",
            "focusRing": false
        },
      ],
      onSubmit: async (data) => {
        await saveContent({'not-found': data});
        cms.alerts.success('Cambios guardados!');
      },
      initialValues: data,
    }
    return formConfig;
  }
  const formConfig = setUp();
  const [modifiedValues, form] = useForm(formConfig);
  usePlugin(form)

  return (
    <div className={`home ${cms.enabled ? `home-cms-enabled`: ``}`}>
      <InlineForm form={form}>
        <NotFoundPage data={modifiedValues}/>
      </InlineForm>
    </div>
  )
}