import React from 'react';
import { BlocksControls, InlineImage } from 'react-tinacms-inline';
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components';
import ContactForm from '../../../../components/forms/ContactForm'

function VideoForm({index, data, modifiedValues, forms}) {
    
    let form = null
  
    if (typeof index != 'undefined' && data && data.forms && forms && forms.length) {
        const index = forms.findIndex(item => item.id === data.forms)
        form = forms[index]
      }

    const getAngle = () => {
        let angle = data.backgroundGradientAngle
        if(angle){
            if(angle >= 0 && angle <= 360){
                return angle
            }
        }
        return 0
    }

    const isFlipped = () => {
        return data.order === 2 ? true : false
    }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
        <Container 
            style={{
                background: `linear-gradient(${getAngle()}deg, ${data.backgroundGradientFirstColor}, ${data.backgroundGradientSecondColor})`
            }}
        >
            {
                data.showImage ? (
                    <InlineImage
                        name="image"
                        className="video-form-inline-image"
                        parse={media => {
                            return media.previewSrc
                        }}
                        previewSrc={formValues => {
                            return formValues;
                        }}
                    >
                        {
                        (props) => (
                            <Image
                                alt="video-form-image"
                                style={{
                                    objectFit: 'contain',
                                    width: `${data.imageWidth}px`,
                                    height: `${data.imageHeight}px`,
                                    marginTop: 40
                                }} 
                                src={props.src}
                            />
                        )
                        }
                    </InlineImage> 
                ) :
                <ImageSpace />
            }  
            <Content style={{ flexDirection: isFlipped() ? 'row-reverse' : 'row'}}>
                {
                    !data.type || data.type === 'video' && (
                        <VideoWrapper>
                            <VideoContainer 
                                style={{
                                    maxWidth: '100%', 
                                    maxHeight: '100%',
                                    width: '100%',
                                    height: '100%'
                                }}
                            >
                                <video
                                    width="100%"
                                    height="100%"  
                                    controls 
                                    autoPlay={data.autoplay ? data.autoplay : false}
                                    muted
                                >
                                    <source src={data.videoUrl} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            </VideoContainer>
                        </VideoWrapper>
                    )
                }
                {
                    data.type === 'image' && (
                        <CoverImageContainer
                            style={{
                                maxWidth: `${data.coverImageWidth}px`,
                                maxHeight: `${data.coverImageHeight}px`,
                                width: `${data.coverImageWidth}px`,
                                height: `${data.coverImageHeight}px`
                            }}
                        >
                            <CoverImage
                                alt="image"
                                src={data.coverImage}
                                style={{
                                    margin: isFlipped() ? `0px ${data.coverImageSpacing}px 0px 0px` : `0px 0px 0px ${data.coverImageSpacing}px`,
                                    height: '100%',
                                    width: '100%'
                                }}
                            />
                        </CoverImageContainer>
                    )
                }
                <ContactFormContainer>
                {
                    form && (
                        <ContactForm
                            form={form}
                            button={'Submit'}
                            onSuccess={()=>alert('Contact Form Sent')}
                            labelType="placeholder"
                            mainTitle={true}
                            containerStyles={formStyles.container}
                            titleStyles={formStyles.title}
                            descriptionStyles={formStyles.description}
                            separatorStyles={formStyles.separator}
                            formStyles={formStyles.form}
                            submitButtonStyles={formStyles.submitButton}
                            inputStyles={formStyles.input}
                        />
                    )
                }
                </ContactFormContainer>
            </Content>
        </Container>
    </BlocksControls>
  );
}

const Container = styled.div`
    width: 100%;
    & .video-form-inline-image{
        padding: 0px 100px;
        @media (max-width: 980px){
            padding: 0px 30px;
        }
    }
`

const Image = styled.img`
    @media(max-width: 980px){
        width: 100% !important;
    }
`

const ImageSpace = styled.div`
    width: 100%;
    height: 100px;
    @media (max-width: 980px){
      height: 30px;
    }
`

const Content = styled.div`
    display: flex;
    justify-content: center; 
    margin-top: 20px;
    padding: 0px 100px 100px 100px;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 980px){
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 30px 30px 30px;
    }
`

const VideoWrapper = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`

const VideoContainer = styled.div`

`

const ContactFormContainer = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 50px;
    width: 370px;
    @media (max-width: 980px){
        margin-left: 0px;
        width: 100%;
        margin-top: 30px;
    }
`

const CoverImageContainer = styled.div`
  overflow: hidden;
  @media (max-width: 980px){
    width: 100% !important;
    height: unset !important;
  }
`

const CoverImage = styled.img`
  object-fit: contain;
  @media (max-width: 980px){
    margin: 0px !important;
    height: auto !important;
  }
`

const formStyles = {
    container: {
        backgroundColor: 'white',
        padding: 32,
        borderRadius: 5,
        width: '100%'
    },
    title: {
        textAlign: 'center',
        fontSize: 26,
        fontFamily: 'Arial',
        fontWeight: '700',
        color: '#000000'
    },
    description: {
        color: '#505050',
        fontWeight: '500',
        fontFamily: 'Arial',
        fontSize: 20,
        textAlign: 'center'
    },
    separator: {
        display: 'none'
    },
    form: {
        paddingTop: 0
    },
    submitButton: {
        width: '100%',
        fontFamily: 'Arial',
        fontSize: 20,
        height: 50,
    },
    input: {
        height: '50px',
        backgroundColor: 'white',
        border: 'grey 1px solid',
        fontSize: 16
    }
}

export const VideoFormTemplate = {
    "label": "VideoForm",
    "key": "videoForm",
    "fields": fields,
    "defaultItem": defaults
}

export const blockVideoForm = {
    Component: VideoForm,
    template: VideoFormTemplate,
}