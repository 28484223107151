import image from '../../assets/images/image.png';
import image2 from '../../assets/images/image2.png';
import image3 from '../../assets/images/image3.png';

export default {
    "_template": 'blockBoxes',
    "boxesList": [
        {
          "_template": "boxesItem",
          "image": image,
          "category": "News",
          "title": "Article name here",
          "description": "Understand how the different aspects of volatility work so you can be better prepared when it hits."
        },
        {
          "_template": "boxesItem",
          "image": image2,
          "category": "Blog",
          "title": "Article name here",
          "description": "Ut sem arcu, ultrices nec tincidunt et, lacinia non enim. Cras et molestie tellus, facilisis fringilla ligula."
        },
        {
          "_template": "boxesItem",
          "image": image3,
          "category": "Insight",
          "title": "Article name here",
          "description": "Ut sem arcu, ultrices nec tincidunt et, lacinia non enim. Cras et molestie tellus, facilisis fringilla ligula."
        }
    ]
}