import React from 'react';
import { InlineTextarea, BlocksControls } from 'react-tinacms-inline';
import defaults from './defaults'
import fields from './fields'
import styled from 'styled-components';
import MainButton from '../../components/buttons/MainButton'

function ContactForm({ index, data }) {
  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <Container>
          <Subtitle>
            <InlineTextarea name="subtitle" focusRing={false} />
          </Subtitle>
          <Title>
            <InlineTextarea name="title" focusRing={false} />
          </Title>
          <Form>
            <TextArea 
              placeholder="Message here..."
            />
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 18}}>
              <TextInput placeholder="Label name here"/>
              <TextInput style={{marginLeft: 30}} placeholder="Label name here"/>
              <MainButton
                label='SEND'
                styles={{
                  flexGrow: 0,
                  flexShrink: 0,
                  marginLeft: 20,
                  width: 180
                }}
              />
            </div>
          </Form>
      
      </Container>
    </BlocksControls>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 70px 150px;
  background-color: #f5f5f2;
  background-color: #f7faff;
`

const Subtitle = styled.div`
  color: #9dbe15;
  font-size: 16px;
  font-weight: 800;
  font-family: Arial;
  text-align: center;
`

const Title = styled.div`
  color: #222e3e;
  font-size: 36px;
  font-weight: 800;
  font-family: Arial;
  text-align: center;
`

const Form = styled.form`
  margin-top: 38px;
`

const TextArea = styled.textarea`
  background-color: white;
  height: 160px;
  width: 100%;
  resize: none;
  border: #e6e6e6 2px solid;
  padding: 20px 24px;
  font-size: 14px;
  color: #bdbdbd;
  font-weight: 700;
  font-family: Arial;
  &::-webkit-input-placeholder {
    font-size: 14px;
    color: #bdbdbd;
    font-weight: 700;
    font-family: Arial;
  }
  &::-moz-placeholder {
    font-size: 14px;
    color: #bdbdbd;
    font-weight: 700;
    font-family: Arial;
  }
  &:-ms-input-placeholder {
    font-size: 14px;
    color: #bdbdbd;
    font-weight: 700;
    font-family: Arial;
  }
  &:-moz-placeholder {
    font-size: 14px;
    color: #bdbdbd;
    font-weight: 700;
    font-family: Arial;
  }  
`

const TextInput = styled.input`
  flex-shrink: 1;
  flex-grow: 1;
  background-color: white;
  border: #e6e6e6 2px solid;
  font-size: 14px;
  height: 48px;
  padding: 0px 24px;
  color: #bdbdbd;
  font-weight: 700;
  font-family: Arial;
  &::-webkit-input-placeholder {
    font-size: 14px;
    color: #bdbdbd;
    font-weight: 700;
    font-family: Arial;
  }
  &::-moz-placeholder {
    font-size: 14px;
    color: #bdbdbd;
    font-weight: 700;
    font-family: Arial;
  }
  &:-ms-input-placeholder {
    font-size: 14px;
    color: #bdbdbd;
    font-weight: 700;
    font-family: Arial;
  }
  &:-moz-placeholder {
    font-size: 14px;
    color: #bdbdbd;
    font-weight: 700;
    font-family: Arial;
  }  
`

export const ContactFormTemplate = {
  "label": "Contact Form",
  "key": "contactForm",
  "fields": fields,
  "defaultItem": defaults
};

export const blockContactForm = {
  Component: ContactForm,
  template: ContactFormTemplate,
}