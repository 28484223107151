import background from '../assets/images/hero-2.webp';

export default {
    "_template": "blockHero2",
    "background": background,
    "title": "Comienza tu curso ahora",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam convallis placerat neque at fermentum. Suspendisse ultrices, massa eget elementum rutrum, nulla justo porttitor justo, eget mol.",
    "buttons": [
        {
            "_template": "button",
            "label": "Más detalles",
            "style": "primary",
            "link": "course-catalog"
        }
    ]
}