import React, { useEffect, useState, useContext } from 'react';
import { BlocksControls } from 'react-tinacms-inline';
import { useCMS } from 'tinacms'
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SessionContext } from '../context/SessionContext';
import { PersistentContext } from '../context';
import { getEvents } from '../helpers/courses';
import Section from '../components/Section';
import Courses from '../components/Courses';
import defaults from './Events.defaults'
import fields from './Events.fields'

function EventsBlock({ index, data }) {
  const cms = useCMS();
  const history = useHistory();

  const { keys, id, production } = useContext(PersistentContext);
  const { isAuthenticated, isSessionLoaded } = useContext(SessionContext);

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadEvents = async (loading = true) => {
    const siteId = production ? keys.id : id 
    loading === true && setLoading(true)
    const events = await getEvents(siteId, {filterName: 'show_on_home', filterValue: 'true'}, isAuthenticated)
    await setEvents(events.data)
    loading === true && setLoading(false)
  }

  useEffect(() => {
    if(!production || isSessionLoaded){
      loadEvents()
    }
  },[production, isSessionLoaded])

  const filter = (events) => {
    return events ? events.slice(0, data.eventsShown) : []
  }

  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      {
        loading ?
          <div style={{ backgroundColor: data.backgroundColor, height: 190, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <CircularProgress style={{ color: '#00A8D8' }}/>
          </div>
        :
          <>
            {
              cms.enabled && filter(events).length <= 0 ?
                <div style={{...styles.emptyState, backgroundColor: data.backgroundColor}}>
                  <div style={styles.emptyStateTitle}>Aún no tienes cursos</div>
                  <div>Agrega cursos en el panel de administrador</div>
                </div>
              :
                <Section
                  containerStyle={{ backgroundColor: data.backgroundColor, paddingBottom: 70 }}
                  titleStyle={{
                    color: data.titleColor
                  }}
                  title={data.title}
                  action={data.link}
                  onActionClick={() => history.push('/course-catalog')}
                >
                  <Courses
                    reload={()=>loadEvents(false)}
                    events={filter(events)}
                  />
                </Section>
            }
          </>
      }
    </BlocksControls>
  );
}

const styles = {
  emptyState: {
    padding: '60px 0px'
  },
  emptyStateTitle: {
    color: 'rgb(67, 67, 74)',
    fontSize: 18,
    fontWeight: 800,
    fontFamily: 'Muli'
  }
}

export const EventsTemplate = {
  "label": "Events",
  "key": "Events",
  "fields": fields,
  "defaultItem": defaults
};

export const blockEvents = {
  Component: EventsBlock,
  template: EventsTemplate,
}