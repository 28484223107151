import React from 'react'
import CategoriesDropdown from './CategoriesDropdown';
import NavigationLink from './NavigationLink'

function NavigationBar({data, containerStyle, listItemStyle, navigationLinkStyle}) {
  return (
    <ul style={{...styles.container, ...containerStyle}}>
      {
        data && data.map((item, index) => (
          <li key={`${item.label}-${index}`} style={listItemStyle}>
            {
              item.type === "categories" ? (
                  <CategoriesDropdown
                    label={item.label}
                    navigationLinkStyle={navigationLinkStyle}
                  />
              ) : 
              <NavigationLink
                styles={navigationLinkStyle} 
                label={item.label} 
                href={ item.type === "internalLink" ? item.internalLink : item.type === "externalLink" ? item.externalLink : item.link } 
              />
            }
          </li>
        ))
      }
    </ul>
  );
}

const styles = {
  container: {
    listStyle: 'none',
    display: 'flex',
    padding: '0px',
    margin: 'auto'
  }
}

export default NavigationBar;