import image from '../assets/images/logo.webp';

export default {
    "_template": "blockAssociated",
    "title": "Conoce nuestras áreas",
    "associatedList": [
        {
          "_template": "associatedItem",
          "image": image,
          "title": 'Associated Item'
        },
        {
            "_template": "associatedItem",
            "image": image,
            "title": 'Associated Item'
        },
        {
            "_template": "associatedItem",
            "image": image,
            "title": 'Associated Item'
        }
    ],
    "imageWidth": 379,
    "imageHeight": 85,
    "titleColor": "#000000",
    "backgroundColor": "#ffffff"
}