import React from 'react';
import '../../App.css';

function SubmitButton(props) {

  const styles = {
    button: {
      width: '100%',
      background: props.loading ? 'gainsboro' : '#016cff',
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      border: 'none',
      borderRadius: 4,
      fontWeight: '600',
      fontFamily: 'Muli',
      fontSize: 16
    },
  }
  
  return (
    <button 
      disabled={props.loading} 
      style={{...styles.button, ...props.styles}} 
      onClick={()=>props.onPress()}  
      className="touchable-opacity"
    >
      {props.label ? props.label : `Iniciar Sesión`}
    </button>
  );
}

export default SubmitButton;