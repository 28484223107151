export default [
    {
      "label": "Title",
      "name": "title",
      "component": "text",
      "focusRing": false
    },
    {
        "label": "Title Color",
        "name": "titleColor",
        "colorFormat": "hex",
        "widget": "sketch",
        "component": "color",
        "focusRing": false
    },
    {
        label: "Content",
        name: "content",
        component: "markdown",
        parse(value) {
          return value || ""
        },
    },
    {
        "label": "Content Color",
        "name": "contentColor",
        "colorFormat": "hex",
        "widget": "sketch",
        "component": "color",
        "focusRing": false
    },
    {
        "label": "Content Align",
        "name": "contentAlign",
        "component": "select",
        "options": [ 
          {
            value: "left",
            label: "Left",
          },
          {
            value: "center",
            label: "Center",
          },
          {
            value: "right",
            label: "Right",
          },
        ],
        "focusRing": false
    },
    {
        "label": "Text Align",
        "name": "textAlign",
        "component": "select",
        "options": [ 
          {
            value: "left",
            label: "Left",
          },
          {
            value: "center",
            label: "Center",
          },
          {
            value: "right",
            label: "Right",
          },
        ],
        "focusRing": false
    },
    {
      "label": "Background Color",
      "name": "backgroundColor",
      "colorFormat": "hex",
      "widget": "sketch",
      "component": "color",
      "focusRing": false
    }
  ]