import React from 'react';
import CourseStat from './CourseStat';
import { formatDuration } from '../helpers/StatisticalFunctions';

function CourseStats(props) {

  const styles = {
    container: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      marginTop: 20
    }
  }

  const renderDuration = () => {
    return props.duration ? props.duration === 0 ? false : true : false
  }

  return (
    <div style={styles.container}>
        {props.sales && (<CourseStat icon="sales" value={props.sales} />)}
        {props.likes && (<CourseStat icon="like" value={props.likes}/>)}
        { renderDuration() && <CourseStat icon="time" value={formatDuration(props.duration)}/> }
    </div>
  );
}

export default CourseStats;
