import { boxesItemBlock } from '../BoxesItem/BoxesItem';

export default [
    {
      "label": "Title",
      "name": "title",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Subtitle",
      "name": "subtitle",
      "component": "text",
      "focusRing": false
    },
    {
      "label": "Background",
      "name": "background",
      "component": "image",
      "parse": media => {
        return media.previewSrc
      },
      "previewSrc": formValues => {
        return formValues;
      },
      "focusRing": false
    },
    {
      label: 'Boxes List',
      name: 'boxesList',
      component: 'blocks',
      templates: {
        boxesItem: boxesItemBlock.template,
      }
    }

  ]